import { connect } from 'react-redux';
import { AddNewVideoPopup } from '../components/AddNewVideoPopup';
import { IState } from '../../../../store/types';
import { isShowNewVideoPopup } from '../../../../store/general/selectors';
import { IAddNewVideoPopupEvents, IAddNewVideoPopupFields } from '../components/types';
import { AddNewVideoPopupEvents } from './events';
import { getGroupTargetLanguage, getLibraryGroup } from '../../../../store/models/selectors';

const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): IAddNewVideoPopupFields => {

    let targetLang = '';
		const group = getLibraryGroup(state);
		if (group) {
			targetLang = getGroupTargetLanguage(state, group)?.name || '';
		}

    return {
      isOpen: isShowNewVideoPopup(state),
      targetLang,
    }
  };
}

const mapDispatchToProps = (
  dispatch: any
): IAddNewVideoPopupEvents => ({
    onValidate: (url) => dispatch(AddNewVideoPopupEvents.onValidate(url)),
    onCreate: (url) => dispatch(AddNewVideoPopupEvents.onCreate(url)),
    onClose: () => dispatch(AddNewVideoPopupEvents.onClose()),
    onCheckInstalled: () => dispatch(AddNewVideoPopupEvents.onCheckInstalled()),
  }
);

export const AddNewVideoPopupHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddNewVideoPopup);

AddNewVideoPopupHOC.displayName = 'AddNewVideoPopupHOC';