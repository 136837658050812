import { API_URL } from '../../constants';
import { TAsyncThunk } from '../../../spa/store/types';
import { postRequest } from '../../utils/requestService';

const VIDEO_UPDATE_INFO_URL = API_URL + '/video/update-info';

export const updateVideoInfo = (
  videoId: string,
  langs: string
): TAsyncThunk => async(
  dispatch
) => {
  const body = { videoId, langs };
  return await dispatch(postRequest(VIDEO_UPDATE_INFO_URL, body));
};