import React from 'react';
import { IPhraseDetailsTabPromptFormProps } from './types';
import { Box, Button, Stack, SxProps, TextField } from '@mui/material';
import { theme } from '../../../../../../common/styles/theme';
import { Text } from '../../../../../../common/components/Text/Text';
import { PhraseDetailsTabPromptFormVar } from './PhraseDetailsTabPromptFormVar';
import { IPhraseDetailsCustomPromptVar } from '../../../../../effects/phrase-details/phraseDetailsCustomPromptVars';
import { PhraseDetailsPhrasePrepareHelper } from '../../helpers/phraseDetailsPhrasePrepareHelper';
import { Paper } from "../../../../../../common/components/Paper/Paper";
import { PhraseDetailsTabPromptTestHOC } from '../../PhraseDetailsTabPromptTest/hocs/PhraseDetailsTabPromptTestHOC';
import { PHRASE_SAVE_BTN_LABEL, PHRASE_SAVE_PROCESSING_LABEL, PHRASE_RESELECT, PHRASE_MODIFY_CONTEXT } from './wordings';
import { PhraseSelectPopupHOC } from '../../../PhraseSelectPopup/hocs/PhraseSelectPopupHOC';

export const PhraseDetailsTabPromptForm: React.FC<IPhraseDetailsTabPromptFormProps> = ({
  id,
  show,
  title,
  prompt,
  description,
  processing,
  showSaveConfirm,
  vars,
  phrase,
  context,
  isNoPhrase,
  onSave,
  onValidate,
  onPromptChange,
  onShowPhraseSelectPopup,
  onShow,
  onClickContext,
}) => {

  if (!show) return null;

  const [inputTitle, setInputTitle] = React.useState<string>('');
  const [inputPrompt, setInputPrompt] = React.useState<string>('');
  const [inputDesc, setInputDesc] = React.useState<string>('');
  const [titleError, setTitleError] = React.useState<string>('');
  const [promptErrors, setPromptErrors] = React.useState<string[] | null>(null);
  const [phraseContext, setPhraseContext] = React.useState<string>('');

  const promptInputRef = React.useRef<HTMLDivElement>(null);
  const promptDescRef = React.useRef<HTMLDivElement>(null);


  React.useEffect(() => {
    onShow();
  }, [id])

  React.useEffect(() => {
    setInputTitle(title);
  }, [title])

  React.useEffect(() => {
    setInputPrompt(prompt);
  }, [prompt])

  React.useEffect(() => {
    setInputDesc(description);
  }, [description])

  React.useEffect(() => {
    onPromptChange(inputPrompt);
  }, [inputPrompt])

  React.useEffect(() => {
    const input = context ? context : phrase;
    const _phraseContextHtml = PhraseDetailsPhrasePrepareHelper.format({
      prompt: input,
      phrase, context,
      phraseCssClass: 'phrase',
      contextCssClass: 'context'
    });
    setPhraseContext(_phraseContextHtml);
  }, [phrase, context])



  const onTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputTitle(e.target.value);
  }
  const handlePromptChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputPrompt(e.target.value);
  }

  const handleDescChange =  (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputDesc(e.target.value);
  }

  const handleSave = () => {
    if (validate()) {
      onSave(inputTitle, inputPrompt, inputDesc);
    }
  }

  const validate = () => {
    let result = true;
    if (!inputTitle.trim().length) {
      setTitleError('Button name is required');
      result = false;
    } else {
      setTitleError('');
    }
    const validateResult = onValidate(inputPrompt);
    if (!validateResult.success) {
      setPromptErrors(validateResult.errors || ['Unknown error']);
      result = false;
    } else {
      setPromptErrors(null);
    }
    return result;
  }

  const handleVarClick = (variable: IPhraseDetailsCustomPromptVar) => {
    const input = promptInputRef.current && promptInputRef.current.querySelector('textarea');
    if (input) {
      let varName = variable.name;
      if (variable.wrapQuotes) {
        varName = '"' + varName + '"';
      }
      input.focus();
      const el = document.activeElement;
      //@ts-ignore
      const selStart = el.selectionStart;
      if (selStart) {
        setInputPrompt( inputPrompt.substr(0, selStart) + varName + inputPrompt.substr(selStart) );
        setTimeout(() => {
          const input = promptInputRef.current && promptInputRef.current.querySelector('textarea');
          if (input) {
            input.focus();
            const cursorPos = selStart + varName.length;
            //@ts-ignore
            input.setSelectionRange(cursorPos, cursorPos);
          }
        })
      } else {
        setInputPrompt(inputPrompt + varName);
      }
    }
  }

  const inputStyle = {
    '.MuiInputBase-input': {
      height: theme.spacing(3),
      fontSize: theme.typography.subtitle2
    },
    '.MuiOutlinedInput-notchedOutline': {
      border: 'none'
    }
  };

  const textareaStyle = {
    '.MuiInputBase-root': {
      padding: 0
    },
    '.MuiInputBase-input': {
      fontSize: theme.typography.subtitle2,
      overflow: 'auto !important',
      height: theme.spacing(25.75) + '!important',
    },
    '.MuiOutlinedInput-notchedOutline': { border: 'none' }
  };

  const paperStyle = {
    flex: `0 1 50%`,
    p: theme.spacing(2),
  };

  const phraseStyle = {
    mt: theme.spacing(1),
    fontSize: theme.typography.subtitle2,

    'span': {
      p: '4px 0',
      lineHeight: theme.spacing(3.25),
    },
    '.context': {
      backgroundColor: '#CEE2FD',
    },
    '.phrase': {
      bgcolor: '#dcb8ff',
    }
  };

  const buttonStyle: SxProps = {
		height: theme.spacing(4),
		p: theme.spacing(1),
		color: theme.palette.primary[400],
		bgcolor: theme.palette.primary[100],

		'&:hover': {
			bgcolor: theme.palette.primary[100],
		}
	};

  return (
    <Stack gap={theme.spacing(2)} sx={{
      flex: `1 1 100%`,
    }}>
      <Stack spacing={theme.spacing(2)} direction={'row'}>
        <Paper sx={paperStyle}>
          <Stack spacing={theme.spacing(3)} sx={{ maxHeight: '100%' }}>
            <Stack gap={theme.spacing(2)}>

              {showSaveConfirm &&
              <Text variant={'h6'} fontWeight={600} sx={{ color: 'green' }}>
                Prompt saved
              </Text>
              }

              <Stack gap={theme.spacing()}>
                <Text variant={'body1'} sx={{ color: theme.palette.error.main }}>
                  {titleError}
                </Text>
                <Text variant={'body1'} fontWeight={600}>Button name:</Text>
                <TextField
                    fullWidth
                    variant={'filled'}
                    autoFocus
                    value={inputTitle}
                    onChange={onTitleChange}
                    multiline={false}
                    sx={inputStyle}
                />
              </Stack>

              <Box>
                <Stack
                  direction={'row'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                  gap={theme.spacing(1)}
                >
                  <Text fontWeight={600}>Phrase and context</Text>

                  <Stack
                    direction={'row'}
                    justifyContent={'flex-end'}
                    gap={theme.spacing(.75)}
                  >
                    <Button
                      onClick={onShowPhraseSelectPopup}
                      variant={'contained'}
                      sx={buttonStyle}
                    >
                      {PHRASE_RESELECT}
                    </Button>
                    {!isNoPhrase &&
                      <Button
                        onClick={onClickContext}
                        variant={'contained'}
                        sx={{...buttonStyle,
                          color: theme.palette.info[600],
                          bgcolor: theme.palette.info[500],

                          '&:hover': {
                            bgcolor: theme.palette.info[500],
                          }
                        }}
                      >
                        {PHRASE_MODIFY_CONTEXT}
                      </Button>
                    }
                  </Stack>
                  
                </Stack>
                <Text
                    sx={phraseStyle}
                    dangerouslySetInnerHTML={{ __html: phraseContext}}
                />
              </Box>

              {promptErrors &&
                  <Stack spacing={theme.spacing(0.25)}>
                    {promptErrors.map(error => {
                      return (<Text
                        key={error}
                        variant={'body1'}
                        sx={{ color: theme.palette.error.main }}>{error}</Text>)
                    })}
                  </Stack>
              }
              <Stack spacing={theme.spacing()}>
                <Text variant={'body1'} fontWeight={600}>Prompt:</Text>
                <TextField
                    className={'prompt-input'}
                    ref={promptInputRef}
                    variant={'filled'}
                    onChange={handlePromptChange}
                    value={inputPrompt}
                    fullWidth
                    multiline
                    sx={textareaStyle}
                />
              </Stack>

              <Stack spacing={theme.spacing()}>
                <Stack direction={'row'} gap={theme.spacing()}>
                  <Text fontWeight={600} variant={'body1'}>Variables</Text>
                  <Text variant={'body1'} sx={{ opacity: 0.5 }}>(click any buttons below to add them to the prompt)</Text>
                </Stack>
                <Stack direction={'row'} sx={{ gap: theme.spacing() }} flexWrap={'wrap'}>
                  {vars.map(variable => {
                    return <PhraseDetailsTabPromptFormVar
                        key={variable.name}
                        color={variable.color}
                        name={variable.name}
                        onClick={() => handleVarClick(variable)}
                    />
                  })}
                </Stack>
              </Stack>

              <Stack spacing={theme.spacing()}>
                <Text variant={'body1'} fontWeight={600}>Description:</Text>
                <TextField
                  className={'prompt-input'}
                  ref={promptDescRef}
                  variant={'filled'}
                  onChange={handleDescChange}
                  value={inputDesc}
                  fullWidth
                  multiline
                  sx={textareaStyle}
                />
              </Stack>

            </Stack>

          </Stack>
        </Paper>
        <PhraseDetailsTabPromptTestHOC />
      </Stack>

      <Button
          onClick={handleSave}
          disabled={processing}
          fullWidth
          variant={'contained'}
          sx={{ height: theme.spacing(6) }}
      >{processing ? PHRASE_SAVE_PROCESSING_LABEL : PHRASE_SAVE_BTN_LABEL}</Button>

      <Box sx={{
        height: '1px', // to add empty space after button with scroll
      }}>
        <br/>
      </Box>

      <PhraseSelectPopupHOC />
    </Stack>
  );



}

