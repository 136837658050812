import { IState } from '../../../../store/types';
import { connect } from 'react-redux';
import { ProfilesList } from '../components/ProfilesList';
import { IProfilesListEvents, IProfilesListFields } from '../components/types';
import { ProfilesSelectors } from '../../../../store/profiles/selectors';
import { ProfilesListEvents } from './events';

const mapStateToProps = (
	dispatch: any
) => {
	return (
		state: IState
	): IProfilesListFields => {
		const profiles = ProfilesSelectors.getList(state);

		return {
			profiles,
		}
	};
}

const mapDispatchToProps = (
	dispatch: any
): IProfilesListEvents => ({
	onClickProfile: (nick) => dispatch(ProfilesListEvents.onClickProfile(nick)),
});

export const ProfilesListHOC = connect(
	mapStateToProps,
	mapDispatchToProps
)(ProfilesList);

ProfilesListHOC.displayName = 'ProfilesListHOC';
