import React from 'react';
import { IPublicProfileProps, TPublicProfile } from './types';
import { theme } from '../../../../../../common/styles/theme';
import { Box, CardMedia, CircularProgress, IconButton, Stack } from '@mui/material';
import { Paper } from '../../../../../../common/components/Paper/Paper';
import { DeviceContext } from '../../../../../../common/contexts/DeviceContext';
import { LogoLabel } from '../../../../../../../icons/LogoLabel';
import background from '../../../../install/Login/components/imgs/background.svg';
import { Text } from '../../../../../../common/components/Text/Text';

export const PublicProfile: React.FC<IPublicProfileProps> = ({
	userId,
	name,
	picture,
	description,
	onClickLogo,
	onLoad,
}) => {
	const { isMobile } = React.useContext(DeviceContext);

	const className = 'public-profile';

	const [progress, setProgress] = React.useState(true);
	const [profile, setProfile] = React.useState<TPublicProfile>({
		name,
		picture,
		description,
	});
	React.useEffect(() => {
		onLoad(userId)
			.then((ret) => {
				setProfile(ret);
			})
			.finally(() => {
				setProgress(false);
			});
	}, [])

	return <Stack
		className={`
			${className}
		`}
		sx={{
			width: 1,
			height: 1,
			alignItems: 'center',
			bgcolor: theme.palette.secondary.main,
			backgroundImage: `url(${background})`,
			backgroundSize: 'cover',
		}}
	>
		<Paper
			className={className}
			sx={{
				position: 'relative',
				zIndex: 100,
				flex: `0 0 ${theme.spacing(8)}`,
				width: 1,
				py: 1.5,
				px: 2,
				borderRadius: 0,
				boxShadow: theme.shadows[8],

				'.mobile &': {
					p: 1,
				}
			}}
		>
			<Stack
				direction={'row'}
				alignItems={'center'}
				gap={theme.spacing(2)}
				sx={{
					width: 1,
					height: 1,

					'.top-bar-panel': {
						width: 'auto',

						'&:before': {
							display: 'none',
						},
					},

					'.mobile &': {
						gap: 1,
						justifyContent: 'space-between',
					}
				}}
			>
				<IconButton
					sx={{ p: 0, }} 
					onClick={onClickLogo}
				>
					<LogoLabel sx={{ height: theme.spacing(4), width: 'auto' }} />
				</IconButton>

			</Stack>
		</Paper>

		<Stack
			sx={{
				flex: `1 1 100%`,
				minHeight: 0,
				width: 1,
				alignItems: 'center',
				p: 2,
	
				overflow: 'auto',
			}}
		>
			{progress
				? <Stack
					sx={{
						width: 1,
						height: 1,
					}}
					alignItems={'center'}
					justifyContent={'center'}
				>
					<CircularProgress />
				</Stack>
				: <Paper
					sx={{
						width: 1,
						maxWidth: theme.spacing(90),
						p: 3,
						fontSize: theme.typography.subtitle2,
						borderRadius: theme.spacing(1.25),
						boxShadow: theme.shadows[2],
					}}
				>
					<Stack
						flexDirection={'row'}
						gap={2}
						alignItems={'center'}
					>
						<CardMedia
							component='img'
							image={profile.picture}
							alt={profile.name}
							sx={{
								flex: `0 0 ${theme.spacing(8)}`,
								height: theme.spacing(8),
								borderRadius: '50%',
							}}
						/>
						<Text
							sx={{
								fontSize: theme.typography.h3,
								fontWeight: 600,
							}}
						>
							{profile.name}
						</Text>
					</Stack>

					{profile.description &&
						<Box
							dangerouslySetInnerHTML={{__html: profile.description}}
							sx={{
								wordBreak: 'break-word',

								'figure, img': {
									maxWidth: '100% !important',
									maxHeight: '70vh !important',
								},
								'iframe': {
									maxWidth: '100% !important',
									maxHeight: '33vh !important',
								},
							}}
						/>
					}
				</Paper>
			}
		</Stack>
	</Stack>
};
