import { IPhraseSelectResult } from '../../effects/phrase/utils/phrase-select-preparator';

export enum ECaptionsSelectionPopupActionButton {
  SAVE_PHRASE,
  SAVE_CONTEXT
}

export type TCurrentSelection = {
  wordPhraseId?: number,
  contextPhraseId?: number,
  captionIndex?: number,
  startSelectCaptionIndex?: number,
  endSelectCaptionIndex?: number,
  startTime?: number,
  endTime?: number
}

export enum ESavePhraseModePopupType {
  PHRASE = 0, NOTE = 1
}

export interface ISavePhraseModePopup {
  show: boolean;
  type:ESavePhraseModePopupType;
  selectResult?: IPhraseSelectResult;
  noteText?: string;
  clickedCaptionIndex?: number
}

export interface ICaptionsSelectionPopupState {
  show: boolean;
  previewPhraseId: number;
  lastSelectText?: string;
  selectResult?: IPhraseSelectResult;
  lastSelectResult?: IPhraseSelectResult;
  actionButton: ECaptionsSelectionPopupActionButton | null;
  selection?: Selection;
  autoSpeechText: boolean;
  playingSelectText: boolean;
  currentSelection: TCurrentSelection;
  savePhraseModePopup: ISavePhraseModePopup
}

export const SET_CAPTIONS_SELECTION_POPUP_SHOW = 'CAPTIONS_SELECTION_POPUP/SET_CAPTIONS_SELECTION_POPUP_SHOW';
export const SET_CAPTIONS_SELECTION_POPUP_SEL_PHRASE_ID = 'CAPTIONS_SELECTION_POPUP/SET_CAPTIONS_SELECTION_POPUP_SEL_PHRASE_ID';
export const SET_CAPTIONS_SELECTION_POPUP_PREVIEW_PHRASE_ID = 'CAPTIONS_SELECTION_POPUP/SET_CAPTIONS_SELECTION_POPUP_PREVIEW_PHRASE_ID';
export const SET_CAPTIONS_SELECTION_POPUP_LAST_SELECT_TEXT = 'CAPTIONS_SELECTION_POPUP/SET_CAPTIONS_SELECTION_POPUP_LAST_SELECT_TEXT';
export const SET_CAPTIONS_SELECTION_POPUP_SET_SELECT_RESULT = 'CAPTIONS_SELECTION_POPUP/SET_CAPTIONS_SELECTION_POPUP_SET_SELECT_RESULT';
export const SET_CAPTIONS_SELECTION_POPUP_SET_ACTION_BUTTON = 'CAPTIONS_SELECTION_POPUP/SET_CAPTIONS_SELECTION_POPUP_SET_ACTION_BUTTON';
export const SET_CAPTIONS_SELECTION_POPUP_SET_SELECTION = 'CAPTIONS_SELECTION_POPUP/SET_CAPTIONS_SELECTION_POPUP_SET_SELECTION';
export const SET_CAPTIONS_SELECTION_POPUP_SET_AUTO_SPEECH_TEST = 'CAPTIONS_SELECTION_POPUP/SET_CAPTIONS_SELECTION_POPUP_SET_AUTO_SPEECH_TEST';
export const SET_CAPTIONS_SELECTION_POPUP_SET_PLAYING_SELECT_TEXT = 'CAPTIONS_SELECTION_POPUP/SET_CAPTIONS_SELECTION_POPUP_SET_PLAYING_SELECT_TEXT';
export const UPDATE_CAPTIONS_SELECTION_POPUP = 'CAPTIONS_SELECTION_POPUP/UPDATE_CAPTIONS_SELECTION_POPUP';
export const SET_CAPTIONS_SELECTION_POPUP_CURRENT_SELECTION = 'CAPTIONS_SELECTION_POPUP/SET_CAPTIONS_SELECTION_POPUP_CURRENT_SELECTION';
export const SET_CAPTIONS_SELECTION_POPUP_SAVE_PHRASE_MODE = 'CAPTIONS_SELECTION_POPUP/SET_CAPTIONS_SELECTION_POPUP_SAVE_PHRASE_MODE';

export interface ISetCaptionsSelectionPopupsShow {
  type: typeof SET_CAPTIONS_SELECTION_POPUP_SHOW;
  show: boolean;
}

export interface ISetCaptionsSelectionPopupsSelPhraseId {
  type: typeof SET_CAPTIONS_SELECTION_POPUP_SEL_PHRASE_ID;
  selelectedPhraseId: number;
}

export interface ISetCaptionsSelectionPopupsPreviewPhraseId {
  type: typeof SET_CAPTIONS_SELECTION_POPUP_PREVIEW_PHRASE_ID;
  previewPhraseId: number
}

export interface ISetCaptionsSelectionPopupsLastSelectText {
  type: typeof SET_CAPTIONS_SELECTION_POPUP_LAST_SELECT_TEXT;
  lastSelectText?: string
}

export interface ISetCaptionsSelectionPopupsSetSelectResult {
  type: typeof SET_CAPTIONS_SELECTION_POPUP_SET_SELECT_RESULT;
  selectResult: IPhraseSelectResult | null | undefined;
}

export interface ISetCaptionsSelectionPopupsSetActionButton {
  type: typeof SET_CAPTIONS_SELECTION_POPUP_SET_ACTION_BUTTON;
  actionButton: ECaptionsSelectionPopupActionButton | null;
}

export interface ISetCaptionsSelectionPopupsSetSelection {
  type: typeof SET_CAPTIONS_SELECTION_POPUP_SET_SELECTION;
  selection?: Selection
}

export interface ISetCaptionsSelectionPopupsSetAutoSpeechText {
  type: typeof SET_CAPTIONS_SELECTION_POPUP_SET_AUTO_SPEECH_TEST;
  autoSpeechText: boolean
}

export interface ISetCaptionsSelectionPopupsSetPlayingSelectText {
  type: typeof SET_CAPTIONS_SELECTION_POPUP_SET_PLAYING_SELECT_TEXT;
  playingSelectText: boolean
}

export interface IUpdateCaptionsSelectionPopupAction {
  type: typeof UPDATE_CAPTIONS_SELECTION_POPUP;
  value: Partial<ICaptionsSelectionPopupState>;
}

export interface ICurrentSelectionCaptionsSelectionPopupAction {
  type: typeof SET_CAPTIONS_SELECTION_POPUP_CURRENT_SELECTION;
  value: Partial<TCurrentSelection>;
}

export interface ISetISavePhraseModePopupAction {
  type: typeof SET_CAPTIONS_SELECTION_POPUP_SAVE_PHRASE_MODE;
  value: Partial<ISavePhraseModePopup>;
}




export type ISetCaptionsSelectionPopupActions =
  | ISetCaptionsSelectionPopupsShow
  | ISetCaptionsSelectionPopupsSelPhraseId
  | ISetCaptionsSelectionPopupsPreviewPhraseId
| ISetCaptionsSelectionPopupsLastSelectText
| ISetCaptionsSelectionPopupsSetSelectResult
| ISetCaptionsSelectionPopupsSetActionButton
| ISetCaptionsSelectionPopupsSetSelection
| ISetCaptionsSelectionPopupsSetAutoSpeechText
| ISetCaptionsSelectionPopupsSetPlayingSelectText
| IUpdateCaptionsSelectionPopupAction
| ICurrentSelectionCaptionsSelectionPopupAction
| ISetISavePhraseModePopupAction

