import { AppThunk } from '../../../../../store/types';
import { FEEDBACk_URL } from '../../../../../../common/constants';
import { MainLayoutEffects } from '../../../../../effects/mainLayoutEffects';
import { getMainViewMode } from '../../../../../store/general/selectors';
import { EMainViewMode } from '../../../../../store/general/types';
import { PhraseDetailsActions } from '../../../../../store/phrase-details/actions';
import { getState } from '../../../../../store';
import { ContentLibSelectors } from '../../../../../store/contentLib/selectors';
import { EContentLibViewMode } from '../../../../../store/contentLib/types';
import { ContentLibActions } from '../../../../../store/contentLib/actions';

export class TopBarPanelEvents {

  public static onClickBack (isMobile?: boolean): AppThunk {
    return (
      dispatch
    ) => {
      const state = getState();
      if (getMainViewMode(state) === EMainViewMode.CONTENT_LIBRARY && ContentLibSelectors.getViewMode(state) === EContentLibViewMode.SELECTED_GROUP) {
        dispatch(ContentLibActions.setViewMode(EContentLibViewMode.GROUP_LIST_WITH_VIDEOS));
      } else {
        dispatch(MainLayoutEffects.backToDashboard());
      }

      if (isMobile) dispatch(PhraseDetailsActions.disActivate());
    }
  }

  public static onClickLogo (): AppThunk {
    return (
      dispatch
    ) => {
      dispatch(MainLayoutEffects.backToDashboard());
    }
  }

  public static onLeaveFeedback(
  ): AppThunk {
    return (
      dispatch
    ) => {
      window.open(FEEDBACk_URL, '_blank');
    }
  }

  public static onHideLeftPanelToggle(): AppThunk {
    return (
      dispatch
    ) => {
      MainLayoutEffects.showLeftPanel(false)
    }
  }

  public static onLayoutToggle(): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      MainLayoutEffects.toggleLayoutType();
    }
  }

  public static onClickHelp (): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      dispatch(MainLayoutEffects.showHelp());
    }
  }


}