import { Box, Button, styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import Stack from '@mui/material/Stack';
import React from 'react';
import { IProfileGroupInfoProps } from './types';
import { theme } from '../../../../../../common/styles/theme';
import { CopyNote } from '../../../../../../../icons/CopyNote';
import LangFlag from '../../../LangFlag/LangFlag';
import { ProfileGroupImg } from '../../ProfileGroupImg/components/ProfileGroupImg';
import { ProfileVideo } from '../../ProfileVideo/components/ProfileVideo';

export function ProfileGroupInfo({
	id,
	title,
	description,
	nativeLang,
	targetLang,
	videos,
	onCopyGroup,
}: IProfileGroupInfoProps) {

	const className = 'profile-group-info';

	const [isShowVideos, setShowVideos] = React.useState(false);
	const onClick = () => {
		setShowVideos(!isShowVideos);
	}

	const [isLongTitle, setLongTitle] = React.useState(false);
	const ref1 = React.useRef(null);
	const ref2 = React.useRef(null);

	const [copyProcess, setCopyProcess] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (!isLongTitle && ref1.current && ref2.current) {
			//@ts-ignore
			if (ref2.current.offsetHeight > ref1.current.offsetHeight) {
				setLongTitle(true);
			}
		}
    }, [title]);

	const titleElement = <Box
		sx={{
			display: '-webkit-box',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			'-webkit-line-clamp': '2',
			'-webkit-box-orient': 'vertical',
			maxHeight: '2.6em',
			lineHeight: `1.3 !important`,
			
			maxWidth: '80%',
			fontSize: theme.typography.h5,
			fontWeight: 600,
			cursor: 'pointer',
		}}
		ref={ref1}
		onClick={onClick}
	>
		<span
			ref={ref2}
		>
			{title}
		</span>
	</Box>

	const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
		<Tooltip {...props} classes={{ popper: className }} />
	))({
		[`& .${tooltipClasses.tooltip}`]: {
			maxWidth: '80vw',
		},
	});

	const handleCopy = async () => {
		setCopyProcess(true);
		try {
			await onCopyGroup();
		} finally {
			setCopyProcess(false);
		}
	}

	return (
		<Stack
			gap={3}
		>
			<Stack
				direction={'row'}
				gap={3}
				sx={{
					alignItems: 'center',
					py: 2,

					'& + &': {
						borderTop: `2px solid ${theme.palette.grey[200]}`
					},

					'.profile-group-info__vertical': {
						gap: 2,
					},

					[theme.breakpoints.down('sm')]: {
						flexDirection: 'column',

						[`.profile-group__img`]: {
							flexBasis: 0,
						},
					},
				}}
			>

				<ProfileGroupImg
					videos={videos}
				/>
				
				<Stack
					className={className}
					direction={'row'}
					gap={2}
					sx={{
						justifyContent: 'space-between',
						width: 1,

						[theme.breakpoints.down('sm')]: {
							flexDirection: 'column',
						},
					}}
				>
					<Stack
						className={`${className}__vertical`}
						gap={theme.spacing(1)}
						sx={{
							flex: `1 1 100%`,
						}}
					>
						<Stack
							direction={'row'}
							gap={1}
						>
							{isLongTitle
								? <NoMaxWidthTooltip
									title={title}
									enterDelay={1000}
								>
									{titleElement}	
								</NoMaxWidthTooltip>
								: titleElement
							}
						</Stack>

						{description &&
							<Box
								sx={{
									display: '-webkit-box',
									overflow: 'hidden',
									textOverflow: 'ellipsis',
									'-webkit-line-clamp': '5',
									'-webkit-box-orient': 'vertical',
									maxHeight: '7.7em',
									lineHeight: `1.3 !important`,
									fontSize: theme.typography.subtitle2,
								}}
							>
								{description}
							</Box>
						}
						<Stack
							direction={'row'}
							gap={3}
							rowGap={1}
							flexWrap={'wrap'}
						>
							<Stack
								direction={'row'}
								gap={1}
								sx={{
									height: theme.spacing(4),
									'.lang-flag__code': {
										color: theme.palette.grey[500],
									},
								}}
							>
								<LangFlag code={targetLang} showCode={true} />
								<LangFlag code={nativeLang} showCode={true} />
							</Stack>


						</Stack>
					</Stack>

					<Button
						variant={'contained'}
						disabled={copyProcess}
						startIcon={
							<CopyNote sx={{ width: theme.spacing(2.5), height: theme.spacing(2.5) }} />
						}
						onClick={handleCopy}
					>
						Copy playlist
					</Button>
				</Stack>

			</Stack>

			{isShowVideos && videos.length &&
				<Stack
					direction={'row'}
					sx={{
						position: 'relative',
						flexWrap: 'wrap',
						gap: theme.spacing(2),
						rowGap: theme.spacing(2),
						mb: theme.spacing(4),
					}}
				>
					{videos.map((video) => {
						return (
							<ProfileVideo
								groupId={id}
								video={video}
								key={video.videoKey}
							/>
						)
					})}
				</Stack>
			}
		</Stack>
	)
};