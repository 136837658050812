import { AppThunk } from '../../../../../store/types';
import { TeachersEffects } from '../../../../../effects/teachersEffects';
import { getVideoTeacherUserActiveId } from '../../../../../store/videos/selectors';
import { PhraseListActions } from '../../../../../store/phrase-list/actions';
import { EPhraseListMode } from '../../../../../store/phrase-list/types';

export class TeacherListEvents {

  public static onTeacherSelect(id: number): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      const userId = getVideoTeacherUserActiveId(getState());
      if (userId === id) {
        // TeachersEffects.hidePhrases();
      } else {
        TeachersEffects.loadPhrases(id);
        dispatch(PhraseListActions.setListMode(EPhraseListMode.TEACHER));
      }
    }
  }


}
