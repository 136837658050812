import { IState } from '../../../../../store/types';
import { getMainViewMode } from '../../../../../store/general/selectors';
import { connect } from 'react-redux';
import { ContentLibraryDashboard } from '../components/ContentLibraryDashboard';
import { IContentLibraryDashboardEvents, IContentLibraryDashboardFields } from '../components/types';
import { EMainViewMode } from '../../../../../store/general/types';
import { ContentLibSelectors } from '../../../../../store/contentLib/selectors';

const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): IContentLibraryDashboardFields => {
    return {
      isShow: getMainViewMode(state) === EMainViewMode.CONTENT_LIBRARY,
      viewMode: ContentLibSelectors.getViewMode(state),
    }
  };
};

const mapDispatchToProps = (
  dispatch: any
): IContentLibraryDashboardEvents => ({
  }
);

export const ContentLibraryDashboardHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(ContentLibraryDashboard);

ContentLibraryDashboardHOC.displayName = 'ContentLibraryDashboardHOC';
