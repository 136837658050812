import React from 'react';
import { alpha } from '@mui/material';
import { theme } from '../../../../../../common/styles/theme';
import Box from '@mui/material/Box';
import { IPhraseNoteHTMLProps } from './types';

export const PhraseNoteHTML: React.FC<IPhraseNoteHTMLProps> = ({
	text = '',
}) => {

	const className = 'phrase-note-html';

	return (
		<Box
			className={className}
			dangerouslySetInnerHTML={{ __html: text }}
			sx={{
				flex: `1 1 100%`,
				minHeight: 0,
				overflow: 'auto',
				width: 1,
				p: theme.spacing(1),
				wordBreak: 'break-word',
				borderRadius: theme.spacing(0.75),
				border: `2px solid ${theme.palette.grey[200]}`,

				fontSize: theme.typography.subtitle2,
				lineHeight: 1.4,

				'& > *': {
					m: '0 0 .7em',
				},

				'img': {
					maxWidth: 1,
				},

				'pre': {
					p: 1,
					whiteSpace: 'pre-wrap',
					background: alpha(theme.palette.grey[100] , .8),
					borderRadius: 1,
				},

				'code': {
					p: `1px 3px`,
					background: alpha(theme.palette.grey[100], .8),
					borderRadius: .5,
				},

				'blockquote': {
					ml: 3,
					pl: 1,
					borderLeft: `4px solid ${theme.palette.grey[200]}`,
				},
			}}
		/>
	);
}
