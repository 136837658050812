import React from 'react';
import { CardMedia, Stack } from '@mui/material';
import { Text } from '../../../../../../common/components/Text/Text';
import { ITeacherListProps } from './types';
import { TeacherPhraseListHOC } from '../../TeacherPhraseList/hocs/TeacherPhraseListHOC';
import { TextUtils } from '../../../../../../common/utils/text-utils';
import { TEACHER_LIST_TEXT } from './wordings';
import { theme } from '../../../../../../common/styles/theme';

export const TeacherList: React.FC<ITeacherListProps> = ({
	show,
	activeId,
	teacherList,
	onTeacherSelect,
}) => {
	if (!show) return null;

	const [text, setText] = React.useState('');
	React.useEffect(() => {
		let wording = TextUtils.getPlural(teacherList.length, TEACHER_LIST_TEXT);
		let text = TextUtils.getText(wording, [teacherList.length]);
		setText(text);
	}, [teacherList]);

	return (
		<Stack
			gap={.5}
		>
			<Text
				sx={{
					fontSize: theme.typography.subtitle2,
				}}
			>
				{text}
			</Text>
			<Stack
				sx={{
					mb: theme.spacing(1.5),
				}}
			>
				{teacherList.map(t => {
					const active = t.user.id === activeId;
					return <Stack
						className={active ? 'active' : ''}
						direction={'row'}
						gap={1}
						alignItems={'center'}
						key={t.user.id}
						onClick={() => onTeacherSelect(t.user.id)}
						sx={{
							width: 1,
							p: theme.spacing(.5),
							borderRadius: theme.spacing(.75),
							cursor: 'pointer',

							'&:hover': {
								background: theme.palette.grey[50],
							},

							'&.active': {
								cursor: 'default',
								background: theme.palette.primary[100],
							},
						}}
					>
						<CardMedia
							component='img'
							image={t.user.picture}
							alt={t.user.name}
							sx={{
								flex: `0 0 ${theme.spacing(3)}`,
								height: theme.spacing(3),
								borderRadius: '50%',
							}}
						/>
						<Text
							sx={{
								height: theme.spacing(3),
								fontSize: theme.typography.subtitle2,
								fontWeight: 400,
								overflow: 'hidden',
							}}
						>
							{t.user.name}
						</Text>
					</Stack>
				})}
			</Stack>

			{!!activeId && <TeacherPhraseListHOC />}
		</Stack>
	);
}
