import { IState } from '../../../../store/types';
import { getEditPlaylistModalId } from '../../../../store/general/selectors';
import {
  getGroupNativeLanguage,
  getGroupTargetLanguage,
  getUserGroupById,
  getUserGroupTitle
} from '../../../../store/models/selectors';
import { connect } from 'react-redux';
import { EditGroup } from '../components/EditGroup';
import { IEditGroupEvents, IEditGroupFields } from '../components/types';
import { EditGroupEvents } from './events';


const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState,
  ): IEditGroupFields => {
    const groupId = getEditPlaylistModalId(state);
    const group = getUserGroupById(state, groupId);
    return {
      show: !!group,
      title: group ? getUserGroupTitle(state, group) : '',
      targetLangCode: group ? getGroupTargetLanguage(state, group)?.code : '',
      nativeLangCode: group ? getGroupNativeLanguage(state, group)?.code : ''
    }
  };
}

const mapDispatchToProps = (
  dispatch: any
): IEditGroupEvents => ({
    onSave: (targetLangCode, nativeLangCode, title) => dispatch(EditGroupEvents.onSave(targetLangCode, nativeLangCode, title)),
    onClose: () => dispatch(EditGroupEvents.onClose())
  }
);

export const EditGroupHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditGroup);

EditGroupHOC.displayName = 'EditGroupHOC';