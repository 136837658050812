import React from 'react';
import { theme } from '../../../../../../common/styles/theme';
import { Modal } from '../../../../../../common/components/Modal/Modal';
import { Box, MenuItem, Stack } from '@mui/material';
import { IProfileVideoPopupProps } from './types';
import { Text } from '../../../../../../common/components/Text/Text';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import { getUserGroupTitle } from '../../../../../store/models/selectors';
import { getState } from '../../../../../store';
import { User } from '../../../../../../common/components/User/User';
import LangFlag from '../../../LangFlag/LangFlag';
import { PlusIcon } from '../../../../../../../icons/PlusIcon';

export const ProfileVideoPopup: React.FC<IProfileVideoPopupProps> = ({
  video,
  onClose,
}) => {

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleSaveClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSelectGroup = (groupId: number) => {
    setAnchorEl(null);
    // onSave(groupId);
  }

  return (
    <Modal
      onClose={onClose}
      open={true}
      PaperProps={{
        sx: {
          width: 1,
          maxWidth: '700px !important',
        }
      }}
    >
      <Stack
        gap={theme.spacing(2)}
        sx={{
          width: 1,
          mt: -3,
        }}
      >
        <Stack
          gap={theme.spacing(.5)}
        >
          <Box
            sx={{
              display: '-webkit-box',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              '-webkit-line-clamp': '2',
              '-webkit-box-orient': 'vertical',
              maxHeight: '2.6em',
              lineHeight: `1.3 !important`,
              
              maxWidth: '80%',
              fontSize: theme.typography.h5,
              fontWeight: 600,
            }}
          >
            {video.title}
          </Box>

          <Text
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              fontSize: theme.typography.subtitle2,
              color: theme.palette.grey[500],
              whiteSpace: 'nowrap',
            }}
          >
            {video.author}
          </Text>
        </Stack>
        
        <Box
          sx={{
            aspectRatio: 16/9,
            backgroundPosition: '50% 50%',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundImage: `url(${video.thumb})`,
            borderRadius: theme.spacing(1),
          }}
        />

        <Stack
					direction={'row'}
					gap={2}
          justifyContent={'space-between'}
          sx={{
            [theme.breakpoints.down('sm')]: {
              flexDirection: 'column',
            },
          }}
				>
          <Stack
            gap={.5}
            sx={{
              minWidth: 0,
            }}
          >
            <Stack
              direction={'row'}
              gap={3}
            >
              {/* <User
                name={'testAuthor'}
                photo={'https://i.ytimg.com/vi/9gidul7vqOw/default.jpg'}
              /> */}
              {/* <Stack
                direction={'row'}
                gap={1}
                sx={{
                  '.lang-flag__code': {
                    color: theme.palette.grey[500],
                  },
                }}
              >
                <LangFlag code={targetLang} showCode={true} />
                <LangFlag code={nativeLang} showCode={true} />
              </Stack> */}
            </Stack>

            {/* <Text
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontSize: theme.typography.subtitle2,
                color: theme.palette.grey[500],
                whiteSpace: 'nowrap',
              }}
            >
              {listTitle}
            </Text> */}
          </Stack>

          {/* <Button
            variant={'contained'}
            startIcon={
              <PlusIcon sx={{ width: theme.spacing(2.5), height: theme.spacing(2.5) }} />
            }
            onClick={handleSaveClick}
          >
            Add to my playlist
          </Button> */}
        </Stack>
  
        {/* <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={!!anchorEl}
          onClose={handleClose}
          sx={{
            '.MuiPaper-root': {
              width: theme.spacing(33),
              mt: theme.spacing(),
              boxShadow: theme.shadows[16],
            },

            '.MuiMenuItem-root': {
              display: 'block',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            },
          }}
        >
          {groups.map(group => {
            return <MenuItem
              onClick={() => onSelectGroup(group.id)}
            >
              {getUserGroupTitle(getState(), group)}
            </MenuItem>
          })}

          <Button
            variant={'contained'}
            sx={{
              ml: 2,
              mt: 1,
            }}
            onClick={() => onSelectGroup(0)}
          >
            Create new playlist
          </Button>

        </Menu> */}
      </Stack>
    </Modal>
  );
}