import { AppThunk } from '../../store/types';
import { IPhraseSelectResult } from './utils/phrase-select-preparator';
import { EVocabularyPhraseType, ICaptionsItem, IVocabularyPhrase, TNewVocabularyPhrase } from '../../types/common';
import { addPhraseToVocabularyEffect, updatePhraseEffect } from './phraseVocabularyEffect';
import {
  getCurrentAudioId,
  getCurrentMovieKey,
  getSelectedNativeCaption,
  getSelectedTargetCaption, isAudioMovieKey
} from '../../store/current-video/selectors';
import { getActiveGroupId, getPhraseDetailsTabById } from '../../store/models/selectors';
import {
  findVideoPhraseById,
  findVideoPhraseByWordId,
  getNativeCaptions,
  getTargetCaptions,
  getVideoTeacherPhraseById,
  getVideoTeacherPhraseByWordId,
  getVideoTeacherShowPhraseId,
  getVideoTeacherUserActiveId
} from '../../store/videos/selectors';
import { getDispatch, getState } from '../../store';
import { CaptionsSelectionPopupSelectors } from '../../store/captions-selection-popup/selectors';
import { CaptionsSelectionPopupActions } from '../../store/captions-selection-popup/actions';
import {
  EFindPhrasesOrder,
  fetchAllVideoPhrases,
  TFindPhraseBody
} from '../../../common/rest/video/fetchAllVideoPhrases';
import { PhraseListSelectors } from '../../store/phrase-list/selectors';
import { EPhraseListMode } from '../../store/phrase-list/types';
import { PhraseListActions } from '../../store/phrase-list/actions';
import { addVideoPhraseAction, deleteVideoPhraseAction, setPhrasesNotesListsAction } from '../../store/videos/actions';
import { PhraseDetailsTranslateManager } from '../phrase-details/translate/phraseDetailsTranslateManager';
import { PhraseContextEditorActions } from '../../store/phrase-context-editor/actions';
import { PhraseContextEditorSelectors } from '../../store/phrase-context-editor/selectors';
import { PhraseDetailsActions } from '../../store/phrase-details/actions';
import { PhraseDetailsSelectors } from '../../store/phrase-details/selectors';
import { PhraseDetailsTabCustomPromptFormEvents } from '../../components/dashboard/PhraseDetailsTabsEditor/PhraseDetailsTabPromptForm/hocs/custom/events';
import { PhraseDetailsTabsEditorPopupSelectors } from '../../store/phrase-details-tabs-editor-popup/selectors';
import { PhraseDetailsTabEffects } from '../phraseDetailsTabEffects';
import construct = Reflect.construct;
import { PhraseNoteEffects } from '../phraseNoteEffects';
import { getAuthUser } from '../../store/general/selectors';
import { fetchPhrasesNotesLists } from '../../../common/rest/video/fetchPhrasesNotesLists';
import { PhraseNoteTimeScaleEffects } from './phraseNoteTimeScaleEffects';
import { phrasesPartLoadSize } from '../../components/dashboard/PhraseList/components/types';
import { VocabularyComponentUtils } from '../../components/dashboard/Vocabulary/vocabulary/utils/VocabularyComponentUtils';
import { TeachersEffects } from '../teachersEffects';

export class PhraseEffects {

  public static async saveFromSelectResult(
    selectResult: IPhraseSelectResult,
    createTranslateNote: boolean = false
  ): Promise<IVocabularyPhrase | null> {
    const state = getState();
    const videoKey = getCurrentMovieKey(state);
    const userGroupId = getActiveGroupId(state);
    if (videoKey && userGroupId) {
      const translate = PhraseEffects.getNativeTextBySelectResult(selectResult);
      const wordPhrase = await PhraseEffects.saveWordPhraseFromSelect(selectResult, videoKey, userGroupId);
      if (wordPhrase) {
        await PhraseEffects.saveContextPhraseFromSelect(selectResult, videoKey, userGroupId, wordPhrase, translate);
        if (translate && createTranslateNote) {
          await PhraseNoteEffects.save(wordPhrase.id, false, translate);
        }
      }
      return wordPhrase;
    }
    return null;
  }

  public static async saveFromCaption(
    caption: ICaptionsItem,
    captionIndex: number
  ): Promise<IVocabularyPhrase | null> {
    const state = getState();
    const videoKey = getCurrentMovieKey(state);
    const userGroupId = getActiveGroupId(state);
    if (videoKey && userGroupId) {
      const wordPhrase = await PhraseEffects.savePhraseFromCaption(caption, captionIndex, videoKey, userGroupId, 0, EVocabularyPhraseType.WORD_SELECTED);
      if (wordPhrase) {
        await PhraseEffects.savePhraseFromCaption(caption, captionIndex, videoKey, userGroupId, wordPhrase.id, EVocabularyPhraseType.WORD_AND_CONTEXT_SELECTED);
      }
      return wordPhrase;
    }
    return null;
  }

  public static async saveFromTeacherPhrase(teacherId: number, phraseId: number): Promise<IVocabularyPhrase | null>  {
    const state = getState();
    const dispatch = getDispatch();
    const videoKey = getCurrentMovieKey(state);
    const userGroupId = getActiveGroupId(state);
    const teacherWordPhrase = getVideoTeacherPhraseById(state, teacherId, phraseId);
    const teacherContextPhrase = getVideoTeacherPhraseByWordId(state, teacherId, teacherWordPhrase?.id);
    if (videoKey && userGroupId && teacherWordPhrase && teacherContextPhrase) {

      let phraseData: TNewVocabularyPhrase = {
        ...teacherWordPhrase,
        ...{
          id: undefined,
          videoKey,
          userGroupId,
          srcTeacherPhraseId: teacherWordPhrase.id
        }
      };
      const wordPhrase = await dispatch(addPhraseToVocabularyEffect(phraseData));

      phraseData = {
        ...teacherContextPhrase,
        ...{
          id: undefined,
          videoKey,
          userGroupId,
          wordPhraseId: wordPhrase.id,
          srcTeacherPhraseId: teacherContextPhrase.id
        }
      };
      await dispatch(addPhraseToVocabularyEffect(phraseData));
      return wordPhrase;
    }
  }

  private static getNativeTextBySelectResult(selectResult: IPhraseSelectResult): string {
    const state = getState();

    const targetCaptions: ICaptionsItem[] = getTargetCaptions(state);
    const nativeCaptions: ICaptionsItem[] = getNativeCaptions(state);
    if (!nativeCaptions || nativeCaptions.length < 1) return '';

    const targetCaptionsMiddleTimes: number[] = [];
    for (let i = selectResult.startCaptionIndex; i <= selectResult.endCaptionIndex; i++) {
      const targetCaption = targetCaptions[i];
      targetCaptionsMiddleTimes.push(targetCaption.startTime + ((targetCaption.endTime - targetCaption.startTime) / 2));
    }

    const textList = nativeCaptions.filter(c => {
      return targetCaptionsMiddleTimes.some(targetMiddleTime => {
        return c.startTime < targetMiddleTime && c.endTime > targetMiddleTime;
      })
    }).map(c => c.text);

    return textList.join(' ');
  }

  private static async savePhraseFromCaption(
    caption: ICaptionsItem,
    captionIndex: number,
    videoKey: string,
    userGroupId: number,
    wordPhraseId: number,
    type: EVocabularyPhraseType
  ) {
    const dispatch = getDispatch();
    const phrase: TNewVocabularyPhrase = {
      highlighted: caption.text,
      fullPhrase: caption.text,
      translated: '',
      startTime: caption.startTime,
      endTime: caption.endTime,
      type,
      startPosition: 0,
      endPosition: caption.text.length,
      startCaptionIndex: captionIndex,
      endCaptionIndex: captionIndex,
      wordPhraseId,
      videoKey,
      userGroupId
    };
    return dispatch(addPhraseToVocabularyEffect(phrase));
  }

  private static async saveWordPhraseFromSelect(selectResult: IPhraseSelectResult, videoKey: string, userGroupId: number): Promise<IVocabularyPhrase | null> {
    const dispatch = getDispatch();
    const phrase: TNewVocabularyPhrase = {
      highlighted: selectResult.text,
      fullPhrase: '',
      translated: '',
      startTime: selectResult.startTime,
      endTime: selectResult.endTime,
      type: EVocabularyPhraseType.WORD_SELECTED,
      startPosition: selectResult.startPosition,
      endPosition: selectResult.endPosition,
      startCaptionIndex: selectResult.startCaptionIndex,
      endCaptionIndex: selectResult.endCaptionIndex,
      wordPhraseId: 0,
      videoKey,
      userGroupId
    };
    return dispatch(addPhraseToVocabularyEffect(phrase));
  }

  private static async saveContextPhraseFromSelect(
    selectResult: IPhraseSelectResult,
    videoKey: string,
    userGroupId: number,
    wordPhrase: IVocabularyPhrase,
    translated: string): Promise<IVocabularyPhrase | null> {
    const state = getState();
    const dispatch = getDispatch();
    const targetCaption: ICaptionsItem[] = getTargetCaptions(state);
    const captions: ICaptionsItem[] = [];
    for(let i=selectResult.startCaptionIndex; i<=selectResult.endCaptionIndex; i++) {
      if (i < targetCaption.length) {
        captions.push(targetCaption[i]);
      }
    }
    if (captions.length) {
      const text = captions.map(c => c.text).join('\n');
      const phrase: TNewVocabularyPhrase = {
        highlighted: wordPhrase.highlighted,
        fullPhrase: text,
        translated,
        startTime: captions[0].startTime,
        endTime: captions[captions.length-1].endTime,
        type: EVocabularyPhraseType.WORD_AND_CONTEXT_SELECTED,
        startPosition: 0,
        endPosition: captions[captions.length-1].text.length,
        startCaptionIndex: selectResult.startCaptionIndex,
        endCaptionIndex: selectResult.endCaptionIndex,
        wordPhraseId: wordPhrase.id,
        videoKey,
        userGroupId
      };
      return await dispatch(addPhraseToVocabularyEffect(phrase));
    }
    return null;
  }


  public static modifyContext(selectResult: IPhraseSelectResult): AppThunk {
    return async (
      dispatch,
      getState
    ): Promise<IVocabularyPhrase | null | undefined> => {
      const state = getState();
      const videoKey = getCurrentMovieKey(state);
      const userGroupId = getActiveGroupId(state);
      if (selectResult.insideWordPhrases && selectResult.insideWordPhrases.length && videoKey && userGroupId) {
        const wordPhrase = selectResult.insideWordPhrases[0];
        if (wordPhrase) {
          const contextPhrase = findVideoPhraseByWordId(state, wordPhrase.id);
          if (contextPhrase) {
            const translated = await PhraseEffects.getTranslateForSaveContext(selectResult);
            contextPhrase.fullPhrase = selectResult.text;
            contextPhrase.startTime = selectResult.startTime;
            contextPhrase.endTime = selectResult.endTime;
            contextPhrase.startPosition = selectResult.startPosition;
            contextPhrase.endPosition = selectResult.endPosition;
            contextPhrase.startCaptionIndex = selectResult.startCaptionIndex;
            contextPhrase.endCaptionIndex = selectResult.endCaptionIndex;
            contextPhrase.translated = translated;
            contextPhrase.contextModified = true;
            dispatch(updatePhraseEffect(contextPhrase));
          }
          return contextPhrase;
        }
      }
    }
  }

  private static async getTranslateForSaveContext(selectResult: IPhraseSelectResult): Promise<string> {
    const state = getState();
    const targetLangCode = getSelectedTargetCaption(state)?.code;
    const nativeLangCode = getSelectedNativeCaption(state)?.code;
    if (targetLangCode && nativeLangCode) {
      return await PhraseDetailsTranslateManager.translate(
        {code: targetLangCode, name: ''},
        {code: nativeLangCode, name: ''},
        selectResult.text) || '';
    }
    return '';


  }

  private static generatePhraseId(): number {
    let id = -1;
    PhraseListSelectors.getList(getState()).forEach(p => {
      if (p.id < id)
        id = p.id;
    })
    return id;
  }

  public static savePreviewPhrase(selectResult: IPhraseSelectResult, translate?: boolean): AppThunk {
    return (
      dispatch,
      getState
    ): number => {
      const state = getState();
      const videoKey = getCurrentMovieKey(state);
      const userGroupId = getActiveGroupId(state);
      const targetCaption = getTargetCaptions(state);
      const captions: ICaptionsItem[] = [];
      for(let i=selectResult.startCaptionIndex; i<=selectResult.endCaptionIndex; i++) {
        if (i < targetCaption.length) {
          captions.push(targetCaption[i]);
        }
      }

      const phraseId = PhraseEffects.generatePhraseId();
      const contextPhraseId = phraseId - 1;

      if (selectResult && videoKey && userGroupId && captions.length) {
        const phrase: IVocabularyPhrase = {
          id: phraseId,
          highlighted: selectResult.text,
          fullPhrase: '',
          translated: '',
          startTime: selectResult.startTime,
          endTime: selectResult.endTime,
          type: EVocabularyPhraseType.PREVIEW_WORD_SELECTED,
          startPosition: selectResult.startPosition,
          endPosition: selectResult.endPosition,
          startCaptionIndex: selectResult.startCaptionIndex,
          endCaptionIndex: selectResult.endCaptionIndex,
          wordPhraseId: 0,
          videoKey,
          userGroupId,
          translate
        };

        const fullPhrase = captions.map(c => c.text).join('\n');

        const contextPhrase: IVocabularyPhrase = {
          id: contextPhraseId,
          highlighted: selectResult.text,
          fullPhrase,
          translated: '',
          type: EVocabularyPhraseType.PREVIEW_WORD_AND_CONTEXT_SELECTED,
          startTime: captions[0].startTime,
          endTime: captions[captions.length-1].endTime,
          startPosition: 0,
          endPosition: captions[captions.length-1].text.length,
          startCaptionIndex: selectResult.startCaptionIndex,
          endCaptionIndex: selectResult.endCaptionIndex,
          wordPhraseId: phraseId,
          videoKey,
          userGroupId
        };

        dispatch(addVideoPhraseAction(phrase));
        dispatch(addVideoPhraseAction(contextPhrase));
        dispatch(PhraseContextEditorActions.updatePhraseContextEditorAction({
          phrases: [
            phrase,
            contextPhrase,
          ],
        }));
      }
      return contextPhraseId;
    }
  }

  public static savePreviewContextByWordPhrase(selectResult: IPhraseSelectResult, wordPhraseId: number): AppThunk {
    return (
      dispatch,
      getState
    ): IVocabularyPhrase | null => {
      const state = getState();
      const phraseId = PhraseEffects.generatePhraseId();
      const videoKey = getCurrentMovieKey(state);
      const userGroupId = getActiveGroupId(state);

      const targetCaption = getTargetCaptions(state);
      const captions: ICaptionsItem[] = [];
      for(let i=selectResult.startCaptionIndex; i<=selectResult.endCaptionIndex; i++) {
        if (i < targetCaption.length) {
          captions.push(targetCaption[i]);
        }
      }
      if (selectResult && videoKey && userGroupId && captions.length) {
        const fullPhrase = captions.map(c => c.text).join('\n');

        const contextPhrase: IVocabularyPhrase = {
          id: phraseId,
          highlighted: selectResult.text,
          fullPhrase,
          translated: '',
          type: EVocabularyPhraseType.PREVIEW_WORD_AND_CONTEXT_SELECTED,
          startTime: selectResult.startTime,
          endTime: selectResult.endTime,
          startPosition: selectResult.startPosition,
          endPosition: selectResult.endPosition,
          startCaptionIndex: selectResult.startCaptionIndex,
          endCaptionIndex: selectResult.endCaptionIndex,
          wordPhraseId,
          videoKey,
          userGroupId
        };
        return contextPhrase;
      }
      return null;
    }
  }

  public static deleteCurrentPreviewPhrase(): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      const state = getState();
      const previewPhraseId = CaptionsSelectionPopupSelectors.getPreviewPhraseId(state);
      if (previewPhraseId) {
        const phrase = findVideoPhraseById(state, previewPhraseId)
        if (phrase && phrase.wordPhraseId) {
          dispatch(PhraseListActions.deletePhrase(phrase.wordPhraseId));
          dispatch(deleteVideoPhraseAction(phrase.wordPhraseId));
          dispatch(PhraseContextEditorActions.deletePhrase(phrase.wordPhraseId));
        }
        dispatch(PhraseListActions.deletePhrase(previewPhraseId));
        dispatch(deleteVideoPhraseAction(previewPhraseId));
        dispatch(PhraseContextEditorActions.deletePhrase(previewPhraseId));
        dispatch(CaptionsSelectionPopupActions.setPreviewPhraseId(0));
      }
    }
  }

  public static findInterceptPhrase(
    videoId: string,
    phraseTypes: EVocabularyPhraseType[],
    startIndex: number,
    startPos: number,
    endIndex: number,
    endPos: number) {
    const state = getState();
    const lineLen = 10000;
    const phrases: IVocabularyPhrase[] = PhraseListSelectors.findPhraseByVideoId(state, videoId);
    //  getVideoPhrases(state, videoId, true);

    const startValue = startIndex * lineLen + startPos;
    const endValue = endIndex * lineLen + endPos;

    return phrases.find(phrase => {
      if (!phrase.type || !phraseTypes.includes(phrase.type))
        return false;

      const phraseStartValue = phrase.startCaptionIndex * lineLen + phrase.startPosition;
      const phraseEndValue = phrase.endCaptionIndex * lineLen + phrase.endPosition;

      return (startValue >= phraseStartValue && startValue <= phraseEndValue) || (endValue >= phraseStartValue && endValue <= phraseEndValue)
        || (startValue < phraseStartValue && endValue > phraseEndValue);
    })

  }

  public static async loadPhrases(offset: number, count: number, append: boolean) {
    const dispatch = getDispatch();
    const state = getState();
    const listMode = PhraseListSelectors.getListMode(state);
    const {teacherMode} = getAuthUser(state);
    const body: TFindPhraseBody = {
      offset,
      count,
      teacherMode,
      order: EFindPhrasesOrder.CREATE_DATE
    }
    if (listMode === EPhraseListMode.CURRENT_VIDEO) {
      if (isAudioMovieKey(getCurrentMovieKey(state))) {
        body.audioId = getCurrentAudioId(state);
      } else {
        body.videoId = getCurrentMovieKey(state);
      }
      body.videoGroupId = getActiveGroupId(state) || undefined;
      body.order = EFindPhrasesOrder.START_TIME;
    } else if (listMode === EPhraseListMode.CURRENT_GROUP) {
      body.groupId = getActiveGroupId(state) || undefined;
    } else if (listMode === EPhraseListMode.LANG) {
      body.targetLangCode = PhraseListSelectors.getListModeLang(state);
    }
    let list = await dispatch(fetchAllVideoPhrases(body));

  /*  let allLoaded = !!(list.length <= count);
    if (list.length > count) {
      list = list.splice(0, count);
    }*/

    if (append) {
      dispatch(PhraseListActions.appendPhraseList(list));
    } else {
      dispatch(PhraseListActions.setPhraseList(list));
    }

    return list.length === 0;
  }


  public static async saveFromHotKey(playerTime: number): Promise<IVocabularyPhrase | null> {
      const state = getState();
      const dispatch = getDispatch();

      const targetCaptions: ICaptionsItem[] = getTargetCaptions(state);
      const nativeCaptions: ICaptionsItem[] = getNativeCaptions(state);

      const targetCaptionIndex = targetCaptions.findIndex(caption => caption.startTime < playerTime && caption.endTime > playerTime);
      const nativeCaptionIndex = nativeCaptions.findIndex(caption => caption.startTime < playerTime && caption.endTime > playerTime);
      const targetCaption = targetCaptionIndex >= 0 ? targetCaptions[targetCaptionIndex] : null;
      const nativeCaption = nativeCaptionIndex >= 0 ? nativeCaptions[nativeCaptionIndex] : null;

      if (!targetCaption) return null;
    const videoKey = getCurrentMovieKey(state);
    const userGroupId = getActiveGroupId(state);
    if (!userGroupId) return null;

      const text = targetCaption.text;
      const lastPhrase = PhraseListSelectors.findLastPhraseByVideoAndType(state, videoKey, EVocabularyPhraseType.WORD_SELECTED);
      if (lastPhrase && lastPhrase.highlighted === text) return;

      const wordPhrase: TNewVocabularyPhrase = {
        highlighted: text,
        fullPhrase: '',
        translated: nativeCaption ? nativeCaption.text : '',
        startTime: targetCaption.startTime,
        endTime: targetCaption.endTime,
        type: EVocabularyPhraseType.WORD_SELECTED,
        startPosition: 0,
        endPosition: text.length,
        startCaptionIndex: targetCaptionIndex,
        endCaptionIndex: targetCaptionIndex,
        wordPhraseId: 0,
        videoKey,
        userGroupId
      };
      const wPhrase: IVocabularyPhrase = await dispatch(addPhraseToVocabularyEffect(wordPhrase));

      const contextPhrase = {...wordPhrase, ...{
          fullPhrase: text,
          wordPhraseId: wPhrase.id,
          type: EVocabularyPhraseType.WORD_AND_CONTEXT_SELECTED
        }};
      return await dispatch(addPhraseToVocabularyEffect(contextPhrase));
  }

  public static savePreviewPhraseModifiedContext(selectResult: IPhraseSelectResult): AppThunk {
    return (
      dispatch,
      getState
    ): number => {
      const state = getState();
      const videoKey = getCurrentMovieKey(state);
      const userGroupId = getActiveGroupId(state);
      const targetCaption = getTargetCaptions(state);
      const captions: ICaptionsItem[] = [];
      for(let i=selectResult.startCaptionIndex; i<=selectResult.endCaptionIndex; i++) {
        if (i < targetCaption.length) {
          captions.push(targetCaption[i]);
        }
      }

      let contextPhraseId;

      if (selectResult && videoKey && userGroupId && captions.length) {
        let phrase: IVocabularyPhrase;
        let phrases = PhraseContextEditorSelectors.getPhrases(state);
			  if (phrases && phrases.length) {
          phrase = phrases.find(item => !item.wordPhraseId) as any;
        } else {
          return dispatch(this.savePreviewPhrase(selectResult)) as any;
        }

        const phraseId = phrase.id;
        contextPhraseId = phraseId - 1;

        const contextPhrase: IVocabularyPhrase = {
          id: contextPhraseId,
          highlighted: phrase.highlighted,
          fullPhrase: selectResult.text,
          translated: '',
          type: EVocabularyPhraseType.PREVIEW_WORD_AND_CONTEXT_SELECTED,
          startTime: selectResult.startTime,
          endTime: selectResult.endTime,
          startPosition: selectResult.startPosition,
          endPosition: selectResult.endPosition,
          startCaptionIndex: selectResult.startCaptionIndex,
          endCaptionIndex: selectResult.endCaptionIndex,
          wordPhraseId: phraseId,
          videoKey,
          userGroupId
        };

        dispatch(addVideoPhraseAction(phrase));
        dispatch(addVideoPhraseAction(contextPhrase));
        dispatch(PhraseContextEditorActions.updatePhraseContextEditorAction({
          phrases: [
            phrase,
            contextPhrase,
          ],
        }));

        TeachersEffects.hidePhrases();
        dispatch(PhraseDetailsActions.activate(
          phrase.highlighted,
          '',
          contextPhrase.fullPhrase,
          phrase.highlighted,
          PhraseDetailsSelectors.getFromLang(state) as any,
          PhraseDetailsSelectors.getToLang(state) as any,
          PhraseDetailsSelectors.getActiveTab(state),
        ));

        const tabId = PhraseDetailsTabsEditorPopupSelectors.getEditTabId(state);
        const libId = PhraseDetailsTabsEditorPopupSelectors.getOpenPromptLibId(state);
        if (tabId || libId) {
          let prompt;
          if (tabId) {
            const tab = getPhraseDetailsTabById(state, tabId);
            prompt = tab && PhraseDetailsTabEffects.getCustomPromptByTab(tab);
          } else if (libId) {
            prompt = PhraseDetailsTabsEditorPopupSelectors.getOpenPromptLib(state);
          }
          if (prompt) {
            dispatch(PhraseDetailsTabCustomPromptFormEvents.onPromptChange(prompt.prompt));
          }
        }

      }

      return contextPhraseId;
    }
  }

  public static async reloadPhrasesNotes() {
    const state = getState();
    const dispatch = getDispatch();
    const {teacherMode} = getAuthUser(state);
    const videoId = getCurrentMovieKey(state);
    const userGroupId = getActiveGroupId(state);
    const result = await fetchPhrasesNotesLists({ teacherMode, videoId, userGroupId });
    dispatch(setPhrasesNotesListsAction(result.phrases, result.notes));
    PhraseNoteTimeScaleEffects.load();

    dispatch(PhraseListActions.setListMode(EPhraseListMode.CURRENT_VIDEO, ''));
    await PhraseEffects.loadPhrases(0, phrasesPartLoadSize, false);
  }


}
