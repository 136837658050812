import {
    CHANGE_AUTO_SYNC,
    ECurrentFocusArea,
    EMainLayoutType,
    EMainViewMode,
    EPlayMode,
    ESavePhraseMode,
    IAddVideoConfirm,
    ICopyVideoConfirm,
    ICopyVideoOnLogin,
    IGeneralActions,
    IOnboarding,
    IOneTimeTooltips,
    ISavedVideoConfirm,
    ISetAddVideoConfirmAction,
    ISetAddVideoOnLogin,
    ISetAppSettingsAction,
    ISetAuthUser,
    ISetAutoPause,
    ISetCopyGroupOnLogin,
    ISetCopyVideoConfirmAction,
    ISetCopyVideoOnLogin,
    ISetCurrentFocusArea,
    ISetExtensionInstalled,
    ISetFlashPhraseId,
    ISetFlashVideoId,
    ISetHoverAutoPause,
    ISetLibraryGroupId,
    ISetMainLayoutShowLeftPanel,
    ISetMainLayoutType,
    ISetMainViewMode,
    ISetOnboardingAction,
    ISetOneTimeTooltip,
    ISetOneTimeTooltipNativeLanguage,
    ISetOneTimeTooltipPhrasesEdit,
    ISetPauseOnNoteAction,
    ISetPhrasesExist,
    ISetPlayerRate,
    ISetPlayerState,
    ISetPlayMode,
    ISetSavedVideoConfirm,
    ISetSavePhraseModeAction,
    ISetShowAddNewVideoPopup,
    ISetShowNativeCaptions,
    ISetShowNewFeaturesPopup,
    ISetShowPinExtensionPopup,
    ISetShowTelegramBotPopup,
    ISetSnackbarPanelAction,
    ISetTextTranslation,
    ISetVideoTutorialMode,
    ISetVocabularyPhraseDetailsHeight, IShowInstallExtensionPopupAction,
    ISyncUserLocalStorageData,
    ITextTranslationState,
    IUserTeacherSetModeAction,
    IUserTranslateLangAdd,
    IUserTranslateLangDelete,
    IUserTranslateLangsUpdate,
    SET_ADD_VIDEO_CONFIRM,
    SET_ADD_VIDEO_ON_LOGIN,
    SET_APP_SETTINGS,
    SET_AUTH_USER,
    SET_AUTO_PAUSE_SIZE,
    SET_CAPTION_SELECT_STATUS,
    SET_COPY_GROUP_ON_LOGIN,
    SET_COPY_VIDEO_CONFIRM,
    SET_COPY_VIDEO_ON_LOGIN,
    SET_CREATE_PLAYLIST_MODAL_OPEN,
    SET_CREATE_PLAYLIST_SAVE_MODAL_OPEN,
    SET_CREATE_PUBLIC_PROFILE_MODAL_OPEN,
    SET_CURRENT_FOCUS_AREA,
    SET_EDIT_PLAYLIST_MODAL_ID,
    SET_EDIT_PLAYLIST_MODAL_OPEN,
    SET_EXTENSION_INSTALLED,
    SET_FEEDBACK_MODAL_OPEN,
    SET_FLASH_PHRASE_ID,
    SET_FLASH_VIDEO_ID,
    SET_HOVER_AUTO_PAUSE,
    SET_LIBRARY_GROUP,
    SET_MAIN_LAYOUT_SHOW_LEFT_PANEL,
    SET_MAIN_LAYOUT_TYPE,
    SET_MAIN_VIEW_MODE,
    SET_ONBOARDING,
    SET_ONE_TIME_TOOLTIP,
    SET_PAUSE_ON_NOTE,
    SET_PHRASES_EXIST,
    SET_PLAY_MODE,
    SET_PLAYER_RATE,
    SET_PLAYER_STATE,
    SET_SAVE_PHRASE_MODE,
    SET_SAVED_VIDEO_CONFIRM,
    SET_SHOW_ADD_NEW_VIDEO_POPUP,
    SET_SHOW_NATIVE_CAPTIONS,
    SET_SHOW_NEW_FEATURES_POPUP, SET_SHOW_OPEN_PUBLIC_PROFILE_POPUP,
    SET_SHOW_PIN_EXTENSION_POPUP,
    SET_SHOW_TELEGRAM_BOT_POPUP,
    SET_SNACKBAR_PANEL,
    SET_TEXT_TRANSLATION,
    SET_VIDEO_TUTORIAL_MODE,
    SET_VOCABULARY_PHRASE_DETAILS_HEIGHT, SHOW_INSTALL_EXTENSION_WARNING_POPUP_ACTION,
    SHOW_ONE_TIME_TOOLTIP_NATIVE_LANGUAGE,
    SHOW_ONE_TIME_TOOLTIP_PHRASES_EDIT,
    SYNC_USER_LOCAL_STORAGE,
    TAuthUser,
    TAuthUserTranslateLang,
    TOGGLE_SETTINGS_MODAL,
    USER_TEACHER_SET_MODE,
    USER_TRANSLATE_LANG_ADD,
    USER_TRANSLATE_LANG_DELETE,
    USER_TRANSLATE_LANGS_UPDATE, ISetShowOpenPublicProfilePopup,
    ISetRouteOnLoginAction,
    IRouteOnLogin,
    SET_ROUTE_ON_LOGIN
} from './types';
import { PlayerStates } from '../../types/common';
import { EVideoTutorialType } from '../models/types';
import { IAppSettings } from '../../../common/rest/user/userRest';


export const syncUserLocalStorageAction = (
  data: ISyncUserLocalStorageData
): IGeneralActions => ({
    type: SYNC_USER_LOCAL_STORAGE,
    data
})

export const toggleSettingsModalAction = (
  forceState?: boolean
): IGeneralActions => ({
    type: TOGGLE_SETTINGS_MODAL,
    forceState,
})

export const setEditPlaylistModalOpenAction = (
    open: boolean
): IGeneralActions => ({
    type: SET_EDIT_PLAYLIST_MODAL_OPEN,
    open,
});

export const setCreatePublicProfileModalOpenAction = (
    open: boolean,
): IGeneralActions => ({
    type: SET_CREATE_PUBLIC_PROFILE_MODAL_OPEN,
    open,
})

export const setEditPlaylistModalIdAction = (
  id: number
): IGeneralActions => ({
    type: SET_EDIT_PLAYLIST_MODAL_ID,
    id
});

export const setCreatePlaylistModalOpenAction = (
    open: boolean
): IGeneralActions => ({
    type: SET_CREATE_PLAYLIST_MODAL_OPEN,
    open,
});

export const setCreatePlaylistSaveModalOpenAction = (
    open: boolean
): IGeneralActions => ({
    type: SET_CREATE_PLAYLIST_SAVE_MODAL_OPEN,
    open,
});

export const setFeedbackModalOpenAction = (
  open: boolean
): IGeneralActions => ({
  type: SET_FEEDBACK_MODAL_OPEN,
  open,
});

export const changeAutoSyncAction = (
    state: boolean
): IGeneralActions => ({
    type: CHANGE_AUTO_SYNC,
    state
})

export const setCaptionSelectStatus = (
    status: boolean
): IGeneralActions => ({
    type: SET_CAPTION_SELECT_STATUS,
    status
})

export const setAuthUserAction = (
    user: TAuthUser | null
): ISetAuthUser => ({
    type: SET_AUTH_USER,
    user
})

export const setAutoPauseAction = (
    pause: number
): ISetAutoPause => ({
    type: SET_AUTO_PAUSE_SIZE,
    pause
});

export const setHoverAutoPauseAction = (
  hoverAutoPause: boolean
): ISetHoverAutoPause => ({
    type: SET_HOVER_AUTO_PAUSE,
    hoverAutoPause
});

export const setVocabularyPhraseDetailsHeightAction = (
    height: number
): ISetVocabularyPhraseDetailsHeight => ({
    type: SET_VOCABULARY_PHRASE_DETAILS_HEIGHT,
    height
});

export const setLibraryGroupIdAction = (
    groupId: number | null
): ISetLibraryGroupId => ({
    type: SET_LIBRARY_GROUP,
    groupId
});

export const setPlayerState = (
    state: PlayerStates
): ISetPlayerState => ({
    type: SET_PLAYER_STATE,
    state
});

export const setPlayerRate = (
    rate: number
): ISetPlayerRate => ({
    type: SET_PLAYER_RATE,
    rate
});

export const setPlayMode = (
    mode: EPlayMode
): ISetPlayMode => ({
    type: SET_PLAY_MODE,
    mode
});

export const setCurrentFocusArea = (
  currentFocusArea: ECurrentFocusArea
): ISetCurrentFocusArea => ({
  type: SET_CURRENT_FOCUS_AREA,
  currentFocusArea
});

export const setShowNewFeaturesPopupAction = (
  show: boolean
): ISetShowNewFeaturesPopup => ({
    type: SET_SHOW_NEW_FEATURES_POPUP,
    show
});

export const setExtensionInstalledAction = (
  extensionInstalled: boolean
): ISetExtensionInstalled => ({
    type: SET_EXTENSION_INSTALLED,
    extensionInstalled
});

export const setSavedVideoConfirmAction = (
  value: Partial<ISavedVideoConfirm>
): ISetSavedVideoConfirm => ({
    type: SET_SAVED_VIDEO_CONFIRM,
    value
});



export const setSetAddVideoOnLoginAction = (
  groupId: number,
  videoUrl: string
): ISetAddVideoOnLogin => ({
    type: SET_ADD_VIDEO_ON_LOGIN,
    groupId,
    videoUrl
});

export const setCopyVideoOnLoginAction = (
  data: Partial<ICopyVideoOnLogin>
): ISetCopyVideoOnLogin => ({
    type: SET_COPY_VIDEO_ON_LOGIN,
    data
});

export const setCopyGroupOnLoginAction = (
  hash: string
): ISetCopyGroupOnLogin => ({
    type: SET_COPY_GROUP_ON_LOGIN,
    hash
});

export const setShowPinExtensionPopupAction = (
  show: boolean
): ISetShowPinExtensionPopup => ({
    type: SET_SHOW_PIN_EXTENSION_POPUP,
    show
});

export const setShowOpenPublicProfilePopupAction = (
    show: boolean
): ISetShowOpenPublicProfilePopup => ({
    type: SET_SHOW_OPEN_PUBLIC_PROFILE_POPUP,
    show
});

export const setTextTranslationAction = (
  textTranslation: ITextTranslationState
): ISetTextTranslation => ({
    type: SET_TEXT_TRANSLATION,
    textTranslation
});

export const setOneTimeTooltipPhrasesEditAction = (
  show: boolean
): ISetOneTimeTooltipPhrasesEdit => ({
    type: SHOW_ONE_TIME_TOOLTIP_PHRASES_EDIT,
    show
});

export const setOneTimeTooltipNativeLanguageAction = (
  show: boolean
): ISetOneTimeTooltipNativeLanguage => ({
    type: SHOW_ONE_TIME_TOOLTIP_NATIVE_LANGUAGE,
    show
});

export const setOneTimeTooltipAction = (
  value: Partial<IOneTimeTooltips>
): ISetOneTimeTooltip => ({
    type: SET_ONE_TIME_TOOLTIP,
    value,
});

export const setShowTelegramBotPopupAction = (
  show: boolean
): ISetShowTelegramBotPopup => ({
    type: SET_SHOW_TELEGRAM_BOT_POPUP,
    show
});

export const setVideoTutorialModeAction = (
  value?: EVideoTutorialType
): ISetVideoTutorialMode => ({
    type: SET_VIDEO_TUTORIAL_MODE,
    value
});

export const setShowAddNewVideoPopupAction = (
  show: boolean
): ISetShowAddNewVideoPopup => ({
    type: SET_SHOW_ADD_NEW_VIDEO_POPUP,
    show
});

export const setMainViewMode = (
  mainViewMode: EMainViewMode
): ISetMainViewMode => ({
    type: SET_MAIN_VIEW_MODE,
    mainViewMode
});

export const setPhrasesExistAction = (
    exist: boolean
): ISetPhrasesExist => ({
    type: SET_PHRASES_EXIST,
    exist
});

export const setFlashVideoIdAction = (
  flashVideoId: string
): ISetFlashVideoId => ({
    type: SET_FLASH_VIDEO_ID,
    flashVideoId
});

export const setFlashPhraseIdAction = (
  flashPhraseId: number
): ISetFlashPhraseId => ({
    type: SET_FLASH_PHRASE_ID,
    flashPhraseId
});



export const userTranslateLangAddAction = (
  langCode: string, orderNum: number
): IUserTranslateLangAdd => ({
    type: USER_TRANSLATE_LANG_ADD,
    langCode,
    orderNum
});

export const userTranslateLangDeleteAction = (
  langCode: string
): IUserTranslateLangDelete => ({
    type: USER_TRANSLATE_LANG_DELETE,
    langCode
});

export const userTranslateLangsUpdateAction = (
  langs: TAuthUserTranslateLang[]
): IUserTranslateLangsUpdate => ({
    type: USER_TRANSLATE_LANGS_UPDATE,
    langs
});

export const setMainLayoutTypeAction = (
  layoutType: EMainLayoutType
): ISetMainLayoutType => ({
    type: SET_MAIN_LAYOUT_TYPE,
    layoutType
});

export const setMainLayoutShowLeftPanelAction = (
  showLeftPanel: boolean
): ISetMainLayoutShowLeftPanel => ({
    type: SET_MAIN_LAYOUT_SHOW_LEFT_PANEL,
    showLeftPanel
});

export const setShowNativeCaptionsAction = (
  show: boolean
): ISetShowNativeCaptions => ({
    type: SET_SHOW_NATIVE_CAPTIONS,
    show
});

export const setOnboardingAction = (
  onboarding: Partial<IOnboarding>
): ISetOnboardingAction => ({
    type: SET_ONBOARDING,
    onboarding
});

export const setSnackbarPanelAction = (
  show: boolean, text?: string
): ISetSnackbarPanelAction => ({
    type: SET_SNACKBAR_PANEL,
    show,
    text: text || ''
});

export const setCopyVideoConfirmAction = (
  copyVideoConfirm: Partial<ICopyVideoConfirm>
): ISetCopyVideoConfirmAction => ({
    type: SET_COPY_VIDEO_CONFIRM,
    copyVideoConfirm
});

export const setAppSettingsAction = (
  value: Partial<IAppSettings>
): ISetAppSettingsAction => ({
    type: SET_APP_SETTINGS,
    value
});

export const setPauseOnNoteAction = (
  pauseOnNote: boolean
): ISetPauseOnNoteAction => ({
    type: SET_PAUSE_ON_NOTE,
    pauseOnNote
});

export const setAddVideoConfirmAction = (
  value: Partial<IAddVideoConfirm>
): ISetAddVideoConfirmAction => ({
    type: SET_ADD_VIDEO_CONFIRM,
    value
});

export const setUserTeacherModeAction = (
  teacherMode: boolean
): IUserTeacherSetModeAction => ({
    type: USER_TEACHER_SET_MODE,
    teacherMode
});

export const setSavePhraseModeAction = (
  mode: ESavePhraseMode
): ISetSavePhraseModeAction => ({
    type: SET_SAVE_PHRASE_MODE,
    mode
});

export const setShowInstallExtensionPopupAction = (
  show: boolean
): IShowInstallExtensionPopupAction => ({
    type: SHOW_INSTALL_EXTENSION_WARNING_POPUP_ACTION,
    show
});

export const setRouteOnLoginAction = (
  data: Partial<IRouteOnLogin>
): ISetRouteOnLoginAction => ({
    type: SET_ROUTE_ON_LOGIN,
    data
});
