import * as React from 'react';
import { IUserTeacherModeSwitchProps } from './types';
import { TEACHER_MODE_LABEL } from './wordings';
import { ControlSwitch } from '../../../Controls/component/ControlSwitch';
import { theme } from '../../../../../../common/styles/theme';
import { CreatePublicProfileModalHOC } from '../../../PublicProfile/CreatePublicProfileModal/hocs/CreatePublicProfileModalHOC';

export const UserTeacherModeSwitch: React.FC<IUserTeacherModeSwitchProps> = ({
	show,
	modeEnabled,
	isPublicProfileExist,
	onSwitch
}) => {
	if (!show) return null;

	const [showCreateProfile, setShowCreateProfile] = React.useState<boolean>(false);
	const [switchEnabled, setSwitchEnabled] = React.useState<boolean>(false)

	React.useEffect(() => {
		setSwitchEnabled(modeEnabled);
	}, [modeEnabled])

	const handleSwitch = () => {
		if (!switchEnabled && !isPublicProfileExist) {
			setShowCreateProfile(true);
		} else {
			onSwitch();
		}
	}

	return (
		<>

		<ControlSwitch
          title={TEACHER_MODE_LABEL}
          checked={switchEnabled}
          sx={{
            m: 0,
			p: `${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(1.5)}`,
			bgcolor: theme.palette.warning[100],
			borderRadius: theme.spacing(.75),

			'.MuiFormControlLabel-label': {
				fontSize: theme.typography.subtitle2,
			}
          }}
          onChange={handleSwitch}
		/>

			<CreatePublicProfileModalHOC
				onClose={(isApply) => {
					setShowCreateProfile(false);
					if (isApply) {
						onSwitch();
					}
				}}
				isShow={showCreateProfile}
			/>

		</>
	);
};
