import { IState } from '../../../../store/types';
import { connect } from 'react-redux';
import { SnackbarPanel } from '../components/SnackbarPanel';
import { ISnackbarPanelEvents, ISnackbarPanelFields } from '../components/types';
import { SnackbarPanelEvents } from './events';
import { getSnackbarPanel } from '../../../../store/general/selectors';

const DEFAULT_HIDE_TIME_SEC = 5;

const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): ISnackbarPanelFields => {
    const {show, text} = getSnackbarPanel(state);
    return {
      show: show && !!text,
      text,
      hideTimeSec: DEFAULT_HIDE_TIME_SEC
    }
  };
}

const mapDispatchToProps = (
  dispatch: any
): ISnackbarPanelEvents => ({
    onClose: () => dispatch(SnackbarPanelEvents.onClose())
  }
);

export const SnackbarPanelHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(SnackbarPanel);

SnackbarPanelHOC.displayName = 'SnackbarPanelHOC';