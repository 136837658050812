import React, { useContext } from 'react';
import { theme } from '../../../../../common/styles/theme';
import { IconButton, Portal, Stack, SxProps, Tooltip, Grid, Button, SwipeableDrawer } from '@mui/material';
import { ITextSelectionPopupProps } from './types';
import { ECaptionsSelectionPopupActionButton } from '../../../../store/captions-selection-popup/types';
import { HtmlUtils } from '../../../../../common/utils/html-utils';
import { PHRASE_DETAILS_HEADER_CLASS_NAME } from '../../PhraseDetails/PhraseDetailsContainerHeader/components/types';
import { PHRASE_DETAILS_NOTES_CLASS_NAME } from '../../PhraseDetails/PhraseNotes/components/types';
import { PHRASE_DETAILS_TAB_EDITOR_CLASS_NAME } from '../../PhraseDetailsTabsEditor/PhraseDetailsTabsEditorRouter/components/types';
import { SpeakerIcon } from '../../../../../../icons/SpeakerIcon';
import { CLASS_ONBOARDING_TRIGGER } from '../../Onboarding/constants';
import { PlayIcon } from '../../../../../../icons/PlayIcon';
import { RedoIcon } from '../../../../../../icons/RedoIcon';
import { PHRASE_DETAILS_COLUMN_CLASS_NAME } from '../../PhraseDetails/PhraseDetailsContainer/components/types';
import { PHRASE_DETAILS_POPUP_MENU_CLASS_NAME } from '../../PhraseDetails/PhraseDetailsPopupMenu/components/types';
import { CopyIcon } from '../../../../../../icons/CopyIcon';
import { PencilIcon } from '../../../../../../icons/PencilIcon';
import { SparklesIcon } from '../../../../../../icons/SparklesIcon';
import { targetItemsHeightList } from '../../CaptionsContainer/TargetCaptions/components/TargetCaptions';
import { DisketteIcon } from '../../../../../../icons/DisketteIcon';
import { DeviceContext } from '../../../../../common/contexts/DeviceContext';
import { CLASS_PLAY_CONTROL } from '../../Video/components/constants';
import { PHRASE_CONTEXT_EDITOR_CLASS_NAME } from '../../PhraseContextEditor/components/types';

const rootElemClassName = 'textSelectionPopupRoot';
const rootCaptionsContainerClassName = 'captions-parent';

export const TextSelectionPopup: React.FC<ITextSelectionPopupProps> = ({
  actionButton,
  selection,
  isActive,
  selectResult,
  autoSpeechSelectText,
  playMode,
  onPreviewPhrase,
  onSavePhrase,
  onSelectText,
  onCheckCanSelect,
  onDismiss,
  onMouseEnter,
  onMouseLeave,
  onSpeechText,
  onPlay,
  onReplay,
  onUpdateActionButton,
  onClose,
  onStartPlay,
  onCopyText,

  onOpenPrompts,
  onSaveNote,
}) => {
  const {isMobile, isTouch} = useContext(DeviceContext);

  const [top, setTop] = React.useState(0);
  const [left, setLeft] = React.useState(0);
  //	let [highlightedEl, setHighlightedEl] = React.useState<HTMLElement | null>(null);
  let [parentContainer, setParentContainer] = React.useState<HTMLElement | null | undefined>(null);

  /*	const data = React.useRef({ highlightedEl });
	data.current = { highlightedEl };*/

  const parentContainerRef = React.useRef<HTMLElement>();
  parentContainerRef.current = parentContainer;

  const anchorElement = selection && selection.anchorNode && selection.anchorNode.parentElement;
  const containerElem = anchorElement && anchorElement.parentElement;

  const lineHeightHalf = 12;

  const speechFullText = () => {
    onSpeechText(true);
  };

  const getRootContainer = (element: HTMLElement | null | undefined) => {
    const parentElem = HtmlUtils.findParentByClassName(element, rootCaptionsContainerClassName);
    return parentElem || element;
  };

  const onParentContainerScroll = () => {
    calcTopPosition();
  };

	const calcTopPosition = () => {
		if (!selectResult) return;
		const index = Math.round(selectResult.startCaptionIndex + (selectResult.endCaptionIndex - selectResult.startCaptionIndex) / 2);
		let selCaptionTop = 0;
		for(let i=0; i<index; i++) {
			selCaptionTop += targetItemsHeightList[i];
		}
		const container = parentContainerRef.current;
		const contRect = container.getBoundingClientRect();
		const offset = selCaptionTop - container.scrollTop;

    let topPos;
    if (offset < 0) {
      topPos = contRect.top + 100;
    } else if (offset > contRect.height) {
      topPos = contRect.top + contRect.height - 100;
    } else {
      topPos = contRect.top + offset + 40;
    }
    setTop(topPos);
  };

  React.useEffect(() => {
    if (parentContainer && !isTouch) {
      parentContainer.addEventListener('scroll', onParentContainerScroll);
    }

    /*const observer = new IntersectionObserver(([e]) => {
			if (e.intersectionRatio === 0) {
				//onDismiss(false);
			}
		}, {
			threshold: 0
		});
		const el = selectResult?.element;
		if (el) observer.observe(el);*/

    return () => {
      if (parentContainer) {
        parentContainer.removeEventListener('scroll', onParentContainerScroll);
      }
      //		if (el) observer.unobserve(el);
    };
  }, [parentContainer, selectResult]);

  const [canSelect, setCanSelect] = React.useState(true);
  React.useEffect(() => {
    const canSelect = onCheckCanSelect(selectResult);
    setCanSelect(canSelect);

    if (!canSelect) {
      if (selectResult) {
        onPreviewPhrase(selectResult, true);
        if (autoSpeechSelectText) {
          onSpeechText(false);
        }
      }
    }

    //	const insidePhrasesSel = selectResult && selectResult.insideWordPhrases && selectResult.insideWordPhrases.length;
    const textSelected = selectResult && selectResult.text && selectResult.text.trim().length;
    if (textSelected) {
      if (selectResult) {
        onSelectText(selectResult);
      }
      onUpdateActionButton(ECaptionsSelectionPopupActionButton.SAVE_PHRASE);
      if (!isTouch) onPreviewPhrase(selectResult);
    }

    const container = getRootContainer(containerElem);
    setParentContainer(container);

    let left = 0;
    const el = document.getElementById(selectResult?.elementId || '');
    if (el) {
      setTimeout(() => {
        let rectLeft;
        rectLeft = el.getBoundingClientRect();
        if (rectLeft) {
          left = rectLeft.left - 20;
        }
        calcTopPosition();
        setLeft(left);
      });
    }
    if (autoSpeechSelectText) {
      onSpeechText(false);
    }
  }, [selectResult]);

  React.useEffect(() => {
    const onMouseDown = (e: MouseEvent) => {
      const ignoreClasses = [
        rootElemClassName,
        rootCaptionsContainerClassName,
        PHRASE_DETAILS_HEADER_CLASS_NAME,
        PHRASE_DETAILS_NOTES_CLASS_NAME,
        PHRASE_DETAILS_TAB_EDITOR_CLASS_NAME,
        PHRASE_DETAILS_COLUMN_CLASS_NAME,
        'onboarding-active',
        PHRASE_DETAILS_POPUP_MENU_CLASS_NAME,
        PHRASE_CONTEXT_EDITOR_CLASS_NAME
      ];
      const parentClasses = HtmlUtils.getParentsClasses(e.target as HTMLElement, ignoreClasses);
      const ignore = ignoreClasses.some((className) => parentClasses.includes(className));
      if (!ignore) {
        close(true);
      }
    };

    document.addEventListener('mousedown', onMouseDown);
    return () => {
      document.removeEventListener('mousedown', onMouseDown);
    };
  }, []);

  React.useEffect(() => {
    if (!isActive) {
      onClose();
    }
  }, [isActive]);

  const handleSavePhrase = async () => {
    if (canSelect && selectResult) {
      onUpdateActionButton(null);
      await onSavePhrase(selectResult);
    }
  };

  const close = (unboundClick: boolean = false) => {
    onDismiss(unboundClick);

    let sel = window.getSelection && window.getSelection() || document.getSelection && document.getSelection();
    if (sel) {
      sel.removeAllRanges && sel.removeAllRanges();
      sel.empty && sel.empty();
    }
  };

  const [visible, setVisible] = React.useState(false);
  React.useEffect(() => {
    let visible = isActive && actionButton !== null;
    if (visible) {
      setTimeout(() => setVisible(true), 50);
    } else {
      setVisible(false);
    }
  }, [actionButton, isActive]);

  React.useEffect(() => {
    if (playMode) {
      onStartPlay();
    }
  }, [playMode]);

  const buttonIconStyle: SxProps = {
    flex: `0 0 ${theme.spacing(4)}`,
    width: theme.spacing(4),
    p: 0,
    color: theme.palette.common.white,
    borderRadius: '50%',
    background: theme.palette.primary.main,
    boxShadow: theme.shadows[16],

    '.MuiSvgIcon-root': {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },

    '&:hover, &:focus': {
      background: theme.palette.primary[500],
    },
  };

  const openPrompts = () => {
    onUpdateActionButton(null);
    onOpenPrompts();
  };

  const saveNote = () => {
    onUpdateActionButton(null);
    onSaveNote();
  };

  if (isMobile) return (
    <SwipeableDrawer
			anchor={'bottom'}
			open={visible}
      hideBackdrop
			onClose={()=>close(true)}
			onOpen={()=>setVisible(true)}
      sx={{
        pointerEvents: 'none',

				'.MuiDrawer-paper': {
          pointerEvents: 'all',
        }
      }}
		>
      <Stack
        className={`${rootElemClassName}`}
        direction='row'
        justifyContent='space-around'
        alignItems='center'
        sx={{
          width: '100%',
          background: theme.palette.common.white,
          zIndex: 100,
          boxShadow: theme.shadows[16],

          button: {
            flex: 1,
            padding: theme.spacing(2),
            color: theme.palette.grey[500],
          },
        }}
      >
        <Button
          variant="text"
          startIcon={<DisketteIcon />}
          onClick={handleSavePhrase}
        >Save</Button>
        <Button
          variant="text"
          startIcon={<SparklesIcon />}
          onClick={openPrompts}
        >Prompts</Button>
        <Button
          variant="text"
          startIcon={<PencilIcon />}
          onClick={saveNote}
        >Note</Button>
      </Stack>
    </SwipeableDrawer>
  );

  return (
    <Portal container={() => document.body!}>
        <Stack
          gap={theme.spacing(0.5)}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          className={`${rootElemClassName} ${visible ? 'visible' : ''}`}
          sx={{
            position: 'absolute',
            top,
            left,
            width: theme.spacing(4),
            transform: `translate(-100%, -50%)`,
            opacity: 0,
            pointerEvents: 'none',
            zIndex: theme.zIndex.modal,

					'&.visible': {
						opacity: 1,
						pointerEvents: 'all',
					},
				}}
				onMouseDown={e => e.stopPropagation()}
				onMouseUp={e => e.stopPropagation()}
				data-popup={true}
			>
				<Tooltip
					title={canSelect ? 'Save' : 'Saved'}
					placement={'right'}
				>
					<IconButton
						className={`${CLASS_ONBOARDING_TRIGGER} ${CLASS_ONBOARDING_TRIGGER}--step7`}
						onClick={handleSavePhrase}
						color={'primary'}
						sx={{...buttonIconStyle,
							color: theme.palette.warning[900], // notes button color
							bgcolor: theme.palette.warning[100],
							boxShadow: `${theme.shadows[16]}, 0 0 6px 0 rgba(38, 45, 56, .2)`,

							'&:hover, &:focus': {
								bgcolor: '#ffdf87', // phrase hover
							},

							'.MuiSvgIcon-root': {
								width: theme.spacing(3),
								height: theme.spacing(3),
							},
							...canSelect ? null : {
								color: theme.palette.primary.main,
								background: `${theme.palette.grey[300]} !important`,
								cursor: 'default',
							}
						}}
					>
						<DisketteIcon />
					</IconButton>
				</Tooltip>

          <Tooltip title={'Replay original'} placement={'right'}>
            <IconButton
              className={CLASS_PLAY_CONTROL}
              onClick={onReplay}
              color={'primary'}
              sx={{
                ...buttonIconStyle,
                '.onboarding-active &': {
                  opacity: 0.3,
                },
              }}
            >
              <RedoIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title={'Play'} placement={'right'}>
            <IconButton
              className={CLASS_PLAY_CONTROL}
              onClick={onPlay}
              color={'primary'}
              sx={{
                ...buttonIconStyle,
                flex: `0 0 ${theme.spacing(6)}`,
                width: theme.spacing(6),
                marginLeft: theme.spacing(-1),
                '.MuiSvgIcon-root': {
                  width: theme.spacing(3),
                  height: theme.spacing(3),
                },
                '.onboarding-active &': {
                  opacity: 0.3,
                },
              }}
            >
              <PlayIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title={'Computer audio'} placement={'right'}>
            <IconButton
              onClick={speechFullText}
              color={'primary'}
              sx={{
                ...buttonIconStyle,
                '.onboarding-active &': {
                  opacity: 0.3,
                },
              }}
            >
              <SpeakerIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title={'Copy text to clipboard'} placement={'right'}>
            <IconButton
              onClick={onCopyText}
              color={'primary'}
              sx={{
                ...buttonIconStyle,
                '.MuiSvgIcon-root': {
                  width: theme.spacing(2.5),
                  height: theme.spacing(2.5),
                },
                '.onboarding-active &': {
                  opacity: 0.3,
                },
              }}
            >
              <CopyIcon />
            </IconButton>
          </Tooltip>
        </Stack>
    </Portal>
  );
};
