import * as React from 'react';
import { IUserTeacherModeSwitchProps } from './types';
import { TEACHER_MODE_LABEL } from './wordings';
import { ControlSwitch } from '../../../Controls/component/ControlSwitch';
import { theme } from '../../../../../../common/styles/theme';

export const UserTeacherModeSwitch: React.FC<IUserTeacherModeSwitchProps> = ({
	show,
	modeEnabled,
	onSwitch
}) => {
	if (!show) return null;

	return (
		<ControlSwitch
          title={TEACHER_MODE_LABEL}
          checked={modeEnabled}
          sx={{
            m: 0,
			p: `${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(1.5)}`,
			bgcolor: theme.palette.warning[100],
			borderRadius: theme.spacing(.75),

			'.MuiFormControlLabel-label': {
				fontSize: theme.typography.subtitle2,
			}
          }}
          onChange={onSwitch}
        />
	);
};
