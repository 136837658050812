import React from 'react';
import { theme } from '../../../../../../common/styles/theme';
import { Box, Button, CardMedia, IconButton, Paper, Stack } from '@mui/material';
import { IVideoTutorialPopupProps } from './types';
import { Modal } from '../../../../../../common/components/Modal/Modal';
import { EVideoTutorialType, TVideoTutorial } from '../../../../../store/models/types';
import { CommentIcon } from '../../../../../../../icons/CommentIcon';
import { DeviceContext } from '../../../../../../common/contexts/DeviceContext';
import { ButtonIconGrey } from '../../../../../../common/components/ButtonIconGrey/ButtonIconGrey';
import { ArrowLightLeftIcon } from '../../../../../../../icons/ArrowLightLeftIcon';
import { Logo } from '../../../../../../../icons/Logo';
import { IYoutubeVideoInfoFull } from '../../../../../types/common';
import { Text } from '../../../../../../common/components/Text/Text';

export const VideoTutorialPopup: React.FC<IVideoTutorialPopupProps> = ({
	tutorialList,
	activeId,
	videoId,
	onLoad,
	onChangeActive,
	onClose,
	onClosed,
	onClickFeedback,
	onPlay,
	loadVideoInfo,
}) => {
	const { isMobile } = React.useContext(DeviceContext);

	const className = 'VideoTutorialPopup';

	const player = React.useRef(null);
	const playerStart = React.useRef({});
	const playerTime = React.useRef({});
	const currentActiveId = React.useRef(0);
	const lastActiveId = React.useRef(0);
	const [playerReady, setPlayerReady] = React.useState<boolean>(false);

	const [videoInfoMap, setVideoInfoMap] = React.useState<Record<string, IYoutubeVideoInfoFull>>({});
	const data = React.useRef({ videoInfoMap });
	data.current = { videoInfoMap };

	React.useEffect(() => {
		const fetchData = async (
			tutoral: TVideoTutorial
		) => {
			const videoInfo: IYoutubeVideoInfoFull = await loadVideoInfo(tutoral.videoId);
			data.current.videoInfoMap[tutoral.title] = videoInfo;
			setVideoInfoMap({
				...data.current.videoInfoMap,
				[tutoral.title]: videoInfo,
			});
		}

		tutorialList.forEach(t => {
			if (!videoInfoMap[t.title]) {
				fetchData(t);
			}
		});

	}, [tutorialList]);

	React.useEffect(() => {
		requestAnimationFrame(() => {

			function init() {
				player.current = new window.YT.Player('ytplayer', {
					videoId,
					playerVars: {
						autoplay: 1,
						showinfo: 0,
						autohide: 1,
						modestbranding: 1,
					},
					events: {
						'onStateChange': (event: any) => {
							if (event.data == window.YT.PlayerState.PLAYING) {
								const id = currentActiveId.current || activeId;
								if (!playerStart.current[id]) {
									playerStart.current[id] = true;
									onPlay(id);
								}
							}
						},
						onReady: () => {
							setPlayerReady(true);
						}
					}
				});
			}
			if (!document.getElementById('ytplayer')) {
				let div = document.createElement('div');
				div.id = 'ytplayer';
				document.getElementById('ytplayer-container')?.append(div);
			}

			if (window.YT && window.YT.Player) {
				init();
			} else if (document.getElementById('yt_iframe_api')) {
				let callback = window.onYouTubeIframeAPIReady;
				window.onYouTubeIframeAPIReady = () => {
					if (callback) callback();
					init();
				}
			} else {
				let tag = document.createElement('script');
				tag.src = 'https://www.youtube.com/iframe_api';
				tag.id = 'yt_iframe_api';
				let firstScriptTag = document.getElementsByTagName('script')[0];
				firstScriptTag?.parentNode?.insertBefore(tag, firstScriptTag);
				window.onYouTubeIframeAPIReady = init;
			}

			if (activeId && player.current) {
				currentActiveId.current = activeId;
			}
		});

		return () => {
			if (activeId && player.current && player.current.getCurrentTime) {
				playerTime.current[activeId] = player.current.getCurrentTime().toFixed(2);
				lastActiveId.current = activeId;
			}
		}
	}, [activeId]);

	React.useEffect(() => {
		onLoad();

		return () => {
			onClosed(lastActiveId.current, playerTime.current);
		}
	}, []);

	return <>
		<Modal
			open={activeId !== null && activeId !== undefined}
			onClose={onClose}
			PaperProps={{
				sx: {
					position: 'fixed',
					top: 0,
					left: 0,
					width: `100vw`,
					height: `100vh`,
					maxHeight: `100vh !important`,
					background: theme.palette.secondary.main,
					borderRadius: 0,

					'& > .MuiStack-root': {
						gap: `0 !important`,
						p: `0 !important`,
						maxHeight: `100% !important`,
					},
				},
			}}
		>
			<Stack
				sx={{
					position: 'relative',
					height: 1,
				}}
			>
				<Paper
					className={className}
					sx={{
						position: 'sticky',
						top: 0,
						zIndex: 100,
						flex: `0 0 ${theme.spacing(8)}`,
						py: 1.5,
						px: 2,
						borderRadius: 0,
						boxShadow: theme.shadows[8],

						'.mobile &': {
							p: 1,
						}
					}}
				>
					<Stack
						direction={'row'}
						alignItems={'center'}
						gap={theme.spacing(1)}
						sx={{
							width: 1,
							height: 1,
						}}
					>
						<ButtonIconGrey
							sx={{
								'.MuiSvgIcon-root': {
									width: theme.spacing(3),
									height: theme.spacing(3),
								},
							}}
							onClick={onClose}
						>
							<ArrowLightLeftIcon />
						</ButtonIconGrey>

						<IconButton
							sx={{
								p: 0,
							}}
							onClick={onClose}
						>
							<Logo sx={{ height: theme.spacing(4), width: 'auto' }} />
						</IconButton>

						{!isMobile &&
							<Stack
								className={`${className}__title`}
								direction={'row'}
								alignItems={'center'}
								gap={theme.spacing(1)}
								sx={{
									ml: theme.spacing(2),
									fontSize: theme.typography.h4,
									fontWeight: 600,
									whiteSpace: 'nowrap',
								}}
							>
								Help Center
							</Stack>
						}

						<Button
							variant={'contained'}
							color={'primary'}
							startIcon={<CommentIcon
								sx={{
									width: `${theme.spacing(2.5)} !important`,
									height: `${theme.spacing(2.5)} !important`,
									color: theme.palette.grey[400],
								}}
							/>}
							sx={{
								ml: theme.spacing(2),
								color: theme.palette.grey[700],
								background: theme.palette.grey[50],

								'&:hover': {
									background: theme.palette.grey[100],
								},
							}}
							onClick={onClickFeedback}
						>
							Send Feedback
						</Button>
					</Stack>
				</Paper>

				<Box
					sx={{
						flex: `1 1 100%`,
						minHeight: 0,
						width: 1,
						p: 2,

						overflow: 'auto',
					}}
				>
					<Paper
						sx={{
							width: 1,
							p: 3,
						}}
					>
						<Box
							sx={{
								display: 'grid',
								gridTemplateColumns: `repeat(auto-fit, minmax(220px, 1fr))`,
								gap: 3,

								[theme.breakpoints.down('sm')]: {
									gridTemplateColumns: `1fr`,

									[`.${className}__img`]: {
										maxWidth: theme.spacing(20),
									},
								}
							}}
						>
							{tutorialList.map(video => {
								let desc = video.description || videoInfoMap[video.title]?.description;
								return <Stack
									key={video.type}
									onClick={() => onChangeActive(video.type)}
									gap={.2}
									sx={{
										cursor: 'pointer',
									}}
								>
									<CardMedia
										className={`${className}__img`}
										component='img'
										image={videoInfoMap[video.title]?.thumbnails.standard.url}
										alt={video.title}
										sx={{
											mb: 1,
											aspectRatio: '16/9',
											borderRadius: theme.spacing(.75),
											background: theme.palette.grey[100],
										}} />
									<Text
										sx={{
											fontSize: theme.typography.subtitle2,
										}}
									>
										{video.title}
									</Text>
									{desc &&
										<Text
											sx={{
												display: '-webkit-box',
												WebkitLineClamp: 4,
												WebkitBoxOrient: 'vertical',
												overflow: 'hidden',
											}}
										>
											{desc}
										</Text>
									}
								</Stack>
							})}
						</Box>
					</Paper>
				</Box>
			</Stack>
		</Modal>

		<Modal
			open={!!activeId}
			onClose={() => onChangeActive(EVideoTutorialType.OPEN)}
			PaperProps={{
				sx: {
					borderRadius: 0,
					background: 'none !important',

					'.modal__close': {
						position: 'fixed',
						color: theme.palette.grey[900],
					},

					'& > .MuiStack-root': {
						position: 'relative',
						p: `0 !important`,
						alignItems: 'center',
						overflow: 'visible !important',

						'iframe': {
							width: `95vw !important`,
							height: `90vh !important`,
							borderRadius: theme.spacing(1.5),
							border: 'none',
						}
					},
				},
			}}
		>
			<div id={'ytplayer-container'} />
		</Modal>
	</>;
}
