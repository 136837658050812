import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { PhraseTeacherNotes } from '../components/PhraseTeacherNotes';
import { IPhraseTeacherNotesEvents, IPhraseTeacherNotesFields } from '../components/types';
import {
  getVideoTeacherByNoteId,
  getVideoTeacherShowNote,
  isExistPhrasesFromTeacherPhrase
} from '../../../../../store/videos/selectors';
import { PhraseTeacherNotesEvents } from './events';

const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): IPhraseTeacherNotesFields => {
    const note = getVideoTeacherShowNote(state);
    const saved = note && isExistPhrasesFromTeacherPhrase(state, note.phraseId);
    const user = getVideoTeacherByNoteId(state, note?.id)
    return {
      text: note?.text || '',
      saved,
      createUserName: user?.user.name
    }
  };
}

const mapDispatchToProps = (
  dispatch: any
): IPhraseTeacherNotesEvents => ({
    onCopy: () => dispatch(PhraseTeacherNotesEvents.onCopy()),
    onClickAuthor: () => dispatch(PhraseTeacherNotesEvents.onClickAuthor()),
  }
);

export const PhraseTeacherNotesHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(PhraseTeacherNotes);

PhraseTeacherNotesHOC.displayName = 'PhraseTeacherNotesHOC';