import React from 'react';
import { CardMedia, IconButton, MenuItem, Stack } from '@mui/material';
import { Text } from '../../../../../../common/components/Text/Text';
import { ITeacherListProps } from './types';
import { TeacherPhraseListHOC } from '../../TeacherPhraseList/hocs/TeacherPhraseListHOC';
import { TextUtils } from '../../../../../../common/utils/text-utils';
import { TEACHER_LIST_MENU_SAVE_NOTES, TEACHER_LIST_MENU_VIEW_PROFILE, TEACHER_LIST_TEXT } from './wordings';
import { theme } from '../../../../../../common/styles/theme';
import { CloseIcon } from '../../../../../../../icons/CloseIcon';
import { ButtonMenu } from '../../../../../../common/components/ButtonMenu/ButtonMenu';

export const TeacherList: React.FC<ITeacherListProps> = ({
	show,
	activeId,
	teacherList,
	onTeacherSelect,
	onLoadNotes,
	onViewProfile,
}) => {
	if (!show) return null;

	const CLASS_CONTROL = 'class-controll';

	const onClickHandler = (e: React.SyntheticEvent, id: number) => {
		if (id === activeId) return;

		const target = e.target as HTMLElement;
		const control = target.closest(`.${CLASS_CONTROL}`);
		if (control) return;

		onTeacherSelect(id)
	};

	const [menuOpened, setMenuOpened] = React.useState(false);

	const [text, setText] = React.useState('');
	React.useEffect(() => {
		let wording = TextUtils.getPlural(teacherList.length, TEACHER_LIST_TEXT);
		let text = TextUtils.getText(wording, [teacherList.length]);
		setText(text);
	}, [teacherList]);

	return (
		<Stack
			gap={.5}
		>
			<Text
				sx={{
					fontSize: theme.typography.subtitle2,
				}}
			>
				{text}
			</Text>
			<Stack
				sx={{
					mb: theme.spacing(1.5),
				}}
			>
				{teacherList.map(t => {
					const active = t.user.id === activeId;
					return <Stack
						className={active ? 'active' : ''}
						direction={'row'}
						gap={1}
						alignItems={'center'}
						key={t.user.id}
						onClick={(e) => onClickHandler(e, t.user.id)}
						sx={{
							width: 1,
							px: theme.spacing(.5),
							borderRadius: theme.spacing(.75),
							cursor: 'pointer',

							'&:hover': {
								background: theme.palette.grey[50],
							},

							'&.active': {
								cursor: 'default',
								background: theme.palette.primary[100],
							},
						}}
					>
						<CardMedia
							component='img'
							image={t.user.picture}
							alt={t.user.name}
							sx={{
								flex: `0 0 ${theme.spacing(3)}`,
								height: theme.spacing(3),
								borderRadius: '50%',
							}}
						/>
						<Text
							sx={{
								flex: `1 1 100%`,
								minWidth: 0,
								height: theme.spacing(3),
								fontSize: theme.typography.subtitle2,
								fontWeight: 400,
								overflow: 'hidden',
							}}
						>
							{t.user.name}
						</Text>
						{active &&
							<IconButton
								onClick={() => onTeacherSelect(t.user.id)}
								color={'primary'}
								sx={{
									mr: theme.spacing(-1.5),
									py: theme.spacing(.75),
									pr: theme.spacing(.5),
									color: theme.palette.grey[400],
									'&:hover, &:active, &:focus': { color: theme.palette.grey[500] },
								}}
							>
								<CloseIcon />
							</IconButton>
						}
						<ButtonMenu
							buttonProps={{
								className: CLASS_CONTROL,
								sx: {
									px: theme.spacing(.5),
								},
							}}
							menuProps={{
								className: CLASS_CONTROL,
								sx: {
									'.MuiMenuItem-root': {
										px: 2,
									},
								},
								open: menuOpened,
							}}
							onClose={()=>setMenuOpened(false)}
							onOpen={()=>setMenuOpened(true)}
						>
							<MenuItem
								onClick={() => {
									onLoadNotes(t.user.id);
									setMenuOpened(false);
								}}
							>
								{TEACHER_LIST_MENU_SAVE_NOTES}
							</MenuItem>
							<MenuItem
								onClick={() => onViewProfile(t.user.id)}
							>
								{TEACHER_LIST_MENU_VIEW_PROFILE}
							</MenuItem>
						</ButtonMenu>
					</Stack>
				})}
			</Stack>

			{!!activeId && <TeacherPhraseListHOC />}
		</Stack>
	);
}
