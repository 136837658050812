import { IState } from '../types';
import { IPhraseSelectResult } from '../../effects/phrase/utils/phrase-select-preparator';
import { ECaptionsSelectionPopupActionButton, ISavePhraseModePopup, TCurrentSelection } from './types';

export class CaptionsSelectionPopupSelectors {

  public static isShow(state: IState): boolean {
    return state.captionsSelectionPopup.show;
  }

  public static getPreviewPhraseId(state: IState): number {
    return state.captionsSelectionPopup.previewPhraseId;
  }

  public static getLastSelectText(state: IState): string | undefined {
    return state.captionsSelectionPopup.lastSelectText;
  }

  public static getSelectResult(state: IState): IPhraseSelectResult | undefined | null {
    return state.captionsSelectionPopup.selectResult;
  }

  public static getLastSelectResult(state: IState): IPhraseSelectResult | undefined | null {
    return state.captionsSelectionPopup.lastSelectResult;
  }

  public static getActionButton(state: IState): ECaptionsSelectionPopupActionButton | null {
    return state.captionsSelectionPopup.actionButton;
  }

  public static getSelection(state: IState): Selection | undefined {
    return state.captionsSelectionPopup.selection;
  }

  public static getAutoSpeechText(state: IState): boolean {
    return state.captionsSelectionPopup.autoSpeechText;
  }

  public static getPlayingSelectText(state: IState): boolean {
    return state.captionsSelectionPopup.playingSelectText;
  }

  public static getCurrentSelection(state: IState): TCurrentSelection {
    return state.captionsSelectionPopup.currentSelection;
  }

  public static getSavePhraseModePopup(state: IState): ISavePhraseModePopup {
    return state.captionsSelectionPopup.savePhraseModePopup;
  }
}
