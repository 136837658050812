import * as React from 'react';
import { TTextModel } from './helper/textPartHelperEffect';
import { Box } from '@mui/material';
import { theme } from '../../styles/theme';
import { HighLightedSpan } from './HighLightedSpan';
import { TextPartUtil } from './helper/textPartUtil';

export enum EHighlightedType {
    Highlighted,
    Normal
}

export type THighLightedTextProps = {
    text: string;
    highLighted: string[];
    highlightedStartPos?: number
    underscore?: boolean
}


export const HighLightedText: React.FC<THighLightedTextProps> = ({
    highLighted,
    text,
  highlightedStartPos,
  underscore
}) => {
    const [textParts, setTextParts] = React.useState<TTextModel[]>([]);

    React.useEffect(() => {
        setTextParts(TextPartUtil.getTextParts(text, highLighted.join(''), highlightedStartPos || 0));
    }, [text, highLighted.join('')]);


    const style = underscore ? {
        // textDecoration: 'underline dotted'
    } : {};

    return (
      <Box sx={style}>
       {/* <React.Fragment>*/}
            {
                textParts.map((textPart, idx) => {
                    const isStart = idx === 0;
                    const isEnd = idx === textParts.length - 1;
                    return textPart.type === EHighlightedType.Highlighted ?
                        <HighLightedSpan
                            key={idx}
                            isStart={idx === 0 || (textPart[idx - 1] && textPart[idx - 1].type !== EHighlightedType.Highlighted)}
                            isEnd={idx === textParts.length -1 || (textPart[idx + 1] && textPart[idx + 1].type !== EHighlightedType.Highlighted)}
                            style={{ backgroundColor: '#dcb8ff' }}
                        >
                            {textPart.text}
                        </HighLightedSpan>
                        :
                        <Box
                            key={idx}
                            component='span'
                            dangerouslySetInnerHTML={{ __html: textPart.text || '' }}
                            sx={{
                                // bgcolor: '#CEE2FD',
                                padding: '4px 0',
                                fontFamily: theme.typography.fontFamily,
                                fontSize: theme.typography.subtitle2
                            }}
                        />;
                })
            }
      {/*  </React.Fragment>*/}
          </Box>
    );
};
