import React from 'react';
import { Stack } from '@mui/material';
import { Text } from '../../../../../../common/components/Text/Text';
import { theme } from '../../../../../../common/styles/theme';
import { ISavedVideoConfirmErrorProps } from './types';

export const SavedVideoConfirmError: React.FC<ISavedVideoConfirmErrorProps> = ({
                                                                          errorMessage
}) => {

  return (
    <Stack >
      <Text
				sx={{
					fontSize: theme.typography.subtitle2,
				}}
			>
				<Text
					sx={{
						mt: -2,
						fontSize: theme.typography.h6,
						fontWeight: 600,
					}}
				>
					An error occurred while adding the video:
				</Text>
				{errorMessage}
			</Text>

    </Stack>
  );
}
