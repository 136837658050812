import { IState } from '../../../../store/types';
import { connect } from 'react-redux';
import { PhraseContextEditor } from '../components/PhraseContextEditor';
import { IPhraseContextEditorEvents, IPhraseContextEditorFields } from '../components/types';
import { PhraseContextEditorSelectors } from '../../../../store/phrase-context-editor/selectors';
import { PhraseContextEditorEvents } from './events';
import { findVideoPhraseById, getTargetCaptions } from '../../../../store/videos/selectors';
import { TTargetCaptionsPhraseInfo } from '../../CaptionsContainer/TargetCaptions/components/helper';
import { EVocabularyPhraseType, ICaptionsItem, IVocabularyPhrase } from '../../../../types/common';

const SHOW_CAPTIONS_OFFSET = 10;

const getPhrasesInfo = (
	state: IState,
	phraseId: number,
): TTargetCaptionsPhraseInfo[] => {
	let phrases: IVocabularyPhrase[] = [];
	const previewPhrase = PhraseContextEditorSelectors.getPreviewContextPhrase(state);
	if (phraseId) { // saved phrase		
		const savedPhrase = findVideoPhraseById(state, phraseId);
		if (savedPhrase) {
			phrases.push(previewPhrase || savedPhrase);
			if (savedPhrase.wordPhraseId) {
				const wordPhrase = findVideoPhraseById(state, savedPhrase.wordPhraseId);
				if (wordPhrase) {
					phrases.push(wordPhrase);
				}
			}
		}
	} else { // selected phrase
		let phrasesSelected = PhraseContextEditorSelectors.getPhrases(state) || [];
		phrases = previewPhrase
			? [previewPhrase, phrasesSelected.find(item => !item.wordPhraseId) as any]
			: phrasesSelected;
		phrases = phrases.map(p => {
			let type = p.type;
			if (p.type === EVocabularyPhraseType.PREVIEW_WORD_SELECTED) {
				type = EVocabularyPhraseType.WORD_SELECTED
			} else if (p.type === EVocabularyPhraseType.PREVIEW_WORD_AND_CONTEXT_SELECTED) {
				type = EVocabularyPhraseType.WORD_AND_CONTEXT_SELECTED
			}
			return type === p.type ? p : {
				...p,
				...{type}
			}
		})
	}

	return phrases.map(phrase => {
		return { phrase }
	});
}

const getActiveCaptionIndex = (phrase?: IVocabularyPhrase): number => {
	if (!phrase) return 0;
	const lineCount = phrase.endCaptionIndex - phrase.startCaptionIndex;
	return Math.round(phrase.startCaptionIndex + lineCount / 2);
}

const getCaptions = (state: IState, phrase?: IVocabularyPhrase): ICaptionsItem[] => {
	const list = getTargetCaptions(state);
	let startIndex = (phrase?.startCaptionIndex || 0) - SHOW_CAPTIONS_OFFSET;
	startIndex = startIndex < 0 ? 0 : startIndex;
	let endIndex = (phrase?.endCaptionIndex || 0) + SHOW_CAPTIONS_OFFSET;
	endIndex = endIndex >= list.length ? list.length - 1 : endIndex;
	return list.slice(startIndex, endIndex);
}

const mapStateToProps = (
	dispatch: any
) => {
	return (
		state: IState
	): IPhraseContextEditorFields => {
		const show = PhraseContextEditorSelectors.getShow(state);
		let activeCaptionIndex = 0;
		let startCaptionIndex = 0;
		let allowSave = false;

		if (!show) return {
			show,
			activeCaptionIndex,
			startCaptionIndex,
			captions: [],
			phrases: [],
			allowSave,
		};

		const phraseId = PhraseContextEditorSelectors.getPhraseId(state);
		let isSavedPhrase;
		let phrase;
		if (phraseId) { // saved phrase
			phrase = findVideoPhraseById(state, phraseId);
			isSavedPhrase = true;
		} else { // selected phrase
			let phrases = PhraseContextEditorSelectors.getPhrases(state);
			//if (phrases && phrases.length) phrase = phrases.find(item => !item.wordPhraseId);
			if (phrases?.length) {
				phrase = phrases.find(item => {
					return item.type === EVocabularyPhraseType.WORD_AND_CONTEXT_SELECTED || item.type === EVocabularyPhraseType.PREVIEW_WORD_AND_CONTEXT_SELECTED;
				});
			}
		}
		 
		const selectResult = PhraseContextEditorSelectors.getSelectResult(state);
		allowSave = !!(selectResult && selectResult.insideWordPhrases && selectResult.insideWordPhrases.length > 0);
		startCaptionIndex = (phrase?.startCaptionIndex || 0) - SHOW_CAPTIONS_OFFSET;
		startCaptionIndex = startCaptionIndex < 0 ? 0 : startCaptionIndex;
		activeCaptionIndex = getActiveCaptionIndex(phrase) - startCaptionIndex;

		return {
			show,
			activeCaptionIndex,
			startCaptionIndex,
			captions: getCaptions(state, phrase),
			phrases: getPhrasesInfo(state, phraseId),
			allowSave,
			isSavedPhrase,
		}
	};
}

const mapDispatchToProps = (
	dispatch: any
): IPhraseContextEditorEvents => ({
	onClose: () => dispatch(PhraseContextEditorEvents.onClose()),
	onSelectText: selection => dispatch(PhraseContextEditorEvents.onSelectText(selection)),
	onDeSelectText: () => dispatch(PhraseContextEditorEvents.onDeSelectText()),
	onSave: () => dispatch(PhraseContextEditorEvents.onSave()),
});

export const PhraseContextEditorHOC = connect(
	mapStateToProps,
	mapDispatchToProps
)(PhraseContextEditor);

PhraseContextEditorHOC.displayName = 'PhraseContextEditorHOC';
