import { PhraseNoteRest } from '../../../common/rest/phraseNote/phraseNoteRest';

export class PhraseNoteImageEffects {

  private static PARAM_RE = new RegExp(/data:([^;]+);base64/g);

  public static async upload(data: string): Promise<string> {
    const contentType = this.getContentType(data.substring(0, 50));
    const _data = this.extractData(data);
    const url = await PhraseNoteRest.saveImage(_data, contentType);
    return url;
  }

  private static getContentType(data: string) {
    const result = [...data.matchAll(PhraseNoteImageEffects.PARAM_RE)];
    if (result.length > 0 && result[0].length > 1) {
      return result[0][1];
    }
  }

  private static extractData(data: string): string {
    const prefix = ';base64,';
    const ps = data.indexOf(prefix);
    let _data = data;
    if (ps >= 0) {
      return data.substring(ps + prefix.length)
    }
    return data;
  }

}