import { AppThunk } from '../../../../../store/types';
import { ContentLibEvents } from '../../effects';

export class ContentLibGroupDetailsEvents {
    public static onSelectGroup(groupId: number): AppThunk {
        return async (
          dispatch,
          getState
        ) => {
          dispatch(ContentLibEvents.openGroup(groupId));
        }
    }
}