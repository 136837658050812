import { IState } from '../../../../../store/types';
import { ContentLibSelectors } from '../../../../../store/contentLib/selectors';
import { connect } from 'react-redux';
import { ProfileVideoPopup } from '../components/ProfileVideoPopup';
import { IProfileVideoPopupEvents, IProfileVideoPopupFields } from '../components/types';
import { ProfileVideoPopupEvents } from './events';
import { findLanguageById, getUserGroupsByLangs } from '../../../../../store/models/selectors';
import { IProfileVideoPopupHOCProps } from './types';

const mapStateToProps = (
) => {
  return (
    state: IState,
    { video }: IProfileVideoPopupHOCProps,
  ): IProfileVideoPopupFields => {
    
    return {
      video,
    }
  };
}

const mapDispatchToProps = (
  dispatch: any,
  { onClose }: IProfileVideoPopupHOCProps,
): IProfileVideoPopupEvents => ({
    onClose,
    onSave: targetGroupId => dispatch(ProfileVideoPopupEvents.onSave(targetGroupId, onClose)),
  }
);

export const ProfileVideoPopupHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileVideoPopup);

ProfileVideoPopupHOC.displayName = 'ProfileVideoPopupHOC';
