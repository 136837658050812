import { ESystemHotKeys, IShortcutHandler } from '../types';
import { getAutoPause, getCurrentFocusArea } from '../../../store/general/selectors';
import { getDispatch, getState } from '../../../store';
import { ECurrentFocusArea } from '../../../store/general/types';
import { PlayerController } from '../../player/manager/playerController';
import { setAutoPauseAction } from '../../../store/general/actions';
import { addPhraseByHotKey } from '../../phrase/phraseVocabularyEffect';
import { EventsRouter } from '../../../../common/events/eventsRouter';
import { Events } from '../../../../common/events/types';
import { PlayerManager } from '../../player/manager/playerManager';
import { UserSettingEffects } from '../../userSettingEffects';

export class CaptionsShortcutHandler implements IShortcutHandler {

  public async handle(keyCode: ESystemHotKeys) {
    const dispatch = getDispatch();

    switch (keyCode) {
      case ESystemHotKeys.SPACE:
        PlayerController.getInstance().togglePlayPause();
        break;

      case ESystemHotKeys.Q:
        const pause = getAutoPause(getState());
        UserSettingEffects.setAutoPause(pause ? 0 : 1);
        break;

      case ESystemHotKeys.R:
        dispatch(addPhraseByHotKey(await PlayerManager.getInstance().getCurrentTime()));
        break;

      case ESystemHotKeys.S:
      case ESystemHotKeys.ArrowDown:
        PlayerController.getInstance().repeat();
        break;

      case ESystemHotKeys.A:
      case ESystemHotKeys.ArrowLeft:
        PlayerController.getInstance().playPrev();
        break;

      case ESystemHotKeys.D:
      case ESystemHotKeys.ArrowRight:
        PlayerController.getInstance().playNext();
        break;
    }
  }

}