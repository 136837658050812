import { getDispatch, getState } from '../store';
import { setLibraryGroupIdAction } from '../store/general/actions';
import { getUserStateStorage } from '../../common/utils/local-storage/user-state';
import { TUserGroup, TUserLanguage } from '../store/models/types';
import { LangUtil } from '../../common/utils/lang-util';
import { ELanguageStatus } from '../store/general/types';
import { createUserGroup } from '../../common/rest/userGroup/createUserGroup';
import { getUserGroupById } from '../store/models/selectors';
import { updateUserGroup } from '../../common/rest/userGroup/updateUserGroup';
import { addGroup, refreshGroupAction, updateGroupAction } from '../store/models/actions';
import { fetchUserGroup } from '../../common/rest/userGroup/fetchUserGroup';

export class UserGroupEffects {

  public static setLibraryGroupId(groupId: number) {
    const dispatch = getDispatch();
    dispatch(setLibraryGroupIdAction(groupId));
    getUserStateStorage().setLibraryGroupId(groupId);
  }

  public static async createGroup(title: string, targetLangCode: string, nativeLangCode: string, createDefVideos: boolean = false): Promise<number> {
    const dispatch = getDispatch();
    const nativeLanguage: TUserLanguage = {name: LangUtil.getLangNameByCode(nativeLangCode), code: nativeLangCode, status: ELanguageStatus.KNOW };
    const targetLanguage: TUserLanguage = {name: LangUtil.getLangNameByCode(targetLangCode), code: targetLangCode, status: ELanguageStatus.TO_LEARN };
    return dispatch(createUserGroup(title, [nativeLanguage, targetLanguage], createDefVideos));
  }

  public static async updateGroup(groupId: number, name: string, targetLangCode?: string, nativeLangCode?: string) {
    const dispatch = getDispatch();
    const group = getUserGroupById(getState(), groupId);
    if (group && (group.name != name || targetLangCode || nativeLangCode)) {
      await dispatch(updateUserGroup({
        id: groupId,
        name: name,
        targetLangCode,
        nativeLangCode
      }));
      let languages = undefined;
      if (targetLangCode && nativeLangCode) {
        const targetLang: TUserLanguage = {
          ...LangUtil.getLanguageItemByCode(targetLangCode),
          status: ELanguageStatus.TO_LEARN
        }
        const nativeLang: TUserLanguage = {
          ...LangUtil.getLanguageItemByCode(nativeLangCode),
          status: ELanguageStatus.KNOW
        }
        languages = [targetLang, nativeLang]
      }
      dispatch(updateGroupAction(groupId, name, languages));
    }
  }


  public static async loadGroup(groupId: number): Promise<TUserGroup> {
    const dispatch = getDispatch();
    const createdGroup = await dispatch(fetchUserGroup(groupId));
    dispatch(addGroup(createdGroup));
    return createdGroup;
  }

  public static async refreshGroup(groupId: number): Promise<TUserGroup> {
    const dispatch = getDispatch();
    const group = await dispatch(fetchUserGroup(groupId));
    dispatch(refreshGroupAction(group));
    return group;
  }
}
