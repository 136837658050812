import { AppThunk } from '../../../../../store/types';
import { PhraseDetailsActions } from '../../../../../store/phrase-details/actions';
import { getAuthUser } from '../../../../../store/general/selectors';
import { PhraseDetailsTranslateManager } from '../../../../../effects/phrase-details/translate/phraseDetailsTranslateManager';
import { IPhrasesDetailTranslateLangResult } from '../../../../../store/phrase-details/types';
import { PhraseDetailsSelectors } from '../../../../../store/phrase-details/selectors';
import { PhraseNoteEffects } from '../../../../../effects/phraseNoteEffects';
import { EventsRouter } from '../../../../../../common/events/eventsRouter';
import { Events } from '../../../../../../common/events/types';
import { StatLogManager } from '../../../../../../common/stats/statLogManager';

export class PhraseTranslationEvents {

  public static onLoad(): AppThunk {
    return async(
      dispatch,
      getState
    ) => {
      const state = getState();
      const user = getAuthUser(state);
      if (user) {
        EventsRouter.trackEvent(Events.TRANSLATION_LOADED);
        dispatch(PhraseDetailsActions.setTranslateResult(null, true, false));
        const text =/* PhraseDetailsSelectors.getContext(state) ||
          PhraseDetailsSelectors.getCaptionLineContext(state) ||*/
          PhraseDetailsSelectors.getSelectText(state);
        const results: IPhrasesDetailTranslateLangResult[] =
          await PhraseDetailsTranslateManager.translateLangsForUser(user, text || '');
        dispatch(PhraseDetailsActions.setTranslateResult(results, false, true));
        StatLogManager.logTranslation();
      }
    }
  }

  public static onRefresh(toLang: string): AppThunk {
    return async(
      dispatch,
      getState
    ) => {
      const state = getState();
      const user = getAuthUser(state);
      if (user) {
        const text = PhraseDetailsSelectors.getSelectText(state);
        const result = await PhraseDetailsTranslateManager.translateLang(text, toLang);
        dispatch(PhraseDetailsActions.setTranslateLangResult(result));
      }
    }
  }

  public static onCopyNote(): AppThunk {
    return async(
      dispatch,
      getState
    ) => {
      const text = PhraseDetailsSelectors.getTranslateResultText(getState())
        ?.filter(r => r.success)
        .map(r => r.result)
        .join("\n\n");
      PhraseNoteEffects.copyToNoteText(text);
    }
  }





}