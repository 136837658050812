export class UserLangsTranslationColors {

  private static DEFAULT_COLOR: string = 'black';

  private static COLORS_MAP: Record<string, string> = {
    'en': '#2A8EF8',
    'zh': '#E74BA3',
    'es': '#67B757',
    'ru': '#DD524C',
    'de': '#FFB047',
    'pt': '#11C8E3',
    'fr': '#11C8E3'
  }

  public static getColor(langCode: string) {
    return UserLangsTranslationColors.COLORS_MAP[langCode] || UserLangsTranslationColors.DEFAULT_COLOR;
  }
}

