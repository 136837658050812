import { AppThunk } from '../../../../../store/types';
import { getCurrentMovieKey } from '../../../../../store/current-video/selectors';
import { removeVideoFromGroup } from '../../../../../effects/phrase/phraseVocabularyEffect';
import { History } from 'history';
import { Navigator } from '../../../../../effects/navigator';
import { PlayerController } from '../../../../../effects/player/manager/playerController';
import { PhraseListActions } from '../../../../../store/phrase-list/actions';
import { getOnboarding } from '../../../../../store/general/selectors';
import { setOnboardingAction, setSnackbarPanelAction } from '../../../../../store/general/actions';
import { CopyVideoEffects } from '../../../../../effects/copyVideoEffects';

export class VideoLibraryGroupItemEvents {

  public static onDelete (
    history: History,
    videoId: string,
    userGroupId: number
  ): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      const currentVideoId = getCurrentMovieKey(getState());
      await dispatch(removeVideoFromGroup(videoId, userGroupId));
      dispatch(PhraseListActions.removeByVideoGroup(videoId, userGroupId));
      if (currentVideoId === videoId) {
        Navigator.openGroup(history, userGroupId);
      }
    }
  }

  public static onClick (
    history: History,
    videoId: string,
    userGroupId: number
  ): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      PlayerController.getInstance().resetMode();
      Navigator.openVideo(userGroupId, videoId);

      const onboarding = getOnboarding(getState());
      if (onboarding && onboarding.isActive && !onboarding.step) {
        dispatch(setOnboardingAction({ step: 1 }))
      }
    }
  }

  public static onGetCopyLink (
    videoId: string,
    groupId: number
  ): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      const url = await CopyVideoEffects.createCopyLink(videoId, groupId);
      await navigator.clipboard.writeText(url);
      dispatch(setSnackbarPanelAction(true, 'Link to public video copied to clipboard'));
    }
  }

}
