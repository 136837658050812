import { IState } from '../../../../../store/types';
import { ContentLibSelectors } from '../../../../../store/contentLib/selectors';
import { connect } from 'react-redux';
import { ContentLibGroupList } from '../components/ContentLibGroupList';
import { IContentLibGroupListEvents, IContentLibGroupListFields } from '../components/types';
import { ContentLibGroupListEvents } from './events';


const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): IContentLibGroupListFields => {
    const {text, nativeLangCode, targetLangCode} = ContentLibSelectors.getSearchFilter(state);
    const {loading, groups} = ContentLibSelectors.getSearchResult(state);
    return {
      groups,
      loading,
      text,
      nativeLangCode,
      targetLangCode,
      viewMode: ContentLibSelectors.getViewMode(state),
    }
  };
};

const mapDispatchToProps = (
  dispatch: any
): IContentLibGroupListEvents => ({
    onSearch: () => dispatch(ContentLibGroupListEvents.onSearch())
  }
);

export const ContentLibGroupListHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(ContentLibGroupList);

ContentLibGroupListHOC.displayName = 'ContentLibGroupListHOC';