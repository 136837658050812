import { ICaptionsItem, IPhraseNote, IVocabularyPhrase } from '../../types/common';
import {
  ADD_VIDEO,
  ADD_VIDEO_PHRASE_ACTION,
  DELETE_VIDEO_PHRASE_ACTION,
  IAddVideoAction,
  IAddVideoPhraseAction,
  IDeleteVideoPhraseAction,
  IPhraseNoteTimeScale,
  IResetVideoAction,
  ISavePhraseNoteAction,
  ISetPhrasesNotesListsAction,
  ISetPhraseTimeScaleAction,
  ISetVideoCaptionsAction,
  ISetVideoCaptionsEmptyAction,
  ISetVideoTeacherAction, ISetVideoTeacherSelectPhraseIdAction,
  ISetVideoTeacherShowNoteIdAction, ISetVideoTeacherShowPhraseIdAction,
  ISetVideoTeacherUserActiveIdAction,
  ITeacherUser,
  IUpdatePhraseNoteAction,
  IUpdateVideoPhraseAction,
  IVideoItem,
  RESET_VIDEO,
  SAVE_PHRASE_NOTE,
  SET_PHRASE_TIME_SCALES,
  SET_PHRASES_NOTES_LISTS_ACTION,
  SET_VIDEO_CAPTIONS,
  SET_VIDEO_CAPTIONS_EMPTY,
  SET_VIDEO_TEACHER_ACTION, SET_VIDEO_TEACHER_SELECT_PHRASE_ID_ACTION,
  SET_VIDEO_TEACHER_SHOW_NOTE_ID_ACTION,
  SET_VIDEO_TEACHER_SHOW_PHRASE_ID_ACTION,
  SET_VIDEO_TEACHER_USER_ACTIVE_ID_ACTION,
  UPDATE_PHRASE_NOTE,
  UPDATE_VIDEO_PHRASE_ACTION
} from './types';

export const addVideoPhraseAction = (
  phrase: IVocabularyPhrase,
): IAddVideoPhraseAction => ({
  type: ADD_VIDEO_PHRASE_ACTION,
  phrase,
});

export const updateVideoPhraseAction = (
  phrase: IVocabularyPhrase,
): IUpdateVideoPhraseAction => ({
  type: UPDATE_VIDEO_PHRASE_ACTION,
  phrase
});

export const deleteVideoPhraseAction = (
  phraseId: number
): IDeleteVideoPhraseAction => ({
  type: DELETE_VIDEO_PHRASE_ACTION,
  phraseId
});

export const addVideoAction = (video: IVideoItem): IAddVideoAction => ({
  type: ADD_VIDEO,
  video
});

export const resetVideoAction = (): IResetVideoAction => ({
  type: RESET_VIDEO
});

export const setVideoCaptionsEmpty = (
  captionsEmpty: boolean
): ISetVideoCaptionsEmptyAction => ({
  type: SET_VIDEO_CAPTIONS_EMPTY,
  captionsEmpty
});


export const setVideoCaptionsAction = (
  code: string,
  captions: ICaptionsItem[]
): ISetVideoCaptionsAction => ({
  type: SET_VIDEO_CAPTIONS,
  code,
  captions,
});

export const savePhraseNoteAction = (
  note: IPhraseNote
): ISavePhraseNoteAction => ({
  type: SAVE_PHRASE_NOTE,
  note
});

export const updatePhraseNoteAction = (
  note: Partial<IPhraseNote>
): IUpdatePhraseNoteAction => ({
  type: UPDATE_PHRASE_NOTE,
  note
});

export const setPhraseTimeScaleAction = (
  phraseNoteTimeScales: IPhraseNoteTimeScale[]
): ISetPhraseTimeScaleAction => ({
  type: SET_PHRASE_TIME_SCALES,
  phraseNoteTimeScales
});

export const setPhrasesNotesListsAction = (
  phrases: IVocabularyPhrase[],
  notes: IPhraseNote[]
): ISetPhrasesNotesListsAction => ({
  type: SET_PHRASES_NOTES_LISTS_ACTION,
  phrases,
  notes
});

export const setVideoTeacherUserActiveIdAction = (
  id: number
): ISetVideoTeacherUserActiveIdAction => ({
  type: SET_VIDEO_TEACHER_USER_ACTIVE_ID_ACTION,
  id
});

export const setVideoTeacherShowNoteIdAction = (
  id: number
): ISetVideoTeacherShowNoteIdAction => ({
  type: SET_VIDEO_TEACHER_SHOW_NOTE_ID_ACTION,
  id
});

export const setVideoTeacherShowPhraseIdAction = (
  id: number
): ISetVideoTeacherShowPhraseIdAction => ({
  type: SET_VIDEO_TEACHER_SHOW_PHRASE_ID_ACTION,
  id
});

export const setVideoTeacherSelectPhraseIdAction = (
  id: number
): ISetVideoTeacherSelectPhraseIdAction => ({
  type: SET_VIDEO_TEACHER_SELECT_PHRASE_ID_ACTION,
  id
});

export const setVideoTeacherAction = (
  userId: number,
  data: Partial<ITeacherUser>
): ISetVideoTeacherAction => ({
  type: SET_VIDEO_TEACHER_ACTION,
  userId,
  data
});