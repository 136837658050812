import { AppThunk } from '../../../../store/types';
import { PlayerFactory } from '../../../../effects/player/player-factory';
import { updateVideoPosition } from '../../../../../common/rest/video/updateVideoPosition';
import { YtUtils } from '../../../../effects/player/yt-utils';
import { IPlayerApiProps } from '../../../../effects/player/player-api-context';
import { getMainViewMode, isPageLocked } from '../../../../store/general/selectors';
import { loadVideoEffect } from '../../../../effects/video_effects';
import { getCurrentMovieKey } from '../../../../store/current-video/selectors';
import { getUserStateStorage } from '../../../../../common/utils/local-storage/user-state';
import { PhraseListSelectors } from '../../../../store/phrase-list/selectors';
import { PlayerController } from '../../../../effects/player/manager/playerController';
import { ShortcutManager } from '../../../../effects/shortcut/shortcutManager';
import { setMainViewMode } from '../../../../store/general/actions';
import { EMainViewMode } from '../../../../store/general/types';
import { historyPush } from '../../../../../common/utils/historyHelper';
import { CaptionsSelectionPopupActions } from '../../../../store/captions-selection-popup/actions';
import { PhraseDetailsActions } from '../../../../store/phrase-details/actions';
import { isValidUserGroupVideo } from '../../../../store/models/selectors';
import { Navigator } from '../../../../effects/navigator';

export class VideoEvents {

  public static onInit(
    videoId: string
  ): AppThunk {
    return async ():Promise<IPlayerApiProps>  => {
      const playerApi = await YtUtils.configure(/*videoId*/ '');
      return playerApi;
    }
  }

  public static onLoad(
    videoId: string,
    groupId: number,
    phraseId: number,
    pause: boolean,
  ): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      const playerApi = await PlayerFactory.getPlayerApi();
      const state = getState();
      if (!isValidUserGroupVideo(state, groupId, videoId)) {
        Navigator.openDefault();
        return;
      }

      if (phraseId) {
        let phrase = PhraseListSelectors.findPhraseById(state, phraseId);
        const startPosition = phrase ? phrase.startTime : 0;
        const onVideoLoaded = () => {
          if (phrase) {
            PlayerController.getInstance().playPhrase(phrase);
          }
        }
        dispatch(loadVideoEffect(videoId, playerApi, groupId, pause, startPosition, onVideoLoaded));
      } else {
        dispatch(loadVideoEffect(videoId, playerApi, groupId, pause));
      }
      getUserStateStorage().setVideoInfo(videoId, groupId, phraseId);
    }
  }

  public static onUnload(
    groupId: number
  ): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      const videoId = getCurrentMovieKey(getState());
      if (videoId && groupId) {
        const playerApi = await PlayerFactory.getPlayerApi();
        dispatch(updateVideoPosition(videoId, groupId, await playerApi.player.getCurrentTime()));
      }
    }
  }

  public static onUpdateVideoId(
    videoId: string
  ): AppThunk {
    return (
      dispatch
    ) => {
      dispatch(PhraseDetailsActions.disActivate()); // mobile
    }
  }

  public static onUpdateGroupId(
    groupId: number
  ): AppThunk {
    return (
      dispatch
    ) => {
    }
  }

  public static onKeyUp(event: KeyboardEvent): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      const pageLocked = isPageLocked(getState());
      if (!pageLocked) {
        ShortcutManager.keyUp(event);
      }
    }
  }

  public static onKeyDown(event: KeyboardEvent): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      const pageLocked = isPageLocked(getState());
      if (!pageLocked) {
        ShortcutManager.keyDown(event);
      }
    }
  }

  public static onChangeTab(value: any): AppThunk {
    return async(
      dispatch,
      getState
    ) => {
      console.log(value)
    }
  }

  public static openNotes(): AppThunk {
    return async(
      dispatch,
      getState
    ) => {
      const mainViewModePrev = getMainViewMode(getState());
      dispatch(setMainViewMode(EMainViewMode.PHRASE_DETAILS));

      historyPush({
        state: { page: 'Notes' },
        title: 'Notes',
        onpopstate: () => dispatch(setMainViewMode(mainViewModePrev))
      });
    }
  }

  public static onCloseTranslation(): AppThunk {
    return async(
      dispatch,
      getState
    ) => {
      dispatch(CaptionsSelectionPopupActions.setShow(false));
    }
  }

  public static onClick(): AppThunk {
    return async(
      dispatch,
      getState
    ) => {
      PlayerController.getInstance().pause();
    }
  }

}