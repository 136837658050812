import { AppThunk } from '../../../../../store/types';
import { PhraseDetailsActions } from '../../../../../store/phrase-details/actions';
import { PhraseDetailsExplainService } from '../../../../../effects/phrase-details/phraseDetailsService/phrase-details-explain-service';
import { PhraseDetailsSelectors } from '../../../../../store/phrase-details/selectors';
import { PhraseDetailsTabsEditorPopupActions } from '../../../../../store/phrase-details-tabs-editor-popup/actions';
import { setMainViewMode } from '../../../../../store/general/actions';
import { EMainViewMode } from '../../../../../store/general/types';
import { EPhraseDetailsTabsEditorViewMode } from '../../../../../store/phrase-details-tabs-editor-popup/types';
import { PhraseContextEditorActions } from '../../../../../store/phrase-context-editor/actions';
import { GptUtils } from '../../../../../effects/gptUtils';
import { PhraseNoteEffects } from '../../../../../effects/phraseNoteEffects';
import { StatLogManager } from '../../../../../../common/stats/statLogManager';

export class PhraseExplainEvents {

	public static onLoad(useCache: boolean): AppThunk {
		return async (
			dispatch,
			getState
		) => {
			const state = getState();
			dispatch(PhraseDetailsActions.setExplainResult('', true, false));
			const text = PhraseDetailsSelectors.getText(state) || '';
			const context = PhraseDetailsSelectors.getContext(state) || PhraseDetailsSelectors.getCaptionLineContext(state) || '';
			const fromLang = PhraseDetailsSelectors.getFromLang(state);
			const toLang = PhraseDetailsSelectors.getToLang(state);
			const result = await new PhraseDetailsExplainService(text, context, fromLang?.code || '', toLang?.code || '').load(useCache);
			StatLogManager.logPromptAccess();
			if (result !== undefined) {
				dispatch(PhraseDetailsActions.setExplainResult(result, false, true));
			}
		}
	}

	public static onClickSettings(): AppThunk {
		return async (
			dispatch,
			getState
		) => {
			const tab = PhraseDetailsSelectors.getActiveTab(getState());
			dispatch(setMainViewMode(EMainViewMode.PHRASE_DETAILS_TABS_EDITOR));
      		dispatch(PhraseDetailsTabsEditorPopupActions.setViewMode(EPhraseDetailsTabsEditorViewMode.CREATE));
			dispatch(PhraseDetailsTabsEditorPopupActions.setEditTabId(tab?.id || 0));
		}
	}

	public static onClickContext(): AppThunk {
		return async (
			dispatch,
			getState
		) => {
			dispatch(PhraseContextEditorActions.updatePhraseContextEditorAction({
				show: true,
			}));
		}
	}

	public static onCopyNote(): AppThunk {
		return async (
			dispatch,
			getState
		) => {
			const text = GptUtils.clear(PhraseDetailsSelectors.getExplainResultText(getState()) || '');
			PhraseNoteEffects.copyToNoteText(text);
		}
	}


}