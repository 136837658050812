import React from 'react';
import { InstallLoginHOC } from '../../install/Login/hocs/install/InstallLoginHOC';
import { InstallSelectLangsHOC } from '../../install/SelectLangs/hocs/InstallSelectLangsHOC';
import { theme } from '../../../../common/styles/theme';
import { CssBaseline, Stack, ThemeProvider } from '@mui/material';
import { MainContainerHOC } from '../MainContainer/hocs/MainContainerHOC';
import { HistoryProrvider } from '../../../effects/historyProrvider';
import { Route, Router, Switch } from 'react-router-dom';
import { AddVideoHOC } from '../AddVideo/hocs/AddVideoHOC';
import { CopyVideoHOC } from '../CopyVideo/CopyVideo/hocs/CopyVideoHOC';
import { CopyGroupHOC } from '../CopyGroup/hocs/CopyGroupHOC';
import { AddVideoUrlHOC } from '../AddVideoUrl/hocs/AddVideoUrlHOC';
import { polyfillCountryFlagEmojis } from 'country-flag-emoji-polyfill';
import { DeviceContext, IDeviceContext, MOBILE_BREAKPOINT } from '../../../../common/contexts/DeviceContext';
import { GlobalStyle } from './GlobalStyle';
import { PublicProfileHOC } from '../PublicProfile/PublicProfile/hocs/PublicProfileHOC';
import { SnackbarPanelHOC } from '../SnackbarPanel/hocs/SnackbarPanelHOC';

export const App: React.FC = () => {
	const history = HistoryProrvider.getHistory();

	let [deviceContext, _setDeviceContext] = React.useState<IDeviceContext>({});
	const setDeviceContext = (
		value: Partial<IDeviceContext>,
	) => {
		deviceContext = {
			...deviceContext,
			...value,
		};
		_setDeviceContext(deviceContext);
	};

	React.useEffect(() => {

		polyfillCountryFlagEmojis();

		let isMobile;
		let isTouch;
		let scrollWidth;

		const onResize = () => {
			let isMobileNew = window.innerWidth < MOBILE_BREAKPOINT;
			if (isMobile !== isMobileNew) {
				isMobile = isMobileNew;
				if (isMobile) {
					document.documentElement.classList.add('mobile');
				} else {
					document.documentElement.classList.remove('mobile');
				}
				setDeviceContext({ isMobile });
			}
		};
		onResize();
		window.addEventListener('resize', onResize);

		const detectTouch = () => {
			const navigator = window.navigator as any;
			isTouch = ('ontouchstart' in window) ||
				(navigator.maxTouchPoints > 0) ||
				(navigator.msMaxTouchPoints > 0);
			const touchClass = isTouch ? 'touch' : 'no-touch';
			document.documentElement.classList.add(touchClass);
		};
		detectTouch();

		const detectScroll = () => {
			const testWidth = 100;
			const test1 = document.createElement('div');
			document.body.append(test1);
			test1.style.overflow = 'scroll';
			test1.style.width = testWidth + 'px';
			const test2 = document.createElement('div');
			test1.append(test2);
			scrollWidth = testWidth - test2.offsetWidth;
			const scrollClass = scrollWidth ? 'scroll-visible' : 'scroll-hidden';
			document.documentElement.classList.add(scrollClass);
			test1.remove();
		};
		detectScroll();

		setDeviceContext({
			isMobile,
			isTouch,
			scrollWidth,
		});

		setTimeout(() => { // detect scrollWidth after style apply
			detectScroll();
			setDeviceContext({
				scrollWidth,
			});
		}, 100)

		const handlePopState = (e: Event) => {
			setTimeout(()=>{
				if (window.onpopstates && window.onpopstates.length) {
					window.onpopstate = window.onpopstates.pop();
				}
			})
			
		}

		window.addEventListener('popstate', handlePopState);
		return () => {
			window.removeEventListener('resize', onResize);
			window.removeEventListener('popstate', handlePopState);
		}
	}, []);

	return (
		<Router history={history}>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				{deviceContext.scrollWidth !== undefined &&
					<GlobalStyle />
				}
				<DeviceContext.Provider value={deviceContext}>
					<Stack
						alignItems={'center'}
						sx={{
							height: 1,
							width: 1,
							pt: '',
						}}
					>
						<Switch>
							<Route exact path='/install/login'>
								<InstallLoginHOC />
							</Route>
							<Route exact path='/install/selectLangs'>
								<InstallSelectLangsHOC />
							</Route>
							<Route exact path='/addvideo/:groupId/:videoId'>
								<AddVideoHOC /> {/* remove after update extension */}
							</Route>
							<Route exact path='/addvideourl/:videoUrl'>
								<AddVideoUrlHOC />
							</Route>
							<Route exact path='/copyVideo/:hash'>
								<CopyVideoHOC />
							</Route>
							<Route exact path='/copyGroup/:hash'>
								<CopyGroupHOC />
							</Route>
							<Route exact path='/@:nick'>
								<>
									<PublicProfileHOC />
									<SnackbarPanelHOC />
								</>
							</Route>
							<Route>
								<MainContainerHOC />
							</Route>
						</Switch>
					</Stack>
				</DeviceContext.Provider>
			</ThemeProvider>
		</Router>
	);
};
