import { Box, Tooltip } from '@mui/material';
import React from 'react';
import { IContentLibVideoProps } from './type';
import Stack from '@mui/material/Stack';
import { Text } from '../../../../../../common/components/Text/Text';
import { theme } from '../../../../../../common/styles/theme';

export function ContentLibVideo({
	title,
	thumbUrl,
	channel,
	onClick
}: IContentLibVideoProps) {

	const [isLongTitle, setLongTitle] = React.useState(false);
	const ref1 = React.useRef(null);
	const ref2 = React.useRef(null);

    React.useEffect(() => {
        if (!isLongTitle && ref1.current && ref2.current) {
			//@ts-ignore
			if (ref2.current.offsetHeight > ref1.current.offsetHeight) {
				setLongTitle(true);
			}
		}
    }, [title]);

	const titleElement = <Text
		lineHeight={`1.3 !important`}
		sx={{
			display: '-webkit-box',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			'-webkit-line-clamp': '2',
			'-webkit-box-orient': 'vertical',
			maxHeight: '2.6em',

			fontSize: theme.typography.subtitle2,
			fontWeight: 600,
		}}
		ref={ref1}
	>
		<span
			ref={ref2}
		>
			{title}
		</span>
	</Text>

	return (
		<Stack
			sx={{
				flex: `1 1 100%`,
				minWidth: 0,
				cursor: 'pointer',
			}}
			gap={1}
			onClick={onClick}
		>
			<Box
				sx={{
					aspectRatio: 16/9,
					backgroundPosition: '50% 50%',
					backgroundSize: 'cover',
					backgroundRepeat: 'no-repeat',
					backgroundImage: `url(${thumbUrl})`,
					borderRadius: theme.spacing(1),
				}}
			/>

			<Stack
				gap={.25}
			>
				{isLongTitle
					? <Tooltip
						enterDelay={1000}
						title={title}
					>
						{titleElement}	
					</Tooltip>
					: titleElement
				}

				<Text
					sx={{
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						fontSize: theme.typography.subtitle2,
						color: theme.palette.grey[500],
						whiteSpace: 'nowrap',
					}}
				>
					{channel}
				</Text>
			</Stack>
		</Stack>

	)
};