import { IState } from '../../../../../store/types';
import { getAuthUser } from '../../../../../store/general/selectors';
import { connect } from 'react-redux';
import { UserTeacherModeSwitch } from '../components/UserTeacherModeSwitch';
import { IUserTeacherModeSwitchEvents, IUserTeacherModeSwitchFields } from '../components/types';
import { UserTeacherModeSwitchEvents } from './events';

const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): IUserTeacherModeSwitchFields => {
    const user = getAuthUser(state)
    return {
      show: user.teacher,
      modeEnabled: user.teacherMode
    }
  };
}

const mapDispatchToProps = (
  dispatch: any
): IUserTeacherModeSwitchEvents => ({
    onSwitch: () => dispatch(UserTeacherModeSwitchEvents.onSwitch())
  }
);

export const UserTeacherModeSwitchHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserTeacherModeSwitch);

UserTeacherModeSwitchHOC.displayName = 'UserTeacherModeSwitchHOC';