import { ContentLibRest } from '../../common/rest/contentLib/contentLibRest';
import { ContentLibActions } from '../store/contentLib/actions';
import { getDispatch, getState } from '../store';
import { ContentLibSelectors } from '../store/contentLib/selectors';
import { createGroupNameIndex, findLanguageById, getUserGroupById } from '../store/models/selectors';
import { UserGroupEffects } from './userGroupEffects';
import { fetchUserGroup } from '../../common/rest/userGroup/fetchUserGroup';
import { addGroup, addVideoInGroup } from '../store/models/actions';
import { updateUserGroups } from './updateUserGroups';
import { TUserGroup } from '../store/models/types';
import { EContentLibViewMode, IContentLibVideo } from '../store/contentLib/types';

export class ContentLibEffects {


  public static async searchGroups() {
    const dispatch = getDispatch();
    const state = getState();
    const {targetLangCode, nativeLangCode, text} = ContentLibSelectors.getSearchFilter(state);
    dispatch(ContentLibActions.setSearchResult({ loading: true }));
    try {
      const groups = await ContentLibRest.searchGroups({
        targetLangCode,
        nativeLangCode,
        searchText: text,
        maxVideoCount: 100
      });
      dispatch(ContentLibActions.setSearchResult({ groups, loading: false }));
    } catch(e) {
      console.error(e)
      dispatch(ContentLibActions.setSearchResult({ groups: [], loading: false }));
    }
  }

  public static async searchVideos(groupId: number, searchText: string, startIndex: number, count: number): Promise<IContentLibVideo[]> {
    return ContentLibRest.videoListPart(groupId, searchText, startIndex, count);
  }

  public static async copyVideo(contentLibGroupId: number, contentLibVideoId: number, targetGroupId: number) {
    const state = getState();
    const dispatch = getDispatch();
    const contentLibGroup = ContentLibSelectors.findGroupById(state, contentLibGroupId);
    const targetGroup = getUserGroupById(state, targetGroupId);
    if (!targetGroup) {
      const targetLangCode = findLanguageById(state, contentLibGroup?.targetLangId || 0)?.code || '';
      const nativeLangCode = findLanguageById(state, contentLibGroup?.nativeLangId || 0)?.code || '';
      let groupName = createGroupNameIndex(state, contentLibGroup?.title || '');
      targetGroupId = await UserGroupEffects.createGroup(groupName, targetLangCode, nativeLangCode);
      const createdGroup = await dispatch(fetchUserGroup(targetGroupId));
      dispatch(addGroup(createdGroup));
    }
    const video = await ContentLibRest.copyVideo(contentLibVideoId, targetGroupId) as any;
    dispatch(addVideoInGroup(video, targetGroupId));
    return {videoId: video.videoKey, groupId: targetGroupId}
  }

  public static async copyGroup(contentLibGroupId: number): Promise<TUserGroup> {
    const dispatch = getDispatch();
    const group: TUserGroup = await ContentLibRest.copyGroup(contentLibGroupId);
    await dispatch(updateUserGroups(true));
    return group;
  }
}