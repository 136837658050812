import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { TargetCaptions } from '../components/TargetCaptions';
import { IPlayerListenerHolder, ITargetCaptionsEvents, ITargetCaptionsFields } from '../components/types';
import { TargetCaptionsEvents } from './events';
import {
  findVideoPhraseById,
  getAllVideoPhrasesWithFilterOrphans,
  getTargetCaptions,
  getVideoTeacherPhrases,
  getVideoTeacherUserActiveId
} from '../../../../../store/videos/selectors';
import {
  getCurrentMovieKey,
  getSelectedNativeCaption,
  getSelectedTargetCaption
} from '../../../../../store/current-video/selectors';
import { ITargetCaptionsHOCOwnProps } from './types';
import { EVocabularyPhraseType, ICaptionsItem, PlayerStates } from '../../../../../types/common';
import { CaptionsSelectionPopupSelectors } from '../../../../../store/captions-selection-popup/selectors';
import { getAuthUser, getPlayerState } from '../../../../../store/general/selectors';
import { PhraseListSelectors } from '../../../../../store/phrase-list/selectors';
import { PhraseContextEditorSelectors } from '../../../../../store/phrase-context-editor/selectors';
import { PlayerManager } from '../../../../../effects/player/manager/playerManager';
import { EPhraseListMode } from '../../../../../store/phrase-list/types';

const getForwardCaptionIndex = (
  state: IState,
  captions: ICaptionsItem[],
): number => {
  const playPhraseId = PhraseListSelectors.getPlayPhraseId(state);
  const forwardPhraseId = PhraseListSelectors.getForwardPhraseId(state);
  const phraseId = playPhraseId || forwardPhraseId;

  const user = getAuthUser(state);
  const isTeacher = user?.teacherMode;
  let teacherId = isTeacher ? user.id : getVideoTeacherUserActiveId(state);
  let phrase;
  if (isTeacher || teacherId) {
    const teacherPhrases = isTeacher
      ? PhraseListSelectors.findPhrasesByTypes(state, [
        EVocabularyPhraseType.DEFAULT,
        EVocabularyPhraseType.WORD_AND_CONTEXT_SELECTED,
        EVocabularyPhraseType.LESSON
      ])
        .filter(phrase => phrase.fullPhrase?.trim()?.length || phrase.type === EVocabularyPhraseType.LESSON)
      : getVideoTeacherPhrases(state, teacherId) || [];
    const phraseIndex = teacherPhrases.findIndex(p => p.id === phraseId);
    phrase = teacherPhrases[phraseIndex];

    if (phrase && phrase.type === EVocabularyPhraseType.LESSON) {
      if (phraseIndex <= 0 || phraseIndex >= teacherPhrases.length-1) { // first and last annotations scroll captions to top
        return 0;
      }
      let phraseStartTime = teacherPhrases[phraseIndex].startTime;
      return captions.findIndex(caption => caption.startTime > phraseStartTime);
    }

  } else {
    phrase = phraseId ? findVideoPhraseById(state, phraseId) : null;
  }
  return phrase ? phrase.startCaptionIndex : -1;
}

const listenerHolder: IPlayerListenerHolder = {
  addListener: (listener: any) => {
    PlayerManager.getInstance().addListener(listener);
  },
  removeListener: (listener: any) => {
    PlayerManager.getInstance().removeListener(listener);
  }
}

const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState,
    { elementRef, isSelectPopup }: ITargetCaptionsHOCOwnProps
  ): ITargetCaptionsFields => {
    const nativeLanguage = getSelectedNativeCaption(state);
    const targetLanguage = getSelectedTargetCaption(state);
    const solo = isSelectPopup || !nativeLanguage || nativeLanguage.code === targetLanguage?.code;
    const langCode = getSelectedTargetCaption(state)?.code || '';
    const phrasesAll = getAllVideoPhrasesWithFilterOrphans(state);
    let phrases = [];
    if (isSelectPopup) {
      phrases = phrasesAll.filter(item => item.type === EVocabularyPhraseType.PREVIEW_WORD_SELECTED || item.type === EVocabularyPhraseType.PREVIEW_WORD_AND_CONTEXT_SELECTED);
    } else {
      const phraseIdList = phrasesAll.map(p => p.id)
      const editorPhrases = PhraseContextEditorSelectors.getPhrases(state)?.filter(p => !phraseIdList.includes(p.id));

      phrases = [
        ...phrasesAll,
        ...editorPhrases || []
      ]
    }
    
    const captions = getTargetCaptions(state);

    return {
      elementRef,
      captions,
      phrases,
      videoId: getCurrentMovieKey(state),
      solo,
      langCode,
      isShowCaptionsSelectionPopup: CaptionsSelectionPopupSelectors.isShow(state),
      selectResult: CaptionsSelectionPopupSelectors.getSelectResult(state),
      playerActive: getPlayerState(state) === PlayerStates.PLAYING,
      forwardCaptionIndex: getForwardCaptionIndex(state, captions),
      isSelectPopup,
      listenerHolder,
      currentSelectionCaptionIndex: CaptionsSelectionPopupSelectors.getCurrentSelection(state).captionIndex || -1
    }
  };
}

const mapDispatchToProps = (
  dispatch: any,
  {onChangeActiveIndex, onCaptionMouseEnter, onCaptionMouseLeave, onScrolling, isSelectPopup}: ITargetCaptionsHOCOwnProps
): ITargetCaptionsEvents => ({
    onCaptionClick: (index, caption, wordIndex, isMobile) =>
      dispatch(TargetCaptionsEvents.onCaptionClick(index, caption, wordIndex, isMobile)),
    onCaptionPlay: (caption, isPause) => dispatch(TargetCaptionsEvents.onCaptionPlay(caption, isPause)),
    onMouseEnter: () => dispatch(TargetCaptionsEvents.onMouseEnter()),
    onMouseLeave: () => dispatch(TargetCaptionsEvents.onMouseLeave()),
    onSelectText: (selection, element) => dispatch(TargetCaptionsEvents.onSelectText(selection, element, isSelectPopup)),
    onDismissSelectText: () => dispatch(TargetCaptionsEvents.onDismissSelectText()),
    onChangeActiveIndex: index => {
      dispatch(TargetCaptionsEvents.onChangeActiveIndex(index));
      onChangeActiveIndex(index);
    },
    onCaptionMouseEnter: index => onCaptionMouseEnter(index),
    onCaptionMouseLeave: index => onCaptionMouseLeave(index),
    onCaptionMouseDown: index => dispatch(TargetCaptionsEvents.onCaptionMouseDown()),
  }
);

export const TargetCaptionsHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(TargetCaptions);

TargetCaptionsHOC.displayName = 'TargetCaptionsHOC';
