import React from 'react';
import { useParams } from 'react-router-dom';
import { IAddVideoUrlProps } from './types';
import { YoutubeUrlUtil } from '../../../../../common/utils/youtube-url-util';

export const AddVideoUrl: React.FC<IAddVideoUrlProps> = ({
  onLoad
}) => {

  const { videoUrl } = useParams();

  React.useEffect(() => {
    const url = YoutubeUrlUtil.decodeUrl(videoUrl);
    console.log('url', url)
    onLoad(url);
  }, []);

  return null;
};
