import { AppThunk } from '../../../../store/types';
import { PhraseSelectPopupActions } from '../../../../store/phrase-select-popup/actions';
import { PhraseDetailsSelectors } from '../../../../store/phrase-details/selectors';
import { PhraseDetailsCustomPromptTestService } from '../../../../effects/phrase-details/phraseDetailsService/phrase-details-custom-prompt-test-service';
import { PhraseDetailsTabsEditorPopupActions } from '../../../../store/phrase-details-tabs-editor-popup/actions';

export class PhraseSelectPopupEvents {

  public static onClose(): AppThunk {
    return async(
      dispatch,
      getState
    ) => {
      dispatch(PhraseSelectPopupActions.setShow(false));
      const input = document.querySelector('.prompt-input') as HTMLInputElement;
      if (input) {
        const state = getState();
        let text = PhraseDetailsSelectors.getText(state) || '';
        if (text) {
          let context = PhraseDetailsSelectors.getContext(state) || PhraseDetailsSelectors.getCaptionLineContext(state) || '';
          let fromLang = PhraseDetailsSelectors.getFromLang(state);
          let toLang = PhraseDetailsSelectors.getToLang(state);
          const service = new PhraseDetailsCustomPromptTestService(text, context, fromLang?.code || '', toLang?.code || '', input.textContent || '');
          dispatch(PhraseDetailsTabsEditorPopupActions.setPromptTestPrompt(await service.getPreparedPrompt()));
        }
      }
    }
  }

  public static onSave(): AppThunk {
    return async(
      dispatch,
      getState
    ) => {
      dispatch(this.onClose());
    }
  }


}