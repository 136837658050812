import React from 'react';
import { ILoginFeatureProps } from './types';
import { Stack, Typography } from '@mui/material';
import { theme } from "../../../../../common/styles/theme";

export const LoginFeature: React.FC<ILoginFeatureProps> = ({
    title,
    imgUrl,
    icon
}) => {
    const containerStyle = {
        position: 'relative',
        gap: theme.spacing(4),
    };

    const imgStyle = {
        maxWidth: '100%',
    };

    const iconStyle = {
        position: 'absolute',
        right: -24,
        top: -16,
    };

    return (
        <Stack sx={containerStyle}>
            <Stack component={'img'} sx={imgStyle} src={imgUrl} />
            <Typography variant={'h6'} lineHeight="1.3" fontWeight={600} textAlign={'center'} sx={{ pl: theme.spacing(1.5) }}>
                {title}
            </Typography>
            {icon &&  <Stack sx={iconStyle}>{icon}</Stack>}
        </Stack>
    );
}
