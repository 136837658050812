import Stack from '@mui/material/Stack';
import { ContentLibVideoHOC } from '../../ContentLibVideo/hocs/ContentLibVideoHOC';
import React from 'react';
import { IContentLibGroupDetailsProps } from './types';
import { theme } from '../../../../../../common/styles/theme';
import { ContentLibGroupInfoHOC } from '../../ContentLibGroupInfo/hocs/ContentLibGroupInfoHOC';
import { Box } from '@mui/material';
import { CONTENT_LIB_LIMIT, CONTENT_LIB_WIDTHS } from '../../constants';

export function ContentLibGroupDetails({
	id,
	videos,
	videoCount,
	onClickMore,
}: IContentLibGroupDetailsProps) {

	const className = 'content-lib-group-details';

	const [limit, setLimit] = React.useState(0);

	React.useEffect(() => {
        function onResize () {
			const w = window.innerWidth;
			let limit = 0;
			for (let i = 0; i<CONTENT_LIB_WIDTHS.length; i++) {
				if (w < CONTENT_LIB_WIDTHS[i]) {
					limit = CONTENT_LIB_LIMIT[i];
				} else break;
			}
			setLimit(limit);
		};
		onResize();

        window.addEventListener('resize', onResize);
        return () => {
			window.removeEventListener('resize', onResize);
		}
    }, []);

	const videosLimited = limit && videoCount > videos.length
		? videos.slice(0, limit)
		: videos;

	return (
		<Stack
			className={`${className}
				limit_${limit}
				content-lib-group-${id}
			`}
			gap={2}
			sx={{
				py: 3,

				'& + &': {
					borderTop: `2px solid ${theme.palette.grey[200]}`
				},
			}}
		>
			<ContentLibGroupInfoHOC
				groupId={id}
			/>

			<Box
				sx={{
					position: 'relative',
					display: 'grid',
					gridTemplateColumns: `repeat(5, 1fr)`,
					gap: 2,

					[`.${className}.limit_0 &`]: {
						gridTemplateColumns: `repeat(7, 1fr)`,
					},

					[`.${className}.limit_5 &`]: {
						gridTemplateColumns: `repeat(3, 1fr)`,
					},

					[`.${className}.limit_3 &`]: {
						gridTemplateColumns: `repeat(2, 1fr)`,
					}
				}}
			>
				{videosLimited.map((video) => {
					return (
						<ContentLibVideoHOC
							groupId={id}
							videoId={video.id}
							key={video.id}
						/>
					)
				})}
				{(videoCount > videosLimited.length) &&
					<Stack
						justifyContent={'center'}
						alignItems={'center'}
						gap={1}
						onClick={onClickMore}
						sx={{
							flex: `1 1 100%`,
							minWidth: 0,
							cursor: 'pointer',
							aspectRatio: 16/9,
							color: theme.palette.grey[400],
							borderRadius: theme.spacing(1),
							background: theme.palette.grey[50],
							
							'&:hover': {
								color: theme.palette.grey[500],
								background: theme.palette.grey[100],
							},
						}}
					>
						<Stack
							justifyContent={'center'}
							alignItems={'center'}
							sx={{
								width: theme.spacing(4),
								height: theme.spacing(4),
								fontSize: theme.typography.h3,
								color: theme.palette.grey[400],
								borderRadius: '50%',
								border: `1px solid ${theme.palette.grey[400]}`,
							}}
						>
							+
						</Stack>
						<Box
							sx={{
								fontSize: theme.typography.subtitle2,
							}}
						>
							More videos
						</Box>
					</Stack>
				}
			</Box>
		</Stack>
	)
};