import { connect } from 'react-redux';
import { AddVideoConfirm } from '../components/AddVideoConfirm';
import { IState } from '../../../../store/types';
import { getAddVideoConfirm } from '../../../../store/general/selectors';
import { IAddVideoConfirmEvents, IAddVideoConfirmFields } from '../components/types';
import { AddVideoConfirmEvents } from './events';
import { createGroupNameIndex, getGroupTargetLanguage, getNativeLanguage, getTargetLanguage, getUserGroupById, getUserOwnGroups } from '../../../../store/models/selectors';
import { TUserGroup } from '../../../../store/models/types';
import { VideoMetaInfo } from '../../../../../common/utils/videoMetaInfo';
import { LangUtil } from '../../../../../common/utils/lang-util';

const mapStateToProps = (
	dispatch: any
) => {
	return (
		state: IState
	): IAddVideoConfirmFields => {
		const {
			show: isOpen = false,
			targetGroupId = 0,
			videoInfo,
			videoLangCodes = [],
		} = getAddVideoConfirm(state) || {};

		if (!isOpen) return {
			isOpen,
			groups: [],
			nativeLang: '',
			targetLang: '',
			videoAuthor: '',
			videoImg: '',
			videoLangCodes,
			videoTitle: '',
			newListName: '',
		}

		let targetLang = '';
		const group = getUserGroupById(state, targetGroupId);
		if (group) {
			targetLang = getGroupTargetLanguage(state, group)?.name || '';
		}

		let groups: TUserGroup[] = [];
		const userGroups = getUserOwnGroups(state);
		if (userGroups) {
			groups = userGroups.reduce((arr, item) => {
				const targetLang = getGroupTargetLanguage(state, item)?.code;
				if (targetLang &&
					videoLangCodes?.includes(targetLang)
				) {
					arr.push(item);
				}
				return arr;
			}, groups);
		}
		
		let videoAuthor = videoInfo?.channelTitle || '';
		let videoImg = videoInfo?.thumbnailUrl || '';
		let videoTitle = videoInfo?.title || '';

		const userTargetLang = getTargetLanguage(state)?.code || '';
		const userNativeLang = getNativeLanguage(state)?.code || '';

		let targetLangCode = userTargetLang;
		let nativeLangCode = userNativeLang;
		const videoLangCode = VideoMetaInfo.getVideoPriorityTargetLang(videoLangCodes, userTargetLang, videoInfo);
		if (videoLangCode && videoLangCode !== nativeLangCode) {
			targetLangCode = videoLangCode;
		}
		let newListName = createGroupNameIndex(state, `${LangUtil.getLangNameByCode(targetLangCode)} / ${LangUtil.getLangNameByCode(nativeLangCode)}`);

		return {
			isOpen,
			groups,
			nativeLang: userNativeLang,
			targetLang,
			videoAuthor,
			videoImg,
			videoLangCodes,
			videoTitle,
			newListName,
		}
	};
}

const mapDispatchToProps = (
	dispatch: any
): IAddVideoConfirmEvents => ({
	onChange: (groupId, newListName) => dispatch(AddVideoConfirmEvents.onChange(groupId, newListName)),
	onClose: () => dispatch(AddVideoConfirmEvents.onClose()),
});

export const AddVideoConfirmHOC = connect(
	mapStateToProps,
	mapDispatchToProps
)(AddVideoConfirm);

AddVideoConfirmHOC.displayName = 'AddVideoConfirmHOC';
