import { API_URL } from '../../constants';
import { TApiResponse } from '../../../spa/effects/phrase-details/phraseDetailsService/phrase-details-service';
import { getDispatch } from '../../../spa/store';
import { postRequest } from '../../utils/requestService';

export class ErrorRest {

  private static LOG_URL = API_URL + '/error/log';

  public static async log(text: string): Promise<TApiResponse> {
    const dispatch = getDispatch();
    return await dispatch(postRequest(this.LOG_URL, {text}));
  }
}
