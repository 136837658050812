import React from 'react';
import { Box, Button, CircularProgress, Stack } from '@mui/material';
import { theme } from '../../../../../../common/styles/theme';
import { Text } from '../../../../../../common/components/Text/Text';
import { IPhraseDetailsTabPromptTestProps } from './types';
import { PhraseDetailsPhrasePrepareHelper } from '../../helpers/phraseDetailsPhrasePrepareHelper';
import { Paper } from "../../../../../../common/components/Paper/Paper";
import { TextWithSpeakerHOC } from '../../../../../../common/components/TextWithSpeaker/hocs/TextWithSpeakerHOC';

export const PhraseDetailsTabPromptTest: React.FC<IPhraseDetailsTabPromptTestProps> = ({
  show,
  prompt,
  phrase,
  context,
  result,
  processing,
  onTest,
  onBack,
}) => {

  if (!show) return null;

  const [promptHtml, setPromptHtml] = React.useState<string>('');

  React.useEffect(() => {
    const _promptHtml = PhraseDetailsPhrasePrepareHelper.format({
      prompt, phrase, context,
      phraseCssClass: 'phrase',
      contextCssClass: 'context'
    });
    setPromptHtml(_promptHtml);
  }, [prompt, phrase, context]);


    const paperStyle = {
      flex: `1 1 50%`,
      p: theme.spacing(2),
    };

  const promptStyle = {
    'span': {
      p: '4px 0',
      lineHeight: theme.spacing(3.25),
    },
    '.context': {
      backgroundColor: '#CEE2FD',
    },
    '.phrase': {
      bgcolor: '#dcb8ff',
    }
  };

  const handleBackClick = () => {
    onBack();
    return false;
  }

  const showPrompt = !!(phrase && context);

  return (
      <Paper sx={paperStyle}>
        <Stack spacing={theme.spacing(3)} sx={{ maxHeight: '100%' }}>
          <Text variant={'h6'} fontWeight={600}>
              Test your prompt here
          </Text>

            <Stack spacing={theme.spacing()}>
              {showPrompt &&
                <>
                <Text variant={'subtitle1'} fontWeight={600}>
                  The following will be sent to ChatGPT
                </Text>
                <Text
                  variant={'subtitle2'}
                  sx={promptStyle}
                  dangerouslySetInnerHTML={{__html: promptHtml}}
                />
                </>
              }
              {!showPrompt &&
              <Text variant={'subtitle1'} fontWeight={600}>
                Please go <a href={'#'} onClick={handleBackClick}>back</a> to subtitles and select a word or phrase to start building/testing Chat GPT prompts
              </Text>
              }
            </Stack>

          {showPrompt &&
          <Button
            onClick={onTest}
            variant={'contained'}
            color={'secondary'}
          >
            Test my prompt
          </Button>
          }

          {processing &&
              <Stack alignItems={'center'} justifyContent={'center'}>
                  <CircularProgress />
              </Stack>
          }
          {!processing && result &&
            <>
              <Stack gap={theme.spacing()} sx={{
                flexGrow: 1,
                ml: theme.spacing(-1),
                pl: theme.spacing(2.5),
              }}>
                  <Text variant={'subtitle1'} fontWeight={600}
                    sx={{
                      ml: theme.spacing(-2.5),
                    }}
                  >
                      ChatGPT output
                  </Text>
         
                <Box
                  sx={{
                    
                  }}
                >
                  <TextWithSpeakerHOC
                    text={result}
                  />
                </Box>
                
              </Stack>
              </>
          }

        </Stack>
      </Paper>
  );
}
