import {
    ADD_GROUP,
    ADD_VIDEO_IN_GROUP,
    IAddGroup,
    IAddVideoInGroup,
    IMoveVideoToGroup,
    IPhraseDetailsTabDelete,
    IPhraseDetailsTabSave,
    IRemoveGroup,
    IRemoveVideoFromGroup,
    IUpdateGroup,
    ISetActiveGroupId,
    ISetDefaultGptPrompts,
    ISetGroupPhraseCount,
    ISetGroups,
    ISetGroupsVideoLangList,
    ISetLanguages,
    ISetPhraseDetailsTabs,
    ISetUserGptPrompts,
    ISetVideoTutorialListAction,
    IUserGptPromptDelete,
    IUserGptPromptSave,
    MOVE_VIDEO_TO_GROUP,
    PHRASE_DETAILS_TAB_DELETE,
    PHRASE_DETAILS_TAB_SAVE,
    REMOVE_GROUP,
    REMOVE_VIDEO_FROM_GROUP,
    UPDATE_GROUP,
    SET_ACTIVE_GROUP,
    SET_DEFAULT_GPT_PROMPTS,
    SET_GROUP_COUNT,
    SET_GROUPS,
    SET_GROUPS_VIDEO_LANG_LIST,
    SET_LANGUAGES,
    SET_PHRASE_DETAILS_TABS,
    SET_USER_GPT_PROMPTS,
    SET_VIDEO_TUTORIAL_LIST,
    TDefaultGptPrompt,
    TPhraseDetailsTab,
    TUserGptPrompt,
    TUserGroup,
    TUserLanguage,
    TVideo,
    TVideoTutorial,
    USER_GPT_PROMPT_DELETE,
    USER_GPT_PROMPT_SAVE,
    SET_LANGS_ACTION,
    ISetLangsAction,
    TLanguage,
    TUserRole,
    SET_USER_ROLES_ACTION,
    ISetUserRolesAction, IRefreshGroupAction, REFRESH_GROUP
} from './types';

export const setUserAvailableGroups = (
    groups: TUserGroup[]
): ISetGroups => ({
    type: SET_GROUPS,
    groups
});

export const setUserLanguages = (
    languages: TUserLanguage[]
): ISetLanguages => ({
    type: SET_LANGUAGES,
    languages
});

export const setActiveGroupId = (
    activeGroupId: number
): ISetActiveGroupId => ({
    type: SET_ACTIVE_GROUP,
    activeGroupId
});

export const addGroup = (
    group: TUserGroup
): IAddGroup => ({
    type: ADD_GROUP,
    group
})

export const refreshGroupAction = (
  group: TUserGroup
): IRefreshGroupAction => ({
    type: REFRESH_GROUP,
    group
})

export const removeGroupAction = (
    groupId: number
): IRemoveGroup => ({
    type: REMOVE_GROUP,
    groupId
})

export const updateGroupAction = (
    groupId: number,
    name: string,
    languages?: TUserLanguage[]
): IUpdateGroup => ({
    type: UPDATE_GROUP,
    groupId,
    name,
    languages
})

export const addVideoInGroup = (
    video: TVideo,
    groupId: number
): IAddVideoInGroup => ({
    type: ADD_VIDEO_IN_GROUP,
    groupId,
    video
})

export const moveVideoToGroupAction = (
    videoId: string,
    fromGroupId: number,
    toGroupId: number
): IMoveVideoToGroup => ({
    type: MOVE_VIDEO_TO_GROUP,
    videoId,
    fromGroupId,
    toGroupId
})


export const removeVideoFromGroupAction = (
    videoKey: string,
    groupId: number
): IRemoveVideoFromGroup => ({
    type: REMOVE_VIDEO_FROM_GROUP,
    videoKey,
    groupId
})

export const setGroupPhraseCount = (
    groupId: number,
    count: number
): ISetGroupPhraseCount => ({
    type: SET_GROUP_COUNT,
    groupId,
    count
})

export const setDefaultGptPromptsAction = (
  defaultPrompts: TDefaultGptPrompt[]
): ISetDefaultGptPrompts => ({
    type: SET_DEFAULT_GPT_PROMPTS,
    defaultPrompts
})

export const setGroupsVideoLangListAction = (
  videoId: string,
  langList: string[]
): ISetGroupsVideoLangList => ({
    type: SET_GROUPS_VIDEO_LANG_LIST,
    videoId,
    langList
})

export const setUserGptPromptsAction = (
  prompts: TUserGptPrompt[]
): ISetUserGptPrompts => ({
    type: SET_USER_GPT_PROMPTS,
    prompts
})

export const userGptPromptSaveAction = (
  prompt: TUserGptPrompt
): IUserGptPromptSave => ({
    type: USER_GPT_PROMPT_SAVE,
    prompt
})

export const userGptPromptDeleteAction = (
  id: number
): IUserGptPromptDelete => ({
    type: USER_GPT_PROMPT_DELETE,
    id
})



export const setPhraseDetailsTabsAction = (
  tabs: TPhraseDetailsTab[]
): ISetPhraseDetailsTabs => ({
    type: SET_PHRASE_DETAILS_TABS,
    tabs
})

export const phraseDetailsTabSaveAction = (
  tab: TPhraseDetailsTab,
  replaceIndex?: number
): IPhraseDetailsTabSave => ({
    type: PHRASE_DETAILS_TAB_SAVE,
    tab,
    replaceIndex
})

export const phraseDetailsTabDeleteAction = (
  id: number
): IPhraseDetailsTabDelete => ({
    type: PHRASE_DETAILS_TAB_DELETE,
    id
})

export const setVideoTutorialListAction = (
  videoTutorials: TVideoTutorial[]
): ISetVideoTutorialListAction => ({
    type: SET_VIDEO_TUTORIAL_LIST,
    videoTutorials
})

export const setLangsAction = (
  langs: TLanguage[]
): ISetLangsAction => ({
    type: SET_LANGS_ACTION,
    langs
});

export const setUserRolesAction = (
  userRoles: TUserRole[]
): ISetUserRolesAction => ({
    type: SET_USER_ROLES_ACTION,
    userRoles
});
