import { IVocabularyListMode } from '../components/types';
import { EPhraseListMode } from '../../../../../store/phrase-list/types';
import { getUserOwnGroups } from '../../../../../store/models/selectors';

import { createSelector } from 'reselect';
import { TUserLanguage } from '../../../../../store/models/types';
import { ELanguageStatus } from '../../../../../store/general/types';
import { IState } from '../../../../../store/types';
import { getAuthUser } from '../../../../../store/general/selectors';
import { getVideoTeacherData, getVideoTeacherUserActiveId } from '../../../../../store/videos/selectors';


const getGroupLangsSelector = createSelector(getUserOwnGroups, groups => {
  const langs: TUserLanguage[] = [];
  groups.forEach(g => {
    const lang = g.languages.find(l => l.status === ELanguageStatus.TO_LEARN);
    if (lang && !langs.find(l => l.code === lang.code)) {
      langs.push(lang);
    }
  })
  return langs;
});

const getPhrasesListModes = createSelector(getGroupLangsSelector, (langs) => {
  const list: IVocabularyListMode[] = [
    {
      id: String(EPhraseListMode.CURRENT_VIDEO),
      listMode: EPhraseListMode.CURRENT_VIDEO,
      title: 'Current video',
    },
    {
      id: String(EPhraseListMode.CURRENT_GROUP),
      listMode: EPhraseListMode.CURRENT_GROUP,
      title: 'Current playlist'
    }
  ];

  langs.forEach(lang => {
    list.push({
      id: String(EPhraseListMode.LANG) + '-' + lang.code,
      listMode: EPhraseListMode.LANG,
      title: lang.name,
      langCode: lang.code
    })
  });

  list.push({
    id: String(EPhraseListMode.ALL),
    listMode: EPhraseListMode.ALL,
    title: 'All videos'
  });

  return list;
})


export class VocabularyComponentUtils {

  public static getListModes(state: IState): IVocabularyListMode[] {
    const list = getPhrasesListModes(state);

    const {teacherMode} = getAuthUser(state)
    if (teacherMode) {
      return list.filter(m => m.listMode === EPhraseListMode.CURRENT_VIDEO);
    }

    const teacherActiveId = getVideoTeacherUserActiveId(state);
    if (teacherActiveId) {
      const teacher = getVideoTeacherData(state, teacherActiveId);
      list.unshift({
        id: String(EPhraseListMode.TEACHER) + '-' + teacher.user.id,
        listMode: EPhraseListMode.TEACHER,
        title: teacher?.user.name || 'Teacher',
      })
    }

    return list;
  }

  public static getDefaultListMode(state: IState): IVocabularyListMode {
    return getPhrasesListModes(state)[0];
  }

  public static getListMode(state: IState, mode: EPhraseListMode, langCode?: string): IVocabularyListMode | undefined {
    return getPhrasesListModes(state).find(listMode => {
      if (listMode.listMode === mode) {
        if (mode === EPhraseListMode.LANG) {
          return listMode.langCode === langCode;
        } else {
          return true;
        }
      }
    })
  }

  public static getListModeById(state: IState, listModeId: string): IVocabularyListMode | undefined {
    return getPhrasesListModes(state).find(listMode => listMode.id === listModeId);
  }

}
