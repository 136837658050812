import { API_URL } from '../../constants';
import { getDispatch } from '../../../spa/store';
import { getRequest, postRequest } from '../../utils/requestService';

export interface IUserProfileRest {
  name?: string;
  picture?: string;
  description?: string;
}

export class UserProfileRest {

  private static PROFILE_URL = API_URL + '/users/profile';

  public static async get(userId: number): Promise<any> {
    const url = `${this.PROFILE_URL}/${userId}`;
    const dispatch = getDispatch();
    return await dispatch(getRequest(url));
  }

  public static async save(html: string): Promise<IUserProfileRest> {
    const dispatch = getDispatch();
    return await dispatch(postRequest(this.PROFILE_URL, {html}));
  }
}