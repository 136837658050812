import { AppThunk } from '../../../../../../store/types';
import { PhraseDetailsTabsEditorPopupActions } from '../../../../../../store/phrase-details-tabs-editor-popup/actions';


export class VideoTutorialTooltipEvents {

  public static onClose(): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      dispatch(PhraseDetailsTabsEditorPopupActions.setShowTutorial(false));
    }
  }

}