import React from 'react';
import { Button, Stack } from '@mui/material';
import { theme } from '../../../../../../common/styles/theme';
import Box from '@mui/material/Box';
import { IPhraseTeacherNotesProps } from './types';

export const PhraseTeacherNotes: React.FC<IPhraseTeacherNotesProps> = ({
	text,
	saved,
	createUserName,
	onClickAuthor,
	onCopy,
}) => {

	const buttonTitle = saved ? 'Already saved' : 'Save to my notes';

	const [_note, setNote] = React.useState<string>('');
    React.useEffect(() => {
        let _note = '';
        if (text) {
            _note = text.trim().replaceAll(/<p><\/p>/gm, '<br>');
        }
        setNote(_note);
    }, [text]);

	return (
		<Stack
			// className={PHRASE_DETAILS_NOTES_CLASS_NAME}
			gap={1.5}
			sx={{
				flex: `1 1 100%`,
				minHeight: 0,
				width: 1,
				pt: theme.spacing(1.5),
				pl: theme.spacing(1),
				justifyContent: 'space-between',
			}}
		>
			<Box
				dangerouslySetInnerHTML={{ __html: _note }}
				sx={{
					flex: `1 1 100%`,
					minHeight: 0,
					overflow: 'auto',
					width: 1,
					p: theme.spacing(1),
					wordBreak: 'break-word',
					borderRadius: theme.spacing(0.75),
					border: `2px solid ${theme.palette.grey[200]}`,

					fontSize: theme.typography.subtitle2,
					lineHeight: 1.4,

					'& > *': {
						m: 0,
					},

					'img': {
						maxWidth: 1,
					},
				}}
			/>

			<Box
				sx={{
					height: theme.spacing(3),
					textAlign: 'right',
					fontSize: theme.typography.subtitle2,
					overflow: 'hidden',
				}}
			>
				Created by
				{' '}
				<Button
				 	variant={'text'}
					onClick={onClickAuthor}
					sx={{
						textDecoration: 'underline',
					}}
				>
					{createUserName}
				</Button>
			</Box>

			<Button
				variant={'contained'}
				disabled={saved}
				onClick={onCopy}>
				{buttonTitle}
			</Button>

		</Stack>
	);

}