import { ErrorRest } from '../../common/rest/error/errorRest';

export class ErrorEffects {

  public static async logError(e: Error | string): Promise<any> {
    try {
      const text: string = (e instanceof Error) ? e.message : e;
      await ErrorRest.log(text);
    } catch(e) {
      console.error(e);
    }
  }
}