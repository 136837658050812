import { getDispatch } from '../store';
import { setSavedVideoConfirmAction } from '../store/general/actions';
import { ESavedVideoConfirmShowMode } from '../store/general/types';

export class SaveVideoConfirmManager {

  public static show(groupId: number, videoId: string) {
    const dispatch = getDispatch();
    dispatch(setSavedVideoConfirmAction({showMode: ESavedVideoConfirmShowMode.NOTIFY, groupId, videoId}));
  }

  public static showError(errorMessage: string) {
    const dispatch = getDispatch();
    dispatch(setSavedVideoConfirmAction({showMode: ESavedVideoConfirmShowMode.ERROR, errorMessage}));
  }
}