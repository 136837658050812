import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { EditPlaylistItem } from '../../components/EditPlaylistItem/EditPlaylistItem';
import { IEditPlaylistItemEvents, IEditPlaylistItemFields } from '../../components/EditPlaylistItem/types';
import { IEditPlaylistItemHOCOwnProps } from './types';
import { EditPlaylistItemEvents } from './events';
import {
  getUserGroupById,
  getUserGroupLangKnown,
  getUserGroupLangToLearn,
  getUserGroupTitle
} from '../../../../../store/models/selectors';
import { TUserGroup, TUserLanguage } from '../../../../../store/models/types';
import { LangUtil } from '../../../../../../common/utils/lang-util';


const mapStateToProps = (
  dispatch: any,
  {groupId}: IEditPlaylistItemHOCOwnProps
) => {
  return (
    state: IState,
    ownProps: IEditPlaylistItemHOCOwnProps
  ): IEditPlaylistItemFields | null => {
    const group: TUserGroup | undefined | null = getUserGroupById(state, groupId);
    if (!group) return null;
    const nativeLang = getUserGroupLangKnown(state, group);
    const targetLang = getUserGroupLangToLearn(state, group);
    return {
      label: getUserGroupTitle(state, group),
      videoCount: group.videos.length,
      phrasesCount: group.phrasesCount,
      nativeLangCode: LangUtil.checkLangCode(nativeLang?.code),
      targetLangCode: LangUtil.checkLangCode(targetLang?.code)
    }
  };
}

const mapDispatchToProps = (
  dispatch: any,
  {groupId}: IEditPlaylistItemHOCOwnProps
): IEditPlaylistItemEvents => ({
    onDelete: (history) => dispatch(EditPlaylistItemEvents.onDelete(groupId, history)),
    onSave: (name) => dispatch(EditPlaylistItemEvents.onSave(groupId, name))
  }
);

export const EditPlaylistItemHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditPlaylistItem);

EditPlaylistItemHOC.displayName = 'EditPlaylistItemHOC';