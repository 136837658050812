import { AppThunk } from '../../../../store/types';
import { ProfilesEffects } from '../../../../effects/profilesEffects';
import { UrlUtil } from '../../../../../common/utils/url-util';
import { Navigator } from '../../../../effects/navigator';

export class ProfilesListEvents {

	public static onClickProfile(
		userName: string
	): AppThunk {
		return async (
		  dispatch,
		  getState
		) => {
		  const url = UrlUtil.getLinkProfile(userName);
		  Navigator.openUrl(url);
		}
	}

}
