import { AppThunk } from '../../../../../store/types';
import { PhraseNoteEffects } from '../../../../../effects/phraseNoteEffects';
import { IPhraseNote } from '../../../../../types/common';
import { PhraseDetailsSelectors } from '../../../../../store/phrase-details/selectors';
import { getMainViewMode } from '../../../../../store/general/selectors';
import { EMainViewMode } from '../../../../../store/general/types';

export class PhraseNotesEvents {

  public static onSave(
    note: Partial<IPhraseNote>
  ): AppThunk {
    return async(
      dispatch,
      getState
    ) => {
      const mainViewMode = getMainViewMode(getState());
      if (mainViewMode === EMainViewMode.PHRASE_DETAILS) {
        history.back();
      }

      let currentNote = PhraseDetailsSelectors.getCurrentNote(getState());
      if (currentNote) {
        return PhraseNoteEffects.updateNote(currentNote.id, note.text, note.pause);
      }
      if (note.text) {
        return PhraseNoteEffects.saveNote(note.text, note.pause as boolean);
      }
      return false; // cannot save without text
    }
  }

  public static onUpdate(): AppThunk {
    return (
      dispatch,
      getState
    ) => {
    }
  }



}