import { IState } from '../../../../../store/types';
import { getAuthUser } from '../../../../../store/general/selectors';
import { connect } from 'react-redux';
import { TeacherList } from '../components/TeacherList';
import { ITeacherListEvents, ITeacherListFields } from '../components/types';
import { getVideoTeacherUserActiveId, getVideoTeacherUsers } from '../../../../../store/videos/selectors';
import { TeacherListEvents } from './events';


const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): ITeacherListFields => {
    const teacherList = getVideoTeacherUsers(state).filter(t => t.user); // filter deleted users
    const activeId = getVideoTeacherUserActiveId(state);
    const {teacherMode} = getAuthUser(state)
    return {
      show: !teacherMode && teacherList.length > 0,
      activeId,
      teacherList
    }
  };
}

const mapDispatchToProps = (
  dispatch: any
): ITeacherListEvents => ({
    onTeacherSelect: id => dispatch(TeacherListEvents.onTeacherSelect(id))
  }
);

export const TeacherListHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(TeacherList);

TeacherListHOC.displayName = 'TeacherListHOC';