import { AppThunk } from '../../../../../../store/types';
import { PhraseDetailsActions } from '../../../../../../store/phrase-details/actions';
import { PhraseDetailsSelectors } from '../../../../../../store/phrase-details/selectors';
import { PhraseDetailsCustomPromptService } from '../../../../../../effects/phrase-details/phraseDetailsService/phrase-details-custom-prompt-service';
import { setMainViewMode } from '../../../../../../store/general/actions';
import { EMainViewMode } from '../../../../../../store/general/types';
import { PhraseDetailsTabsEditorPopupActions } from '../../../../../../store/phrase-details-tabs-editor-popup/actions';
import { EPhraseDetailsTabsEditorViewMode } from '../../../../../../store/phrase-details-tabs-editor-popup/types';
import { PhraseContextEditorActions } from '../../../../../../store/phrase-context-editor/actions';
import { EPhraseDetailsTabType } from '../../../../../../store/models/types';
import { GptUtils } from '../../../../../../effects/gptUtils';
import { PhraseNoteEffects } from '../../../../../../effects/phraseNoteEffects';
import { StatLogManager } from '../../../../../../../common/stats/statLogManager';

export class PhraseCustomPromptEvents {

	public static onLoad(useCache: boolean): AppThunk {
		return async (
			dispatch,
			getState
		) => {
			const state = getState();
			const activeTab = PhraseDetailsSelectors.getActiveTab(state);
			if (!activeTab || activeTab.type !== EPhraseDetailsTabType.CUSTOM_PROMPT) return;
			const id = activeTab?.relationId || 0;
			dispatch(PhraseDetailsActions.setCustomPromptResult(id, '', true, false));
			const text = PhraseDetailsSelectors.getText(state) || '';
			const context = PhraseDetailsSelectors.getContext(state) || PhraseDetailsSelectors.getCaptionLineContext(state) || '';
			const fromLang = PhraseDetailsSelectors.getFromLang(state);
			const toLang = PhraseDetailsSelectors.getToLang(state);
			const result = await new PhraseDetailsCustomPromptService(id, text, context, fromLang?.code || '', toLang?.code || '').load(useCache);
			StatLogManager.logPromptAccess();
			if (result !== undefined) {
				dispatch(PhraseDetailsActions.setCustomPromptResult(id, result, false, true));
			}
		}
	}

	public static onClickSettings(): AppThunk {
		return async (
			dispatch,
			getState
		) => {
			let tab = PhraseDetailsSelectors.getActiveTab(getState());
			if (tab && tab.type === EPhraseDetailsTabType.CUSTOM_PROMPT) {
				dispatch(setMainViewMode(EMainViewMode.PHRASE_DETAILS_TABS_EDITOR));
				dispatch(PhraseDetailsTabsEditorPopupActions.setViewMode(EPhraseDetailsTabsEditorViewMode.CREATE));
				dispatch(PhraseDetailsTabsEditorPopupActions.setEditTabId(tab.id));
			}
		}
	}

	public static onClickContext(): AppThunk {
		return async (
			dispatch,
			getState
		) => {
			dispatch(PhraseContextEditorActions.updatePhraseContextEditorAction({
				show: true,
			}));
		}
	}

	public static onCopyNote(): AppThunk {
		return async (
			dispatch,
			getState
		) => {
			const state = getState();
			const activeTab = PhraseDetailsSelectors.getActiveTab(state);
			const customPrompt = PhraseDetailsSelectors.getCustomPrompt(state, activeTab?.relationId || 0);
			const text = customPrompt && customPrompt.resultText && GptUtils.clear(customPrompt.resultText) || '';
			PhraseNoteEffects.copyToNoteText(text);
		}
	}



}