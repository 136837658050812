import { AppThunk } from '../../../../../store/types';
import {
  setEditPlaylistModalOpenAction,
  setLibraryGroupIdAction,
  setSnackbarPanelAction
} from '../../../../../store/general/actions';

import { UserGroupEffects } from '../../../../../effects/userGroupEffects';
import { getUserStateStorage } from '../../../../../../common/utils/local-storage/user-state';
import { getActiveGroupId } from '../../../../../store/models/selectors';
import { getLibraryGroupId } from '../../../../../store/general/selectors';
import { CopyGroupEffects } from '../../../../../effects/copyGroupEffects';

export class VideoLibraryHeaderEvents {

  public static onGroupChange(
    groupId: number
  ): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      if (groupId) {
        UserGroupEffects.setLibraryGroupId(groupId);
      }
    }
  }

  public static onShowEditPlayLists(): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      dispatch(setEditPlaylistModalOpenAction(true))
    }
  }

  public static onCopyLink(): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      const groupId = getLibraryGroupId(getState())
      const url = await CopyGroupEffects.createCopyLink(groupId);
      await navigator.clipboard.writeText(url);
      dispatch(setSnackbarPanelAction(true, 'Link to public playlist copied to clipboard'));
    }
  }



}
