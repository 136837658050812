import { AppThunk } from '../../../../store/types';
import { AuthManager } from '../../../../../common/utils/auth-manager';
import { LoginUserEffects } from '../../../../effects/loginUserEffects';
import { Navigator } from '../../../../effects/navigator';
import { EventsRouter } from '../../../../../common/events/eventsRouter';
import { Events } from '../../../../../common/events/types';
import { UserRoleEffects } from '../../../../effects/userRoleEffects';
import { setAuthUserAction } from '../../../../store/general/actions';
import { getOneTimeTooltipsStorage } from '../../../../../common/utils/local-storage/onetime-tooltips';
import { prepareAfterOpen } from '../../../../effects/prepareAfterOpen';
import { EUserRoleId } from '../../../../store/models/types';

export class LandingEvents {

	public static onDidMount(): AppThunk {
		return (
			dispatch,
			getState
		) => {
			EventsRouter.trackEvent(Events.SIGN_IN_LOADED);
		}
	}

	public static onLogin(path: string): AppThunk {
		return async (
			dispatch,
			getState
		): Promise<boolean> => {
			EventsRouter.trackEvent(Events.SIGN_IN_CLICKED);
			try {
				const result = await AuthManager.login();
				if (!result || !result.authUser) return false;

				if (result.authUser.isNew) {
					dispatch(setAuthUserAction(result.authUser));
					EventsRouter.setUser(result.authUser);

					const landingName = path.startsWith('/') ? path.substring(1) : path;
					const nativeLang = 'en';
					const targetLang = 'fr';
					await LoginUserEffects.prepareLangsOnInstall(nativeLang, targetLang, landingName, true);
					await UserRoleEffects.saveUserRoles([EUserRoleId.Student]);
					Navigator.openDefault();

					getOneTimeTooltipsStorage().setShow({ showTutorialPopup: false });
					return true;
				} else {
					dispatch(prepareAfterOpen(result.authUser, result.settings, result.appSettings));
				}
				return true;
			} catch (e) {
				console.log('err', e);
				return false;
			}
		}
	}


}