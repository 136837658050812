import React from 'react';
import { theme } from '../../../../../../common/styles/theme';
import { Button, Stack } from '@mui/material';
import { Text } from '../../../../../../common/components/Text/Text';
import { IContentLibCopyVideoConfirmProps } from './types';
import { Modal } from '../../../../../../common/components/Modal/Modal';


export const ContentLibCopyVideoConfirm: React.FC<IContentLibCopyVideoConfirmProps> = ({
  show,
  onClose,
  onOpenVideo
}) => {
  if (!show) return null;

  return (
    <Modal
      onClose={onClose}
      open={show}
      PaperProps={{
        sx: {
          maxWidth: '700px !important',
        }
      }}
    >
        <Stack
          gap={theme.spacing(2)}
        >
          <Stack
            direction='row'
            alignItems={'center'}
            gap={theme.spacing(2)}
            sx={{ pr: theme.spacing(2.5) }}
          >

            <Text
              fontWeight={600}
              variant={'subtitle2'}
              sx={{
                display: '-webkit-box',
                WebkitLineClamp: 3,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
              }}
            >
              Video was saved
            </Text>
            <Button onClick={onOpenVideo}>Open</Button>
          </Stack>

        </Stack>
      </Modal>
  );
}