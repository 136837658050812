import { API_URL } from '../../constants';
import { getDispatch } from '../../../spa/store';
import { getRequest, postRequest } from '../../utils/requestService';
import { TPublicProfile } from '../../../spa/store/general/types';

export interface IUserProfileRest {
  id: number;
  userId: number;
  bio?: string;
  html?: string;
  name?: string;
  userName?: string;
  avatar?: string;
  showAvatar?: boolean;
}


export enum EUserProfileSaveErrorType {
  USER_NAME_EXIST = 1
}

export type TUserProfileRestResp = {
  result: {
    success: boolean,
    errorType?: EUserProfileSaveErrorType
  }
  profile?: TPublicProfile
}


export type TUserProfileGroupLang = {
  name: string,
  code: string
}

export type TUserProfileGroupVideo = {
  videoKey: string,
  title: string;
  thumb: string;
  thumbMedium: string;
  thumbStandard: string;
  author: string;
}

export type TUserProfileGroup = {
  id: number,
  name: string,
  targetLang: TUserProfileGroupLang,
  nativeLang: TUserProfileGroupLang,
  videos: TUserProfileGroupVideo[]
}

export class UserProfileRest {

  private static PROFILE_URL = API_URL + '/users/profile';
  private static PROFILE_GROUP_URL = API_URL + '/users/profile/groups/';
  private static PROFILE_LIST_URL = API_URL + '/users/profile/list';

  /*public static async get(userId: number): Promise<any> {
    const url = `${this.PROFILE_URL}/${userId}`;
    const dispatch = getDispatch();
    return await dispatch(getRequest(url));
  }*/

  public static async getByNick(nick: string): Promise<IUserProfileRest> {
    const url = `${this.PROFILE_URL}/nick/${nick}`;
    const dispatch = getDispatch();
    return await dispatch(getRequest(url));
  }

  public static async save(data: TPublicProfile): Promise<TUserProfileRestResp> {
    const dispatch = getDispatch();
    return await dispatch(postRequest(this.PROFILE_URL, data));
  }

  public static async findGroups(profileNickName: string): Promise<TUserProfileGroup[]> {
    const dispatch = getDispatch();
    const url = this.PROFILE_GROUP_URL + profileNickName;
    return dispatch(getRequest(url));
  }

  public static async getList(): Promise<IUserProfileRest[]> {
    const dispatch = getDispatch();
    const url = this.PROFILE_LIST_URL;
    return dispatch(getRequest(url));
  }
}