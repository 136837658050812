import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { PublicProfile } from '../components/PublicProfile';
import { IPublicProfileEvents, IPublicProfileFields } from '../components/types';
import { PublicProfileEvents } from './events';
import { getVideoTeacherUser } from '../../../../../store/videos/selectors';
import { UrlUtil } from '../../../../../../common/utils/url-util';

const mapStateToProps = (
	dispatch: any
) => {
	return (
		state: IState,
	): IPublicProfileFields => {
		const userId = +UrlUtil.getRouteProfileId();
		const user = getVideoTeacherUser(state, userId)?.user;
		return {
			userId,
			name: user?.name || '',
			picture: user?.picture || '',
			description: ''
		}
	};
};

const mapDispatchToProps = (
	dispatch: any
): IPublicProfileEvents => ({
	onClickLogo: () => dispatch(PublicProfileEvents.onClickLogo()),
	onLoad: (userId) => dispatch(PublicProfileEvents.onLoad(userId)),
});

export const PublicProfileHOC = connect(
	mapStateToProps,
	mapDispatchToProps
)(PublicProfile);

PublicProfileHOC.displayName = 'PublicProfileHOC';
