import { Button, Popover, Stack, Box } from "@mui/material";
import { theme } from "../../../../common/styles/theme";
import React from "react";
import { Text } from "../../../../common/components/Text/Text";
import { IConfirmPopupProps } from './types';

export const ConfirmPopup: React.FC<IConfirmPopupProps> = ({
    anchor,
    text,
    lines,
  confirmTitle,
  closeTitle,
    onConfirm,
    onClose
}) => {
    const textList = text ? [text] : (lines || []);
    return (
        <Popover
            open={!!anchor}
            anchorEl={anchor}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            onClose={onClose}
        >
            <Stack spacing={theme.spacing()} sx={{ p: theme.spacing() }}>
                <Box>
                    {textList.map((text, index) => {
                        return <Text fontWeight={600} key={index}>{text}</Text>
                    })}
                </Box>
                <Stack
                    direction='row'
                    spacing={theme.spacing()}
                >
                    <Button
                        onClick={onConfirm}
                        variant={'contained'}
                        color={'info'}
                        sx={{ height: theme.spacing(4) }}
                    >
                        {confirmTitle || 'Yes'}
                    </Button>
                    <Button
                        onClick={onClose}
                        variant={'contained'}
                        color={'info'}
                        sx={{ height: theme.spacing(4) }}
                    >
                        {closeTitle || 'No'}
                    </Button>
                </Stack>
            </Stack>
        </Popover>
    );
}
