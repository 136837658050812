import { AppThunk } from '../../../../store/types';
import { PhraseContextEditorActions } from '../../../../store/phrase-context-editor/actions';
import { getSelectedTargetCaption } from '../../../../store/current-video/selectors';
import { findVideoPhraseById, getTargetCaptions } from '../../../../store/videos/selectors';
import { LangUtil } from '../../../../../common/utils/lang-util';
import { PhraseSelectPreparator } from '../../../../effects/phrase/utils/phrase-select-preparator';
import { PhraseContextEditorSelectors } from '../../../../store/phrase-context-editor/selectors';
import { PhraseEffects } from '../../../../effects/phrase/PhraseEffects';
import { SelectionUtil } from '../../../../../common/utils/selection-util';
import { getOneTimeTooltipsStorage } from '../../../../../common/utils/local-storage/onetime-tooltips';
import { setOneTimeTooltipPhrasesEditAction } from '../../../../store/general/actions';
import { CaptionsSelectionPopupActions } from '../../../../store/captions-selection-popup/actions';
import { PhraseNoteTimeScaleEffects } from '../../../../effects/phrase/phraseNoteTimeScaleEffects';

export class PhraseContextEditorEvents {

  public static onClose(): AppThunk {
    return async(
      dispatch,
      getState
    ) => {
      dispatch(PhraseContextEditorActions.deActivate());
    }
  }

  public static onSelectText(selection: Selection): AppThunk {
    return async(
      dispatch,
      getState
    ) => {
      const state = getState();
      const phraseId = PhraseContextEditorSelectors.getPhraseId(state);
      const targetLanguage = getSelectedTargetCaption(state);

      let phrase;
      let wordPhrase;
      if (phraseId) { // saved phrase
        phrase = findVideoPhraseById(state, phraseId);
        wordPhrase = findVideoPhraseById(state, phrase?.wordPhraseId || 0);
      } else { // selected phrase
        let phrases = PhraseContextEditorSelectors.getPhrases(state);
        if (phrases && phrases.length) {
          wordPhrase = phrases.find(item => !item.wordPhraseId);
          phrase = phrases.find(item => !!item.wordPhraseId);
        }
      }
      
      const wordSelectPhrases = wordPhrase ? [wordPhrase] : [];
      const contextPhrases = phrase ? [phrase] : [];

      const useCheckSpanWords = !LangUtil.isHieroglyphLang(targetLanguage?.code || '');
      const selResult = new PhraseSelectPreparator(selection, getTargetCaptions(state), wordSelectPhrases, contextPhrases, null, useCheckSpanWords).execute();
      let previewContextPhrase = null;
      if (wordPhrase && selResult) {
        previewContextPhrase = dispatch(PhraseEffects.savePreviewContextByWordPhrase(selResult, wordPhrase.id)) as any;
      }

      dispatch(PhraseContextEditorActions.setSelectResult(selResult));
      dispatch(PhraseContextEditorActions.setPreviewContextPhrase(previewContextPhrase));
      SelectionUtil.removeWindowSelection();
    }
  }

  public static onDeSelectText(): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      dispatch(PhraseContextEditorActions.setSelectResult(null));
      dispatch(PhraseContextEditorActions.setPreviewContextPhrase(null));
    }
  }

  public static onSave(): AppThunk {
    return async(
      dispatch,
      getState
    ) => {
      const state = getState();
      const selectResult = PhraseContextEditorSelectors.getSelectResult(state);
      if (selectResult) {        
        getOneTimeTooltipsStorage().setShowPhrasesEdit(false);
        dispatch(setOneTimeTooltipPhrasesEditAction(false));

        if (PhraseContextEditorSelectors.getPhraseId(state)) { // saved phrase
          await dispatch(PhraseEffects.modifyContext(selectResult));
          PhraseNoteTimeScaleEffects.load();
        } else { // selected phrase
          dispatch(PhraseEffects.deleteCurrentPreviewPhrase());
          const phraseId = dispatch(PhraseEffects.savePreviewPhraseModifiedContext(selectResult));
          dispatch(CaptionsSelectionPopupActions.setPreviewPhraseId(phraseId as any));
          SelectionUtil.removeWindowSelection();
        }
      }
      dispatch(PhraseContextEditorActions.deActivate());
    }
  }


}