import { AppThunk } from '../../../../../store/types';
import { ContentLibEffects } from '../../../../../effects/contentLibEffects';

export class ContentLibGroupListEvents {

  public static onSearch(): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      ContentLibEffects.searchGroups();
    }
  }

}