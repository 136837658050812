import React from 'react';
import { Box, Grid } from '@mui/material';
import { IProfileGroupImgProps } from './types';
import { theme } from '../../../../../../common/styles/theme';


export function ProfileGroupImg({
	videos
}: IProfileGroupImgProps) {

	const className = 'profile-group__img';

	let v0: string = '',
		v1: string = '',
		v2: string = '',
		v3: string = '';
	
	if (videos.length) {
		v0 = videos[0].thumb;

		if (videos.length > 1) {
			v1 = videos[1].thumb;

			if (videos.length > 2) {
				v2 = videos[2].thumb;

				if (videos.length > 3) {
					v3 = videos[3].thumb;
				}
			}
		}
	}

	return (
		<Box
			className={`${className}`}
			sx={{
				alignSelf: 'flex-start',
				flex: `1 1 256px`,
				width: 1,
				position: 'relative',
				mt: v2 ? theme.spacing(1) : v1 ? theme.spacing(.5) : 0,

				[`&:before, &:after`]: {
					content: '""',
					position: 'absolute',
					zIndex: 1,
					aspectRatio: 16/9,
					backgroundPosition: '50% 50%',
					backgroundSize: 'cover',
					backgroundRepeat: 'no-repeat',
					backgroundImage: `url(${v0})`,
					borderRadius: theme.spacing(1),
					border: `1px solid ${theme.palette.common.white}`,
				},

				'&:before': v2 ? {
					top: theme.spacing(-1),
					left: theme.spacing(1),
					right: theme.spacing(1),
					backgroundImage: `url(${v2})`,
				} : {},

				'&:after': v1 ? {
					top: theme.spacing(-.5),
					left: theme.spacing(.5),
					right: theme.spacing(.5),
					backgroundImage: `url(${v1})`,
				} : {},
			}}
		>
			<Box
				sx={{
					position: 'relative',
					zIndex: 2,
					aspectRatio: 16/9,
					backgroundPosition: '50% 50%',
					backgroundSize: 'cover',
					backgroundRepeat: 'no-repeat',
					backgroundImage: `url(${v0})`,
					borderRadius: theme.spacing(1),
					overflow: 'hidden',
				}}
			>
				{v1 &&
					<Grid container
						sx={{
							width: 1,
							height: 1,

							'.MuiGrid-root': {
								width: .5,
								height: .5,
								backgroundPosition: '50% 50%',
								backgroundSize: 'cover',
								backgroundRepeat: 'no-repeat',
							}
						}}
					>
						<Grid
							sx={{
								backgroundImage: `url(${v0})`,
							}}
						/>
						<Grid
							sx={{
								backgroundImage: `url(${v1})`,
							}}
						/>
						{v2 &&
							<Grid
								sx={{
									backgroundImage: `url(${v2})`,
								}}
							/>
						}
						{v3 &&
							<Grid
								sx={{
									backgroundImage: `url(${v3})`,
								}}
							/>
						}
					</Grid>
				}
			</Box>
		</Box>
	)
};