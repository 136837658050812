import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { TeacherPhraseList } from '../components/TeacherPhraseList';
import { ITeacherPhraseListEvents, ITeacherPhraseListFields } from '../components/types';
import {
  getVideoTeacherData,
  getVideoTeacherPhrases,
  getVideoTeacherUserActiveId
} from '../../../../../store/videos/selectors';
import { EVocabularyPhraseType } from '../../../../../types/common';
import { TeacherPhraseListEvents } from './events';

const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): ITeacherPhraseListFields => {
    const userId = getVideoTeacherUserActiveId(state);
    const show = userId > 0;
    const teacher = getVideoTeacherData(state, userId);
    const phraseList = getVideoTeacherPhrases(state, userId)?.filter(p => p.type === EVocabularyPhraseType.WORD_AND_CONTEXT_SELECTED) || [];
    return {
      show,
      phraseList,
      loading: !!teacher?.phrasesLoading,
    }
  };
}

export const TeacherPhraseListHOC = connect(
  mapStateToProps,
  null,
)(TeacherPhraseList);

TeacherPhraseListHOC.displayName = 'TeacherPhraseListHOC';