import { AppThunk } from '../../../../../../store/types';
import { PhraseDetailsSelectors } from '../../../../../../store/phrase-details/selectors';
import { PhraseDetailsActions } from '../../../../../../store/phrase-details/actions';
import { PhraseDetailsTabsEditorPopupSelectors } from '../../../../../../store/phrase-details-tabs-editor-popup/selectors';
import { PhraseDetailsTabEffects } from '../../../../../../effects/phraseDetailsTabEffects';
import { getPhraseDetailsTabById } from '../../../../../../store/models/selectors';
import { setMainViewMode } from '../../../../../../store/general/actions';
import { EMainViewMode } from '../../../../../../store/general/types';
import { PhraseDetailsTabsEditorPopupActions } from '../../../../../../store/phrase-details-tabs-editor-popup/actions';
import { EPhraseDetailsTabsEditorViewMode } from '../../../../../../store/phrase-details-tabs-editor-popup/types';
import { PhraseContextEditorActions } from '../../../../../../store/phrase-context-editor/actions';
import { PhraseDetailsLibPromptService } from '../../../../../../effects/phrase-details/phraseDetailsService/phrase-details-lib-prompt-service';
import { EPhraseDetailsTabType } from '../../../../../../store/models/types';
import { GptUtils } from '../../../../../../effects/gptUtils';
import { PhraseNoteEffects } from '../../../../../../effects/phraseNoteEffects';
import { PhraseDetailsPromptLibEffects } from '../../../../../../effects/phrase-details/phraseDetailsPromptLibEffects';
import { StatLogManager } from '../../../../../../../common/stats/statLogManager';

export class PhraseLibPromptEvents {

  public static onLoad(useCache: boolean): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      const state = getState();
      const activeTab = PhraseDetailsSelectors.getActiveTab(state);
      if (!activeTab || activeTab.type !== EPhraseDetailsTabType.LIB_PROMPT) return;
      const id = activeTab?.relationId || 0;
      dispatch(PhraseDetailsActions.setLibPromptResult(id, '', true, false));
      const text = PhraseDetailsSelectors.getText(state) || '';
      const context = PhraseDetailsSelectors.getContext(state) || PhraseDetailsSelectors.getCaptionLineContext(state) || '';
      const fromLang = PhraseDetailsSelectors.getFromLang(state);
      const toLang = PhraseDetailsSelectors.getToLang(state);
      const result = await new PhraseDetailsLibPromptService(id, text, context, fromLang?.code || '', toLang?.code || '').load(useCache);
      StatLogManager.logPromptAccess();
      if (result !== undefined) {
        dispatch(PhraseDetailsActions.setLibPromptResult(id, result, false, true));
      }
    }
  }

  public static onClickSettings(): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      let tab = PhraseDetailsSelectors.getActiveTab(getState());
      if (tab && tab.type === EPhraseDetailsTabType.LIB_PROMPT) {
        await PhraseDetailsPromptLibEffects.loadPromptLibs();
        const lib = PhraseDetailsTabsEditorPopupSelectors.getPromptLibById(getState(), tab.relationId);
        if (lib) {
          dispatch(setMainViewMode(EMainViewMode.PHRASE_DETAILS_TABS_EDITOR));
          dispatch(PhraseDetailsTabsEditorPopupActions.setViewMode(EPhraseDetailsTabsEditorViewMode.LIBS));
          dispatch(PhraseDetailsTabsEditorPopupActions.setOpenPromptLibId(lib.id));
          dispatch(PhraseDetailsTabsEditorPopupActions.setPromptTestResult(''));
        }
      }
    }
  }

  public static onClickContext(): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      dispatch(PhraseContextEditorActions.updatePhraseContextEditorAction({
        show: true,
      }));
    }
  }

  public static onCopyNote(): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      const state = getState();
      const activeTab = PhraseDetailsSelectors.getActiveTab(state);
      let id = activeTab?.relationId || 0;
      const libPrompt = PhraseDetailsSelectors.getLibPrompt(state, id);
      const text = libPrompt && libPrompt.resultText && GptUtils.clear(libPrompt.resultText) || '';
      PhraseNoteEffects.copyToNoteText(text);
    }
  }

}