import { TPublicProfile } from "../../../../../store/general/types";
import { setAuthUserAction, setCreatePublicProfileModalOpenAction } from '../../../../../store/general/actions';
import { AppThunk } from "../../../../../store/types";
import { getAuthUser } from '../../../../../store/general/selectors';
import { UserProfileRest } from '../../../../../../common/rest/user/userProfileRest';

export class CreatePublicProfileModalEvents {

  public static onCreate(
      profile: TPublicProfile,
      onOwnClose?: () => any,
      ): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      const result = await UserProfileRest.save(profile);
      if (!result || !result.result || !result.result.success) {
        return result && result.result && result.result.errorType;
      }
      const authUser = getAuthUser(getState());
      dispatch(setAuthUserAction({
        ...authUser,
        ...{publicProfile: result.profile}
      }));
      dispatch(setCreatePublicProfileModalOpenAction(false));
      if (onOwnClose) {
        onOwnClose();
      }
      return null;
    }
  }

	public static onClose(
		onOwnClose?: () => any,
	): AppThunk {
		return async (
			dispatch,
			getState
		) => {
			dispatch(setCreatePublicProfileModalOpenAction(false));
			if (onOwnClose) onOwnClose();
		}
	}

	// public static checkNick (
	// 	nick: string,
	// ): AppThunk {
	// 	return async (
	// 		dispatch,
	// 		getState
	// 	) => {
	// 		const profile = await UserProfileRest.getByNick(nick);
	// 		const ret = !!profile && profile.name;
	// 		return ret;
	// 	}
	// }

}

