import { IState } from '../../../../store/types';
import { connect } from 'react-redux';
import { SavePhraseModeSelectPopup } from '../components/SavePhraseModeSelectPopup';
import { ISavePhraseModeSelectPopupEvents, ISavePhraseModeSelectPopupFields } from '../components/types';
import { SavePhraseModeSelectPopupEvents } from './events';
import { CaptionsSelectionPopupSelectors } from '../../../../store/captions-selection-popup/selectors';


const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): ISavePhraseModeSelectPopupFields => {
    const {show} = CaptionsSelectionPopupSelectors.getSavePhraseModePopup(state)
    return {
      show
    }
  };
}

const mapDispatchToProps = (
  dispatch: any
): ISavePhraseModeSelectPopupEvents => ({
    onSelect: (useContext, defaultAction) => dispatch(SavePhraseModeSelectPopupEvents.onSelect(useContext, defaultAction)),
    onClose: () => dispatch(SavePhraseModeSelectPopupEvents.onClose())
  }
);

export const SavePhraseModeSelectPopupHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(SavePhraseModeSelectPopup);

SavePhraseModeSelectPopupHOC.displayName = 'SavePhraseModeSelectPopupHOC';
