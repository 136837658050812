import { IState } from '../../../../../store/types';
import { ContentLibSelectors } from '../../../../../store/contentLib/selectors';
import { connect } from 'react-redux';
import { IContentLibCopyGroupConfirmEvents, IContentLibCopyGroupConfirmFields } from '../components/types';
import { ContentLibCopyGroupConfirmEvents } from './events';
import { ContentLibCopyGroupConfirm } from '../components/ContentLibCopyGroupConfirm';

const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): IContentLibCopyGroupConfirmFields => {
    const {show} = ContentLibSelectors.getCopyGroupConfirm(state);
    return {
      show
    }
  };
}

const mapDispatchToProps = (
  dispatch: any
): IContentLibCopyGroupConfirmEvents => ({
    onClose: () => dispatch(ContentLibCopyGroupConfirmEvents.onClose()),
    onOpenGroup: () => dispatch(ContentLibCopyGroupConfirmEvents.onOpenGroup())
  }
);

export const ContentLibCopyGroupConfirmHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(ContentLibCopyGroupConfirm);

ContentLibCopyGroupConfirmHOC.displayName = 'ContentLibCopyGroupConfirmHOC';