import { AppThunk } from '../../../../../store/types';
import {
  findVideoPhraseByWordId,
  getVideoTeacherShowNote,
  getVideoTeacherShowPhraseId,
  getVideoTeacherUserActiveId
} from '../../../../../store/videos/selectors';
import { PhraseEffects } from '../../../../../effects/phrase/PhraseEffects';
import { PhraseDetailsActions } from '../../../../../store/phrase-details/actions';
import { PhraseNoteEffects } from '../../../../../effects/phraseNoteEffects';
import { TeachersEffects } from '../../../../../effects/teachersEffects';
import { setPhrasesExistAction } from '../../../../../store/general/actions';
import { CaptionsSelectionPopupActions } from '../../../../../store/captions-selection-popup/actions';
import { PhraseListActions } from '../../../../../store/phrase-list/actions';
import { VocabularyEffects } from '../../../../../effects/phrase/vocabulary-effects';
import { EventsRouter } from '../../../../../../common/events/eventsRouter';
import { Events } from '../../../../../../common/events/types';
import { StatLogManager } from '../../../../../../common/stats/statLogManager';
import { Navigator } from '../../../../../effects/navigator';

export class PhraseTeacherNotesEvents {

  public static onCopy(): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      const teacherId = getVideoTeacherUserActiveId(getState());
      const teacherPhraseId = getVideoTeacherShowPhraseId(getState());
      const wordPhrase = await PhraseEffects.saveFromTeacherPhrase(teacherId, teacherPhraseId);
      if (wordPhrase) {
        const teacherNote = getVideoTeacherShowNote(getState());
        await PhraseNoteEffects.saveFromTeacherNote(teacherNote, wordPhrase.id)
        dispatch(PhraseDetailsActions.setPhraseId(wordPhrase.id));
        TeachersEffects.hidePhrases();

        dispatch(setPhrasesExistAction(true));
        const contextPhrase = findVideoPhraseByWordId(getState(), wordPhrase.id);
        if (contextPhrase) {
          dispatch(CaptionsSelectionPopupActions.setCurrentSelection({wordPhraseId: wordPhrase.id, contextPhraseId: contextPhrase.id}));
          dispatch(PhraseListActions.setForwardPhraseId(contextPhrase.id));
        }
        dispatch(VocabularyEffects.flashPhrase(wordPhrase.id));
        EventsRouter.trackEvent(Events.SAVE_PHRASE);
        StatLogManager.logPhraseSave();
      }
    }
  }

  public static onClickAuthor(): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      const teacherId = getVideoTeacherUserActiveId(getState());
      Navigator.openProfile(teacherId);
    }
  }

}