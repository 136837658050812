import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { IContentLibSelectedGroupEvents, IContentLibSelectedGroupFields } from '../components/types';
import { ContentLibSelectedGroupEvents } from './events';
import { ContentLibSelectors } from '../../../../../store/contentLib/selectors';
import { ContentLibSelectedGroup } from '../components/ContentLibSelectedGroup';

const mapStateToProps = (
) => {
  return (
    state: IState
  ): IContentLibSelectedGroupFields => {
    return {
      groupId: ContentLibSelectors.getSelectedGroupId(state),
      searchText: ContentLibSelectors.getSearchFilter(state).text
    }
  };
}

const mapDispatchToProps = (
  dispatch: any
): IContentLibSelectedGroupEvents => ({
    onLoadVideos: (startIndex, count) => dispatch(ContentLibSelectedGroupEvents.onLoadVideos(startIndex, count)),
    onVideoClick: videoId => dispatch(ContentLibSelectedGroupEvents.onVideoClick(videoId))
  }
);

export const ContentLibSelectedGroupHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(ContentLibSelectedGroup);

ContentLibSelectedGroupHOC.displayName = 'ContentLibSelectedGroupHOC';