import React from 'react';
import { IPublicProfileProps, TPublicProfile } from './types';
import { theme } from '../../../../../../common/styles/theme';
import { Box, CardMedia, CircularProgress, IconButton, MenuItem, Stack } from '@mui/material';
import { Paper } from '../../../../../../common/components/Paper/Paper';
import { DeviceContext } from '../../../../../../common/contexts/DeviceContext';
import { LogoLabel } from '../../../../../../../icons/LogoLabel';
import background from '../../../../install/Login/components/imgs/background.svg';
import { Text } from '../../../../../../common/components/Text/Text';
import { ButtonMenu } from '../../../../../../common/components/ButtonMenu/ButtonMenu';
import { IconMenuItem } from '../../../../../../common/components/IconMenuItem/IconMenuItem';
import { PencilIcon } from '../../../../../../../icons/PencilIcon';
import { CreatePublicProfileModalHOC } from '../../CreatePublicProfileModal/hocs/CreatePublicProfileModalHOC';
import { TUserProfileGroup } from '../../../../../../common/rest/user/userProfileRest';
import { ProfileGroupInfoHOC } from '../../ProfileGroupInfo/hocs/ProfileGroupInfoHOC';
import { CheckIcon } from '../../../../../../../icons/CheckIcon';
import { LinkIcon } from '../../../../../../../icons/LinkIcon';
import { ButtonIconGrey } from '../../../../../../common/components/ButtonIconGrey/ButtonIconGrey';
import { ArrowLightLeftIcon } from '../../../../../../../icons/ArrowLightLeftIcon';

export const PublicProfile: React.FC<IPublicProfileProps> = ({
	id,
	userName = '',
	onClickBack,
	onClickLogo,
	onLoad,
	onLoadGroups,
}) => {
	const { isMobile } = React.useContext(DeviceContext);

	const className = 'public-profile';

	const [progress, setProgress] = React.useState(true);
	const [profile, setProfile] = React.useState<TPublicProfile | null>(null);
	const [canEdit, setCanEdit] = React.useState(false);
	const [openEdit, setOpenEdit] = React.useState(false);
	const [progressGroups, setProgressGroups] = React.useState(true);
	const [groups, setGroups] = React.useState<TUserProfileGroup[]>([]);

	const load = () => {
		onLoad(userName)
			.then((ret) => {
				setProfile(ret?.id ? ret : null);
				setCanEdit(ret?.userId === id);
				if (ret?.id) {
					onLoadGroups(userName)
						.then((ret) => {
							setGroups(ret);
						})
						.catch((e) => {
							console.log(e);
						})
						.finally(() => {
							setProgressGroups(false);
						});
				}
			})
			.catch((e) => {
				console.log(e);
			})
			.finally(() => {
				setProgress(false);
			});
	}

	const [copied, setCopied] = React.useState(false);
    const onCopy = () => {
        navigator.clipboard.writeText(`${window.location.origin}/@${userName}`);
        setCopied(true);
    };

	React.useEffect(() => {
		load();
	}, [userName])

	return <Stack
		className={`
			${className}
		`}
		sx={{
			width: 1,
			height: 1,
			alignItems: 'center',
			bgcolor: theme.palette.secondary.main,
			backgroundImage: `url(${background})`,
			backgroundSize: 'cover',
		}}
	>
		<Paper
			className={className}
			sx={{
				position: 'relative',
				zIndex: 100,
				flex: `0 0 ${theme.spacing(8)}`,
				width: 1,
				py: 1.5,
				px: 2,
				borderRadius: 0,
				boxShadow: theme.shadows[8],

				'.mobile &': {
					p: 1,
				}
			}}
		>
			<Stack
				direction={'row'}
				alignItems={'center'}
				gap={theme.spacing(2)}
				sx={{
					width: 1,
					height: 1,

					'.mobile &': {
						gap: 1,
						justifyContent: 'space-between',
					}
				}}
			>
				<ButtonIconGrey
					sx={{
						'.MuiSvgIcon-root': {
							width: theme.spacing(3),
							height: theme.spacing(3),
						},
					}}
					onClick={onClickBack}
				>
					<ArrowLightLeftIcon />
				</ButtonIconGrey>
				
				<IconButton
					sx={{ p: 0, }} 
					onClick={onClickLogo}
				>
					<LogoLabel sx={{ height: theme.spacing(4), width: 'auto' }} />
				</IconButton>

			</Stack>
		</Paper>

		<Stack
			sx={{
				flex: `1 1 100%`,
				minHeight: 0,
				width: 1,
				alignItems: 'center',
				p: 2,
	
				overflow: 'auto',
			}}
		>
			{progress
				? <Stack
					sx={{
						width: 1,
						height: 1,
					}}
					alignItems={'center'}
					justifyContent={'center'}
				>
					<CircularProgress />
				</Stack>
				: profile && <Paper
					sx={{
						width: 1,
						maxWidth: theme.spacing(90),
						p: 3,
						fontSize: theme.typography.subtitle2,
						borderRadius: theme.spacing(1.25),
						boxShadow: theme.shadows[2],
					}}
				>
					<Stack
						flexDirection={'row'}
						gap={2}
						alignItems={'center'}
						sx={{
							position: 'relative',
						}}
					>
						{profile.showAvatar &&
							<CardMedia
								component='img'
								image={profile.avatar}
								alt={profile.name}
								sx={{
									flex: `0 0 ${theme.spacing(8)}`,
									height: theme.spacing(8),
									borderRadius: '50%',
								}}
							/>
						}
						<Stack
							gap={theme.spacing(.25)}
						>
							<Text
								sx={{
									fontSize: theme.typography.h3,
									fontWeight: 600,
								}}
							>
								{profile.name}
							</Text>
							<Text
								sx={{
									fontSize: theme.typography.subtitle2,
									color: theme.palette.grey[400],
								}}
							>
								@{profile.userName}
							</Text>
						</Stack>

						{canEdit &&
							<ButtonMenu
								buttonProps={{
									sx: {
										position: 'absolute',
										right: theme.spacing(-1),
										top: theme.spacing(-1),
									},
								}}
								menuProps={{
									sx: {
										'.MuiMenuItem-root': {
											px: theme.spacing(.5),
										},
									},
								}}
								onClose={() => setCopied(false)}
							>
								<IconMenuItem
									title={'Edit profile'}
									onClick={() => setOpenEdit(true)}
								>
									<PencilIcon />
								</IconMenuItem>
								<IconMenuItem
									title={'Copy public link'}
									onClick={onCopy}
								>
									{copied
										? <CheckIcon sx={{
											width: theme.spacing(1.5),
											mr: theme.spacing(-.5),
											color: `${theme.palette.success.main} !important`,
										}} />
										: <LinkIcon />
									}
								</IconMenuItem>
							</ButtonMenu>
						}
					</Stack>

					{profile.bio &&
						<Box
							dangerouslySetInnerHTML={{__html: profile.bio}}
							sx={{
								mt: theme.spacing(1),
								wordBreak: 'break-word',

								'figure, img': {
									maxWidth: '100% !important',
									maxHeight: '70vh !important',
								},
								'iframe': {
									maxWidth: '100% !important',
									maxHeight: '33vh !important',
								},
							}}
						/>
					}

					{progressGroups
						? <Stack
							sx={{
								width: 1,
								mt: theme.spacing(4),
							}}
							alignItems={'center'}
							justifyContent={'center'}
						>
							<CircularProgress />
						</Stack>
						: !!groups.length && <Box
							sx={{
								mt: theme.spacing(4),
								borderTop: `2px solid ${theme.palette.grey[200]}`,
							}}
						>
							{groups?.map(group => {
								return <ProfileGroupInfoHOC
									id={group.id}
									description={''}
									nativeLang={group.nativeLang.code}
									targetLang={group.targetLang.code}
									title={group.name}
									key={group.name}
									videos={group.videos}
								/>
							})}
						</Box>
					}
				</Paper>
			}

			{!profile && !progress &&
			<Text
				sx={{
					fontSize: theme.typography.h3,
					fontWeight: 600,
				}}
			>
				This user has not created their profile yet.
			</Text>
			}
		</Stack>

		{openEdit &&
			<CreatePublicProfileModalHOC
				isShow={openEdit}
				onClose={() => {
					setOpenEdit(false);
					load();
				}}
			/>
		}
	</Stack>
};
