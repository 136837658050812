import { Radio as RadioComponent, RadioProps, styled } from "@mui/material";
import React from 'react';

const RadioIcon = styled('span')(({ theme }) => ({
    borderRadius: '50%',
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    backgroundColor: theme.palette.grey[600],
    'input:hover ~ &': {
        backgroundColor: theme.palette.grey[500],
    },
    'input:disabled ~ &': {
        backgroundColor: theme.palette.grey[600],
    }
}));

const RadioIconChecked = styled('span')(({ theme }) => ({
    backgroundColor: theme.palette.grey[600],
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    borderRadius: '50%',
    '&:before': {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        width: theme.spacing(1),
        height: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
        borderRadius: '50%',
        content: '""',
    },
    'input:hover ~ &': {
        backgroundColor: theme.palette.grey[500],
    },
    'input:disabled ~ &': {
        backgroundColor: theme.palette.grey[600],
    }
}));


export const Radio = ({
    ...props
}: RadioProps) => {
    return (
        <RadioComponent
            disableRipple
            color="default"
            checkedIcon={<RadioIconChecked />}
            icon={<RadioIcon />}
            {...props}
        />
    );
}
