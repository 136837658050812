import { UserProfileRest } from '../../common/rest/user/userProfileRest';
import { ProfilesSelectors } from '../store/profiles/selectors';
import { getDispatch, getState } from '../store';
import { IProfilesState, TUserProfile } from '../store/profiles/types';
import { ProfilesActions } from '../store/profiles/actions';

export class ProfilesEffects {

  public static async load() {
    const dispatch = getDispatch();
    if (!ProfilesSelectors.isLoading(getState())) {
      dispatch(ProfilesActions.update({ loading: true, }));
      const list: TUserProfile[] = await UserProfileRest.getList();
      let update: Partial<IProfilesState> = {
        loading: false,
      }
      if (list && list.length) {
        update.list = list;
      }
      dispatch(ProfilesActions.update(update));
    }

  }
}