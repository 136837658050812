import { Reducer } from 'redux';
import {
  IPhraseContextEditorActions,
  IPhraseContextEditorState, PHRASE_CONTEXT_EDITOR_DELETE_PHRASE_ACTION,
  SET_PHRASE_CONTEXT_EDITOR_ACTIVATE_ACTION,
  SET_PHRASE_CONTEXT_EDITOR_DEACTIVATE_ACTION,
  SET_PHRASE_CONTEXT_EDITOR_PHRASE_ID_ACTION,
  SET_PHRASE_CONTEXT_EDITOR_PREVIEW_CONTEXT_PHRASE_ACTION,
  SET_PHRASE_CONTEXT_EDITOR_SELECT_RESULT_ACTION,
  SET_PHRASE_CONTEXT_EDITOR_SHOW_ACTION,
  UPDATE_PHRASE_CONTEXT_EDITOR
} from './types';

const initialState: IPhraseContextEditorState = {
  show: false,
  phraseId: 0,
  selectResult: null,
  previewContextPhrase: null
};

export const phraseContextEditorReducer: Reducer<IPhraseContextEditorState, IPhraseContextEditorActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SET_PHRASE_CONTEXT_EDITOR_SHOW_ACTION:
      return { ...state, show: action.show }
    case SET_PHRASE_CONTEXT_EDITOR_PHRASE_ID_ACTION:
      return { ...state, phraseId: action.phraseId }
    case SET_PHRASE_CONTEXT_EDITOR_ACTIVATE_ACTION:
      return { ...state, show: true, selectResult: null, phraseId: action.phraseId }
    case SET_PHRASE_CONTEXT_EDITOR_DEACTIVATE_ACTION:
      return { ...state, show: false, selectResult: null, previewContextPhrase: null, phraseId: 0 }
    case SET_PHRASE_CONTEXT_EDITOR_SELECT_RESULT_ACTION:
      return { ...state, selectResult: action.selectResult }
    case SET_PHRASE_CONTEXT_EDITOR_PREVIEW_CONTEXT_PHRASE_ACTION:
      return { ...state, previewContextPhrase: action.previewContextPhrase }
    case PHRASE_CONTEXT_EDITOR_DELETE_PHRASE_ACTION:
      return { ...state, phrases: state.phrases.filter(p => p.id !== action.phraseId) };
    case UPDATE_PHRASE_CONTEXT_EDITOR:
      return {
        ...state,
        ...action.value
      };
    default:
      return state;
  }
};