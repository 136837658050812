import React from 'react';
import {
  CLASS_PHRASE_DETAILS_COLUMN_SCROLL,
  IPhraseDetailsContainerProps,
  PHRASE_DETAILS_COLUMN_CLASS_NAME
} from './types';
import { PhraseExplainHOC } from '../../PhraseExplain/hocs/PhraseExplainHOC';
import { PhraseNotesHOC } from '../../PhraseNotes/hocs/PhraseNotesHOC';
import { PhraseUsageHOC } from '../../PhraseUsage/hocs/PhraseUsageHOC';
import { PhraseTranslationHOC } from '../../PhraseTranslation/hocs/PhraseTranslationHOC';
import { Box, Stack } from '@mui/material';
import { theme } from '../../../../../../common/styles/theme';
import { EPhraseDetailsTabType } from '../../../../../store/models/types';
import { PhraseCustomPromptHOC } from '../../PhraseCustomPrompt/hocs/CustomPrompt/PhraseCustomPromptHOC';
import { Paper } from '../../../../../../common/components/Paper/Paper';
import { PhraseDetailsContainerHeaderHOC } from '../../PhraseDetailsContainerHeader/hocs/PhraseDetailsContainerHeaderHOC';
import { CLASS_ONBOARDING_BLUR, CLASS_ONBOARDING_TRIGGER } from '../../../Onboarding/constants';
import { PhraseLibPromptHOC } from '../../PhraseCustomPrompt/hocs/LibPrompt/PhraseLibPromptHOC';
import { SelectionUtil } from '../../../../../../common/utils/selection-util';
import { PhraseDetailsPopupMenuHOC } from '../../PhraseDetailsPopupMenu/hocs/PhraseDetailsPopupMenuHOC';
import { PhrasePromptHeader } from '../../PhrasePromptHeader/components/PhrasePromptHeader';
import { HtmlUtils } from '../../../../../../common/utils/html-utils';
import { PhraseTextHOC } from '../../PhraseText/hocs/PhraseTextHOC';
import { PlayerFactory } from '../../../../../effects/player/player-factory';
import { PlayerStates } from '../../../../../types/common';
import { DeviceContext } from '../../../../../../common/contexts/DeviceContext';
import { Hint } from '../../../../../../common/components/Hint/Hint';
import { Text } from '../../../../../../common/components/Text/Text';
import { PhraseTeacherNotesHOC } from '../../PhraseTeacherNotes/hocs/PhraseTeacherNotesHOC';
import { PhraseTeacherTextHOC } from '../../PhraseTeacherText/hocs/PhraseTeacherTextHOC';

export const PhraseDetailsContainer: React.FC<IPhraseDetailsContainerProps> = ({
  activeTab,
  groupId,
  videoId,
  teacherMode,
  onLoad,
  onSelection,
  onPlayerPositionChange
}) => {
  const {isMobile} = React.useContext(DeviceContext);

  const classTab = 'phrase-details-tab';

  const isTranslation = activeTab && activeTab.type !== EPhraseDetailsTabType.NOTES;
  const isExplain = activeTab && activeTab.type === EPhraseDetailsTabType.EXPLAIN;
  const isUsage = activeTab && activeTab.type === EPhraseDetailsTabType.USAGE;
  const isNotes = activeTab && activeTab.type === EPhraseDetailsTabType.NOTES;
  const isCustom = activeTab && activeTab.type === EPhraseDetailsTabType.CUSTOM_PROMPT;
  const isLib = activeTab && activeTab.type === EPhraseDetailsTabType.LIB_PROMPT;

  const isMobileNoPrompt = isMobile && !isNotes && activeTab && activeTab.type === EPhraseDetailsTabType.TRANSLATION;

  const [_isNotes, setIsNotes] = React.useState(isNotes); // dont unmount
  React.useEffect(() => {
    if (isNotes) setIsNotes(isNotes);
  }, [isNotes]);

  React.useEffect(() => {
    onLoad();
  }, [groupId, videoId]);

  const onMouseUp = () => {
    const selection = SelectionUtil.getSelectedText();
    if (selection && selection.toString()) {
      if (selection.anchorNode?.parentElement &&
        HtmlUtils.findParentByClassName(selection.anchorNode.parentElement, PHRASE_DETAILS_COLUMN_CLASS_NAME)) {
        onSelection(selection);
      }
    }
  }

  React.useEffect(() => {
    let tm: number;
    PlayerFactory.getPlayerApi().then((player) => {
      // @ts-ignore
      tm = setInterval(async () => {
        const playerState = await player.player.getPlayerState();
        if (playerState === PlayerStates.PLAYING) {
          const time = await player.player.getCurrentTime();
          onPlayerPositionChange(time);
        }
      }, 500);
    })

    return () => {
      clearInterval(tm);
    }
  }, []);

  return (
    <Paper
      onMouseUp={onMouseUp}
      className={`
        ${PHRASE_DETAILS_COLUMN_CLASS_NAME}
        ${CLASS_ONBOARDING_BLUR}
      `}
      sx={{
        position: 'relative',
          display: 'flex',
          flexDirection: 'column',
        flex: '1 1 25%',
        width: 1,
        minHeight: 0,
        p: `${theme.spacing(1)} 0 0 0`,
        zIndex: 1000,

        '.columns-4 &': {
          flexBasis: '25%',
        },

        '.one-time-tooltip': {
          mb: theme.spacing(2)
        },
      }}
    >
          <PhraseDetailsPopupMenuHOC />
          {isMobile && isNotes
            ? null
            : <PhraseDetailsContainerHeaderHOC />
          }

          <Box
            className={`${CLASS_PHRASE_DETAILS_COLUMN_SCROLL} ${isNotes ? 'tab-notes' : ''}`}
            sx={{
              position: 'relative',
              overflowY: 'auto',
              flex: `1 1 100%`,
              minHeight: 0,

              '.phrase-details-tab': {
                display: 'none',
                pr: theme.spacing(1),

                '&.active': {
                  display: 'block',
                },
              },

              '&.tab-notes': {
                flex: `1 1 100%`,
                minHeight: 0,
                display: 'flex',
                flexDirection: 'column',

                '.phrase-details-tab': {
                  '&.active': {
                    display: 'flex',
                  },
                }
              },

            }}>

        
            <Box sx={{
              mb: theme.spacing(1.5),
              p: `0 ${theme.spacing(.5)} 0 ${theme.spacing(3)}`,
              overflowX: 'hidden',
              overflowY: 'auto',
              lineHeight: 1,

              '.tab-notes &': {
                maxHeight: '5.4em',
                minHeight: '1.8em',
                mb: 0,
              },
            }}>
              {!teacherMode && <PhraseTextHOC />}
              {teacherMode && <PhraseTeacherTextHOC />}
            </Box>

            <Box
              className={`${classTab} ${isTranslation ? 'active' : ''}`}
              sx={{
                pb: theme.spacing(2),
              }}
            >
              <PhraseTranslationHOC />
            </Box>

            {(isExplain || isUsage || isCustom || isLib) &&
              <PhrasePromptHeader />
            }

            <Box className={`${classTab} ${isExplain ? 'active' : ''}`}>
              <PhraseExplainHOC />
            </Box>
            <Box className={`${classTab} ${isUsage ? 'active' : ''}`}>
              <PhraseUsageHOC />
            </Box>
            <Box className={`${classTab} ${isCustom ? 'active' : ''}`}>
              <PhraseCustomPromptHOC />
            </Box>
            <Box className={`${classTab} ${isLib ? 'active' : ''}`}>
              <PhraseLibPromptHOC />
            </Box>

            {_isNotes &&
              <Stack
                className={`${classTab} ${isNotes ? 'active' : ''}`}
                sx={{
                  flex: `1 1 100%`,
                  minHeight: 0,
                  pr: theme.spacing(1),
                  pb: theme.spacing(2),
                }}
              >
                {teacherMode && <PhraseTeacherNotesHOC />}
                {!teacherMode && <PhraseNotesHOC />}
              </Stack>
            }

            {isMobileNoPrompt &&
              <Stack
                alignItems={'left'}
                margin={theme.spacing(2)}
              >
                <Hint>
                  <Text>
                    Please, select a prompt from library.
                  </Text>
                </Hint>
              </Stack>
            }

          </Box>

          <Box
							className={`${CLASS_ONBOARDING_TRIGGER} ${CLASS_ONBOARDING_TRIGGER}--step3-highlight`}
							sx={{
								position: 'absolute',
								pointerEvents: 'none',
								left: theme.spacing(-.5),
								right: theme.spacing(-.5),
								top: theme.spacing(-.5),
								bottom: theme.spacing(-.5),
								borderRadius: theme.spacing(1),
							}}
						/>
    </Paper>
  );

}

