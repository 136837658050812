import { IState } from '../../../../../../store/types';
import { connect } from 'react-redux';
import { CopyVideoConfirm } from '../components/CopyVideoConfirm';
import { ICopyVideoConfirmEvents, ICopyVideoConfirmFields } from '../components/types';
import { getCopyVideoConfirm } from '../../../../../../store/general/selectors';
import {
  createGroupNameIndex,
  getGroupNativeLanguage,
  getGroupTargetLanguage,
  getUserGroupById,
  getUserGroupTitle,
  getUserOwnGroups,
} from '../../../../../../store/models/selectors';
import { CopyVideoConfirmEvents } from './events';
import { VideoLibraryGroupItemHelpers } from '../../../../VideoGroups/VideoLibraryGroupItem/hocs/helpers';
import { TVideoInfo } from '../../../../../../store/models/types';
import { LangUtil } from '../../../../../../../common/utils/lang-util';
import { ICopyVideoConfirm } from '../../../../../../store/general/types';
import { getState } from '../../../../../../store';

const getGetNewGroupName = (copyVideoConfirm: ICopyVideoConfirm) => {
  const targetLangName = copyVideoConfirm.srcGroupTargetLang ? LangUtil.getLangNameByCode(copyVideoConfirm.srcGroupTargetLang) : null;
  const nativeLangName = copyVideoConfirm.srcGroupNativeLang ? LangUtil.getLangNameByCode(copyVideoConfirm.srcGroupNativeLang) : null;
  let title = '';
  if (targetLangName) {
    if (nativeLangName) {
      title = `${targetLangName} / ${nativeLangName}`
    } else {
      title = `${targetLangName}`
    }
  }
  return title ? createGroupNameIndex(getState(), title) : '';
}

const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): ICopyVideoConfirmFields => {
    const copyVideoConfirm = getCopyVideoConfirm(state);
    const {show, targetGroupId = 0, targetGroupName} = copyVideoConfirm;


    let videoInfo = copyVideoConfirm?.videoInfo as TVideoInfo;
    const group = getUserGroupById(state, targetGroupId);
    const videoLangCodes = videoInfo?.langs?.split(',').map(l => LangUtil.checkLangCode(l)) || [];
    const langCodes = group ? VideoLibraryGroupItemHelpers.getSelectedVideoLangs(state, group, videoLangCodes) : [];
    const groupName = targetGroupName ? targetGroupName : (group ? getUserGroupTitle(state, group) : '');
    
    const groups = getUserOwnGroups(state).filter(g => {
      const targetLang = g ? getGroupTargetLanguage(state, g) : null;
      return videoLangCodes && targetLang &&
        videoLangCodes.includes(targetLang.code);
    })

    let newListName = getGetNewGroupName(copyVideoConfirm);

    return {
      groupId: targetGroupId,
      groupName,
      groups,
      show,
      videoInfo,
      langCodes,
      newListName,
    }
  };
}

const mapDispatchToProps = (
  dispatch: any
): ICopyVideoConfirmEvents => ({
    onClose: () => dispatch(CopyVideoConfirmEvents.onClose()),
    onChange: (groupId, name) => dispatch(CopyVideoConfirmEvents.onGroupChange(groupId, name)),
  }
);

export const CopyVideoConfirmHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(CopyVideoConfirm);

CopyVideoConfirmHOC.displayName = 'CopyVideoConfirmHOC';
