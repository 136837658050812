import {
  changeAutoSyncAction,
  setAutoPauseAction,
  setHoverAutoPauseAction, setPauseOnNoteAction, setSavePhraseModeAction,
  setShowNativeCaptionsAction
} from '../store/general/actions';
import { getDispatch } from '../store';
import { UserRest } from '../../common/rest/user/userRest';
import { PhraseListActions } from '../store/phrase-list/actions';
import { CaptionsSelectionPopupActions } from '../store/captions-selection-popup/actions';
import { ESavePhraseMode } from '../store/general/types';

export class UserSettingEffects {

  public static async setAutoPause(pause: number) {
    const dispatch = getDispatch();
    dispatch(setAutoPauseAction(pause));
    await UserRest.saveSettings({pause});
  }

  public static async setHoverAutoPause(hoverAutoPause: boolean) {
    const dispatch = getDispatch();
    dispatch(setHoverAutoPauseAction(hoverAutoPause));
    await UserRest.saveSettings({hoverAutoPause});
  }

  public static async setAutoSync(autoSync: boolean) {
    const dispatch = getDispatch();
    dispatch(changeAutoSyncAction(autoSync));
    await UserRest.saveSettings({autoSync});
  }

  public static async setShowNativeCaptions(show: boolean) {
    const dispatch = getDispatch();
    dispatch(setShowNativeCaptionsAction(show));
    await UserRest.saveSettings({showNativeCaptions: show});
  }

  public static async setPhraseListPauseDelay(value: number) {
    const dispatch = getDispatch();
    dispatch(PhraseListActions.setPauseDelay(value));
    await UserRest.saveSettings({phraseListPauseDelay: value});
  }

  public static async setPhraseListStartPlayOffset(value: number) {
    const dispatch = getDispatch();
    dispatch(PhraseListActions.setPlayOffset({start: value}));
    await UserRest.saveSettings({phraseListStartPlayOffset: value});
  }

  public static async setPhraseListEndPlayOffset(value: number) {
    const dispatch = getDispatch();
    dispatch(PhraseListActions.setPlayOffset({end: value}));
    await UserRest.saveSettings({phraseListEndPlayOffset: value});
  }

  public static async setAutoSpeechSelectedText(value: boolean) {
    const dispatch = getDispatch();
    dispatch(CaptionsSelectionPopupActions.setAutoSpeechText(value));
    await UserRest.saveSettings({autoSpeechSelectedText: value});
  }

  public static async setPauseOnNote(value: boolean) {
    const dispatch = getDispatch();
    dispatch(setPauseOnNoteAction(value));
    await UserRest.saveSettings({pauseOnNote: value});
  }

  public static async setSavePhraseMode(savePhraseMode: ESavePhraseMode) {
    const dispatch = getDispatch();
    dispatch(setSavePhraseModeAction(savePhraseMode));
    await UserRest.saveSettings({savePhraseMode});
  }
}


