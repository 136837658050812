import { AppThunk } from '../../../../../store/types';
import { CopyGroupEffects } from '../../../../../effects/copyGroupEffects';
import { updateUserGroups } from '../../../../../effects/updateUserGroups';

export class ProfileGroupInfoEvents {

  public static onCopyGroup(groupId: number): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      const result = await CopyGroupEffects.copyPublic(groupId);
      if (result) {
        await dispatch(updateUserGroups(true));
      }
    }
  }

}