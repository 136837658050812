import React from 'react';
import { Modal } from '../../../../../common/components/Modal/Modal';
import { FormGroup, Box, Button, CircularProgress, Stack, TextField } from '@mui/material';
import { theme } from '../../../../../common/styles/theme';
import { Text } from '../../../../../common/components/Text/Text';
import { IAddNewVideoPopupProps } from './types';
import { InstallExtensionWarningHOC } from '../../InstallExtensionWarning/hocs/InstallExtensionWarningHOC';
import { LogoSmall } from '../../../../../../icons/LogoSmall';
import { DeviceContext } from '../../../../../common/contexts/DeviceContext';

export const AddNewVideoPopup: React.FC<IAddNewVideoPopupProps> = ({
  isOpen,
  onValidate,
  onCreate,
  onClose
}) => {
  if (!isOpen) return null;

  const {isMobile} = React.useContext(DeviceContext);

  const [url, setUrl] = React.useState<string>('');
  const [error, setError] = React.useState<string | null>(null);
  const [progress, setProgress] = React.useState<boolean>(false);

  React.useEffect(() => {
    setError('');
  }, [url]);

  const onUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUrl(e.target.value);
  }

  const handleAddVideo = async () => {
    setError(null);
    const result = onValidate(url.trim());
    if (!result.success) {
      return setError(result.error || 'Unknown result');
    }
    setProgress(true);
    try {
      await onCreate(url.trim());
    } catch(e) {
      setProgress(false)
    }
    onClose();
  }

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleAddVideo();
    }
  };

  const loaderStyle = {
    position: 'absolute',
    left: `50%`,
    bottom: theme.spacing(1),
    ml: theme.spacing(-1.5) + ' !important',
  };

  const iconStyle = {
    height: theme.spacing(2.5),
    width: 'auto',
    marginLeft: '5px',
    marginRight: '5px',
  }

  return (
    <Modal
      onClose={onClose}
      open={isOpen}
      title={'Add Video'}
      isMobile={isMobile}
    >
      <Stack
        gap={theme.spacing(2)}
        sx={{
          width: theme.spacing(45.75),
          maxWidth: '100%',
        }}
      >
        {!isMobile && <InstallExtensionWarningHOC />}
        <Box
          sx={{
            p: theme.spacing(2),
            borderRadius: theme.spacing(0.75),
            backgroundColor: theme.palette.hint.light
          }}
        >
          <Text sx={{ fontSize: theme.typography.subtitle2 }}>
            {!isMobile && <>
              Use chrome extension icon
              <LogoSmall sx={iconStyle} />
              on your browser our YouTube toolbar to add videos.<br/>
            </>}
            To manually add a new video into your current
            playlist, navigate to YouTube, select the desired video, copy its URL from the address bar of your browser, and finally, paste it here
          </Text>
        </Box>
        <FormGroup onKeyDown={handleKeyDown} sx={{ gap: theme.spacing(1) }}>     
          <TextField
            label={'YouTube video url'}
            fullWidth
            value={url}
            onChange={onUrlChange}
            multiline={false}
            variant={'filled'}
            autoFocus
          />
          <Text sx={{ color: theme.palette.error.main }}>
            {error}
          </Text>
          <Box
            sx={{
              position: 'relative',
            }}
          >
            <Button
              disabled={!!error || progress || !url.length}
              onClick={handleAddVideo}
              variant={'contained'}
              fullWidth
            >
              Add Video
            </Button>
            {progress && <CircularProgress size={theme.spacing(3)} sx={loaderStyle}/>}
          </Box>
        </FormGroup>
      </Stack>
    </Modal>
  );
}
