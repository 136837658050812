import { TPublicProfile } from '../general/types';

export const PROFILE_LIST_SET_ACTION = 'PROFILE_LIST/PROFILE_LIST_SET_ACTION';
export const PROFILES_UPDATE_ACTION = 'PROFILE_LIST/PROFILES_UPDATE_ACTION';

export interface IProfileListSetAction {
  type: typeof PROFILE_LIST_SET_ACTION;
  list: TUserProfile[]
}

export interface IProfilesUpdateAction {
  type: typeof PROFILES_UPDATE_ACTION;
  profiles: Partial<IProfilesState>;
}


export type TUserProfile = TPublicProfile;

export interface IProfilesState {
  list: TUserProfile[]
  loading: boolean;
}

export type TProfilesActions =
  | IProfileListSetAction
  | IProfilesUpdateAction
