import { API_URL } from '../../constants';
import { getRequest } from '../../utils/requestService';
import { TAsyncThunk } from '../../../spa/store/types';
import { TVideo } from '../../../spa/store/models/types';
import { IPhraseNote, IVocabularyPhrase } from '../../../spa/types/common';
import { ITeacherUser } from '../../../spa/store/videos/types';
import { TPhraseNoteRest } from '../phraseNote/phraseNoteRest';

const VIDEO_URL = API_URL + '/video';

export interface IRestVideoGroup extends TVideo {
  phrases: IVocabularyPhrase[];
  phraseNotes: TPhraseNoteRest[];
  position: number;
  teacherUsers: ITeacherUser[];
}

export const fetchGroupVideo = (videoId: string, groupId: number, teacherMode: boolean): TAsyncThunk => (
  dispatch
): Promise<TVideo | null> => {
  const teacherModeValue = teacherMode ? '1' : '0';
  const requestUri = `${VIDEO_URL}/${groupId}/${videoId}/${teacherModeValue}`;
  return dispatch(getRequest(requestUri));
};
