import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { SavedVideoConfirmNotify } from '../components/SavedVideoConfirmNotify';
import { ISavedVideoConfirmNotifyEvents, ISavedVideoConfirmNotifyFields } from '../components/types';
import { SavedVideoConfirmNotifyEvents } from './events';
import {
  getUserGroupById,
  getUserGroupLangToLearn,
  getUserGroupTitle,
  getUserOwnGroups,
  getVideoById
} from '../../../../../store/models/selectors';
import { getSavedVideoConfirm } from '../../../../../store/general/selectors';

const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState,
  ): ISavedVideoConfirmNotifyFields => {
    const confirmState = getSavedVideoConfirm(state);
    const video = confirmState.videoId ? getVideoById(state, confirmState.videoId) : null;
    const videoTitle = video ? video.info.title : '';
    const group = confirmState.groupId ? getUserGroupById(state, confirmState.groupId) : null;
    const groupTitle = group ? getUserGroupTitle(state, group) : '';
    const showSelectGroup = getUserOwnGroups(state).length > 1;

    let isWrongLang = false;
    let videoTargetLang = '';
    let groupTargetLang = '';
    if (group && video) {
      const targetLang = getUserGroupLangToLearn(state, group);
      if (video.info.langList &&
        targetLang &&
        !video.info.langList.includes(targetLang.code)
      ) {
        isWrongLang = true;
        if (video.info.langList && video.info.langList.length) videoTargetLang = video.info.langList[0];
        groupTargetLang = targetLang.name;
      }
    }

    return {
      videoTitle,
      groupTitle,
      showSelectGroup,
      isWrongLang,
      videoTargetLang,
      groupTargetLang,
    }
  };
}


const mapDispatchToProps = (
  dispatch: any
): ISavedVideoConfirmNotifyEvents => ({
  onSelectGroup: () => dispatch(SavedVideoConfirmNotifyEvents.onSelectGroup())
});


export const SavedVideoConfirmNotifyHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(SavedVideoConfirmNotify);

SavedVideoConfirmNotifyHOC.displayName = 'SavedVideoConfirmNotifyHOC';