import { AppThunk } from '../../../../../store/types';
import { ContentLibActions } from '../../../../../store/contentLib/actions';
import { ContentLibSelectors } from '../../../../../store/contentLib/selectors';
import { ContentLibEffects } from '../../../../../effects/contentLibEffects';

export class ProfileVideoPopupEvents {

  public static onSave(
    targetGroupId: number,
    onClose: () => void,
  ): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      const {groupId, videoId} = ContentLibSelectors.getVideoPopup(getState());
      dispatch(ContentLibActions.setVideoPopup({groupId: 0, videoId: 0}));
      const result = await ContentLibEffects.copyVideo(groupId, videoId, targetGroupId);
      dispatch(ContentLibActions.setCopyVideoConfirm({
        show: true,
        videoId: result.videoId,
        groupId: result.groupId
      }));
      onClose();
    }
  }
}