import { IPhraseSelectResult } from '../../effects/phrase/utils/phrase-select-preparator';
import { IVocabularyPhrase } from '../../types/common';

export const SET_PHRASE_CONTEXT_EDITOR_SHOW_ACTION = 'SET_PHRASE_CONTEXT_EDITOR_SHOW_ACTION';
export const SET_PHRASE_CONTEXT_EDITOR_PHRASE_ID_ACTION = 'SET_PHRASE_CONTEXT_EDITOR_PHRASE_ID_ACTION';
export const SET_PHRASE_CONTEXT_EDITOR_ACTIVATE_ACTION = 'SET_PHRASE_CONTEXT_EDITOR_ACTIVATE_ACTION';
export const SET_PHRASE_CONTEXT_EDITOR_DEACTIVATE_ACTION = 'SET_PHRASE_CONTEXT_EDITOR_DEACTIVATE_ACTION';
export const SET_PHRASE_CONTEXT_EDITOR_SELECT_RESULT_ACTION = 'SET_PHRASE_CONTEXT_EDITOR_SELECT_RESULT_ACTION';
export const SET_PHRASE_CONTEXT_EDITOR_PREVIEW_CONTEXT_PHRASE_ACTION = 'SET_PHRASE_CONTEXT_EDITOR_PREVIEW_CONTEXT_PHRASE_ACTION';
export const UPDATE_PHRASE_CONTEXT_EDITOR = 'UPDATE_PHRASE_CONTEXT_EDITOR';
export const PHRASE_CONTEXT_EDITOR_DELETE_PHRASE_ACTION = 'SET_PHRASE_CONTEXT_EDITOR_DELETE_PHRASE_ACTION';

export interface IPhraseContextEditorState {
  show: boolean,
  phraseId: number,
  selectResult: IPhraseSelectResult | null,
  previewContextPhrase: IVocabularyPhrase | null
  phrases?: IVocabularyPhrase[]; // not saved selected phrase for prompt
}

export interface IPhraseContextEditorSetShowAction {
  type: typeof SET_PHRASE_CONTEXT_EDITOR_SHOW_ACTION;
  show: boolean
}

export interface IPhraseContextEditorSetPhraseIdAction {
  type: typeof SET_PHRASE_CONTEXT_EDITOR_PHRASE_ID_ACTION;
  phraseId: number
}

export interface IPhraseContextEditorActiveAction {
  type: typeof SET_PHRASE_CONTEXT_EDITOR_ACTIVATE_ACTION;
  phraseId: number
}

export interface IPhraseContextEditorDeActiveAction {
  type: typeof SET_PHRASE_CONTEXT_EDITOR_DEACTIVATE_ACTION;
}

export interface IPhraseContextEditorSetSelectResultAction {
  type: typeof SET_PHRASE_CONTEXT_EDITOR_SELECT_RESULT_ACTION;
  selectResult: IPhraseSelectResult | null
}

export interface IPhraseContextEditorSetPreviewContextPhraseAction {
  type: typeof SET_PHRASE_CONTEXT_EDITOR_PREVIEW_CONTEXT_PHRASE_ACTION;
  previewContextPhrase: IVocabularyPhrase | null
}

export interface IUpdatePhraseContextEditorAction {
  type: typeof UPDATE_PHRASE_CONTEXT_EDITOR;
  value: Partial<IPhraseContextEditorState>;
}

export interface IPhraseContextEditorDeletePhraseAction {
  type: typeof PHRASE_CONTEXT_EDITOR_DELETE_PHRASE_ACTION;
  phraseId: number
}

export type IPhraseContextEditorActions =
  | IPhraseContextEditorSetShowAction
  | IPhraseContextEditorSetPhraseIdAction
  | IPhraseContextEditorActiveAction
  | IPhraseContextEditorDeActiveAction
  | IPhraseContextEditorSetSelectResultAction
  | IPhraseContextEditorSetPreviewContextPhraseAction
  | IUpdatePhraseContextEditorAction
  | IPhraseContextEditorDeletePhraseAction