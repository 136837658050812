import { Reducer } from 'redux';
import { IProfilesState, PROFILE_LIST_SET_ACTION, PROFILES_UPDATE_ACTION, TProfilesActions } from './types';

const initialState: IProfilesState = {
  loading: false,
  list: []
};

export const profilesReducer: Reducer<IProfilesState, TProfilesActions> = (
  state = initialState,
  action
) => {

  switch (action.type) {
    case PROFILE_LIST_SET_ACTION:
      return {
        ...state,
        list: [...action.list],
      };

    case PROFILES_UPDATE_ACTION:
      return {
        ...state,
        ...action.profiles,
      };
      
    default:
      return state;
  }
};