import * as React from 'react';
import { IButtonMenuProps } from './types';
import { IconButton, Menu } from '@mui/material';
import { MoreIcon } from '../../../../icons/MoreIcon';
import { theme } from '../../styles/theme';
import { useCombinedRefs } from '../../utils/useCombinedRefs';
import { v4 as uuidv4 } from 'uuid';
import { DeviceContext } from '../../contexts/DeviceContext';

export function ButtonMenu (props: IButtonMenuProps) {
    const {
        buttonProps,
        menuProps,
        children,
        onClose,
        onOpen,
    } = props;

    const {
        ref,
        sx: buttonSx,
        ...buttonProps1
    } = buttonProps;

    const {
        open,
        sx: menuSx,
        ...menuProps1
    } = menuProps;

    const { isTouch } = React.useContext(DeviceContext);

    const _buttonRef = React.useRef(null);
    const buttonRef =  useCombinedRefs(ref, _buttonRef);

    const className = 'button-menu';
    const [classUid] = React.useState(uuidv4());
    const classRoot = className + classUid;
    const classMenu = className + '__menu' + classUid;

    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const data = React.useRef({ anchorEl });
	data.current = { anchorEl }

    const timerOpen = React.useRef(null);
    const timerClose = React.useRef(null);
	const onMouseEnterHandler = React.useCallback(() => {
        if (isTouch) return;
        if (timerClose.current) { clearTimeout(timerClose.current); timerClose.current = null } 
        timerOpen.current = setTimeout(() => {
            handleOpen();
            timerOpen.current = null
        }, 500);
	}, []);
	const onMouseLeaveHandler = React.useCallback(() => {
        if (isTouch) return;
        if (timerOpen.current) { clearTimeout(timerOpen.current); timerOpen.current = null } 
        timerClose.current = setTimeout(() => {
            handleClose();
            timerClose.current = null
        }, 1000);
	}, []);
    const onMouseMoveHandler = React.useCallback(() => {
        if (isTouch) return;
        if (!timerClose.current) timerClose.current = setTimeout(() => {
            if (!timerOpen.current) handleClose();
        }, 1000);
	}, []);
    const onOpenedHandler = React.useCallback(() => {
		if (timerClose.current) { clearTimeout(timerClose.current); timerClose.current = null } 
	}, []);

	const handleOpen = () => {
        if (data.current.anchorEl) return;
		setAnchorEl(buttonRef.current);
        if (onOpen) onOpen();

        setTimeout(() => {
            const popover = document.querySelector(`.${classMenu}`);
            if (popover) {
                popover.addEventListener('mousemove', onOpenedHandler);
            }

            const backdrop = document.querySelector(`.${classRoot} .MuiBackdrop-root`);
            if (backdrop) {
                backdrop.addEventListener('mousemove', onMouseMoveHandler);
            }
        }, 100);
	};

	const handleClose = () => {
        if (!data.current.anchorEl) return;
		setAnchorEl(null);
        if (onClose) onClose();
	};

    React.useEffect(() => {
        if (!open) setAnchorEl(null);
    }, [open]);

    React.useEffect(() => {
        return () => {
            if (timerOpen.current) clearTimeout(timerOpen.current);
            if (timerClose.current) clearTimeout(timerClose.current);
        }
    }, []);

    return <>
        <IconButton
            {...buttonProps1}
            sx={{
                color: theme.palette.grey[400],
                '&:hover, &:active, &:focus': { color: theme.palette.grey[900], },
                '.MuiSvgIcon-root': { width: theme.spacing(2), height: theme.spacing(2) },
                
                ...buttonSx
            }}
            ref={buttonRef}
            onMouseEnter={onMouseEnterHandler}
            onMouseLeave={onMouseLeaveHandler}
            onClick={handleOpen}
        >
            <MoreIcon />
        </IconButton>

        <Menu
            PopoverClasses={{
                paper: classMenu,
            }}
            tabIndex={0}

            {...menuProps1}

            className={`${classRoot} ${menuProps1.className}`}

            sx={{
                zIndex: theme.zIndex.modal + 10,

                '& > .MuiPaper-root': {
                    width: theme.spacing(20),
                },

                '.MuiMenuItem-root': {
                    height: theme.spacing(4.5),
                    p: `0 ${theme.spacing(.5)}`,
                },

                '.MuiListItemIcon-root': {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: theme.spacing(3),
                    mr: theme.spacing(.5),
                    color: theme.palette.grey[400],
                },

                '.MuiListItemText-primary': {
                    fontSize: theme.typography.subtitle2,
                },

                ...menuSx
            }}

            anchorEl={anchorEl}
            open={!!anchorEl}
            onClose={handleClose}

            onMouseEnter={onMouseEnterHandler}
            onMouseLeave={onMouseLeaveHandler}
        >
            {children}
        </Menu>
    </>;
};
