import { ICaptionsHOCOwnProps } from '../../../CaptionsContainer/Captions/hocs/types';
import { IState } from '../../../../../store/types';
import { ICaptionsEvents, ICaptionsFields } from '../../../CaptionsContainer/Captions/components/types';
import { getNativeCaptions, getTargetCaptions, isVideoCaptionsEmpty } from '../../../../../store/videos/selectors';
import {
  getAutoSync,
  getMainViewMode,
  getPlayerState,
  getShowNativeCaptions,
  isShowNativeLanguageTooltip
} from '../../../../../store/general/selectors';
import { EMainViewMode } from '../../../../../store/general/types';
import {
  getActiveGroupId,
  getActiveGroupNativeLanguage,
  getActiveGroupTargetLanguage
} from '../../../../../store/models/selectors';
import {
  getCurrentMovieKey,
  getSelectedNativeCaption,
  getSelectedTargetCaption
} from '../../../../../store/current-video/selectors';
import { CaptionsSelectionPopupSelectors } from '../../../../../store/captions-selection-popup/selectors';
import { connect } from 'react-redux';
import { AudioCaptionsContainer } from '../components/AudioCaptionsContainer';
import { AudioCaptionsContainerEvents } from './events';

const mapStateToProps = (
  dispatch: any,
  {}: ICaptionsHOCOwnProps
) => {
  return (
    state: IState
  ): ICaptionsFields => {
    const targetCaptions = getTargetCaptions(state);
    const nativeCaptions = getNativeCaptions(state);
    const showNativeLangTooltip = (!!targetCaptions.length || !!nativeCaptions.length) && isShowNativeLanguageTooltip(state);

    const showNativeCaptions = getShowNativeCaptions(state) &&
      getMainViewMode(state) !== EMainViewMode.PHRASES;

    const captionsEmpty = isVideoCaptionsEmpty(state);

    const groupTargetLangName = getActiveGroupTargetLanguage(state)?.name || '';
    const groupNativeLangName = getActiveGroupNativeLanguage(state)?.name || '';

    return {
      playerState: getPlayerState(state),
      targetCaptions,
      nativeCaptions,
      captionsEmpty,
      targetLang: getSelectedTargetCaption(state)?.code,
      nativeLang: getSelectedNativeCaption(state)?.code,
      showSelectionTool: CaptionsSelectionPopupSelectors.isShow(state),
      autoSync: getAutoSync(state),
      groupId: getActiveGroupId(state) || 0,
      videoId: getCurrentMovieKey(state),
      showNativeLangTooltip,
      showNativeCaptions,
      groupTargetLangName,
      groupNativeLangName
    }
  };
}

const mapDispatchToProps = (
  dispatch: any
): ICaptionsEvents => ({
    onKeyDown: () => dispatch(AudioCaptionsContainerEvents.onKeyDown()),
    onVideoChange: () => dispatch(AudioCaptionsContainerEvents.onVideoChange()),
    onTargetCaptionsChange: () => dispatch(AudioCaptionsContainerEvents.onTargetCaptionsChange()),
    onNativeCaptionsChange: () => dispatch(AudioCaptionsContainerEvents.onNativeCaptionsChange()),
  }
);

export const AudioCaptionsContainerHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(AudioCaptionsContainer);

AudioCaptionsContainerHOC.displayName = 'AudioCaptionsContainerHOC';