import { AppThunk } from '../../../../store/types';
import {
  setMainViewMode,
  setPhrasesExistAction,
  setShowTelegramBotPopupAction,
  setSnackbarPanelAction
} from '../../../../store/general/actions';
import { CaptionsSelectionPopupActions } from '../../../../store/captions-selection-popup/actions';
import { IPhraseSelectResult } from '../../../../effects/phrase/utils/phrase-select-preparator';
import { PhraseEffects } from '../../../../effects/phrase/PhraseEffects';
import { SelectionUtil } from '../../../../../common/utils/selection-util';
import { getSelectedTargetCaption } from '../../../../store/current-video/selectors';
import { IVocabularyPhrase } from '../../../../types/common';
import { PhraseDetailsEffects } from '../../../../effects/phrase-details/phrase-details-effects';
import { getGeneralStorage } from '../../../../../common/utils/local-storage/local-storage-helpers';
import { EventsRouter } from '../../../../../common/events/eventsRouter';
import { Events } from '../../../../../common/events/types';
import { VocabularyEffects } from '../../../../effects/phrase/vocabulary-effects';
import { SelectionPopupEffects } from '../../../../effects/selectionPopupEffects';
import { PlayerController } from '../../../../effects/player/manager/playerController';
import { CaptionsSelectionPopupSelectors } from '../../../../store/captions-selection-popup/selectors';
import { getPhraseDetailsTabByType, getTargetLanguage } from '../../../../store/models/selectors';
import { textSpeaker } from '../../../../effects/textSpeaker';
import { LangUtil } from '../../../../../common/utils/lang-util';
import { ECaptionsSelectionPopupActionButton } from '../../../../store/captions-selection-popup/types';
import { TextSelectionPopupUtils } from './utils';
import { getMainViewMode } from '../../../../store/general/selectors';
import { EMainViewMode } from '../../../../store/general/types';
import { historyPush } from '../../../../../common/utils/historyHelper';
import { UserTranslateEffects } from '../../../../effects/userTranslateEffects';
import { StatLogManager } from '../../../../../common/stats/statLogManager';
import { findVideoPhraseById, findVideoPhraseByWordId } from '../../../../store/videos/selectors';
import { PhraseListActions } from '../../../../store/phrase-list/actions';
import { PhraseDetailsActions } from '../../../../store/phrase-details/actions';
import { EPhraseDetailsTabType } from '../../../../store/models/types';
import { CLASS_MOBILE_TABS_SAVED } from '../../Video/components/constants';

export class TextSelectionPopupEvents {

 
  public static onPreviewPhrase(selectResult: IPhraseSelectResult, translate?: boolean): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      dispatch(PhraseEffects.deleteCurrentPreviewPhrase());
      const phraseId: number = dispatch(PhraseEffects.savePreviewPhrase(selectResult, translate));
      dispatch(CaptionsSelectionPopupActions.setPreviewPhraseId(phraseId));
      const phrase = findVideoPhraseById(getState(), phraseId);
      if (phrase) {
        dispatch(CaptionsSelectionPopupActions.setCurrentSelection({contextPhraseId: phraseId, wordPhraseId: phrase.wordPhraseId}));
      }
      SelectionUtil.removeWindowSelection();
    }
  }

  public static onSelectText(selectResult: IPhraseSelectResult | null): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      dispatch(CaptionsSelectionPopupActions.setLastSelectText(selectResult ? selectResult.text : undefined));
      if (selectResult) {
        UserTranslateEffects.save(selectResult);
      }
    }
  }

  public static onSavePhrase(selectResult: IPhraseSelectResult): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      EventsRouter.trackEvent(Events.SAVE_PHRASE_BUTTON_CLICKED);
      dispatch(PhraseEffects.deleteCurrentPreviewPhrase());
      const phrase: IVocabularyPhrase | null = await PhraseEffects.saveFromSelectResult(selectResult, true);
      if (phrase) {
        PhraseDetailsEffects.saveForPhrase(phrase.id);
        dispatch(setPhrasesExistAction(true));
        const contextPhrase = findVideoPhraseByWordId(getState(), phrase.id);
        if (contextPhrase) {
          dispatch(CaptionsSelectionPopupActions.setCurrentSelection({wordPhraseId: phrase.id, contextPhraseId: contextPhrase.id}));
          dispatch(PhraseListActions.setPlayCaptionByPhraseId(contextPhrase.id));
        }
        dispatch(VocabularyEffects.flashPhrase(phrase.id));
        EventsRouter.trackEvent(Events.SAVE_PHRASE);
        StatLogManager.logPhraseSave();
      }
      PlayerController.getInstance().onDeselectText();
      dispatch(CaptionsSelectionPopupActions.updateCaptionsSelectionPopupAction({
        selectResult: null as any,
        show: false,
      }));

      if (!getGeneralStorage().getData().showTelegramBotPopup) {
        dispatch(setShowTelegramBotPopupAction(true));
        getGeneralStorage().setShowTelegramBotPopup(true);
      }

      dispatch(setSnackbarPanelAction(true, 'Saved to <b>Saved phrases</b>'));
      const mobileTabSaved = document.querySelector(`.${CLASS_MOBILE_TABS_SAVED}`);
      if (mobileTabSaved) {
        mobileTabSaved.classList.add('flash');
        setTimeout(() => {
          mobileTabSaved.classList.remove('flash');
        }, 3000);
      }

    }
  }

  public static onCheckCanSelect(selectResult: IPhraseSelectResult | null | undefined): AppThunk {
    return (
      dispatch,
      getState
    ): boolean => {
      if (!selectResult) return false;

     /* const insidePhrasesSel = selectResult.insideWordPhrases && selectResult.insideWordPhrases.length;
      if (!insidePhrasesSel) {
        const videoId = getCurrentVideoId(getState());
        const phrase =
          PhraseEffects.findInterceptPhrase(videoId, [EVocabularyPhraseType.WORD_AND_CONTEXT_SELECTED],
            selectResult.startCaptionIndex, selectResult.startPosition, selectResult.endCaptionIndex, selectResult.endPosition);
        if (phrase) {
          return false;
        }
      }*/
      return true;
    }
  }

  public static onDismiss(unboundClick: boolean = false): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      SelectionUtil.removeWindowSelection();
      SelectionPopupEffects.dismissSelectText(unboundClick);
    }
  }

  public static onMouseEnter(): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      PlayerController.getInstance().mouseEnter();
    }
  }

  public static onMouseLeave(): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      PlayerController.getInstance().mouseLeave();
    }
  }

  public static onSpeechText(stopOnPlay: boolean): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      const state = getState();
      const selectResult = CaptionsSelectionPopupSelectors.getSelectResult(state);
      const targetLanguage = getSelectedTargetCaption(state) || getTargetLanguage(state);
      const lang = LangUtil.checkLangCode(targetLanguage?.code || '');
      if (selectResult?.text) {
        const text = TextSelectionPopupUtils.getSpeechTextFromSelect(selectResult);
        if (text) {
          textSpeaker.speak(text, lang, stopOnPlay);
        }
      }
    }
  }

  public static onPlay(): AppThunk { // play from selected phrase
    return (
      dispatch,
      getState
    ) => {
      const selectResult = CaptionsSelectionPopupSelectors.getSelectResult(getState());
      if (selectResult && selectResult.startTime) {
        PlayerController.getInstance().play(selectResult.startTime);
      }
    }
  }

  public static onUpdateActionButton(actionButton: ECaptionsSelectionPopupActionButton | null): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      dispatch(CaptionsSelectionPopupActions.setActionButton(actionButton));
    }
  }

  public static onReplay(): AppThunk { // play selected phrase and stop
    return (
      dispatch,
      getState
    ) => {
      const selectResult = CaptionsSelectionPopupSelectors.getSelectResult(getState());
      if (selectResult) {
        PlayerController.getInstance().playSelectText(selectResult);
      }
    }
  }

  public static onClose(): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      textSpeaker.stop();
    }
  }

  public static onStartPlay(): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      SelectionUtil.removeWindowSelection();
      SelectionPopupEffects.dismissSelectText();
    }
  }

  public static onCopyText(): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      const selectResult = CaptionsSelectionPopupSelectors.getSelectResult(getState());
      if (selectResult?.text) {
        await navigator.clipboard.writeText(selectResult?.text);
        dispatch(setSnackbarPanelAction(true, 'Text copied to clipboard'));
      }
    }
  }

  public static onOpenPrompts(): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      const mainViewModePrev = getMainViewMode(getState());
      dispatch(setMainViewMode(EMainViewMode.PHRASE_DETAILS));
      dispatch(PhraseDetailsActions.setActiveTab(PhraseDetailsEffects.getDefaultTab() as any));

      historyPush({
        state: { page: 'Prompts' },
        title: 'Prompts',
        onpopstate: () => dispatch(setMainViewMode(mainViewModePrev))
      });
    }
  }

  public static onSaveNote(): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      const mainViewModePrev = getMainViewMode(getState());
      dispatch(setMainViewMode(EMainViewMode.PHRASE_DETAILS));
      const noteTab = getPhraseDetailsTabByType(getState(), EPhraseDetailsTabType.NOTES);
      if (noteTab) {
        dispatch(PhraseDetailsActions.setActiveTab({
          ...noteTab,
          isNoteEdit: true,
        }));
        dispatch(PhraseDetailsActions.setNotePreviewMode(true));
      }

      historyPush({
        state: { page: 'Notes' },
        title: 'Notes',
        onpopstate: () => dispatch(setMainViewMode(mainViewModePrev))
      });
    }
  }

}
