import React from 'react';
import { theme } from '../../../../../../common/styles/theme';
import { Button, Stack, Tab, Tabs } from '@mui/material';
import { IVideoTutorialPopupProps } from './types';
import { Modal } from '../../../../../../common/components/Modal/Modal';
import { StarIcon } from '../../../../../../../icons/StarIcon';
import { EVideoTutorialType } from '../../../../../store/models/types';
import { CommentIcon } from '../../../../../../../icons/CommentIcon';
import { DeviceContext } from '../../../../../../common/contexts/DeviceContext';

export const VideoTutorialPopup: React.FC<IVideoTutorialPopupProps> = ({
  tutorialList,
  activeId,
  videoId,
  onLoad,
  onChangeActive,
  onClose,
  onClosed,
  onButtonClick,
  onClickFeedback,
  onPlay,
}) => {
  if (!activeId) return null;

  const {isMobile} = React.useContext(DeviceContext);

  const player = React.useRef(null);
  const playerStart = React.useRef({});
  const playerTime = React.useRef({});
  const currentActiveId = React.useRef(0);
  const lastActiveId = React.useRef(0);
  const [playerReady, setPlayerReady] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (activeId && player.current) {
      player.current.loadVideoById(videoId);
      player.current.stopVideo();
      currentActiveId.current = activeId;
    }

    return () => {
      if (activeId && player.current && player.current.getCurrentTime) {
        playerTime.current[activeId] = player.current.getCurrentTime().toFixed(2);
        lastActiveId.current = activeId;
      }
    }
  }, [activeId]);

  React.useEffect(() => {
    onLoad();

    requestAnimationFrame(()=>{

      let div = document.createElement('div');
      div.id = 'ytplayer';
      document.getElementById('ytplayer-container')?.append(div);
    
      function init() {
        player.current = new window.YT.Player('ytplayer', {
          videoId,
          events: {
            'onStateChange': (event: any) => {
              if (event.data == window.YT.PlayerState.PLAYING) {
                const id = currentActiveId.current || activeId;
                if (!playerStart.current[id]) {
                  playerStart.current[id] = true;
                  onPlay(id);
                }
              }
            },
            onReady: () => {
              setPlayerReady(true);
            }
          }
        });
      }
      if (window.YT && window.YT.Player) {
        init();
      } else if (document.getElementById('yt_iframe_api')) {
        let callback = window.onYouTubeIframeAPIReady;
        window.onYouTubeIframeAPIReady = () => {
          if (callback) callback();
          init();
        }
      } else {
        let tag = document.createElement('script');
        tag.src = 'https://www.youtube.com/iframe_api';
        tag.id = 'yt_iframe_api';
        let firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag?.parentNode?.insertBefore(tag, firstScriptTag);
        window.onYouTubeIframeAPIReady = init;
      }

    });

    return () => {
      onClosed(lastActiveId.current, playerTime.current);
    }
  }, []);

  const onTabChange = (event: React.SyntheticEvent, newValue: EVideoTutorialType) => {
    onChangeActive(newValue);
  }

  const handleClose = () => {
    if (playerReady) {
      onClose();
    }
  }

  const buttonStyle = {
      height: theme.spacing(6),
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    }
  };

  const titleButton = (
    <Button
      variant={'contained'}
      color={'primary'}
      startIcon={<CommentIcon
        sx={{
          width: `${theme.spacing(2.5)} !important`,
          height: `${theme.spacing(2.5)} !important`,
          color: theme.palette.grey[400],
        }}
      />}
      sx={{
        ml: theme.spacing(2),
        color: theme.palette.grey[700],
        background: theme.palette.grey[50],

        '&:hover': {
          background: theme.palette.grey[100],
        },
      }}
      onClick={onClickFeedback}
    >
      Send Feedback
    </Button>
  );

  return (
    <Modal
      className={'VideoTutorialPopup'}
      onClose={handleClose}
      open={true}
      sx={{
        '.MuiTabs-root': {
          ml: theme.spacing(-2),
          mr: theme.spacing(-2),
        },
      }}
      title={'Help'}
      titleButton={titleButton}
      isMobile={isMobile}
    >
      <Tabs
        variant="scrollable"
        scrollButtons="auto"
        value={activeId}
        onChange={onTabChange}
      >
      {
        tutorialList.map(video => {
          return (<Tab
            key={video.type}
            label={video.title}
            value={video.type}
            sx={{ fontWeight: 600 }} />)
        })
      }
      </Tabs>

      <Stack sx={{
        position: 'relative',
        alignItems: 'center',

        'iframe': {
            width: theme.spacing(80),
            maxWidth: '100%',
            maxHeight: '60vh',
            borderRadius: theme.spacing(0.5),
            border: 'none',
            aspectRatio: '16/9'
        }
    }} spacing={theme.spacing(3)}>
        
        <div id={'ytplayer-container'} />

        {!isMobile && activeId === EVideoTutorialType.PROMPTS &&
          <Button
            fullWidth
            variant={'contained'}
            sx={{
              height: theme.spacing(6),
            }}
            startIcon={<StarIcon sx={{ width: theme.spacing(2), height: theme.spacing(2) }}/>}
            onClick={onButtonClick}
          >
            Add a Favourite prompt
          </Button>
        }
      </Stack>
    </Modal>
  );
}
