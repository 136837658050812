import { getDispatch, getState } from '../store';
import { getMainViewMode } from '../store/general/selectors';
import { EMainViewMode } from '../store/general/types';
import { MainLayoutEffects } from './mainLayoutEffects';

export class BackButtonHandler {

  public static handle(e: Event) {
    const state = getState();
    const dispatch = getDispatch();

    if (getMainViewMode(state) === EMainViewMode.PHRASE_DETAILS_TABS_EDITOR) {
      dispatch(MainLayoutEffects.backToDashboard());
      BackButtonHandler.blockDefaultHandle(e);
    }
  }

  private static blockDefaultHandle(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();
  }
}