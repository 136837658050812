import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { VideoLibraryGroupItem } from '../components/VideoLibraryGroupItem';
import { IVideoLibraryGroupItemEvents, IVideoLibraryGroupItemFields } from '../components/types';
import { IVideoLibraryGroupItemHOCOwnProps } from './types';
import { VideoLibraryGroupItemEvents } from './events';
import { TUserGroup } from '../../../../../store/models/types';
import { getUserGroupById } from '../../../../../store/models/selectors';
import { VideoLibraryGroupItemHelpers } from './helpers';
import { getFlashVideoId } from '../../../../../store/general/selectors';

const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState,
    {isOpened, video, groupId}: IVideoLibraryGroupItemHOCOwnProps
  ): IVideoLibraryGroupItemFields | null => {
    const {author, thumb, title} = video.info;
    const group: TUserGroup | undefined | null = getUserGroupById(state, groupId);
    if (!group) return null;
    const langCodes = VideoLibraryGroupItemHelpers.getSelectedVideoLangsByGroup(state, group, video);
    const flashVideo = getFlashVideoId(state) === video.videoKey;
    return {
      isOpened,
      author,
      thumb,
      title,
      langCodes,
      flashVideo,
      showActionMenu: true
    }
  };
}

const mapDispatchToProps = (
  dispatch: any,
  {groupId, video}: IVideoLibraryGroupItemHOCOwnProps
): IVideoLibraryGroupItemEvents => ({
    onClick: history => dispatch(VideoLibraryGroupItemEvents.onClick(history, video.videoKey, groupId)),
    onDelete: history => dispatch(VideoLibraryGroupItemEvents.onDelete(history, video.videoKey, groupId)),
    onGetCopyLink: () => dispatch(VideoLibraryGroupItemEvents.onGetCopyLink(video.videoKey, groupId))
  }
);

export const VideoLibraryGroupItemHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(VideoLibraryGroupItem);

VideoLibraryGroupItemHOC.displayName = 'VideoLibraryGroupItemHOC';