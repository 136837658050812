import React from 'react';
import { InputAdornment, MenuItem, Select, Stack, TextField } from '@mui/material';
import { theme } from '../../../../../../common/styles/theme';
import { Paper } from '../../../../../../common/components/Paper/Paper';
import { IContentLibraryHeaderProps } from './types';
import { TopBarPanelHOC } from '../../../ToolbarPanel/Toolbar/hocs/TopBarPanelHOC';
import { BookIcon } from '../../../../../../../icons/BookIcon';
import { VideoIcon } from '../../../../../../../icons/VideoIcon';
import { AudioIcon } from '../../../../../../../icons/AudioIcon';
import { TextIcon } from '../../../../../../../icons/TextIcon';
import { Tabs } from '../../../../../../common/components/Tabs/Tabs';
import { Tab } from '../../../../../../common/components/Tab/Tab';
import { GridIcon } from '../../../../../../../icons/GridIcon';
import { ListIcon } from '../../../../../../../icons/ListIcon';
import { LangFlag } from '../../../LangFlag/LangFlag';
import { SearchIcon } from '../../../../../../../icons/SearchIcon';

export function ContentLibraryHeader({
}: IContentLibraryHeaderProps) {

	const className = 'content-library-header';

	return (
		<Paper
			className={className}
			sx={{
				flex: `0 0 ${theme.spacing(8)}`,
				width: 1,
				py: theme.spacing(1.5),
				px: theme.spacing(2),
			}}
		>
			<Stack
				direction={'row'}
				alignItems={'center'}
				gap={theme.spacing(2)}
				sx={{
					width: 1,
					height: 1,

					'.top-bar-panel': {
						width: 'auto',

						'&:before': {
							display: 'none',
						},
					},
				}}
			>
				<TopBarPanelHOC />

				<Stack
					className={`${className}__title`}
					direction={'row'}
					alignItems={'center'}
					gap={theme.spacing(1)}
					sx={{
						ml: theme.spacing(2),
						fontSize: theme.typography.h4,
						fontWeight: 600,
						whiteSpace: 'nowrap',
					}}
				>
					<BookIcon
						sx={{
							color: theme.palette.grey[400],
						}}
					/>
					Content Library
				</Stack>

				<Tabs
					className={`${className}__tabs`}
					sx={{
						px: theme.spacing(1),
						bgcolor: theme.palette.grey[50],
						overflow: 'visible',

						'.MuiTabs-flexContainer': {
							gap: theme.spacing(1),
						},

						'.MuiTab-root': {
							justifyContent: 'flex-start',
							minHeight: theme.spacing(4.5),
							padding: theme.spacing(0, 1),
							fontSize: theme.typography.subtitle2,

							'.MuiSvgIcon-root': {
								opacity: .6,
							},

							'&.Mui-selected': {
								color: theme.palette.grey[400],

								'.MuiSvgIcon-root': {
									color: theme.palette.primary.main,
								}
							}
						},
					}}
					value={0}
					onChange={(e, value) => {}}
				>
					<Tab
						icon={<VideoIcon />}
						iconPosition={'start'}
						label={'Videos'}
						disabled
					/>
					<Tab
						icon={<AudioIcon />}
						iconPosition={'start'}
						label={'Audiobooks'}
						disabled
					/>
					<Tab
						icon={<TextIcon />}
						iconPosition={'start'}
						label={'Text'}
						disabled
					/>
				</Tabs>

				<TextField
					sx={{
						flex: '1 1 100%',
						pointerEvents: 'none',

						'.MuiInputBase-root': {
							height: theme.spacing(6),
							bgcolor: theme.palette.grey[50],
						},

						'.MuiInputAdornment-root': {
							opacity: .3,
						},
					}}
					variant={'filled'}
					hiddenLabel
					InputProps={{
						startAdornment: (
							<InputAdornment position={'start'}>
								<SearchIcon />
							</InputAdornment>
						),
					}}
					placeholder={'Search...'}
				/>

				<Select
					sx={{
						minWidth: theme.spacing(18),
						height: theme.spacing(6),
						bgcolor: theme.palette.grey[50],
						pointerEvents: 'none',

						'& > .MuiSelect-select, & > .MuiSelect-icon': {
							opacity: .4,
						},
					}}
					value={0}
					variant={'filled'}
				>
					<MenuItem value={0}>
						<Stack
							direction='row'
							spacing={theme.spacing(1)}
							alignItems={'center'}
						>
							<LangFlag code={'es'} showCode={true}/>
							<LangFlag code={'en'} showCode={true}/>
						</Stack>
					</MenuItem>
				</Select>

				<Tabs
					sx={{
						p: theme.spacing(.5, .75),
						bgcolor: theme.palette.grey[50],
						overflow: 'visible',

						'.MuiTabs-flexContainer': {
							gap: theme.spacing(1),
						},

						'.MuiTab-root': {
							minWidth: theme.spacing(5),
							minHeight: theme.spacing(5),
							padding: theme.spacing(0, 1),

							'.MuiSvgIcon-root': {
								opacity: .6,
							},

							'&.Mui-selected': {
								color: theme.palette.grey[400],

								'.MuiSvgIcon-root': {
									color: theme.palette.primary.main,
								}
							}
						},
					}}
					value={0}
					onChange={(e, value) => {}}
				>
					<Tab
						icon={<GridIcon />}
						iconPosition={'start'}
						disabled
					/>
					<Tab
						icon={<ListIcon />}
						iconPosition={'start'}
						disabled
					/>
				</Tabs>

			</Stack>
		</Paper>
	);
};
