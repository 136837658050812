import { IState } from '../../../../../../store/types';
import { getVideoTutorialMode } from '../../../../../../store/general/selectors';
import { connect } from 'react-redux';
import { IVideoTutorialPopupContainerFields } from '../../components/VideoTutorialPopupContainer/types';
import { VideoTutorialPopupContainer } from '../../components/VideoTutorialPopupContainer/VideoTutorialPopupContainer';

const mapStateToProps = (
  dispatch: any,
) => {
  return (
    state: IState
  ): IVideoTutorialPopupContainerFields => {
    const activeId = getVideoTutorialMode(state);
    return {
      isShow: activeId !== undefined && activeId !== null,
    }
  };
}

export const VideoTutorialPopupContainerHOC = connect(
  mapStateToProps,
  null,
)(VideoTutorialPopupContainer);

VideoTutorialPopupContainerHOC.displayName = 'VideoTutorialPopupContainerHOC';