import { IState } from '../../../../store/types';
import { connect } from 'react-redux';
import { Video } from '../components/Video';
import { IVideoEvents, IVideoFields } from '../components/types';
import { getActiveGroupId } from '../../../../store/models/selectors';
import { VideoEvents } from './events';
import { getMainLayoutType } from "../../../../store/general/selectors";
import { PhraseDetailsSelectors } from '../../../../store/phrase-details/selectors';
import { CaptionsSelectionPopupSelectors } from '../../../../store/captions-selection-popup/selectors';

const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): IVideoFields => {
    const activeTab = PhraseDetailsSelectors.getActiveTab(state);
    const isTextSelected = CaptionsSelectionPopupSelectors.isShow(state);

    return {
      activeGroupId: getActiveGroupId(state) || 0,
      activeTabType: activeTab?.type || null,
      layoutType: getMainLayoutType(state),
      isTextSelected,
    }
  };
}

const mapDispatchToProps = (
  dispatch: any
): IVideoEvents => ({
    onInit: (videoId) => dispatch(VideoEvents.onInit(videoId)),
    onLoad: (videoId, groupId, phraseId, pause) => dispatch(VideoEvents.onLoad(videoId, groupId, phraseId, pause)),
    onUnload: (groupId) => dispatch(VideoEvents.onUnload(groupId)),
    onUpdateGroupId: groupId => dispatch(VideoEvents.onUpdateGroupId(groupId)),
    onUpdateVideoId: videoId => dispatch(VideoEvents.onUpdateVideoId(videoId)),
    onKeyDown: (event) => dispatch(VideoEvents.onKeyDown(event)),
    onKeyUp: (event) => dispatch(VideoEvents.onKeyUp(event)),
    onChangeTab: (value) => dispatch(VideoEvents.onChangeTab(value)),
    openNotes: () => dispatch(VideoEvents.openNotes()),
    onCloseTranslation: () => dispatch(VideoEvents.onCloseTranslation()),
    onClick: () => dispatch(VideoEvents.onClick()),
  }
);

export const VideoHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(Video);

VideoHOC.displayName = 'VideoHOC';
