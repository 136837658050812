import { connect } from 'react-redux';
import { AddNewVideoPopup } from '../components/AddNewVideoPopup';
import { IState } from '../../../../store/types';
import { isShowNewVideoPopup } from '../../../../store/general/selectors';
import { IAddNewVideoPopupEvents, IAddNewVideoPopupFields } from '../components/types';
import { AddNewVideoPopupEvents } from './events';

const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): IAddNewVideoPopupFields => {
    return {
      isOpen: isShowNewVideoPopup(state)
    }
  };
}

const mapDispatchToProps = (
  dispatch: any
): IAddNewVideoPopupEvents => ({
    onValidate: (url) => dispatch(AddNewVideoPopupEvents.onValidate(url)),
    onCreate: (url) => dispatch(AddNewVideoPopupEvents.onCreate(url)),
    onClose: () => dispatch(AddNewVideoPopupEvents.onClose()),
  }
);

export const AddNewVideoPopupHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddNewVideoPopup);

AddNewVideoPopupHOC.displayName = 'AddNewVideoPopupHOC';