import { IState } from '../types';
import { ELanguageStatus } from '../general/types';
import { getAuthUser, getLibraryGroupId } from '../general/selectors';
import {
  EDefaultGptPromptType,
  EPhraseDetailsTabType, EVideoTutorialType, TAudio, TLanguage,
  TPhraseDetailsTab,
  TUserGptPrompt,
  TUserGroup,
  TUserLanguage, TUserRole,
  TVideo, TVideoTutorial
} from './types';

export const getUserGroups = (state: IState) => state.models.groups || [];
export const getUserOwnGroups = (state: IState) => {
  const groups = getUserGroups(state);
  const user = getAuthUser(state);
  if (!user) return [];

  return groups.filter(group => group.ownerId === user.id);
};

export const getUserGroupsWithCheckedLangs = (state: IState): TUserGroup[] => {
  return getUserGroups(state).filter(({languages})  => {
    const nativeGroupLang = languages.find(lang => {
      return lang.status === ELanguageStatus.KNOW;
    }) as TUserLanguage;
    const targetGroupLang = languages.find(lang => {
      return lang.status === ELanguageStatus.TO_LEARN;
    }) as TUserLanguage;
    return Boolean(nativeGroupLang && targetGroupLang);
  });
}

export const getUserLanguages = (state: IState) => state.models.languages;

export const getTargetLanguage = (state: IState) => {
  return getUserLanguageByStatus(state, ELanguageStatus.TO_LEARN);
};
export const getNativeLanguage = (state: IState) => {
  return getUserLanguageByStatus(state, ELanguageStatus.KNOW);
};

export const getUserLanguageByStatus = (state: IState, status: ELanguageStatus) => {
  const languages = getUserLanguages(state);
  return languages.find((lang) => lang.status === status) || null;
};

export const getActiveGroupId = (state: IState) => {
  return state.models.activeGroupId;
};

export const getUserGroupById = (
  state: IState,
  groupId: number
) => {
  if (!groupId) return null;
  const groups = getUserGroups(state);
  if (!groups || !groups.length) return null;
  return groups.find(group => group.id === groupId);
};

export const getActiveUserGroup = (state: IState) => {
  const activeGroupId = getActiveGroupId(state);
  if (!activeGroupId) return null;
  return getUserGroupById(state, activeGroupId);
};

export const getActiveGroupTargetLanguage = (state: IState) => {
  const activeGroup = getActiveUserGroup(state);
  if (!activeGroup) return null;
  return activeGroup.languages.find(lang => lang.status === ELanguageStatus.TO_LEARN) || null;
};

export const getActiveGroupNativeLanguage = (state: IState) => {
  const activeGroup = getActiveUserGroup(state);
  if (!activeGroup) return null;
  return activeGroup.languages.find(lang => lang.status === ELanguageStatus.KNOW) || null;
};

export const getLibraryGroup = (state: IState) => {
  const libraryGroupId = getLibraryGroupId(state);
  if (!libraryGroupId) return null;
  return getUserGroupById(state, libraryGroupId);
};


export const getGroupTargetLanguage = (state: IState, group: TUserGroup): TUserLanguage | null => {
  return group.languages.find(lang => lang.status === ELanguageStatus.TO_LEARN) || null;
};

export const getGroupNativeLanguage = (state: IState, group: TUserGroup): TUserLanguage | null => {
  return group.languages.find(lang => lang.status === ELanguageStatus.KNOW) || null;
};



export const getUserGroupLangByStatus = (state: IState, userGroup: TUserGroup, status: ELanguageStatus) => {
  return userGroup.languages.find(lang => lang.status === status);
}

export const getUserGroupLangKnown = (state: IState, userGroup: TUserGroup) => {
  return getUserGroupLangByStatus(state, userGroup, ELanguageStatus.KNOW);
}

export const getUserGroupLangToLearn = (state: IState, userGroup: TUserGroup) => {
  return getUserGroupLangByStatus(state, userGroup, ELanguageStatus.TO_LEARN);
}

export const getUserGroupTitle = (state: IState, group: TUserGroup): string => {
  if (!group) {
    return '';
  }
  if (group.name) {
    return group.name;
  }
  const nativeLng = getUserGroupLangKnown(state, group);
  const targetLng = getUserGroupLangToLearn(state, group);
  if (nativeLng && targetLng) {
    return nativeLng.name + ' / ' + targetLng.name;
  }
  return '';
}

export const getUserGroupLangByGroupIdAndStatus = (state: IState, groupId: number, langStatus: ELanguageStatus): TUserLanguage | undefined => {
  const group = getUserGroupById(state, groupId);
  if (!group) return undefined;
  return group.languages.find(lang => lang.status === langStatus);
}

export const getUserGroupWithLangCode = (state: IState, langStatus: ELanguageStatus, langCode: string) => {
  return getUserGroups(state).find(group => {
    const lang = getUserGroupLangByStatus(state, group, langStatus);
    return (lang && lang.code === langCode);
  })
}

export const getVideoById = (state: IState, videoId: string): TVideo | undefined => {
  let result: TVideo | undefined;
  getUserGroups(state).forEach(g => {
    if (result) return;
    result = g.videos.find(v => v.videoKey === videoId);
  })
  return result;
}

export const getAudioById = (state: IState, id: number): TAudio | undefined => {
  let result: TAudio | undefined;
  getUserGroups(state).forEach(g => {
    if (result) return;
    result = g.audios.find(a => a.id === id);
  })
  return result;
}

export const getUserGptPrompts = (state: IState): TUserGptPrompt[] => {
  return state.models.userGptPrompts || [];
}

export const getUserGptPromptById = (state: IState, id: number): TUserGptPrompt | undefined => {
  return getUserGptPrompts(state).find(g => g.id === id)
}

export const getPhraseDetailsTabs = (state: IState): TPhraseDetailsTab[] => {
  return state.models.phraseDetailsTabs || [];
}

export const getFavoritePhraseDetailsTabs = (state: IState): TPhraseDetailsTab[] => {
  return (state.models.phraseDetailsTabs || []).filter(t => t.favorite);
}

export const getPhraseDetailsTabById = (state: IState, id: number): TPhraseDetailsTab | undefined => {
  return getPhraseDetailsTabs(state).find(t => t.id === id);
}

export const getPhraseDetailsTabByType = (state: IState, type: EPhraseDetailsTabType): TPhraseDetailsTab | undefined => {
  return getPhraseDetailsTabs(state).find(t => t.type === type);
}

export const getPhraseDetailsTabByTypeAndRelationId = (state: IState, type: EPhraseDetailsTabType, relationId: number): TPhraseDetailsTab | undefined => {
  return getPhraseDetailsTabs(state).find(t => t.type === type && t.relationId === relationId);
}

export const isValidUserGroupVideo = (state: IState, groupId: number, videoId: string): boolean => {
  const group = getUserGroupById(state, groupId);
  if (!group) return false;
  return (!!group.videos.find(v => v.videoKey === videoId));
}

export const getUserLangByGroups = (state: IState): TUserLanguage[] => {
  const langs: TUserLanguage[] = [];
  getUserOwnGroups(state).forEach(g => {
    const lang = g.languages.find(l => l.status === ELanguageStatus.TO_LEARN);
    if (lang && !langs.find(l => l.code === lang.code)) {
      langs.push(lang);
    }
  })
  return langs;
}

export const getUserGroupsByLangs = (state: IState, targetLangCode: string, nativeLangCode: string): TUserGroup[] => {
  return getUserOwnGroups(state).filter(g => {
    const targetLang = getGroupTargetLanguage(state, g);
    const nativeLang = getGroupNativeLanguage(state, g);
    return targetLang?.code && targetLang?.code === targetLangCode &&
      nativeLang?.code && nativeLang?.code === nativeLangCode;
  })
}

const getDefaultGptPrompts = (state: IState) => {
  return state.models.defaultGptPrompts;
}

export const getDefaultGptPrompt = (state: IState, type: EDefaultGptPromptType) => {
  return getDefaultGptPrompts(state).find(p => p.type === type)
}

export const getVideoTutorialList = (state: IState): TVideoTutorial[] => {
  return state.models.videoTutorials;
}

export const getVideoTutorialByType = (state: IState, type: EVideoTutorialType) => {
  return getVideoTutorialList(state).find(v => v.type === type);
}

export const getLanguages = (state: IState): TLanguage[] => {
  return state.models.langs;
}

export const findLanguageByCode = (state: IState, code: string): TLanguage | undefined => {
  return getLanguages(state).find(l => l.code === code);
}

export const getUserRoles = (state: IState): TUserRole[] => {
  return state.models.userRoles;
}

export const createGroupNameByLangs = (group: TUserGroup): string => {
  if (
    !group ||
    !group.languages
  ) return null;

  let target = group.languages.find(lang => lang.status === ELanguageStatus.TO_LEARN)?.name || null;
  let native = group.languages.find(lang => lang.status === ELanguageStatus.KNOW)?.name || null;

  let langs = [];
  if (target) langs.push(target);
  if (native) langs.push(native);

  return langs.join(' / ');
}
