import { AppThunk } from '../../../../store/types';
import { setAddVideoConfirmAction } from '../../../../store/general/actions';
import { YoutubeUrlUtil } from '../../../../../common/utils/youtube-url-util';
import { getAddVideoConfirm } from '../../../../store/general/selectors';
import { TAddVideoResult, addVideoEffect } from '../../../../effects/video_effects';
import { Navigator } from '../../../../effects/navigator';
import { ADD_VIDEO_CONFIRM_NEW_GROUP_NAME } from '../components/wordings';
import { getNativeLanguage, getTargetLanguage } from '../../../../store/models/selectors';
import { UserGroupEffects } from '../../../../effects/userGroupEffects';
import { IYoutubeVideoInfo } from '../../../../types/common';
import { LangUtil } from '../../../../../common/utils/lang-util';

export class AddVideoConfirmEvents {

	public static onChange(
		groupId: number,
	): AppThunk {
		return async (
			dispatch,
			getState
		) => {
			const state = getState();
			const {
				videoId,
				videoLangCodes,
				videoInfo
			} = getAddVideoConfirm(state) || {};
			if (!videoId) {
				dispatch(this.onClose());
				return Promise.reject();
			}

			if (!groupId) { // create group
				const userTargetLang = getTargetLanguage(state)?.code || '';
				const userNativeLang = getNativeLanguage(state)?.code || '';

				let targetLangCode = userTargetLang;
				let nativeLangCode = userNativeLang;
				const videoLangCode = this.getVideoPriorityTargetLang(videoLangCodes, userTargetLang, videoInfo);

				if (videoLangCode && videoLangCode !== nativeLangCode) {
					targetLangCode = videoLangCode;
				}

				groupId = await UserGroupEffects.createGroup(
					`${ADD_VIDEO_CONFIRM_NEW_GROUP_NAME} ${targetLangCode} / ${nativeLangCode}`,
					targetLangCode, nativeLangCode);

				if (groupId) {
					await UserGroupEffects.loadGroup(groupId);
				}
			}
			// @ts-ignore
			const result: TAddVideoResult = await dispatch(addVideoEffect(groupId, YoutubeUrlUtil.getUrlById(videoId), '', false));
			if (result && result.videoId) {
				Navigator.openVideo(groupId, videoId);
			}
			dispatch(this.onClose());
			return Promise.resolve();
		}
	}

	private static getVideoPriorityTargetLang(videoLangCodes: string[], userTargetLang: string, videoInfo?: IYoutubeVideoInfo): string {
		const audioCode = LangUtil.checkLangCode(videoInfo?.defaultAudioLanguage || '');
		if (audioCode && videoLangCodes.includes(audioCode)) {
			return audioCode;
		}
		if (videoLangCodes.includes(userTargetLang))
			return userTargetLang;
		return videoLangCodes.length ? videoLangCodes[0] : '';
	}

	public static onClose(): AppThunk {
		return (
			dispatch,
			getState
		) => {
			dispatch(setAddVideoConfirmAction({ show: false }));
		}
	}


}