import * as React from 'react';
import {Box} from "@mui/material";
import {theme} from "../../styles/theme";

export type THighLightedSpanProps = {
    className?: string;
    isStart: boolean;
    isEnd: boolean;
    style?: {};
    index?: number;
    phraseId? : number;
    word? : boolean;
    isSelect?: boolean;

    onMouseEnter?: ()=>void;
    onMouseLeave?: ()=>void;
}

export const HighLightedSpan: React.FC<THighLightedSpanProps> = ({
    className,
    children,
    isStart,
    isEnd,
    style,
    index,
    phraseId,
    word,
    isSelect,
    onMouseEnter,
    onMouseLeave,
}) => {
    return <Box
        className={`highlighted-span ${isSelect ? 'selected': ''} ${className} ${isEnd ? 'end': ''}`}
        sx={{
            padding: '4px 0',
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.subtitle2,
            fontWeight: 400,
            transition: `background .2s`,
            ...style,
        }}
        dangerouslySetInnerHTML={{ __html: ''+children }}
        component='span'
        data-word-index={index}
        data-phrase-id={phraseId}
        data-word={word ? '1' : '0'}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
    />
};
