import { AppThunk } from '../../../../../store/types';
import { PhraseDetailsActions } from '../../../../../store/phrase-details/actions';
import { PhraseDetailsTabEffects } from '../../../../../effects/phraseDetailsTabEffects';
import { getPhraseDetailsTabById } from '../../../../../store/models/selectors';

export class PromptsListItemEvents {

	public static onClick (
		id: number,
		onSelected?: () => void,
	): AppThunk {
		return async (
			dispatch,
			getState
		) => {
			const tab = getPhraseDetailsTabById(getState(), id);
			dispatch(PhraseDetailsActions.setActiveTab(tab as any));
			if (onSelected) onSelected();
		}
	}

	public static onClickFavorite(id: number): AppThunk {
		return async(
		  dispatch,
		  getState
		) => {
			PhraseDetailsTabEffects.toggleFavorite(id);  
		}
	}

}
