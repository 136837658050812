import React from "react";
import customGPTImgUrl from './imgs/customGPT.png';
import dictionaryImgUrl from './imgs/dictionary.png';
import telegramImgUrl from './imgs/telegram.png';
import savedPhrasesImgUrl from './imgs/savedPhrases.png';
import { TelegramFilledBlueIcon } from "../../../../../../icons/TelegramFilledBlueIcon";
import { theme } from "../../../../../common/styles/theme";

export const LOGIN_FEATURES = [
    {
        title: `Use the power of ChatGPT for pattern learning`,
        imgUrl: customGPTImgUrl
    },
    {
        title: 'Access the dictionary with 15+ languages',
        imgUrl: dictionaryImgUrl
    },
    {
        title: 'Save and repeat phrases with context',
        imgUrl: savedPhrasesImgUrl
    },
    {
        title: 'Telegram bot for daily practice',
        imgUrl: telegramImgUrl,
        icon: <TelegramFilledBlueIcon sx={{ height: theme.spacing(6), width: theme.spacing(6) }} />
    },
]
