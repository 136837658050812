import { AppThunk, IState } from '../store/types';
import { EventsRouter } from '../../common/events/eventsRouter';
import { Events } from '../../common/events/types';
import {
  changeAutoSyncAction, setAppSettingsAction,
  setAuthUserAction,
  setAutoPauseAction,
  setCopyGroupOnLoginAction,
  setHoverAutoPauseAction,
  setLibraryGroupIdAction,
  setOnboardingAction,
  setOneTimeTooltipAction, setPauseOnNoteAction, setSavePhraseModeAction,
  setRouteOnLoginAction,
  setSetAddVideoOnLoginAction,
  setShowNativeCaptionsAction,
  setVideoTutorialModeAction,
  syncUserLocalStorageAction, setAddVideoConfirmAction
} from '../store/general/actions';
import { updateUserLanguages } from './updateUserLanguages';
import { updateUserGroups } from './updateUserGroups';


import { EMainLayoutType, ESavePhraseMode, TAuthUser } from '../store/general/types';
import { EExtMessageType, ExtMessageSender } from '../../common/utils/ext-message-sender';
import { getAddVideoOnLogin, getCopyGroupOnLogin, getCopyVideoOnLogin, getRouteOnLogin } from '../store/general/selectors';
import { getDispatch, getState } from '../store';
import { addVideoEffect, TAddVideoResult } from './video_effects';
import { getGeneralStorage } from '../../common/utils/local-storage/local-storage-helpers';
import { getUserGroupById, getUserGroups, isValidUserGroupVideo } from '../store/models/selectors';
import { getUserStateStorage } from '../../common/utils/local-storage/user-state';
import { getOneTimeTooltipsStorage } from '../../common/utils/local-storage/onetime-tooltips';
import { NewFeaturesChecker } from './new-features-checker';
import { PhraseDetailsTabEffects } from './phraseDetailsTabEffects';
import { Navigator } from './navigator';
import { PlayerController } from './player/manager/playerController';
import { PhrasesExistEffects } from './phrasesExistEffects';
import { SaveVideoConfirmManager } from './saveVideoConfirmManager';
import { VideoTutorialEffects } from './videoTutorialEffects';
import { IAppSettings, IUserSettings } from '../../common/rest/user/userRest';
import { Pause } from '../types/pause-constants';
import { PhraseListActions } from '../store/phrase-list/actions';
import { CopyVideoEffects } from './copyVideoEffects';
import { CopyGroupEffects } from './copyGroupEffects';
import { CaptionsSelectionPopupActions } from '../store/captions-selection-popup/actions';
import { UserGroupEffects } from './userGroupEffects';
import { EVideoTutorialType } from '../store/models/types';

import { IVideoMetaInfo, VideoMetaInfo } from '../../common/utils/videoMetaInfo';
import { LangUtil } from '../../common/utils/lang-util';
import { historyPush } from '../../common/utils/historyHelper';

type TNavigateInfo = {
  groupId?: number,
  videoId?: string,
  phraseId?: number,
  url?: string,
}

let navigate: TNavigateInfo;

export const prepareAfterOpen = (user: TAuthUser, settings: IUserSettings, appSettings: IAppSettings): AppThunk => async (
  dispatch,
  getState
): Promise<any> => {
  const state = getState();
  dispatch(setAuthUserAction(user));
  if (user && user.googleId) {
    try {
      syncUserLocalStorage();
      await checkAddVideo(state);
      await Promise.all([
        dispatch(updateUserLanguages()),
        dispatch(updateUserGroups(true)),
        PhraseDetailsTabEffects.load(),
        PhrasesExistEffects.load(),
        VideoTutorialEffects.loadList()
      ]);

      try {
        await ExtMessageSender.send({type: EExtMessageType.SET_AUTH_USER, payload: {authUser: user}});
      } catch(e) {
        console.log('err', e);
      }

      await checkCopyVideo(state);
      await checkCopyGroup(state);
      await checkRoute(state);

      restoreUserState();
      restoreUserSettings(settings);
      restoreAppSettings(appSettings);

      syncOneTimeTooltips();
      sendEvents(user);

      new NewFeaturesChecker().run();
      //new ExtensionInstallChecker().run();
      PlayerController.init();

      if (navigate) {
        if (navigate.url) {
          Navigator.openUrl(navigate.url);
        } else {
          const {
            groupId = 0,
            videoId = '',
            phraseId,
          } = navigate;
          Navigator.openVideo(groupId, videoId, phraseId, true);
        }
        navigate = null as any;
      }

    } catch(e) {
      console.log('err', e);
    }
  }
}

const syncUserLocalStorage = () => {
  const dispatch = getDispatch();
  const data = getGeneralStorage().getData();
  if (data.showLeftPanel === undefined) {
    data.showLeftPanel = true;
  }
  if (data.mainLayoutType == undefined) {
    data.mainLayoutType = EMainLayoutType.COLUMNS_4;
  }
  dispatch(syncUserLocalStorageAction(data));
}

const restoreUserState = () => {
  const dispatch = getDispatch();
  const state = getState();
  const data = getUserStateStorage().getData();
  const groups = getUserGroups(state);
  if (data.libraryGroupId && getUserGroupById(state, data.libraryGroupId)) {
    dispatch(setLibraryGroupIdAction(data.libraryGroupId));
  } else {
    if (groups && groups.length) {
      dispatch(setLibraryGroupIdAction(groups[0].id));
    }
  }

  const groupId = data.groupId || 0;
  const videoId = data.videoId || '';
  if (isValidUserGroupVideo(getState(), groupId, videoId) && !navigate) {
    //Navigator.openVideo(groupId, videoId, data.phraseId, true);
    navigate = {
      groupId, videoId, phraseId: data.phraseId
    }
  }
}

const checkIntSetting = (value: number, defValue: number) => parseInt(''+value) || defValue;
const checkBoolSetting = (value: boolean, defValue: boolean) => value === undefined ? defValue : (''+value) === 'true';

const restoreUserSettings = (settings: IUserSettings) => {
  const dispatch = getDispatch();
  dispatch(setAutoPauseAction(checkIntSetting(settings.pause, Pause.NoPauseValue)));
  dispatch(setHoverAutoPauseAction(checkBoolSetting(settings.hoverAutoPause, false)));
  dispatch(changeAutoSyncAction(checkBoolSetting(settings.autoSync, true)));
  dispatch(setShowNativeCaptionsAction(checkBoolSetting(settings.showNativeCaptions, false)));
  dispatch(PhraseListActions.setPauseDelay(checkIntSetting(settings.phraseListPauseDelay, 0)));
  dispatch(PhraseListActions.setPlayOffset({
    start: checkIntSetting(settings.phraseListStartPlayOffset, 0),
    end: checkIntSetting(settings.phraseListEndPlayOffset, 0)
  }));
  dispatch(setOnboardingAction({ show: settings.showOnboarding }))
  dispatch(CaptionsSelectionPopupActions.setAutoSpeechText(checkBoolSetting(settings.autoSpeechSelectedText, true)));
  dispatch(setPauseOnNoteAction(checkBoolSetting(settings.pauseOnNote, false)));
  dispatch(setSavePhraseModeAction(checkIntSetting(settings.savePhraseMode, ESavePhraseMode.NOT_SET)));
}

const restoreAppSettings = (appSettings: IAppSettings) => {
  const dispatch = getDispatch();
  dispatch(setAppSettingsAction(appSettings));
}

const syncOneTimeTooltips = () => {
  const dispatch = getDispatch();
  const data = getOneTimeTooltipsStorage().getData();
  dispatch(setOneTimeTooltipAction({
    showNativeAvailableTooltip: data.showNativeAvailable,
    showNativeLanguageTooltip: data.showNativeLanguage,
    showPhrasesEditTooltip: data.showPhrasesEdit,
    showTutorialPopup: data.showTutorialPopup,
    showTutorialTooltip: data.showTutorial,
  }));

  if (data.showTutorialPopup) {
    dispatch(setVideoTutorialModeAction(EVideoTutorialType.OPEN));
    historyPush({
      state: { page: 'help' },
      title: 'Help',
      onpopstate: () => dispatch(setVideoTutorialModeAction(null as any))
    });
  }

}

const checkAddVideo = async (state: IState) => {
  const addVideo = getAddVideoOnLogin(state);
  if (addVideo && addVideo.videoUrl) {
    const dispatch = getDispatch();
    dispatch(setSetAddVideoOnLoginAction(0, ''));

   // const userStorage = getUserStateStorage();
   /* let groupId = addVideo.groupId;
    if (!groupId) {
      groupId = userStorage.getData().libraryGroupId || 0;
    }*/

    const url = addVideo.videoUrl; // YoutubeUrlUtil.getUrlById(addVideo.videoId);

    try {
      await dispatch(updateUserGroups(false));
      const metaInfo: IVideoMetaInfo = await VideoMetaInfo.getMetaInfoByVideoUrl(addVideo.videoUrl);
      const videoLangCodes = [...new Set(metaInfo.captions.map(c => LangUtil.checkLangCode(c.code)))];
      const targetGroupId = await UserGroupEffects.findCorrectGroupIdByVideo(addVideo.videoUrl, metaInfo, videoLangCodes);
      if (!targetGroupId) {
        return dispatch(setAddVideoConfirmAction({
          show: true,
          targetGroupId: 0,
          videoInfo: metaInfo.videoInfo,
          videoId: metaInfo.videoId,
          videoLangCodes,
        }));
      }


      const result: TAddVideoResult = await dispatch(addVideoEffect(targetGroupId, url, '', false));
      if (result) {
        const addedGroupId: number = result.userGroupId;
        if (addedGroupId && result.videoId) {
          const userStorage = getUserStateStorage();
          userStorage.setVideoInfo(result.videoId, addVideo.groupId, 0);
          userStorage.setLibraryGroupId(addedGroupId);

          SaveVideoConfirmManager.show(addedGroupId, result.videoId);
          // Navigator.openVideo(addedGroupId, addVideo.videoId, 0, true);
          navigate = {
            groupId: addedGroupId, videoId: result.videoId, phraseId: 0
          }
          EventsRouter.trackEvent(Events.ADD_VIDEO);
        }
      }
    } catch(e) {
      console.log('error', e)
      SaveVideoConfirmManager.showError(e);
    }

  }
}

const checkCopyVideo = async (state: IState) => {
  const copyData = getCopyVideoOnLogin(state);
  if (copyData && copyData.hash) {
    await CopyVideoEffects.startCopy(copyData.hash, 0);
    navigate = {
      groupId: 0,
      videoId: '',
    }
  }
}

const checkCopyGroup = async (state: IState) => {
  const copyData = getCopyGroupOnLogin(state);
  if (copyData && copyData.hash) {
    getDispatch()(setCopyGroupOnLoginAction(''));
    const result = await CopyGroupEffects.copy(copyData.hash);
    if (result.groupId) {
      await UserGroupEffects.loadGroup(result.groupId);
      const userStorage = getUserStateStorage();
      userStorage.setLibraryGroupId(result.groupId);
      const group = getUserGroupById(getState(), result.groupId);
      getDispatch()(setLibraryGroupIdAction(result.groupId));
      if (group && group.videos && group.videos.length) {
        userStorage.setVideoInfo(group.videos[0].videoKey, result.groupId, 0);
        navigate = {
          groupId: result.groupId, videoId: group.videos[0].videoKey
        }
      }
    }
  }
}

const checkRoute = async (state: IState) => {
  const routeOnLogin = getRouteOnLogin(state);
  if (routeOnLogin && routeOnLogin.route) {
    getDispatch()(setRouteOnLoginAction({route: ''}));
    navigate = {
      url: routeOnLogin.route,
    };
  }
}

const sendEvents = async(user: TAuthUser) => {
    EventsRouter.setUser(user);
    EventsRouter.trackEvent(Events.USER_LOGGED_IN);
    EventsRouter.trackEvent(Events.OPEN_SESSION, new Date(), {
     userId: user.hashId
    });
}
