import { AppThunk } from '../../../../../../store/types';
import {
  setFeedbackModalOpenAction,
  setOneTimeTooltipAction,
  setVideoTutorialModeAction
} from '../../../../../../store/general/actions';
import { PlayerManager } from '../../../../../../effects/player/manager/playerManager';
import { EVideoTutorialType } from '../../../../../../store/models/types';
import { getOneTimeTooltipsStorage } from '../../../../../../../common/utils/local-storage/onetime-tooltips';
import { EventsRouter } from '../../../../../../../common/events/eventsRouter';
import { Events } from '../../../../../../../common/events/types';
import { TVideoTutorialTimes } from '../../types';
import { VideoMetaInfo } from '../../../../../../../common/utils/videoMetaInfo';
import { historyPush } from '../../../../../../../common/utils/historyHelper';


export class VideoTutorialPopupEvents {

  public static onLoad(): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      await PlayerManager.getInstance().pausePlay();
    }
  }

  public static onChangeActive(id: EVideoTutorialType): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      dispatch(setVideoTutorialModeAction(id));
      if (id > 0) {
        historyPush({
          state: { page: 'help' },
          title: 'Help',
          onpopstate: () => dispatch(setVideoTutorialModeAction(EVideoTutorialType.OPEN))
        });
      }
    }
  }

  public static onClose(): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      dispatch(setVideoTutorialModeAction(null as any));
      
      getOneTimeTooltipsStorage().setShow({ showTutorialPopup: false });
      dispatch(setOneTimeTooltipAction({ showTutorialPopup: false }));
    }
  }

  public static onClosed(
    activeId: EVideoTutorialType,
    times: TVideoTutorialTimes,
  ): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      let event = {
        ...Events.TUTORIAL_VIDEO_CLOSED,
        action: Events.TUTORIAL_VIDEO_CLOSED.action.replace('{0}', String(activeId))
      };
      let options = {};
      for (let i in times) {
        options['video' + i] = times[i];
      }
      EventsRouter.trackEvent(event, undefined, options);
    }
  }

  public static onPlay(
    activeId: EVideoTutorialType,
  ): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      let event = {
        ...Events.TUTORIAL_VIDEO_PLAYED,
        action: Events.TUTORIAL_VIDEO_PLAYED.action.replace('{0}', String(activeId))
      };
      EventsRouter.trackEvent(event);
    }
  }

  public static onClickFeedback (): AppThunk {
    return (
      dispatch,
    ) => {
      dispatch(setFeedbackModalOpenAction(true));
      historyPush({
        state: { page: 'feedback' },
        title: 'Feedback',
        onpopstate: () => dispatch(setFeedbackModalOpenAction(false))
      });
    }
  }

  public static loadVideoInfo(
    videoId: string,
  ): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      return VideoMetaInfo.getVideoInfoFull(videoId);
    }
  }

}
