import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { IPhraseTextFields } from '../components/types';
import { PhraseText } from '../components/PhraseText';
import { PhraseDetailsSelectors } from '../../../../../store/phrase-details/selectors';
import { getPlayerState } from '../../../../../store/general/selectors';
import { PlayerStates } from '../../../../../types/common';
import { findVideoPhraseById, getVideoPhraseNoteByPhraseId } from '../../../../../store/videos/selectors';
import { EPlayerControllerMode, PlayerController } from '../../../../../effects/player/manager/playerController';
import { PhraseListSelectors } from '../../../../../store/phrase-list/selectors';

const mapStateToProps = (
	dispatch: any
) => {
	return (
		state: IState
	): IPhraseTextFields => {
		let text = '';
		const playMode = PlayerController.getInstance().getMode();
		if (playMode === EPlayerControllerMode.PLAY_PHRASE_LIST || playMode === EPlayerControllerMode.PLAY_PHRASE) {
			const phraseId = PhraseListSelectors.getCurrentPhraseId(state);
			const phrase = findVideoPhraseById(state, phraseId);
			text = phrase?.highlighted || '';
		} else {
			if (getPlayerState(state) === PlayerStates.PLAYING) {
				const phraseId = PhraseDetailsSelectors.getNotePhraseId(state) || 0;
				const phrase = findVideoPhraseById(state, phraseId);
				text = phrase?.highlighted || PhraseDetailsSelectors.getSelectText(state) || '';
			} else {
				text = PhraseDetailsSelectors.getSelectText(state) || '';
			}
		}

		const fromLangCode = PhraseDetailsSelectors.getFromLang(state)?.code || '';
		return {
			fromLangCode,
			text,
		}
	};
}

export const PhraseTextHOC = connect(
	mapStateToProps,
	null
)(PhraseText);

PhraseTextHOC.displayName = 'PhraseTextHOC';
