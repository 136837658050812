import React from 'react';
import { Box, Button, Portal, Stack, alpha } from '@mui/material';
import { IOnboardingHintProps } from './types';
import { theme } from '../../../../../../common/styles/theme';
import { HintTooltip } from '../../../../../../common/components/HintTooltip/HintTooltip';
import { ArrowLightLeftIcon } from '../../../../../../../icons/ArrowLightLeftIcon';
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
	.onboarding-active .onboarding-hide {
		display: none !important;
	}
	.onboarding-active .onboarding-blur {
		pointer-events: none;
		& > * {
			opacity: .3;
			filter: blur(6px);
			transition: all 1s ease;
		}
	}
	.onboarding-active .onboarding-noevent {
		pointer-events: none;
	}
	.onboarding-highlight {
		border: 3px solid ${theme.palette.primary[400]};
		animation: animation_onboarding_highlight 1s 3 ease-in-out forwards;
	}
	@keyframes animation_onboarding_highlight {
		0%, 100% { transform: scale(1); border-color: ${theme.palette.primary[400]}; };
		50% { transform: scale(.99); border-color: transparent; };
	}

	.onboarding-active:not(.onboarding-active--step7) {
		.textSelectionPopupRoot {
			opacity: 0;
			pointer-events: none;
		}
	}

	.onboarding-hint--phrase-context-editor-wrong-selection {
		min-width: 270px;
		width: 270px;

		.highlighted-span {
			display: inline-block;
			background: #dcb8ff;
		}
	}
`

export function OnboardingHint ({
	show,
	step,
	settings,
	onShow,
	onHide,
	onBack,
	onNext,
	onSkip,
}: IOnboardingHintProps) {
	if (!show || settings === undefined || step === undefined) return null;

	const className = 'onboarding-hint';
	const classHighlight = 'onboarding-highlight';

	const [canShow, setCanShow] = React.useState(false);

	const {
		id,
		canSkip,
		highlightSelector,
		tooltipProps,
		targetSelector,
		text,
		title,
		onShow: onShowHint,
		onHide: onHideHint,
		onBack: onBackHint,
		onNext: onNextHint,
	} = settings[step];

	const steps = settings.length;
	const showBack = step > 0;
	const showNext = canSkip;
	const showFinish = canSkip && step === steps - 1;

	const onBackHandler = () => {
		if (onBackHint) onBackHint();
		if (onBack) onBack();
	}

	const onNextHandler = () => {
		if (onNextHint) onNextHint();
		if (onNext) onNext();
	}

	const onSkipHandler = () => {
		hide();
		onSkip();
	}

	const hide = () => {
		if (onHideHint) onHideHint();
		if (highlightSelector) {
			const highlight = document.querySelector(highlightSelector) as HTMLElement;
			if (highlight) highlight.classList.remove(classHighlight);
		}
	}

	React.useEffect(() => {
		setCanShow(false);
		let target;
		let wait = 0;
		const interval = setInterval(() => { // wait for target
			target = targetSelector && document.querySelector(targetSelector) as HTMLElement;
			if (target) {
				setCanShow(true);
				clearInterval(interval);

				if (onShowHint) onShowHint();
				if (highlightSelector) {
					const highlight = document.querySelector(highlightSelector) as HTMLElement;
					if (highlight) highlight.classList.add(classHighlight);
				}
			}
			wait++;
			if (wait > 10) { // no target, exit onboarding
				onHide();
			}
		}, 500);
		
		return () => {
			clearInterval(interval);
			hide();
		};
	}, [id]);

	React.useEffect(() => {
		onShow();
		
		return () => {
			hide();
			onHide();
		};
	}, []);

	return <>
		{canShow &&
			<HintTooltip
				className={`${className} ${className}--${id}`}
				arrow
				open
				title={
					<>
						{(!!title || steps > 1) &&
							<Stack
								direction={'row'}
								gap={theme.spacing(1)}
								justifyContent={'space-between'}
							>
								{!!title &&
									<Box
										sx={{
											mb: theme.spacing(1),
											fontWeight: 600,
										}}
										dangerouslySetInnerHTML={{ __html: title as string }}
									/>
								}
								{steps > 1 &&
									<Box
										sx={{
											mt: '2px',
											fontSize: '12px',
											color: theme.palette.grey[400],
											whiteSpace: 'nowrap',
										}}
									>
										Step {(step + 1)} of {steps}
									</Box>
								}
							</Stack>
						}
						<Box
							dangerouslySetInnerHTML={{ __html: text as string }}
						/>
						{(showBack || showNext || steps > 1) &&
							<Stack
								direction={'row'}
								gap={theme.spacing(1)}
								justifyContent={'space-between'}
								sx={{
									mt: theme.spacing(1.5),
								}}
							>
								{showBack &&
									<Button
										size={'small'}
										sx={{
											flex: `1 1 33%`,
											justifyContent: 'flex-start',
											fontSize: '12px',
										}}
										onClick={onBackHandler}
									>
										<ArrowLightLeftIcon sx={{
											width: theme.spacing(1.75),
											mr: theme.spacing(.5),
										}}/>
										Go back
									</Button>
								}
								{steps > 1 &&
									<Button
										size={'small'}
										sx={{
											flex: `1 1 33%`,
											p: theme.spacing(1),
											fontSize: '12px',
											whiteSpace: 'nowrap',
											backgroundColor: alpha(theme.palette.common.white, .7),

											'&:hover, &:focus': {
												backgroundColor: theme.palette.common.white,
											}
										}}
										onClick={onSkipHandler}
									>
										Skip tutorial
									</Button>
								}
								{showNext
									? <Button
										size={'small'}
										sx={{
											flex: `1 1 33%`,
											justifyContent: 'flex-end',
											fontSize: '12px',
										}}
										onClick={onNextHandler}
									>
										{showFinish
											? <>Finish</>
											: <>
												Next 
												<ArrowLightLeftIcon sx={{
													width: theme.spacing(1.75),
													ml: theme.spacing(.5),
													transform: 'rotate(180deg)',
												}}/>
											</>
										}
									</Button>
									: <Box sx={{
										flex: `1 1 33%`,
									}} />
								}
							</Stack>
						}
					</>
				}
				{...tooltipProps}
			>
				<Portal
					container={() => document.querySelector(targetSelector)}
					sx={{
						position: 'absolute',
						left: 0,
						right: 0,
						top: 0,
						bottom: 0,
					}}
				></Portal>
			</HintTooltip>
		}
		<GlobalStyle />
	</>;
}
  