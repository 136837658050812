import { AppThunk } from '../../../../store/types';
import { setEditPlaylistModalIdAction } from '../../../../store/general/actions';
import { updateUserGroup } from '../../../../../common/rest/userGroup/updateUserGroup';
import { getEditPlaylistModalId } from '../../../../store/general/selectors';
import { UserGroupEffects } from '../../../../effects/userGroupEffects';

export class EditGroupEvents {

  public static onSave(targetLangCode: string, nativeLangCode: string, name: string): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      const groupId = getEditPlaylistModalId(getState());
      await UserGroupEffects.updateGroup(groupId, name, targetLangCode, nativeLangCode);
    }
  }

  public static onClose(): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      dispatch(setEditPlaylistModalIdAction(0));


    }
  }

}