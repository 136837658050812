import React from 'react';
import { theme } from '../../../../../common/styles/theme';
import { Box, Button, Stack } from '@mui/material';
import { Modal } from '../../../../../common/components/Modal/Modal';
import { IPhraseContextEditorProps, PHRASE_CONTEXT_EDITOR_CLASS_NAME } from './types';
import { ECaptionsItemType } from '../../CaptionsContainer/NativeCaptions/components/types';
import { TargetCaptionItem } from '../../CaptionsContainer/TargetCaptions/components/TargetCaptionItem';
import { Paper } from '../../../../../common/components/Paper/Paper';
import { SelectionUtil } from '../../../../../common/utils/selection-util';
import { OneTimeTooltipPhrasesEditHOC } from '../../OneTimeTooltip/hocs/OneTimeTooltipPhrasesEditHOC/OneTimeTooltipPhrasesEditHOC';
import { OnboardingHint } from '../../Onboarding/components/OnboardingHint/OnboardingHint';
import { PHRASE_CONTEXT_EDITOR_BUTTON, PHRASE_CONTEXT_EDITOR_TITLE, SAVED_PHRASE_CONTEXT_EDITOR_BUTTON, SAVED_PHRASE_CONTEXT_EDITOR_TITLE } from '../wordings';
const md5 = require('md5');
import { DeviceContext } from '../../../../../common/contexts/DeviceContext';


export const PhraseContextEditor: React.FC<IPhraseContextEditorProps> = ({
  show,
  activeCaptionIndex,
  startCaptionIndex,
  allowSave,
  captions,
  phrases,
  isSavedPhrase,
  onClose,
  onSelectText,
  onDeSelectText,
  onSave
}) => {
  if (!show) return null;

  const {isMobile} = React.useContext(DeviceContext);

  const captionsContainer = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    if (show) {
      setTimeout(() => {
        const containerElement = captionsContainer.current;
        if (containerElement) {
          let topOffset = 0;
          if (containerElement.children.length) {
            const el = containerElement.children[0] as HTMLElement;
            topOffset = el.offsetTop;
          }
          const captionElement = containerElement.children[activeCaptionIndex] as HTMLElement;
          if (captionElement) {
            const scrollTo = captionElement.offsetTop - topOffset - containerElement.offsetHeight / 2;
              containerElement.scrollTo({
              top: scrollTo
            })
          }
        }
      })
    }
  }, [show]);

  const defEventHandler = React.useCallback(() => {}, []);

  const [isSelection, setSelection] = React.useState(false);
  const [isWrongSelection, setWrongSelection] = React.useState(false);

  const handleMouseUp = (event: React.SyntheticEvent) => {
    const selection = SelectionUtil.getSelectedText();
    const highlighted = selection ? selection.toString().replace(/[\n\r]/gm, '') : '';
    if (selection && selection.anchorNode && selection.focusNode && highlighted.length > 0) {
      event.preventDefault();
      onSelectText(selection);
      setSelection(true);
    } else {
      onDeSelectText();
    }
  }

  const handleMouseDown = (event: React.SyntheticEvent) => {
    setSelection(false);
  }

  React.useEffect(() => {
    setWrongSelection(isSelection && !allowSave);
  }, [allowSave, isSelection]);

  const buttonTitle = isSavedPhrase
    ? SAVED_PHRASE_CONTEXT_EDITOR_BUTTON
    : PHRASE_CONTEXT_EDITOR_BUTTON;

  return (
    <Modal
      className={PHRASE_CONTEXT_EDITOR_CLASS_NAME}
      onClose={onClose}
      open={true}
      title={isSavedPhrase
        ? SAVED_PHRASE_CONTEXT_EDITOR_TITLE
        : PHRASE_CONTEXT_EDITOR_TITLE
      }
      sx={{
        '& > .MuiPaper-root': {
          display: 'flex',
          flexDirection: 'column',
          width: 1,
          maxWidth: '500px',
          maxHeight: '100vh',
          minHeight: '0',
          p: theme.spacing(2),

          '& > .MuiStack-root': {
            minHeight: '0',
          },
        },

        '.one-time-tooltip': {
          mb: theme.spacing(2),

          '.highlighted-span': {
            display: 'inline-block',
            height: theme.spacing(2.5),
            lineHeight: theme.spacing(2.5),
            backgroundColor: '#dcb8ff',
          },
        },
      }}
      isMobile={isMobile}
    >
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '0',
        }}
      >
        <OneTimeTooltipPhrasesEditHOC
          buttonTitle={buttonTitle}
        />
        <Stack
          gap={theme.spacing(2)}
          sx={{
            minHeight: '0',
          }}
        >

          <Box
            sx={{
              minHeight: 0,
              margin: '0',
              overflowY: 'auto',
            }}
            ref={captionsContainer}
            onMouseUp={handleMouseUp}
            onMouseDown={handleMouseDown}
          >
          {
            captions.map((caption, index) => {
              const phrasesHash = md5(JSON.stringify(phrases));
              return (
                <TargetCaptionItem
                  index={startCaptionIndex + index}
                  captionText={caption.text}
                  startTime={caption.startTime}
                  endTime={caption.endTime}
                  phrases={phrases}
                  phrasesHash={phrasesHash}
                  isActive={false}
                  type={ECaptionsItemType.TARGET}
                  canHide={false}
                  filterSelectContext={false}
                  disableSelectMode={false}
                  onItemClick={defEventHandler}
                  onMouseEnter={defEventHandler}
                  onMouseLeave={defEventHandler}
                  onMouseDown={defEventHandler}
                  onMouseUp={defEventHandler}
                  onPhraseHover={defEventHandler}
                  onItemTextSelect={defEventHandler}
                  key={`${caption.startTime}-${caption.endTime}`}
                />
              );
            })
          }
        </Box>

        <Button
          variant={'contained'}
          disabled={!allowSave}
          sx={{
            '&.Mui-disabled': {
              color: theme.palette.common.white,
              backgroundColor: theme.palette.primary.main,
              opacity: .3,
            },
          }}
          onClick={onSave}
        >
          {buttonTitle}
        </Button>

        {isWrongSelection &&
          <OnboardingHint
            show
            settings={[{
              id: 'phrase-context-editor-wrong-selection',
              tooltipProps: {
                placement: 'bottom',
              },
              targetSelector: `.phrase-context-editor .highlight-type_word.end`,
              text: 'Select the text around your <span class="highlighted-span">phrase</span> to&nbsp;modify it’s context',
            }]}
            step={0}
            onShow={defEventHandler}
            onBack={defEventHandler}
            onHide={defEventHandler}
            onNext={defEventHandler}
            onSkip={defEventHandler}
          />
        }

      </Stack>
      </Paper>
    </Modal>
  );

}
