import { AppThunk } from '../../../../../store/types';
import { ContentLibActions } from '../../../../../store/contentLib/actions';
import { setMainViewMode } from '../../../../../store/general/actions';
import { EMainViewMode } from '../../../../../store/general/types';
import { ContentLibSelectors } from '../../../../../store/contentLib/selectors';
import { Navigator } from '../../../../../effects/navigator';
import { getUserGroupById } from '../../../../../store/models/selectors';

export class ContentLibCopyGroupConfirmEvents {

  public static onClose(): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      dispatch(ContentLibActions.setCopyGroupConfirm({show: false}));
    }
  }

  public static onOpenGroup(): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      dispatch(setMainViewMode(EMainViewMode.DASHBOARD));
      const {groupId = 0} = ContentLibSelectors.getCopyGroupConfirm(getState());
      const group = getUserGroupById(getState(), groupId);
      if (group && group.videos && group.videos.length) {
        Navigator.openVideo(groupId, group.videos[0].videoKey);
      }
      dispatch(this.onClose());
    }
  }
}