import React from 'react';
import { ILoginFeatureProps } from './types';
import { Stack, Typography } from '@mui/material';
import { theme } from "../../../../../common/styles/theme";

export const LoginFeature: React.FC<ILoginFeatureProps> = ({
    title,
    imgUrl,
    icon
}) => {
    const className = 'login-feature';

    return (
        <Stack
            className={className}
            gap={theme.spacing(3)}
            alignItems={'center'}
            sx={{
                position: 'relative',
            }}
        >
            <Stack
                className={`${className}__img`}
                sx={{
                    position: 'relative',
                    width: 'fit-content',
                    height: theme.spacing(22),
                }}
            >
                <Stack
                    component={'img'}
                    src={imgUrl}
                    sx={{
                        height: 1,
                        width: 'fit-content',
                        objectFit: 'contain',
                    }}
                />
                {icon &&
                    <Stack
                        sx={{
                            position: 'absolute',
                            right: -24,
                            top: -16,
                        }}
                    >
                        {icon}
                    </Stack>
                }
            </Stack>

            <Typography
                variant={'h6'}
                lineHeight="1.3"
                fontWeight={600}
                textAlign={'center'}
                sx={{
                    pl: theme.spacing(1.5)
                }}
            >
                {title}
            </Typography>
        </Stack>
    );
}
