import { IState } from '../../../../../../store/types';
import { IVideoTutorialPopupEvents, IVideoTutorialPopupFields} from '../../components/types';
import { getVideoTutorialMode } from '../../../../../../store/general/selectors';
import { connect } from 'react-redux';
import { VideoTutorialPopup } from '../../components/VideoTutorialPopup';
import { VideoTutorialPopupEvents } from './events';
import { getVideoTutorialByType, getVideoTutorialList } from '../../../../../../store/models/selectors';


const mapStateToProps = (
  dispatch: any,
) => {
  return (
    state: IState
  ): IVideoTutorialPopupFields => {
    const activeId = getVideoTutorialMode(state);
    const videoTutorial = getVideoTutorialByType(state, activeId);
    return {
      tutorialList: getVideoTutorialList(state),
      activeId,
      videoId: videoTutorial ? videoTutorial.videoId : '',
    }
  };
}

const mapDispatchToProps = (
  dispatch: any
): IVideoTutorialPopupEvents => ({
    onLoad: () => dispatch(VideoTutorialPopupEvents.onLoad()),
    onChangeActive: id => dispatch(VideoTutorialPopupEvents.onChangeActive(id)),
    onClose: () => dispatch(VideoTutorialPopupEvents.onClose()),
    onClosed: (activeId, times) => dispatch(VideoTutorialPopupEvents.onClosed(activeId, times)),
    onClickFeedback: () => dispatch(VideoTutorialPopupEvents.onClickFeedback()),
    onPlay: (activeId) => dispatch(VideoTutorialPopupEvents.onPlay(activeId)),
    loadVideoInfo: (videoId) => dispatch(VideoTutorialPopupEvents.loadVideoInfo(videoId)),
  }
);

export const VideoTutorialPopupHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(VideoTutorialPopup);

VideoTutorialPopupHOC.displayName = 'VideoTutorialPopupHOC';