import { IState } from '../../../../../store/types';
import { ContentLibSelectors } from '../../../../../store/contentLib/selectors';
import { connect } from 'react-redux';
import { ContentLibCopyVideoConfirm } from '../components/ContentLibCopyVideoConfirm';
import { IContentLibCopyVideoConfirmEvents, IContentLibCopyVideoConfirmFields } from '../components/types';
import { ContentLibCopyVideoConfirmEvents } from './events';


const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): IContentLibCopyVideoConfirmFields => {
    const {show} = ContentLibSelectors.getCopyVideoConfirm(state);
    return {
      show
    }
  };
}

const mapDispatchToProps = (
  dispatch: any
): IContentLibCopyVideoConfirmEvents => ({
    onClose: () => dispatch(ContentLibCopyVideoConfirmEvents.onClose()),
    onOpenVideo: () => dispatch(ContentLibCopyVideoConfirmEvents.onOpenVideo())
  }
);

export const ContentLibCopyVideoConfirmHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(ContentLibCopyVideoConfirm);

ContentLibCopyVideoConfirmHOC.displayName = 'ContentLibCopyVideoConfirmHOC';
