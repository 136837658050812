import React from 'react';
import { ISavedVideoConfirmNotifyProps } from './types';
import { Button, Stack, alpha } from '@mui/material';
import { theme } from '../../../../../../common/styles/theme';
import { Text } from '../../../../../../common/components/Text/Text';
import { getShortTitle } from "../../helpers/getShortTitle";


export const SavedVideoConfirmNotify: React.FC<ISavedVideoConfirmNotifyProps> = ({
  videoTitle,
  groupTitle,
  showSelectGroup,
  isWrongLang,
  videoTargetLang,
  groupTargetLang,
  onSelectGroup
}) => {
  return (
    <Stack spacing={theme.spacing(2)}>
      <Text
          sx={{ color: theme.palette.common.white, pr: theme.spacing(3) }}
          variant={'subtitle2'}
      >
          Video “<b>{getShortTitle(videoTitle)}</b>” was saved to <b>{getShortTitle(groupTitle)}</b>

          {isWrongLang && <Text
            sx={{
              mt: 1,
              p: 1,
              color: theme.palette.common.white,
              background: alpha(theme.palette.common.white, .1),
              borderRadius: theme.spacing(1),
            }}
          >
            Whoops... the main language of the video you’ve added ({videoTargetLang}) does not coincide with the target language set for a playlist: {groupTargetLang}.
          </Text>}
      </Text>
      {showSelectGroup &&
      <Button
          onClick={onSelectGroup}
          fullWidth
          variant={'contained'}
          color={'secondary'}
      >
        Modify
      </Button>
      }
    </Stack>
  );
}
