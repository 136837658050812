import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { ContentLibVideo } from '../components/ContentLibVideo';
import { IContentLibVideoEvents, IContentLibVideoFields } from '../components/type';
import { IContentLibVideoHOCOwnProps } from './types';
import { ContentLibSelectors } from '../../../../../store/contentLib/selectors';
import { ContentLibVideoEvents } from './events';

const mapStateToProps = (
  dispatch: any,
  {groupId, videoId}: IContentLibVideoHOCOwnProps
) => {
  return (
    state: IState
  ): IContentLibVideoFields => {
    const video = ContentLibSelectors.findVideo(state, groupId, videoId);
    return {
      title: video?.title || '',
      thumbUrl: video?.youtubeThumbMediumUrl || video?.youtubeThumbUrl || '',
      channel: video?.youtubeChannel || '',
    }
  };
}

const mapDispatchToProps = (
  dispatch: any,
  {groupId, videoId}: IContentLibVideoHOCOwnProps
): IContentLibVideoEvents => ({
    onClick: () => dispatch(ContentLibVideoEvents.onClick(groupId, videoId))
  }
);

export const ContentLibVideoHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(ContentLibVideo);

ContentLibVideoHOC.displayName = 'ContentLibVideoHOC';