import { AppThunk } from '../../../../store/types';
import { createVideoEffect, TAddVideoResult } from '../../../../effects/video_effects';
import { setSetAddVideoOnLoginAction } from '../../../../store/general/actions';
import { isAuthorised } from '../../../../store/general/selectors';
import { getUserStateStorage } from '../../../../../common/utils/local-storage/user-state';
import { Navigator } from '../../../../effects/navigator';
import { YoutubeUrlUtil } from '../../../../../common/utils/youtube-url-util';

export class AddVideoEvents {

  public static onLoad(groupId: number, videoId: string): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      const state = getState();
      const url = YoutubeUrlUtil.getUrlById(videoId);
      if (isAuthorised(state)) {
        if (!groupId) {
          const userStorageData = getUserStateStorage().getData();
          if (userStorageData.libraryGroupId) {
            groupId = userStorageData.libraryGroupId;
          }
        }
        const result: TAddVideoResult = await dispatch(createVideoEffect(groupId, url));
        const addedGroupId: number = result.userGroupId;
        const userStorage = getUserStateStorage();
        userStorage.setVideoInfo(videoId, groupId, 0);
        userStorage.setLibraryGroupId(groupId);
        if (addedGroupId) {
          Navigator.openVideo(addedGroupId, videoId);
        }
      } else {
        dispatch(setSetAddVideoOnLoginAction(groupId, url));
        Navigator.openDefault();
      }
    }
  }

}