import { AppThunk } from '../../../../../store/types';
import { AuthManager } from '../../../../../../common/utils/auth-manager';
import { prepareAfterOpen } from '../../../../../effects/prepareAfterOpen';
import { LoginUserEffects } from '../../../../../effects/loginUserEffects';
import { Navigator } from '../../../../../effects/navigator';
import { LangUtil } from '../../../../../../common/utils/lang-util';
import { getUserGroups } from '../../../../../store/models/selectors';
import { EventsRouter } from '../../../../../../common/events/eventsRouter';
import { Events } from '../../../../../../common/events/types';
import { getCopyGroupOnLogin } from '../../../../../store/general/selectors';
import { UserGroupCopyRest } from '../../../../../../common/rest/userGroupCopy/userGroupCopyRest';
import { setAuthUserAction } from '../../../../../store/general/actions';

export class DefaultLoginEvents {

  public static onDidMount(): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      EventsRouter.trackEvent(Events.SIGN_IN_LOADED);
    }
  }

  public static onLogin(targetLangName: string | null, nativeLangName: string | null): AppThunk {
    return async(
      dispatch,
      getState
    ): Promise<boolean> => {
      EventsRouter.trackEvent(Events.SIGN_IN_CLICKED);
      try {
        const result = await AuthManager.login();
        if (!result || !result.authUser)
          return false;
        if (result.authUser.isNew) {
          EventsRouter.trackEvent(Events.AUTHORIZATION, new Date(), {
            userId: result.authUser.hashId
          });
          EventsRouter.trackEvent(Events.NEW_USER_SIGNED_UP, undefined, {
            email: result.authUser.email,
          });
          const copyGroupData = getCopyGroupOnLogin(getState());
          if (copyGroupData && copyGroupData.hash) {
            dispatch(setAuthUserAction(result.authUser));
            const copyGroupInfo = await UserGroupCopyRest.getCopyInfo(copyGroupData.hash);
            if (copyGroupInfo.targetLangCode && copyGroupInfo.nativeLangCode) {
              await LoginUserEffects.prepareLangsOnInstall(copyGroupInfo.nativeLangCode, copyGroupInfo.targetLangCode, false);
              dispatch(prepareAfterOpen(result.authUser, result.settings, result.appSettings));
              Navigator.openDefault();
              return;
            }
          }

          const targetLangCode = targetLangName && LangUtil.getLangCodeByName(targetLangName);
          const nativeLangCode = nativeLangName && LangUtil.getLangCodeByName(nativeLangName);

          await LoginUserEffects.prepareBeforeShowSelectLangs(result.authUser);
          if (targetLangCode && nativeLangCode && targetLangCode !== nativeLangCode) {
            await LoginUserEffects.prepareLangsOnInstall(nativeLangCode, targetLangCode, true);

            const groups = getUserGroups(getState());
            if (groups && groups.length && groups[0].videos.length) {
              const groupId = groups[0].id;
              const videoId = groups[0].videos[0].videoKey;
              Navigator.openVideo(groupId, videoId, undefined, true); // pause video because of tutorial
            } else {
              Navigator.openDefault();
            }
          } else {
            Navigator.openInstallSelectLangs();
          }
        } else {
          dispatch(prepareAfterOpen(result.authUser, result.settings, result.appSettings));
        }
        return true;
      } catch (e) {
        console.log('err', e);
        return false;
      }
    }
  }


}