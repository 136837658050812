import { AppThunk } from '../../../../store/types';
import {
  setCopyVideoConfirmAction,
  setCreatePlaylistModalOpenAction,
  setCreatePlaylistSaveModalOpenAction
} from '../../../../store/general/actions';
import { TLanguageCode } from '../../../../store/models/types';
import { addGroup } from '../../../../store/models/actions';
import { findLanguageByCode, getUserGroups } from '../../../../store/models/selectors';
import { EExtMessageType, ExtMessageSender } from '../../../../../common/utils/ext-message-sender';
import { UserGroupEffects } from '../../../../effects/userGroupEffects';
import { fetchUserGroup } from '../../../../../common/rest/userGroup/fetchUserGroup';
import { EventsRouter } from '../../../../../common/events/eventsRouter';
import { Events } from '../../../../../common/events/types';
import { getCopyVideoConfirm, getIsCreatePlaylistSaveModalOpen } from "../../../../store/general/selectors";
import { CopyVideoEffects } from "../../../../effects/copyVideoEffects";
import { updateUserGroups } from "../../../../effects/updateUserGroups";
import { Navigator } from "../../../../effects/navigator";


export class CreatePlaylistEvents {

  public static onCreate(nativeLangCode: TLanguageCode, targetLangCode: TLanguageCode, title: string, createDefVideos: boolean): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      EventsRouter.trackEvent(Events.CREATE_PLAYLIST_BUTTON_CLICKED);
      const createdGroupId: number = await UserGroupEffects.createGroup(title, targetLangCode, nativeLangCode, createDefVideos);
      const createdGroup = await dispatch(fetchUserGroup(createdGroupId));
      dispatch(addGroup(createdGroup));
      UserGroupEffects.setLibraryGroupId(createdGroup.id);

      ExtMessageSender.send({
        type: EExtMessageType.SET_USER_GROUPS,
        payload: {userGroups: getUserGroups(getState())}
      });

      if (getIsCreatePlaylistSaveModalOpen(getState())) {
        const {hash} = getCopyVideoConfirm(getState());
        const result = await CopyVideoEffects.createCopy(hash, createdGroup.id);
        dispatch(setCopyVideoConfirmAction({show: false}));
        await dispatch(updateUserGroups(true));
        Navigator.openVideo(createdGroup.id, result.videoId);
      }
    }
  }

  public static onClose(): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      dispatch(setCreatePlaylistModalOpenAction(false));
      dispatch(setCreatePlaylistSaveModalOpenAction(false));
      if (getIsCreatePlaylistSaveModalOpen(getState())) {
        const {videoId, targetGroupId} = getCopyVideoConfirm(getState());
        dispatch(setCopyVideoConfirmAction({show: false}));
        await dispatch(updateUserGroups(true));
        Navigator.openVideo(targetGroupId, videoId);
      }
    }
  }

  public static onCheckConfirmCreateDefVideos(targetLangCode: TLanguageCode): AppThunk {
    return (
      dispatch,
      getState
    ): boolean => {
      const lang = findLanguageByCode(getState(), targetLangCode);
      return lang && lang.installUserGroupExist;
    }
  }

}

