import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { IAccountButtonEvents, IAccountButtonFields } from '../components/types';
import { AccountButton } from '../components/AccountButton';
import { AccountButtonEvents } from './events';
import { getGoogleUser } from '../../../../../store/general/selectors';
import { EAuthUserRole } from '../../../../../store/general/types';
import { IAccountButtonHOCProps } from './types';

const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): IAccountButtonFields => {
    const user = getGoogleUser(state);
    return {
      userExist: !!user,
      isAdmin: !!user && user.role === EAuthUserRole.ADMIN,
      userName: user?.name || '',
      userPicture: user?.picture || ''
    }
  };
}

const mapDispatchToProps = (
  dispatch: any,
  {closeMenu}: IAccountButtonHOCProps,
): IAccountButtonEvents => ({
    closeMenu,
    onSignOut: () => dispatch(AccountButtonEvents.onSignOut()),
    onSwitchAccount: () => dispatch(AccountButtonEvents.onSwitchAccount()),
    onShowAdminPanel: () => dispatch(AccountButtonEvents.onShowAdminPanel()),
    onLoginAs: (email) => dispatch(AccountButtonEvents.onLoginAs(email)),
    onOpenPublicAccount: () => dispatch(AccountButtonEvents.onOpenPublicAccount()),
  }
);

export const AccountButtonHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountButton);

AccountButtonHOC.displayName = 'AccountButtonHOC';
