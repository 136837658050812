import React from 'react';
import { EContentLibViewMode } from '../../../../../store/contentLib/types';
import { ContentLibGroupDetailsHOC } from '../../ContentLibGroupDetails/hocs/ContentLibGroupDetailsHOC';
import { ContentLibGroupHOC } from '../../ContentLibGroup/hocs/ContentLibGroupHOC';
import { IContentLibGroupListProps } from './types';
import { Stack } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { theme } from '../../../../../../common/styles/theme';


export const ContentLibGroupList: React.FC<IContentLibGroupListProps> = ({
  viewMode,
  groups,
  loading,
  text,
  nativeLangCode,
  targetLangCode,
  onSearch
}) => {

  React.useEffect(() => {
    onSearch();
  }, [text, nativeLangCode, targetLangCode])

  const groupsNotFound = groups && groups.length === 0;

  if (groupsNotFound) {
    return <Stack
      sx={{
        width: 1,
        height: 1,
        p: 4,
        fontSize: theme.typography.h4,
      }}
      alignItems={'center'}
      justifyContent={'center'}
    >
      No results
    </Stack>
  }

  if (loading && !groups?.length && !text) {
    return  <Stack
      sx={{
        width: 1,
        height: 1,
        p: 4,
      }}
      alignItems={'center'}
      justifyContent={'center'}
    >
      <CircularProgress />
    </Stack>
  }

  return <>
    {groups?.map(group => {
      if (viewMode === EContentLibViewMode.GROUP_LIST_WITH_VIDEOS) {
        return <ContentLibGroupDetailsHOC
          key={group.id}
          groupId={group.id}
        />
      }
      return <ContentLibGroupHOC
        key={group.id}
        groupId={group.id}
      />
    })}
  </>
};
