import React from 'react';
import { IAddAnnotationButtonProps } from './types';
import { theme } from '../../../../../common/styles/theme';
import { PlusIcon } from '../../../../../../icons/PlusIcon';
import { ButtonIconGrey } from '../../../../../common/components/ButtonIconGrey/ButtonIconGrey';
import { Box, Tooltip } from '@mui/material';

export const AddAnnotationButton: React.FC<IAddAnnotationButtonProps> = ({
	isCollapsed,
	onClick,
}) => {
	
	return (
		<Box
			className={isCollapsed ? 'collapsed' : ''}
			sx={{
				mt: theme.spacing(-1.5),
				height: theme.spacing(4),
				py: theme.spacing(.5),
				transition: 'all ease .2s .1s',

				'.MuiButtonBase-root': {
					width: theme.spacing(3),
					height: theme.spacing(3),
					p: 0,
					transition: 'all ease .2s .1s',
					transformOrigin: '0 0',
				},

				'&.collapsed': {
					height: theme.spacing(2),

					'&:not(:hover) .MuiButtonBase-root': {
						opacity: .5,
						transform: 'scale(.4)',
						background: theme.palette.grey[50],
					},
					
					'&:hover': {
						height: theme.spacing(4),

						'.MuiButtonBase-root': {
							opacity: 1,
							transform: 'scale(1)',
						},
					},
				},
			}}
		>
			<Tooltip
				title={'Add exercise / annotation'}
			>
				<ButtonIconGrey
					sx={{
						width: theme.spacing(3),
						height: theme.spacing(3),
					}}
					onClick={onClick}
				>
					<PlusIcon />
				</ButtonIconGrey>
			</Tooltip>
		</Box>
	);
};
