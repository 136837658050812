import React from 'react';
import { SvgIcon, SvgIconProps } from "@mui/material";

export const SquarePinIcon = (props: SvgIconProps) => (
    <SvgIcon {...props} viewBox='0 0 32 32'>
        <rect width="32" height="32" rx="4" fill="#29BA98"/>
        <g clipPath="url(#clip0_1773_16120)">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M20 13V8H21C21.55 8 22 7.55 22 7C22 6.45 21.55 6 21 6H11C10.45 6 10 6.45 10 7C10 7.55 10.45 8 11 8H12V13C12 14.66 10.66 16 9 16V18H14.97V25L15.97 26L16.97 25V18H23V16C21.34 16 20 14.66 20 13Z" fill="#E1F4EF"/>
        </g>
        <defs>
            <clipPath id="clip0_1773_16120">
                <rect width="24" height="24" fill="white" transform="translate(4 4)"/>
            </clipPath>
        </defs>
    </SvgIcon>
);
