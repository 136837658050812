import { AppThunk } from '../../../../store/types';
import { ESavePhraseMode } from '../../../../store/general/types';
import { UserSettingEffects } from '../../../../effects/userSettingEffects';
import { PhraseEffects } from '../../../../effects/phrase/PhraseEffects';
import { CaptionsSelectionPopupActions } from '../../../../store/captions-selection-popup/actions';
import { CaptionsSelectionPopupSelectors } from '../../../../store/captions-selection-popup/selectors';
import { ESavePhraseModePopupType } from '../../../../store/captions-selection-popup/types';
import { PhraseNoteEffects } from '../../../../effects/phraseNoteEffects';
import { getState } from '../../../../store';

export class SavePhraseModeSelectPopupEvents {


  public static onSelect(useContext: boolean, defaultAction: boolean): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      if (defaultAction) {
        const mode = useContext ? ESavePhraseMode.SAVE_CONTEXT : ESavePhraseMode.NOT_SAVE_CONTEXT;
        UserSettingEffects.setSavePhraseMode(mode);
      }

      const {selectResult, type, noteText, clickedCaptionIndex} = CaptionsSelectionPopupSelectors.getSavePhraseModePopup(getState());
      if (type === ESavePhraseModePopupType.NOTE) {
        PhraseNoteEffects.copyToNoteText(noteText, selectResult, clickedCaptionIndex, useContext);
      } else {
        const previewPhraseId = CaptionsSelectionPopupSelectors.getPreviewPhraseId(getState());
        PhraseEffects.runSavePhrase({...selectResult}, previewPhraseId, useContext);
      }

      dispatch(CaptionsSelectionPopupActions.setSavePhraseModePopup({show: false}));
    }
  }

  public static onClose(): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      dispatch(CaptionsSelectionPopupActions.setSavePhraseModePopup({show: false}));
    }
  }

}

