import { TCreateCopyResponse, VideoCopyRest } from '../../common/rest/videoCopy/videoCopyRest';
import { getHomeUrl } from '../../common/utils/getHomeUrl';
import { getDispatch, getState } from '../store';
import { setAddVideoConfirmAction, setCopyVideoConfirmAction, setCopyVideoOnLoginAction } from '../store/general/actions';
import { getNativeLanguage, getUserGroupsByLangs, getUserOwnGroups } from '../store/models/selectors';
import { Navigator } from './navigator';
import { UserGroupEffects } from './userGroupEffects';
import { getLibraryGroupId } from '../store/general/selectors';

export class CopyVideoEffects {

  private static COPY_SUB_URL = 'copyVideo';

  public static async startCopy(hash: string, groupId: number) {
    const dispatch = getDispatch();
    dispatch(setCopyVideoOnLoginAction({hash: ''}));
    const videoInfoResult = await VideoCopyRest.getCopyVideoInfo(hash);
    const videoInfo = videoInfoResult.videoInfo;
    if (groupId) {
      // @ts-ignore
			//const result: TAddVideoResult = await dispatch(addVideoEffect(groupId, YoutubeUrlUtil.getUrlById(videoInfoResult.videoId), ''));
      const result: TCreateCopyResponse = await CopyVideoEffects.createCopy(hash, groupId);
			if (result && result.videoId) {
      //  await dispatch(updateUserGroups(true));
        await UserGroupEffects.refreshGroup(groupId);
				Navigator.openVideo(groupId, result.videoId, 0, true);
			}
      return;
    }

    const langMatchGroups = getUserGroupsByLangs(getState(), videoInfoResult.groupTargetLang, videoInfoResult.groupNativeLang);
    const targetGroupId = !!langMatchGroups && langMatchGroups.length && langMatchGroups[0].id;

    const nativeLang = getNativeLanguage(getState())?.code || '';
  //  let isOnlyNative = videoInfoResult.videoInfo.langs === nativeLang;
   /* if (!targetGroupId || isOnlyNative) {
      dispatch(setAddVideoConfirmAction({
        show: true,
        targetGroupId,
        videoInfo: {
          title: videoInfoResult.videoInfo.title,
          thumbnailUrl: videoInfoResult.videoInfo.thumb,
          channelTitle: videoInfoResult.videoInfo.author,
        },
        videoId: videoInfoResult.videoId,
        videoLangCodes: isOnlyNative ? [nativeLang] : videoInfoResult.videoInfo.langList || videoInfoResult.videoInfo.langs.split(','),
        copyHash: hash
      }));
      return;
    }*/


    dispatch(setCopyVideoConfirmAction({
      show: true,
      targetGroupId: targetGroupId || getLibraryGroupId(getState()),
      hash,
      videoInfo,
      videoId: videoInfoResult.videoId,
      srcGroupTargetLang: videoInfoResult.groupTargetLang,
      srcGroupNativeLang: videoInfoResult.groupNativeLang
    }));

  }

  public static async createCopyLink(videoId: string, groupId: number): Promise<string> {
    const resp = await VideoCopyRest.createCopyLink({
      videoId, groupId
    })
    return CopyVideoEffects.getCopyBaseUrl() + resp.hash;
  }

  public static async createCopy(hash: string, groupId: number): Promise<TCreateCopyResponse | null> {
    return VideoCopyRest.createCopy(hash, groupId);
  }

  public static isCopyUrl(url: string): boolean {
    return url.indexOf(CopyVideoEffects.getCopyBaseUrl()) === 0;
  }

  public static getHashFromCopyUrl(url: string) {
    const ps = CopyVideoEffects.getCopyBaseUrl().length;
    let hash = url.substr(ps);
    if (hash.endsWith('/'))
      hash = hash.substr(0, hash.length - 1);
    return hash;
  }

  private static getCopyBaseUrl() {
    return getHomeUrl() + '/' + CopyVideoEffects.COPY_SUB_URL + '/';
  }

}