import { API_URL } from '../../constants';
import { TAsyncThunk } from '../../../spa/store/types';
import { postRequest } from '../../utils/requestService';

const INSTALL_USER_GROUP_VIDEO_CREATE_URL = API_URL + '/installUserGroupVideo/';

type TInstallUserGroupVideoCreateBody = {
  nativeLangCode: string;
  targetLangCode: string;
}

export const createInstallUserGroupVideo = (
  nativeLangCode: string,
  targetLangCode: string
): TAsyncThunk => async(
  dispatch
) => {
  const body: TInstallUserGroupVideoCreateBody = { nativeLangCode, targetLangCode}
  return dispatch(postRequest(INSTALL_USER_GROUP_VIDEO_CREATE_URL, body));
};