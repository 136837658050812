import * as React from 'react';
import { Menu, MenuItem, Select, SelectChangeEvent, Stack, Box, Button } from '@mui/material';
import { IPlaybackSettingsProps } from './types';
import { Text } from '../../../../../../common/components/Text/Text';
import { ControlSwitch } from '../../../Controls/component/ControlSwitch';
import { theme } from '../../../../../../common/styles/theme';
import { Pause } from '../../../../../types/pause-constants';
import { Divider } from '../../../../../../common/components/Divider/Divider';
import { DeviceContext } from '../../../../../../common/contexts/DeviceContext';
import { Modal } from '../../../../../../common/components/Modal/Modal';

const rateList = [0.5, 0.75, 1];

export const PlaybackSettings: React.FC<IPlaybackSettingsProps> = ({
  anchor,
  pause,
  autoSync,
  hoverAutoPause,
  showNativeCaptions,
  autoSpeechText,
  rate,
  pauseOnNote,
  onClose,
  onPauseSizeChange,
  onAutoSyncChange,
  onHoverAutoPauseChange,
  onShowNativeCaptionsChange,
  onAutoSpeechTextChange,
  onRateChange,
  onPauseOnNoteChange
}) => {
  const {isMobile} = React.useContext(DeviceContext);
  
  const handlePauseSize = ({ target }: SelectChangeEvent) => {
    onPauseSizeChange(Number(target.value));
  };

  const handleAutoPauseChange = () => {
    if (pause === Pause.NoPauseValue) {
      onPauseSizeChange(1);
    } else {
      onPauseSizeChange(Pause.NoPauseValue);
    }
  };

  const autoPause = pause !== Pause.NoPauseValue;

  const textStyle = {
    pt: theme.spacing(1),
    color: theme.palette.grey[500],
  };

  const textBoldStyle = {
    fontWeight: 'bold',
    pt: theme.spacing(1),
  };

  const element = (
    <Stack direction={'column'}>
        <Text
          sx={{
            fontSize: theme.typography.subtitle2,
            fontWeight: 600,
          }}
        >
          Display subtitles
        </Text>

        <ControlSwitch
          title='Native'
          checked={showNativeCaptions}
          sx={{
            mt: theme.spacing(1.5),
          }}
          onChange={onShowNativeCaptionsChange}
        />

        <ControlSwitch
          title='Foreign'
          checked={true}
          disabled={true}
          sx={{
            mt: theme.spacing(1.5),
          }}
        />

        <Divider />
        <Text
          sx={{
            fontSize: theme.typography.subtitle2,
            fontWeight: 600,
          }}
        >
          Playback settings
        </Text>

        <Box sx={textBoldStyle}>Player speed</Box>

        <Stack
          direction='row'
          gap={theme.spacing(0.5)}
          sx={{
            mt: theme.spacing(0.5),
          }}
        >
          {rateList.map((rt) => (
            <Button
              key={rt}
              variant={rt === rate ? 'contained' : 'outlined'}
              sx={{
                px: 1,
                height: theme.spacing(4),
              }}
              onClick={() => onRateChange(rt)}
            >
              {rt === 1 ? 'Normal' : 'x' + rt}
            </Button>
          ))}
        </Stack>

        <ControlSwitch
          title='Auto pause'
          checked={autoPause}
          sx={{}}
          onChange={handleAutoPauseChange}
        />

        <Box sx={textStyle}>Automatically pauses video after each phrase</Box>

        {autoPause && (
          <Stack
            direction={'row'}
            gap={theme.spacing(1)}
            sx={{
              pt: theme.spacing(1),
            }}
          >
            <Select
              value={'' + pause}
              onChange={handlePauseSize}
              variant={'filled'}
              sx={{
                width: theme.spacing(18),
              }}
            >
              {Pause.pauseLengths.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.text}
                </MenuItem>
              ))}
            </Select>
          </Stack>
        )}

        <ControlSwitch
          title='Switch pause on notes'
          checked={pauseOnNote}
          sx={{}}
          onChange={onPauseOnNoteChange}
        />
        <Box sx={textStyle}>Automatically pauses video after each notes</Box>

        <ControlSwitch
          title='Pause on mouse over'
          checked={hoverAutoPause}
          sx={{}}
          onChange={onHoverAutoPauseChange}
        />

        <Box sx={textStyle}>Stops video when the cursor hovers over foreign subtitles area</Box>

        <ControlSwitch title='Auto sync' checked={autoSync} onChange={onAutoSyncChange} />
        <Box sx={textStyle}>Synchronizes subtitles in both native and foreign languages</Box>

        <ControlSwitch
          title='Automatically play selected text'
          checked={autoSpeechText}
          onChange={onAutoSpeechTextChange}
        />
      </Stack>
  );

  return (isMobile
    ? <Modal
      onClose={onClose}
      open={true}
      isMobile={isMobile}
    >
      {element}
    </Modal>
    : <Menu
        id='account-menu'
        anchorEl={anchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={true}
        onClose={onClose}
        sx={{
          '.MuiPaper-root': {
            width: theme.spacing(38),
            boxShadow: theme.shadows[16],
          },

          '.MuiList-root': {
            p: theme.spacing(2),
          },
        }}
      >
        {element}
      </Menu>
  );
};
