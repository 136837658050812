import React, { useState } from 'react';
import { HighLightedText } from '../../../../../../common/components/HighLightedText/HighLightedText';
import { Box, ListItem, Stack, SxProps } from '@mui/material';
import { theme } from '../../../../../../common/styles/theme';
import { IVocabularyPhraseProps } from './types';
import { TrashIcon } from '../../../../../../../icons/TrashIcon';
import { SelectionUtil } from '../../../../../../common/utils/selection-util';
import { PencilIcon } from '../../../../../../../icons/PencilIcon';
import { PlayIcon } from '../../../../../../../icons/PlayIcon';
import { PauseIcon } from '../../../../../../../icons/PauseIcon';
import { NoteIcon } from '../../../../../../../icons/NoteIcon';
import { PHRASE_DELETE_PHRASE, PHRASE_MODIFY_CONTEXT, PHRASE_NOTE_ADD, PHRASE_NOTE_EDIT } from '../wordings';
import { EPhraseListViewType } from '../../../../../store/phrase-list/types';
import { DeviceContext } from '../../../../../../common/contexts/DeviceContext';
import { ButtonMenu } from '../../../../../../common/components/ButtonMenu/ButtonMenu';
import { IconMenuItem } from '../../../../../../common/components/IconMenuItem/IconMenuItem';

export const VocabularyPhrase: React.FC<IVocabularyPhraseProps> = ({
  phraseId,
  highlighted,
  fullPhrase,
  playPhrase,
  top,
  playRelationCaption,
  highlightedStartPos,
  flashPhrase,
  note,
  play,
  viewType,
  showMenu,
  onPlayPhrase,
  onClickPhrase,
  onRemovePhrase,
  onEditPhrase,
  onSelectText,
  onOpenNote,
}) => {

    if (fullPhrase.trim().length < 1) return null;

    const classNameItem = 'vocabulary-phrase';

    const { isTouch } = React.useContext(DeviceContext);

    const [className, setClassName] = useState<string>('');
    const itemRef = React.useRef<HTMLDivElement | null>(null);

    const selected = playPhrase || playRelationCaption;
    const isPause = selected && play;

    const pauseDebounce = React.useRef(null);
    const [pause, setPause] = useState(false);
    const [lastTextSelection, setLastTextSelection] = React.useState<string>('');

    const isCompact = viewType === EPhraseListViewType.COMPACT;

    React.useEffect(() => {
        if (pauseDebounce.current) clearTimeout(pauseDebounce.current);
        if (isPause) {
            setPause(isPause);
        } else {
            pauseDebounce.current = setTimeout(() => {  
                setPause(false);
            }, 200) as any;
        }
    }, [play, selected]);


    const handlePlayPhrase = () => {
        onPlayPhrase(isPause);
    };

    const handleMouseUp = () => {
        const selection = SelectionUtil.getSelectedText();
        setLastTextSelection(selection?.toString() || '');
        if (selection) {
            onSelectText(selection);
        }
    }

    const handleClickPhrase = () => {
      if (!lastTextSelection) {
        onClickPhrase();
      }
    }

    const [menuEl, setMenuEl] = React.useState(false);
    const handleMenuOpen = () => {
		setMenuEl(true);
	};
	const handleMenuClose = () => {
		setMenuEl(false);
	};

    const handleEditNote = () => {
        handleMenuClose();
        onOpenNote();
    }

    const handleEditPhrase = () => {
        handleMenuClose()
        onEditPhrase();
    }

    const handleRemovePhrase = () => {
        handleMenuClose()
        onRemovePhrase();
    }

    const itemStyle: SxProps = {
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        position: 'relative',
        
		pt: theme.spacing(1),
        pl: theme.spacing(4),
		pb: theme.spacing(1),
		mb: theme.spacing(1.5),

        background: theme.palette.grey[100],
        borderRadius: theme.spacing(.75),

		'&:hover .phrase-item__play-icon': {
			opacity: .4,
		},

		'&.active': {
            background: theme.palette.primary[100],

			'.phrase-item__play-icon': {
				opacity: 1,
			},
		},
    };

    let playStyle = {
		position: 'absolute',
		zIndex: 0,
		left: 0,
		right: 0,
		top: theme.spacing(-.25),
		bottom: theme.spacing(.25),
		borderRadius: theme.spacing(2),
	}

 	let playIconStyle = {
		position: 'absolute',
		left: '2px',
		top: '6px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: theme.spacing(3.5),
		height: theme.spacing(3.5),
		color: `${theme.palette.common.white}`,
        background: `${theme.palette.primary.main}`,
		borderRadius: '50%',
		cursor: 'pointer',
		opacity: 0,
		transition: 'all .3s ease',

		'svg': {
			width: theme.spacing(1.3),
			height: theme.spacing(1.3),
		},

        '&:before': {
			content: '""',
			position: 'absolute',
			inset: '-5px',
		},
		
		'.caption-item:hover &:hover': {
			opacity: 1,
		},
	}

    React.useEffect(() => {
        if (isTouch) return; // strange scroll on mobile
        setClassName(flashPhrase ? 'animate__animated animate__flash' : '');
        if (flashPhrase) {
            if (itemRef.current) {
                itemRef.current.scrollIntoView();
            }
        }
    }, [flashPhrase]);

    React.useEffect(() => {
        if (isTouch) return; // strange scroll on mobile
        if (top || playPhrase || playRelationCaption) {
            if (itemRef.current) {
                itemRef.current.scrollIntoView();
            }
        }
    }, [top, playPhrase, playRelationCaption]);

    const [_note, setNote] = useState<string>('');
    React.useEffect(() => {
        let _note = '';
        if (note) {
            let br = (isCompact) ? ' ' : '<br>';
            if (isCompact) {
                _note = note.trim().replaceAll(/\n/gm, br);
            } else if (!note.includes('</')) { // old input without html
                _note = note.trim().replaceAll(/\n/gm, br);
            } else { // html
                _note = note.trim().replaceAll(/<p><\/p>/gm, br);
            }
        }
        setNote(_note);
    }, [note, viewType]);

    return (
        <ListItem
            sx={itemStyle}
            className={`
                ${classNameItem}
                ${className}
                ${selected ? 'active' : ''}
                ${isCompact ? 'compact' : ''}
            `}
            disablePadding
        >
            <Box
                className={`phrase-item__play`}
                sx={playStyle}
            >
                <Box
                    className={`phrase-item__play-icon`}
                    sx={playIconStyle}
                    onClick={handlePlayPhrase}
                >
                    {pause
						? <PauseIcon />
						: <PlayIcon />
					}
                </Box>
            </Box>
            <Stack
                sx={{
                    position: 'relative',
                    zIndex: 100,
                    width: '100%',
                }}
                ref={itemRef}
            >
                <Box
                    role={'button'}
                    sx={{
                        lineHeight: theme.spacing(3.25),
                        cursor: 'pointer',

                        '.highlighted-span' : {
                            backgroundColor: '#ffdf8766',
                        },
                    }}
                    onClick={handleClickPhrase}
                    onMouseUp={handleMouseUp}
                >
                    <HighLightedText
                        text={fullPhrase}
                        highLighted={[highlighted]}
                        highlightedStartPos={highlightedStartPos}
                    />
                </Box>
                {/*<NativePhrase text={translated} />*/}
                {_note && <>
                    <Box
                        dangerouslySetInnerHTML={{ __html: _note}}
                        sx={{
                            color: theme.palette.grey[500],
                            fontSize: theme.typography.subtitle2,
                            wordBreak: 'break-word',
                            cursor: 'pointer',

                            lineHeight: 1.4,
                            mt: theme.spacing(1),

                            '& > *': {
                                m: 0,
                            },

                            'img': {
                                maxWidth: 1,
                            },

                            [`.${classNameItem}.compact &`]: {
                                display: '-webkit-box',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                '-webkit-line-clamp': '3',
                                '-webkit-box-orient': 'vertical',
                                maxHeight: '4.2em',
                            },
                        }}
                        onClick={onClickPhrase}
                    />
                </>}
            </Stack>

            {showMenu &&
                <ButtonMenu
                    buttonProps={{}}
                    menuProps={{
                        open: !!menuEl,
                    }}
                    onOpen={handleMenuOpen}
                    onClose={handleMenuClose}
                >
                    <IconMenuItem
                        title={!!_note ? PHRASE_NOTE_EDIT : PHRASE_NOTE_ADD}
                        onClick={handleEditNote}
                        sx={{
                            '.MuiSvgIcon-root': {
                                width: theme.spacing(3),
                                height: theme.spacing(3),
                            },
                        }}
                    >
                        <NoteIcon />
                    </IconMenuItem>

                    <IconMenuItem
                        title={PHRASE_MODIFY_CONTEXT}
                        onClick={handleEditPhrase}
                        sx={{
                            '.MuiSvgIcon-root': {
                                width: theme.spacing(2.3),
                                height: theme.spacing(2.3),
                            },
                        }}
                    >
                        <PencilIcon />
                    </IconMenuItem>
                    
                    <IconMenuItem
                        title={PHRASE_DELETE_PHRASE}
                        onClick={handleRemovePhrase}
                        sx={{
                            '.MuiSvgIcon-root': {
                                width: theme.spacing(2.3),
                                height: theme.spacing(2.3),
                            },
                            '&:hover, &:active, &:focus': {
                                '.MuiSvgIcon-root': {
                                    color: theme.palette.error.main,
                                },
                            },
                        }}
                    >
                        <TrashIcon />
                    </IconMenuItem>

                </ButtonMenu>
            }
                
        </ListItem>
    );
};
