import { Action, applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { IState } from './types';
import ReduxThunk from 'redux-thunk';
import { videoReducer } from './videos/reducer';
import { currentVideoReducer } from './current-video/reducer';
import { generalReducer } from './general/reducer';
import { modelsReducer } from './models/reducer';
import { youtubeButtonReducer } from '../../extension/youtube-button/store/reducer';
import { captionsSelectionPopupReducer } from './captions-selection-popup/reducer';
import { phraseDetailsReducer } from './phrase-details/reducer';
import { phraseDetailsTabsEditorPopupReducer } from './phrase-details-tabs-editor-popup/reducer';
import { phraseListReducer } from './phrase-list/reducer';
import { phraseContextEditorReducer } from './phrase-context-editor/reducer';
import { phraseSelectPopupReducer } from './phrase-select-popup/reducer';

// @ts-ignore
export let appStore;

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const REDUX_DEVTOOLS_DEFAULTS = {
  instanceId: 'store',
  trace: true,
  traceLimit: 25
};

export const getEnhancers = (
  options: any = {}
) => {
  return window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          ...REDUX_DEVTOOLS_DEFAULTS,
          ...options
      })
      : compose;
}

export const createApplicationStore = (initialState: Partial<IState>) => {
  const store = createStore<IState, Action, {}, {}>(
    combineReducers({
      videos: videoReducer,
      currentVideo: currentVideoReducer,
      general: generalReducer,
      models: modelsReducer,
      youtubeButton: youtubeButtonReducer,
      captionsSelectionPopup: captionsSelectionPopupReducer,
      phraseDetails: phraseDetailsReducer,
      phraseDetailsTabsEditorPopup: phraseDetailsTabsEditorPopupReducer,
      phraseList: phraseListReducer,
      phraseContextEditor: phraseContextEditorReducer,
      phraseSelectPopup: phraseSelectPopupReducer,
    }),
    initialState,
    getEnhancers()(
      applyMiddleware(ReduxThunk)
    )
  );
  appStore = store;
  window.getState = store.getState;
  return store;
};

export const getState = () => {
  return appStore.getState();
}

export const getDispatch = () => {
  return appStore.dispatch;
}
