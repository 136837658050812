import { API_URL } from '../../constants';
import { getDispatch } from '../../../spa/store';
import { postRequest } from '../../utils/requestService';

export type TDetectLangExecResponse = {
  code: string
}


export class DetectLangRest {

  private static DETECT_LANG_API_URL = API_URL + '/detectLang/exec';

  public static async exec(text: string): Promise<string> {
    const dispatch = getDispatch();
    const resp: TDetectLangExecResponse = await dispatch(postRequest(DetectLangRest.DETECT_LANG_API_URL, {text}));
    return resp?.code || '';
  }
}