import { AppThunk } from '../../../../../../store/types';
import {
	setCopyVideoConfirmAction,
} from '../../../../../../store/general/actions';
import { CopyVideoEffects } from '../../../../../../effects/copyVideoEffects';
import { getCopyVideoConfirm } from '../../../../../../store/general/selectors';
import { updateUserGroups } from '../../../../../../effects/updateUserGroups';
import { Navigator } from '../../../../../../effects/navigator';
import { TCreateCopyResponse } from '../../../../../../../common/rest/videoCopy/videoCopyRest';
import { UserGroupEffects } from '../../../../../../effects/userGroupEffects';
import { COPY_VIDEO_CONFIRM_NEW_GROUP_NAME } from '../components/wordings';

export class CopyVideoConfirmEvents {

	public static onClose(): AppThunk {
		return async (
			dispatch,
			getState
		) => {
			dispatch(setCopyVideoConfirmAction({ show: false }));
		}
	}

	public static onGroupChange(groupId: number): AppThunk {
		return async (
			dispatch,
			getState
		) => {
			const state = getState();
			const {
				videoId,
				srcGroupNativeLang = '',
				srcGroupTargetLang = '',
			} = getCopyVideoConfirm(state) || {};
			if (!videoId) {
				dispatch(this.onClose());
				return Promise.reject();
			}

			if (!groupId) { // create group
				groupId = await UserGroupEffects.createGroup(
					`${COPY_VIDEO_CONFIRM_NEW_GROUP_NAME} ${srcGroupTargetLang} / ${srcGroupNativeLang}`,
					srcGroupTargetLang, srcGroupNativeLang);
				if (groupId) {
					await UserGroupEffects.loadGroup(groupId);
				}
			}
			
			const { hash = '' } = getCopyVideoConfirm(state);
			// @ts-ignore
			const result: TCreateCopyResponse = await CopyVideoEffects.createCopy(hash, groupId);

			await dispatch(updateUserGroups(true));
			dispatch(this.onClose());
			if (result && result.videoId) {
				Navigator.openVideo(groupId, videoId);
			}

			return Promise.resolve();
		}
	}


}
