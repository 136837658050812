import React from 'react';
import { theme } from '../../../../../../../common/styles/theme';
import { Modal } from '../../../../../../../common/components/Modal/Modal';
import { Box, Button, CircularProgress, FormGroup, MenuItem, Select, SelectChangeEvent, Stack, TextField } from '@mui/material';
import { Text } from '../../../../../../../common/components/Text/Text';
import { ICopyVideoConfirmProps } from './types';
import { createGroupNameByLangs } from "../../../../../../store/models/selectors";
import { DeviceContext } from '../../../../../../../common/contexts/DeviceContext';
import { COPY_VIDEO_CONFIRM_TEXT } from './wordings';
import { Divider } from '../../../../../../../common/components/Divider/Divider';
import { VideoCard } from '../../../../VideoCard/VideoCard';

export const CopyVideoConfirm: React.FC<ICopyVideoConfirmProps> = ({
    groupId,
    groupName,
    groups,
    show,
    videoInfo,
    langCodes,
	newListName,
    onClose,
    onChange,
}) => {
    if (!show) return null;

    const { isMobile } = React.useContext(DeviceContext);

    const [progress, setProgress] = React.useState<boolean>(false);
	const [selected, setSelected] = React.useState(-1);
	const [_newListName, setNewListName] = React.useState(newListName);
	const [isNewList, setIsNewList] = React.useState(false);

	const onChangeHandle = (groupId: number, newListName?: string) => {
		setSelected(groupId);
		setProgress(true);
		try {
			onChange(groupId, newListName);
		} catch(e) {
			setProgress(false);
		}
	};

	const onChangeSelect = (event: SelectChangeEvent) => {
		const groupId = +event.target.value;
		if (groupId) {
			onChangeHandle(groupId);
		} else {
			setIsNewList(true);
		}
	};

	const onChangeNewList = (event: React.ChangeEvent<HTMLInputElement>) => {
		setNewListName(event.target.value);
	};

	const onSubmitNewList = () => {
		onChangeHandle(0, _newListName);
	};

	const handleKeyDown = (event: React.KeyboardEvent) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			onSubmitNewList();
		}
	};

	const CLASS_NEWLIST_CONTROL = 'newlist-control';
	const onBlurNewList = (event: React.FocusEvent) => {
		if (event.relatedTarget && event.relatedTarget.classList.contains(CLASS_NEWLIST_CONTROL)) {
			// not blur
		} else {
			setIsNewList(false);
		}
	};
    
    return (
        <Modal
			onClose={onClose}
			open={show}
			isMobile={isMobile}
			PaperProps={{
				sx: {
					width: 1,
					maxWidth: `${theme.spacing(62)} !important`,
				}
			}}
		>
			<Stack
				gap={theme.spacing(2)}
			>
				<Text
					sx={{
						mt: -2,
						fontSize: theme.typography.h6,
						fontWeight: 600,
					}}
				>
					Select playlist
				</Text>

				<Text
					sx={{
						fontSize: theme.typography.subtitle2,
					}}
				>
					{COPY_VIDEO_CONFIRM_TEXT}
				</Text>

				<VideoCard
					author={videoInfo.author}
					img={videoInfo.thumb}
					title={videoInfo.title}
					langCodes={langCodes}
				/>

				<Box
					sx={{
						position: 'relative',
					}}
				>
					{progress
						? <Box
							sx={{
								height: theme.spacing(5),
								width: 1,
								pt: theme.spacing(1),
								textAlign: 'center',
							}}
						>
							<CircularProgress
								size={theme.spacing(3)}
							/>
						</Box>
						: isNewList || !groups.length
							? <FormGroup
								sx={{
									flexFlow: 'row nowrap',
									gap: theme.spacing(1),

									[theme.breakpoints.down('sm')]: {
										flexWrap: 'wrap',
										rowGap: theme.spacing(1),
									}
								}}
								onKeyDown={handleKeyDown}
								onBlur={onBlurNewList}
							>     
								<TextField
									autoFocus
									fullWidth
									value={_newListName}
									onChange={onChangeNewList}
									multiline={false}
									variant={'filled'}
								/>
								<Button
									className={CLASS_NEWLIST_CONTROL}
									disabled={progress}
									onClick={onSubmitNewList}
									variant={'contained'}
									fullWidth
									sx={{
										'&[disabled]': {
											background: theme.palette.grey[100],
										}
									}}
								>
									Create new playlist
								</Button>
							</FormGroup>
							: <Select
								disabled={progress}
								variant={'filled'}
								fullWidth
								value={String(selected)}
								onChange={onChangeSelect}
							>
								<MenuItem
									disabled
									value={'-1'}
									key={'placeholder'}
									sx={{
										display: 'none',
									}}
								>
									Select playlist
								</MenuItem>

								{groups.map((group) => {
									return <MenuItem
										key={group.id}
										value={group.id}
									>
										{group.name || createGroupNameByLangs(group)}
									</MenuItem>
								})}

								<Divider sx={{
									mt: theme.spacing(.5),
									mb: theme.spacing(.5),
								}}/>

								<MenuItem
									key={0}
									value={0}
								>
									Create new playlist
								</MenuItem>
							</Select>
					}
				</Box>
			</Stack>
		</Modal>
    );
}
