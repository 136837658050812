import { EVocabularyPhraseType, IVocabularyPhrase } from '../../../../types/common';
import { EPlayerControllerMode, PlayerController } from '../playerController';
import { PlayerManager } from '../playerManager';
import { getDispatch, getState } from '../../../../store';
import { PhraseListActions } from '../../../../store/phrase-list/actions';
import { EPhraseListPlayMode } from '../../../../store/phrase-list/types';
import { batch } from 'react-redux';
import { PhraseListSelectors } from '../../../../store/phrase-list/selectors';
import { PlayPhraseBaseHandler } from './playPhraseBaseHandler';
import {
  getVideoTeacherPhrases,
  getVideoTeacherSelectPhraseId,
  getVideoTeacherUserActiveId
} from '../../../../store/videos/selectors';
import {
  setVideoTeacherSelectPhraseIdAction,
  setVideoTeacherShowPhraseIdAction
} from '../../../../store/videos/actions';

export class PlayTeacherPhraseHandler extends PlayPhraseBaseHandler {

  public async playList() {
    const teacherId = getVideoTeacherUserActiveId(getState());
    const phrase = getVideoTeacherPhrases(getState(), teacherId).find(p => p.type === EVocabularyPhraseType.WORD_AND_CONTEXT_SELECTED);
    if (phrase) {
      this.play(phrase);
    }
  }

  public async play(phrase: IVocabularyPhrase) {
    const playerController = PlayerController.getInstance();
    const playerManager = PlayerManager.getInstance();
    playerController.reset();
    await playerManager.pausePlay();

    playerController.setMode(EPlayerControllerMode.PLAY_TEACHER_PHRASE);

    const dispatch = getDispatch();
    dispatch(PhraseListActions.setPlayMode(EPhraseListPlayMode.PLAY));
    batch(() => {
      dispatch(PhraseListActions.setTopPhraseId(phrase.id));
      dispatch(setVideoTeacherShowPhraseIdAction(phrase.id));
      dispatch(setVideoTeacherSelectPhraseIdAction(phrase.id));
    })
    const offset = PhraseListSelectors.getPlayOffset(getState());
    const playManager = PlayerManager.getInstance();
    await playManager.startPlay(phrase.startTime - offset.start);
    playManager.setTimer(phrase.endTime + offset.end);
  }

  protected onBeforeStartPhrase(phrase: IVocabularyPhrase) {
    const state = getState();
    const dispatch = getDispatch();
    let phraseId = phrase.id;
    if (phrase.type === EVocabularyPhraseType.WORD_SELECTED) {
      const teacherId = getVideoTeacherUserActiveId(state);
      const phrases = getVideoTeacherPhrases(state, teacherId);
      phraseId = phrases.find(p => p.wordPhraseId === phrase.id)?.id;
    }
    if (phraseId) {
      dispatch(setVideoTeacherShowPhraseIdAction(phraseId));
      dispatch(setVideoTeacherSelectPhraseIdAction(phraseId));
    }
  }

  protected getNextPhrase(): IVocabularyPhrase | null {
    const state = getState();
    const currentPhraseId = getVideoTeacherSelectPhraseId(getState());
    const teacherId = getVideoTeacherUserActiveId(state);
    if (!teacherId || !currentPhraseId) return null;
    const phrases = getVideoTeacherPhrases(state, teacherId);
    const index = phrases.findIndex(p => p.id === currentPhraseId && p.type === EVocabularyPhraseType.WORD_AND_CONTEXT_SELECTED);

    if (index >= 0 && index < phrases.length) {
      for(let i=index + 1; i<phrases.length; i++) {
        if (phrases[i].type === EVocabularyPhraseType.WORD_AND_CONTEXT_SELECTED) {
          return phrases[i];
        }
      }
    }
    return null;
  }

  protected onAfterFinish() {
    //const dispatch = getDispatch();
    //dispatch(setVideoTeacherSelectPhraseIdAction(0));
  }
}