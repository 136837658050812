import { AppThunk } from '../../../../../store/types';
import { EMainViewMode } from '../../../../../store/general/types';
import { setFeedbackModalOpenAction, setMainViewMode } from '../../../../../store/general/actions';
import { PhraseDetailsTabsEditorPopupActions } from '../../../../../store/phrase-details-tabs-editor-popup/actions';
import { EPhraseDetailsTabsEditorViewMode } from '../../../../../store/phrase-details-tabs-editor-popup/types';
import { EXTENSION_INSTALL_URL, TELEGRAM_BOT_URL } from '../../../../../../common/constants';
import { EventsRouter } from '../../../../../../common/events/eventsRouter';
import { Events } from '../../../../../../common/events/types';
import { historyPush } from '../../../../../../common/utils/historyHelper';
import { MainLayoutEffects } from '../../../../../effects/mainLayoutEffects';
import { getMainViewMode } from '../../../../../store/general/selectors';
import { ProfilesEffects } from '../../../../../effects/profilesEffects';

export class MenuEvents {

  public static onClickHome (): AppThunk {
    return (
      dispatch
    ) => {
      dispatch(MainLayoutEffects.backToDashboard());
    }
  }

  public static onClickContent (): AppThunk {
    return (
      dispatch
    ) => {
      dispatch(setMainViewMode(EMainViewMode.CONTENT_LIBRARY));

      historyPush({
        state: { page: 'Library' },
        title: 'Library',
        onpopstate: () => dispatch(MainLayoutEffects.backToDashboard())
      });
    }
  }

  public static onClickChatGpt (): AppThunk {
    return (
      dispatch
    ) => {
      dispatch(setMainViewMode(EMainViewMode.PHRASE_DETAILS_TABS_EDITOR));
      dispatch(PhraseDetailsTabsEditorPopupActions.setViewMode(EPhraseDetailsTabsEditorViewMode.MAIN));

      historyPush({
        state: { page: 'settings' },
        title: 'Settings',
        onpopstate: () => dispatch(MainLayoutEffects.backToDashboard())
      });
    }
  }

  public static onClickPhrases (): AppThunk {
    return (
      dispatch
    ) => {
      dispatch(setMainViewMode(EMainViewMode.PHRASES));

      historyPush({
        state: { page: 'Phrases' },
        title: 'Phrases',
        onpopstate: () => dispatch(MainLayoutEffects.backToDashboard())
      });
    }
  }

  public static onClickHelp (): AppThunk {
    return (
      dispatch,
    ) => {
      dispatch(MainLayoutEffects.showHelp());
    }
  }

  public static onClickPlaylists (): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      const mainViewModePrev = getMainViewMode(getState());
      dispatch(setMainViewMode(EMainViewMode.PLAYLISTS));

      historyPush({
        state: { page: 'Playlists' },
        title: 'Playlists',
        onpopstate: () => dispatch(setMainViewMode(mainViewModePrev))
      });
    }
  }

  public static onExtension(
    ): AppThunk {
      return (
        dispatch
      ) => {
        window.open(EXTENSION_INSTALL_URL);
        EventsRouter.trackEvent(Events.INSTALL_EXTENSION_BUTTON_CLICKED);
      }
  }

  public static onTelegram(
    ): AppThunk {
      return (
        dispatch
      ) => {
        window.open(TELEGRAM_BOT_URL);
        EventsRouter.trackEvent(Events.INSTALL_TELEGRAM_BOT_BUTTON_CLICKED);
      }
  }

  public static onClickFeedback (): AppThunk {
    return (
      dispatch,
    ) => {
      dispatch(setFeedbackModalOpenAction(true));
      historyPush({
        state: { page: 'feedback' },
        title: 'Feedback',
        onpopstate: () => dispatch(setFeedbackModalOpenAction(false))
      });
    }
  }

  public static onClickStatistics (): AppThunk {
    return (
      dispatch,
    ) => {
      dispatch(setMainViewMode(EMainViewMode.STATISTICS));
      historyPush({
        state: { page: 'Statistics' },
        title: 'Statistics',
        onpopstate: () => dispatch(MainLayoutEffects.backToDashboard())
      });
    }
  }

  public static onLoadProfiles (
	): AppThunk {
		return async (
			dispatch,
			getState
		) => {
			return ProfilesEffects.load();
		}
	}


}