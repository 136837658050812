import { TAuthUser } from '../../spa/store/general/types';

enum EventSubject {
  APP = 'App',
  USER = 'user',
  PHRASE = 'phrase',
  VIDEO = 'video',
  PROMPT = 'prompt',
  ONBOARDING = 'Onboarding',
}

export interface IEvent {
  subject: EventSubject,
  action: string
}

export const Events = {
  INSTALL: {subject: EventSubject.USER, action: 'install'},
  AUTHORIZATION: {subject: EventSubject.USER, action: 'authorization'},
  OPEN_SESSION: {subject: EventSubject.USER, action: 'open_session'},
  ADD_VIDEO: {subject: EventSubject.VIDEO, action: 'add_video'},
  SAVE_PHRASE: {subject: EventSubject.PHRASE, action: 'save_phrase'},
  SAVE_PROMPT: {subject: EventSubject.PROMPT, action: 'save_prompt'},

  SIGN_IN_LOADED: {subject: EventSubject.APP, action: 'Sign In Page Opened'},
  SIGN_IN_CLICKED: {subject: EventSubject.APP, action: 'Sign In With Google Account'},

  NEW_USER_SIGNED_UP: {subject: EventSubject.APP, action: 'New user signed up'},
  GET_STARTED_CLICKED: {subject: EventSubject.APP, action: 'Languages Choice'},

  USER_LOGGED_IN: {subject: EventSubject.APP, action: 'User logged in'},
  DICTIONARY_LOADED: {subject: EventSubject.APP, action: 'Dictionary loaded'},
  SAVE_PHRASE_BUTTON_CLICKED: {subject: EventSubject.APP, action: 'Save phrase button clicked'},
  PHRASE_SAVED: {subject: EventSubject.APP, action: 'Phrase saved'},
  NOTE_ADDED: {subject: EventSubject.APP, action: 'Note added'},
  PROMPT_BUTTON_CLICKED: {subject: EventSubject.APP, action: 'Prompt From The List Added'},
  PROMPT_PAGE_VIEWED: {subject: EventSubject.APP, action: 'Prompt Page Opened'},
  SAVE_TO_MY_PROMPTS_BUTTON_CLICKED: {subject: EventSubject.APP, action: 'Prompt Saved'},
  CREATE_PLAYLIST_BUTTON_CLICKED: {subject: EventSubject.APP, action: 'Playlist created'},
  INSTALL_EXTENSION_BUTTON_CLICKED: {subject: EventSubject.APP, action: 'Chrome Extension Following'},
  ADD_VIDEO_BUTTON_CLICKED: {subject: EventSubject.APP, action: 'Video Added'},
  CHROME_EXTENSION_INSTALLED: {subject: EventSubject.APP, action: 'Chrome extension Installed'},
  INSTALL_TELEGRAM_BOT_BUTTON_CLICKED: {subject: EventSubject.APP, action: 'Telegram Bot Following'},
  TELEGRAM_BOT_INSTALLED: {subject: EventSubject.APP, action: 'Telegram bot installed'},
  TRANSLATION_LOADED: {subject: EventSubject.APP, action: 'Translation Loaded'},

  TUTORIAL_VIDEO_PLAYED: {subject: EventSubject.ONBOARDING, action: 'Tutorial Video {0} Played'},
  TUTORIAL_VIDEO_CLOSED: {subject: EventSubject.ONBOARDING, action: 'Tutorial Video {0} Closed'},
  TUTORIAL_VIDEO_ADD_PROMPT_CLICKED: {subject: EventSubject.ONBOARDING, action: 'Add Prompt From Tutorial'},
  USER_ROLE_SELECTED: {subject: EventSubject.ONBOARDING, action: 'User Role Selected'},
}

export interface IInstallEventData {
  userId: string
}

export type IEventData =
  IInstallEventData |
  Record<string, string | number>

export interface IEventsProvider {
  setUser(user: TAuthUser): void;
  trackEvent(event: IEvent, date: Date, data: IEventData): void;
}