import React from 'react';
import { IPhraseNotesProps, PHRASE_DETAILS_NOTES_CLASS_NAME } from './types';
import { Button, CircularProgress, Stack } from '@mui/material';
import { theme } from '../../../../../../common/styles/theme';
import { ControlSwitch } from '../../../Controls/component/ControlSwitch';
import { DraftWysiwyg } from '../../../../../../common/components/DraftWysiwyg/components/DraftWysiwyg';
import Box from '@mui/material/Box';
import { PhraseNoteHTML } from '../../PhraseNoteHTML/components/PhraseNoteHTML';

export const PhraseNotes: React.FC<IPhraseNotesProps> = ({
  active,
  phraseId,
  selectResult,
  clickedCaptionIndex,
  text,
  processing,
  canEdit,
  pause,
  isEdit,
  authorId,
  authorName,
  onSave,
  onUpdate,
  onClickAuthor,
  onLoadImage
}) => {
  const activeRef = React.useRef(false);

  const [value, setValue] = React.useState<string>('');
  const [pauseValue, setPauseValue] = React.useState<boolean>(false);
  const [valueChanged, setValueChanged] = React.useState<boolean>(false);

  React.useEffect(() => {
    setValue(text);
    setValueChanged(false);
    setPauseValue(pause);
  }, [text, phraseId, pause]);

  React.useEffect(() => {
    activeRef.current = active
  }, [active]);

  React.useEffect(() => {
    onUpdate();
  }, [phraseId, selectResult, clickedCaptionIndex]);


  const handleSave = () => {
    if (canEdit && valueChanged && value) {
      onSave({
        text: value,
        pause: pauseValue
      });
      setValueChanged(false);
    }
  }

  const handleOnChange = (value: string) => {
    if (canEdit) {
      setValue(value);
      setValueChanged(value !== text);
    }
  }

  const handlePauseChange = () => {
    const pause = !pauseValue;
    setPauseValue(pause);
    onSave({
      text: value,
      pause,
    });
  }

  let saveLabel = canEdit ? (phraseId ? 'Save Note' : 'Save Note and Phrase') : 'Save';
  const saveDisabled = !canEdit || !valueChanged || !value;

  if (!!text && !valueChanged) saveLabel = 'Saved';

  const [_note, setNote] = React.useState<string>('');
  React.useEffect(() => {
    let _note = '';
    if (text) {
      let br = '<br>';
      if (!text.includes('</')) { // input without html (translates -> save to note)
        _note = text.trim().replaceAll(/\n/gm, br);
      } else { // html
        _note = text.trim().replaceAll(/<p><\/p>/gm, br);
      }
    }
    setNote(_note);
  }, [canEdit, text]);

  return (
      <Stack
        className={PHRASE_DETAILS_NOTES_CLASS_NAME}
          sx={{
            flex: `1 1 100%`,
            minHeight: 0,
            width: 1,
            pt: theme.spacing(1.5),
            pl: theme.spacing(1),
            overflow: 'visible',
            justifyContent: processing ? 'center' : null,
            alignItems: processing ? 'center' : null,           
          }}
      >
        {processing ?
            <CircularProgress sx={{ margin: 'auto', width: theme.spacing(3), height: theme.spacing(3) }} />
            :
          <>
            {canEdit
              ? <>
                <DraftWysiwyg
                  value={text}
                  isActive={active}
                  isEdit={isEdit}
                  placeholder={'Write some notes...'}
                  onChangeEditor={handleOnChange}
                  onLoadImage={onLoadImage}
                />
                <Stack
                  direction={'row'}
                  alignItems={'center'}
                  sx={{
                    mt: theme.spacing(1),
                  }}
                >
                  <Button
                    variant={'contained'}
                    onClick={handleSave}
                    disabled={saveDisabled}
                  >
                    {saveLabel}
                  </Button>

                  <ControlSwitch
                    title='Pause'
                    checked={pauseValue}
                    onChange={handlePauseChange}
                    sx={{
                      mt: 0,
                      ml: theme.spacing(2),
                    }}
                  />

                </Stack>

                {!!authorId &&
                <Box
                  sx={{
                    height: theme.spacing(3),
                    textAlign: 'right',
                    fontSize: theme.typography.subtitle2,
                    overflow: 'hidden',
                  }}
                >
                  Created by
                  {' '}
                  <Button
                    variant={'text'}
                    onClick={onClickAuthor}
                    sx={{
                      textDecoration: 'underline',
                    }}
                  >
                    {authorName}
                  </Button>
                </Box>
                }
              </>
              : <PhraseNoteHTML text={_note} />
              }
            </>
        }
      </Stack>
  );

}
