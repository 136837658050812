import { IContentLibVideo } from '../../../../../store/contentLib/types';

export interface IContentLibSelectedGroupFields {
  groupId: number,
  searchText: string
}

export interface IContentLibSelectedGroupEvents {
  onLoadVideos: (startIndex: number, count: number) => Promise<IContentLibVideo[]>
  onVideoClick: (videoId: number) => void;
}

export interface IContentLibSelectedGroupProps extends
  IContentLibSelectedGroupFields,
  IContentLibSelectedGroupEvents
{}

export const contentLibVideosLoadPartSize = 200;