import { API_URL, USE_REMOTE_DEV_BACKEND } from '../../constants';
import { TAuthUser } from '../../../spa/store/general/types';
import { getDispatch } from '../../../spa/store';
import { execGetRequestByToken, postRequest } from '../../utils/requestService';

export interface IUserSettings {
  pause: number,
  hoverAutoPause: boolean,
  autoSync: boolean,
  showNativeCaptions: boolean,
  phraseListPauseDelay: number,
  phraseListStartPlayOffset: number,
  phraseListEndPlayOffset: number,
  showOnboarding: string,
  autoSpeechSelectedText: boolean,
  pauseOnNote: boolean,
  savePhraseMode: number
}


export interface IAppSettings {
  saveVideoCaptions: boolean;
  saveUserTranslations: boolean;
  statServersUrl: string;
}

export interface IUserRestResponse {
  authUser: TAuthUser,
  settings: IUserSettings,
  appSettings: IAppSettings
}

export class UserRest {

  private static AUTH_USER = API_URL + '/user/authCode';
  private static USER_BY_EMAIL = API_URL + '/user/authCodeByEmail';
  private static USER_BY_TOKEN_URL = API_URL + '/users/info';
  private static USER_SETTINGS_SAVE = API_URL + '/users/settingsSave';
  private static USER_ROLES_SAVE = API_URL + '/users/rolesSave';
  private static USER_UPDATE = API_URL + '/users/update';

  public static async getAuthUserByCode(code: string): Promise<IUserRestResponse | null> {
    const devmode = USE_REMOTE_DEV_BACKEND ? '1' : '';
    const headers = {
      code,
      devmode
    };
    try {
      const response = await fetch(UserRest.AUTH_USER, {headers});
      const result = await response.json();
      return result;
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  public static async getUserByEmail(email: string): Promise<IUserRestResponse | null> {
    try {
      const dispatch = getDispatch();
      return await dispatch(postRequest(UserRest.USER_BY_EMAIL, {email}));
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  public static async getUserByToken(token: string): Promise<IUserRestResponse | null> {
    try {
      return await execGetRequestByToken(UserRest.USER_BY_TOKEN_URL, token);
    } catch(e) {
      return null;
    }
  }

  public static async saveSettings(settings: Partial<IUserSettings>) {
    const dispatch = getDispatch();
    return await dispatch(postRequest(UserRest.USER_SETTINGS_SAVE, settings));
  }

  public static async saveRoles(roles: number[]): Promise<number[]> {
    const dispatch = getDispatch();
    return await dispatch(postRequest(UserRest.USER_ROLES_SAVE, roles));
  }

  public static async updateUser(data: Partial<TAuthUser> ): Promise<number[]> {
    const dispatch = getDispatch();
    return await dispatch(postRequest(UserRest.USER_UPDATE, data));
  }

}
