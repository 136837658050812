import { AppThunk } from '../../../../../store/types';
import { ContentLibActions } from '../../../../../store/contentLib/actions';

export class ContentLibVideoEvents {

  public static onClick(groupId: number, videoId: number): AppThunk {
    return (
      dispatch,
      getState
    ) => {
       dispatch(ContentLibActions.setVideoPopup({groupId, videoId}));
    }
  }
}