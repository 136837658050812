import React from 'react';
import { CircularProgress } from '@mui/material';
import { IMainContainerProps } from './types';
import { DefaultLoginHOC } from '../../../install/Login/hocs/default/DefaultLoginHOC';
import { PhraseDetailsTabsEditorRouterHOC } from '../../PhraseDetailsTabsEditor/PhraseDetailsTabsEditorRouter/hocs/PhraseDetailsTabsEditorRouterHOC';
import { DashboardHOC } from '../../Dashboard/hocs/DashboardHOC';
import { PhrasesViewHOC } from '../../PhrasesView/hocs/PhrasesViewHOC';
import { ContentLibraryHOC } from '../../ContentLibrary/hocs/ContentLibraryHOC';
import { PhraseContextEditorHOC } from '../../PhraseContextEditor/hocs/PhraseContextEditorHOC';
import { FeedbackHOC } from '../../ToolbarPanel/Feedback/hocs/FeedbackHOC';
import { VideoTutorialPopupContainerHOC } from '../../VideoTutorial/VideoTutorialPopup/hocs/VideoTutorialPopupContainerHOC/VideoTutorialPopupContainerHOC';
import { StatisitcsHOC } from '../../ToolbarPanel/Statisitcs/hocs/StatisitcsHOC';
import { DeviceContext } from '../../../../../common/contexts/DeviceContext';
import { PlaylistsMobileHOC } from '../../PlaylistsMobile/hocs/PlaylistsMobileHOC';
import { PromptsMobileHOC } from '../../PromptsMobile/hocs/PromptsMobileHOC';
import { SnackbarPanelHOC } from '../../SnackbarPanel/hocs/SnackbarPanelHOC';
import { useLocation } from 'react-router-dom';
import { AddVideoConfirmHOC } from '../../AddVideoConfirm/hocs/AddVideoConfirmHOC';

export const MainContainer: React.FC<IMainContainerProps> = ({
  isAuth,
  onLoad,
  onMouseDown,
  onChangeRoute,
}) => {
  const {isMobile} = React.useContext(DeviceContext);

  const location = useLocation();
	React.useEffect(() => {
		onChangeRoute();
	}, [location]);

  const [isLoaded, setIsLoaded] = React.useState(false);
  React.useEffect(() => {
    onLoad().then(() => {
      setIsLoaded(true);
    })

    document.addEventListener('mousedown', onMouseDown);
    return () => {
      document.removeEventListener('mousedown', onMouseDown);
    }
  }, []);

  return (
    <>
      {isLoaded ?
        <>
          {isAuth &&
          <>
            <DashboardHOC />
            <PhrasesViewHOC />
            <PhraseDetailsTabsEditorRouterHOC />
            <ContentLibraryHOC />
            <VideoTutorialPopupContainerHOC />
            <PhraseContextEditorHOC />
            <FeedbackHOC />
            <StatisitcsHOC />
            <SnackbarPanelHOC />
            {isMobile &&
              <PlaylistsMobileHOC />
            }
            {isMobile &&
              <PromptsMobileHOC />
            }
            <AddVideoConfirmHOC />
          </>
          }
          {!isAuth &&
          <DefaultLoginHOC />
          }
        </>
        :
        <CircularProgress sx={{ m: 'auto' }} />
      }
      </>
  );

};
