import { IState } from '../../../../store/types';
import {
  getCopyVideoConfirm,
  getEditPlaylistModalId,
  getMainLayoutShowLeftPanel,
  getMainLayoutType,
  getMainViewMode,
  getOnboarding,
  getShowNativeCaptionsIfAvailable
} from '../../../../store/general/selectors';
import { connect } from 'react-redux';
import { Dashboard } from '../components/Dashboard';
import { IDashboardEvents, IDashboardFields } from '../components/types';
import { EMainViewMode } from '../../../../store/general/types';
import { DashboardEvents } from './events';
import { PhraseListSelectors } from '../../../../store/phrase-list/selectors';
import { EVocabularyPhraseType } from '../../../../types/common';
import { getLibraryGroup } from '../../../../store/models/selectors';
import { getCurrentMovieKey } from '../../../../store/current-video/selectors';

const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): IDashboardFields => {
     let showOnboardingStep = 0;
     let showOnboarding = !getOnboarding(state).show;
     if (showOnboarding) {
       if (getCurrentMovieKey(state)) {
         showOnboardingStep++; // video already opened
       } else {
         const group = getLibraryGroup(state);
         if (!group || !group.videos || !group.videos.length
         ) {
           showOnboarding = false; // add video first
         }
       }
     }
     if (showOnboarding) {
       const phraseList = PhraseListSelectors
         .findPhrasesByTypes(state, [EVocabularyPhraseType.DEFAULT, EVocabularyPhraseType.WORD_AND_CONTEXT_SELECTED])
         .filter(phrase => phrase.fullPhrase?.trim()?.length);
       if (phraseList.length) {
         showOnboarding = false;
       }
     }
    
    if (showOnboarding) {
      const copyVideoConfirm = getCopyVideoConfirm(state);
      if (copyVideoConfirm && copyVideoConfirm.show) {
        showOnboarding = false;
      }
    }
    if (showOnboarding) {
      const editPlaylistModalGroupId = getEditPlaylistModalId(state);
      if (editPlaylistModalGroupId) {
        showOnboarding = false;
      }
    }
    const currentMovieKey = getCurrentMovieKey(state);
    const isCaptions2Columns = getShowNativeCaptionsIfAvailable(state) && getMainViewMode(state) !== EMainViewMode.PHRASES &&
      !!currentMovieKey;
    return {
      isCaptions2Columns,
      layoutType: getMainLayoutType(state),
      visible: getMainViewMode(state) === EMainViewMode.DASHBOARD,
      showLeftPanel: getMainLayoutShowLeftPanel(state) || !currentMovieKey,
      showOnboardingStep: showOnboarding ? showOnboardingStep : null,
    }
  };
}

const mapDispatchToProps = (
  dispatch: any
): IDashboardEvents => ({
    onShowLeftPanel: () => dispatch(DashboardEvents.onShowLeftPanel()),
    onShowHelp: () => dispatch(DashboardEvents.onShowHelp()),
    onShowOnboarding: (step) => dispatch(DashboardEvents.onShowOnboarding(step)),
  }
);

export const DashboardHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard);

DashboardHOC.displayName = 'DashboardHOC';
