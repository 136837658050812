import { API_URL } from '../../constants';
import { getDispatch } from '../../../spa/store';
import { getRequest, postRequest } from '../../utils/requestService';
import { IContentLibGroup, IContentLibVideo } from '../../../spa/store/contentLib/types';
import { TUserGroup, TVideo } from '../../../spa/store/models/types';

export type TContentLibSearchRequest = {
  targetLangCode: string;
  nativeLangCode: string;
  searchText: string;
  maxVideoCount: number
};

export class ContentLibRest {

  private static SEARCH_URL = API_URL + '/contentLib/search';
  private static COPY_VIDEO_URL = API_URL + '/contentLib/copyVideo';
  private static COPY_GROUP_URL = API_URL + '/contentLib/copyGroup';
  private static VIDEO_LIST_PART_URL = API_URL + '/contentLib/videoListPart';
  private static VIDEO_URL = API_URL + '/contentLib/video/';

  public static async searchGroups(filter: TContentLibSearchRequest): Promise<IContentLibGroup[]> {
    const dispatch = getDispatch();
    return await dispatch(postRequest(ContentLibRest.SEARCH_URL, filter));
  }

  public static async videoListPart(groupId: number, searchText: string, startIndex: number, count: number): Promise<IContentLibVideo[]> {
    const dispatch = getDispatch();
    return await dispatch(postRequest(ContentLibRest.VIDEO_LIST_PART_URL, {
      groupId, searchText, startIndex, count
    }));
  }

  public static async copyVideo(libVideoId: number, targetGroupId: number): Promise<TVideo | null> {
    const dispatch = getDispatch();
    return await dispatch(postRequest(ContentLibRest.COPY_VIDEO_URL, {libVideoId, targetGroupId}));
  }

  public static async copyGroup(libGroupId: number): Promise<TUserGroup> {
    const dispatch = getDispatch();
    return await dispatch(postRequest(ContentLibRest.COPY_GROUP_URL, {libGroupId}));
  }

  public static async getVideo(videoId: number): Promise<IContentLibVideo> {
    const dispatch = getDispatch();
    const url = ContentLibRest.VIDEO_URL + videoId;
    return await dispatch(getRequest(url));
  }

}