import { connect } from 'react-redux';
import { AddVideoConfirm } from '../components/AddVideoConfirm';
import { IState } from '../../../../store/types';
import { getAddVideoConfirm } from '../../../../store/general/selectors';
import { IAddVideoConfirmEvents, IAddVideoConfirmFields } from '../components/types';
import { AddVideoConfirmEvents } from './events';
import { getGroupTargetLanguage, getUserGroupById, getUserOwnGroups } from '../../../../store/models/selectors';
import { TUserGroup } from '../../../../store/models/types';

const mapStateToProps = (
	dispatch: any
) => {
	return (
		state: IState
	): IAddVideoConfirmFields => {
		const {
			show: isOpen = false,
			targetGroupId = 0,
			videoInfo,
			videoId,
			videoLangCodes,
		} = getAddVideoConfirm(state) || {};

		if (!isOpen) return {
			isOpen,
			groups: [],
			targetLang: '',
			videoAuthor: '',
			videoImg: '',
			videoLangCodes,
			videoTitle: '',
		}

		let targetLang = '';
		const group = getUserGroupById(state, targetGroupId);
		if (group) {
			targetLang = getGroupTargetLanguage(state, group)?.name || '';
		}

		let groups: TUserGroup[] = [];
		const userGroups = getUserOwnGroups(state);
		if (userGroups) {
			groups = userGroups.reduce((arr, item) => {
				const targetLang = getGroupTargetLanguage(state, item)?.code;
				if (targetLang &&
					videoLangCodes?.includes(targetLang)
				) {
					arr.push(item);
				}
				return arr;
			}, groups);
		}
		
		let videoAuthor = videoInfo?.channelTitle || '';
		let videoImg = videoInfo?.thumbnailUrl || '';
		let videoTitle = videoInfo?.title || '';

		return {
			isOpen,
			groups,
			targetLang,
			videoAuthor,
			videoImg,
			videoLangCodes,
			videoTitle,
		}
	};
}

const mapDispatchToProps = (
	dispatch: any
): IAddVideoConfirmEvents => ({
	onChange: (groupId) => dispatch(AddVideoConfirmEvents.onChange(groupId)),
	onClose: () => dispatch(AddVideoConfirmEvents.onClose()),
});

export const AddVideoConfirmHOC = connect(
	mapStateToProps,
	mapDispatchToProps
)(AddVideoConfirm);

AddVideoConfirmHOC.displayName = 'AddVideoConfirmHOC';
