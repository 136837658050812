import { IState } from '../types';
import {
  ECurrentFocusArea, EMainLayoutType, EMainViewMode, ESavePhraseMode,
  IAddVideoConfirm,
  IAddVideoOnLogin, ICopyGroupOnLogin, ICopyVideoConfirm, ICopyVideoOnLogin,
  IOnboarding,
  IOneTimeTooltips,
  IRouteOnLogin,
  ISavedVideoConfirm, ISnackbarPanel,
  ITextTranslationState, TAuthUserTranslateLang
} from './types';
import { EVideoTutorialType } from '../models/types';
import { IAppSettings } from '../../../common/rest/user/userRest';
import { getNativeCaptions } from '../videos/selectors';

export const getIsCreatePublicProfileModalOpen = (state: IState) => state.general.isCreatePublicProfileModalOpen;
export const getIsEditPlaylistModalOpen = (state: IState) => state.general.isEditPlaylistModalOpen;
export const getIsCreatePlaylistModalOpen = (state: IState) => state.general.isCreatePlaylistModalOpen;
export const getIsCreatePlaylistSaveModalOpen = (state: IState) => state.general.isCreatePlaylistSaveModalOpen;
export const isFeedbackModalOpen = (state: IState): boolean => state.general.isFeedbackModalOpen;
export const getEditPlaylistModalId = (state: IState) => state.general.editPlaylistModalId;
export const getAutoSync = (state: IState) => state.general.autoSync || false;
export const isHoverAutoPause = (state: IState) => state.general.hoverAutoPause || false;
export const getCaptionSelectStatus = (state: IState) => state.general.showCaptionSelect || false;
export const getAuthUser = (state: IState) => state.general.authUser;
export const getGoogleUser = (state: IState) => {
  const user = getAuthUser(state);
  return user && user.googleId ? user : null;
};
export const getPublicProfile = (state: IState) => state.general.authUser?.publicProfile;

export const isAuthorised = (state: IState): boolean => {
  const authUser = getAuthUser(state);
  return authUser ? Boolean(authUser.email && authUser.googleId) : false
}

export const getAutoPause = (state: IState) => state.general.autoPause;
export const getLibraryGroupId = (state: IState) => state.general.libraryGroupId;
export const getPlayerState = (state: IState) => state.general.playerState;
export const getPlayerRate = (state: IState) => state.general.playerRate;
export const getVocabularyPhraseDetailsHeight = (state: IState) => state.general.vocabularyPhraseDetailsHeight;
export const getPlayMode = (state: IState) => state.general.playMode;
export const getCurrentFocusArea = (state: IState): ECurrentFocusArea => state.general.currentFocusArea;

export const isPageLocked = (state: IState): boolean => {
  return getIsEditPlaylistModalOpen(state) || getIsCreatePlaylistModalOpen(state);
}

export const isShowNewFeaturesPopup = (state: IState): boolean => state.general.showNewFeaturesPopup;
export const isExtensionInstalled = (state: IState): boolean => state.general.extensionInstalled;
export const getSavedVideoConfirm = (state: IState): ISavedVideoConfirm => state.general.savedVideoConfirm;
export const getSnackbarPanel = (state: IState): ISnackbarPanel => state.general.snackbarPanel;
export const getAddVideoOnLogin = (state: IState): IAddVideoOnLogin | undefined => state.general.addVideoOnLogin;
export const getCopyVideoOnLogin = (state: IState): ICopyVideoOnLogin | undefined => state.general.copyVideoOnLogin;
export const getCopyGroupOnLogin = (state: IState): ICopyGroupOnLogin | undefined => state.general.copyGroupOnLogin;
export const isShowPinExtensionPopup = (state: IState): boolean => state.general.showPinExtensionPopup;
export const isShowOpenPublicProfilePopup = (state: IState): boolean => state.general.showOpenPublicProfilePopup;
export const getTextTranslation = (state: IState): ITextTranslationState => state.general.textTranslation;
export const isShowOneTimeTooltipPhrasesEdit = (state: IState): boolean => state.general.oneTimeTooltip.showPhrasesEditTooltip;
export const isShowNativeLanguageTooltip = (state: IState): boolean => state.general.oneTimeTooltip.showNativeLanguageTooltip;
export const getOneTimeTooltip = (state: IState): IOneTimeTooltips => state.general.oneTimeTooltip;
export const isShowTelegramBotPopup = (state: IState): boolean => state.general.showTelegramBotPopup;
export const getVideoTutorialMode = (state: IState): EVideoTutorialType | undefined => state.general.videoTutorialMode;
export const isShowNewVideoPopup = (state: IState): boolean => state.general.showNewVideoPopup;
export const getMainViewMode = (state: IState): EMainViewMode => state.general.mainViewMode;
export const isPhrasesExist = (state: IState): boolean => state.general.phrasesExist;
export const getFlashVideoId = (state: IState): string | undefined => state.general.flashVideoId;
export const getFlashPhraseId = (state: IState): number | undefined => state.general.flashPhraseId;

export const getMainLayoutType = (state: IState): EMainLayoutType => EMainLayoutType.COLUMNS_4; // пока один тип
export const getMainLayoutShowLeftPanel = (state: IState): boolean => state.general.mainLayout.showLeftPanel;
export const getShowNativeCaptions = (state: IState): boolean => state.general.showNativeCaptions;
export const getShowNativeCaptionsIfAvailable = (
  state: IState,
): boolean => {
  return getShowNativeCaptions(state) && !!getNativeCaptions(state).length;
}

export const getAuthUserTranslateLangs = (state: IState): TAuthUserTranslateLang[] => getAuthUser(state)?.translateLangs || [];
export const getAuthUserTranslateLangsMaxOrder = (state: IState): number => {
  let result = 0;
  getAuthUserTranslateLangs(state).forEach(l => {
    if (l.orderNum >= result) {
      result = l.orderNum + 1;
    }
  })
  return result;
}

export const getOnboarding = (state: IState): IOnboarding => state.general.onboarding;
export const getCopyVideoConfirm = (state: IState): ICopyVideoConfirm => state.general.copyVideoConfirm;
export const getAppSettings = (state: IState): IAppSettings => state.general.appSettings;
export const isPauseOnNote = (state: IState): boolean => state.general.pauseOnNote;
export const getAddVideoConfirm = (state: IState): IAddVideoConfirm => state.general.addVideoConfirm;
export const getSavePhraseMode = (state: IState): ESavePhraseMode => state.general.savePhraseMode;
export const isShowInstallExtensionWarningPopup = (state: IState): boolean => state.general.showInstallExtensionWarningPopup;
export const getRouteOnLogin = (state: IState): IRouteOnLogin => state.general.routeOnLogin;
