import {
  ADD_VIDEO,
  ADD_VIDEO_PHRASE_ACTION,
  DELETE_VIDEO_PHRASE_ACTION, ISetVideoTeacherAction,
  IVideoItem,
  IVideosActions,
  IVideosState,
  RESET_VIDEO,
  SAVE_PHRASE_NOTE,
  SET_PHRASE_TIME_SCALES,
  SET_PHRASES_NOTES_LISTS_ACTION,
  SET_VIDEO_CAPTIONS,
  SET_VIDEO_CAPTIONS_EMPTY,
  SET_VIDEO_TEACHER_ACTION, SET_VIDEO_TEACHER_SHOW_NOTE_ID_ACTION,
  SET_VIDEO_TEACHER_USER_ACTIVE_ID_ACTION,
  UPDATE_PHRASE_NOTE,
  UPDATE_VIDEO_PHRASE_ACTION
} from './types';
import { Reducer } from 'redux';
import { IPhraseNote } from '../../types/common';

const initialState: IVideosState = {
  author: '',
  title: '',
  videoId: '',
  captions: {},
  phraseNotes: [],
  phrases: [],
  thumbnail: '',
  captionsEmpty: false,
  phraseNoteTimeScales: [],
  teachers: {
    list: [],
    activeId: 0,
    showPhraseNoteId: 0
  }
};

export const videoReducer: Reducer<IVideosState, IVideosActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ADD_VIDEO_PHRASE_ACTION:
      return {...state, phrases: [...state.phrases, {...action.phrase}]}
    case UPDATE_VIDEO_PHRASE_ACTION:
      return {...state, phrases: state.phrases.map(p => p.id == action.phrase.id ? {...action.phrase} : p) }
     case DELETE_VIDEO_PHRASE_ACTION:
       return {...state, phrases: state.phrases.filter(p => p.id !== action.phraseId)}
    case ADD_VIDEO:
      return {...state, ...action.video}
    case RESET_VIDEO:
      return {...initialState}
    case SET_VIDEO_CAPTIONS:
      return {...state, captions: {
        ...state.captions, [action.code]: [...action.captions]
      }}
    case SAVE_PHRASE_NOTE:
      return savePhraseNote(state, action.note);
    case UPDATE_PHRASE_NOTE:
      return updatePhraseNote(state, action.note);
    case SET_VIDEO_CAPTIONS_EMPTY:
      return {...state, ...{captionsEmpty: action.captionsEmpty}}
    case SET_PHRASE_TIME_SCALES:
      return {...state, ...{phraseNoteTimeScales: action.phraseNoteTimeScales}}
    case SET_PHRASES_NOTES_LISTS_ACTION:
      return {...state, ...{
          phrases: action.phrases,
          phraseNotes: action.notes
      }}
    case SET_VIDEO_TEACHER_USER_ACTIVE_ID_ACTION:
      return {
        ...state,
         teachers: {
           ...state.teachers,
           activeId: action.id
         }
      }
    case SET_VIDEO_TEACHER_SHOW_NOTE_ID_ACTION:
      return {
        ...state,
        teachers: {
          ...state.teachers,
          showPhraseNoteId: action.id
        }
      }
    case SET_VIDEO_TEACHER_ACTION:
      return setTeacher(state, action);
    default:
      return state;
  }
};


const savePhraseNote = (state: IVideosState, note: IPhraseNote): IVideoItem => {
  const index = state.phraseNotes.findIndex(n => n.id === note.id);
  if (index >= 0) {
    return updatePhraseNote(state, note);
  }
  return {
    ...state,
    phraseNotes: [
      ...state.phraseNotes,
      {...note},
    ],
  };
}

const updatePhraseNote = (
  state: IVideosState,
  note: Partial<IPhraseNote>
): IVideoItem => {
  if (!note.id) return state;

  const phraseNotes = [...state.phraseNotes];
  const index = phraseNotes.findIndex(n => n.id === note.id);
  if (index >= 0) {
    phraseNotes[index] = {
      ...phraseNotes[index],
      ...note,
    };
  }
  return {
    ...state,
    phraseNotes,
  };
}

const setTeacher = (state: IVideosState, action: ISetVideoTeacherAction): IVideosState => {
  const index = state.teachers.list.findIndex(t => t.user?.id === action.userId);
  if (index < 0)
    return state;
  const resultState = {...state};
  const teacher = {...resultState.teachers.list[index]};
  resultState.teachers.list[index] = {
    ...teacher,
    ...action.data
  }
  return resultState;
}