export enum EPhraseDetailsCustomPromptType {
  PHRASE, CONTEXT, TARGET_LANG, NATIVE_LANG, ENTIRE_SCRIPT
}

export interface IPhraseDetailsCustomPromptVar {
  name: string;
  wrapQuotes: boolean;
  originalName: string;
  color: string;
  type: EPhraseDetailsCustomPromptType;
  visible: boolean
}

export class PhraseDetailsCustomPromptVars {

  private static CONTEXT_VAR = '$Context';

  public static ENTIRE_SCRIPT_PARAM = 'entireScript';

  private static VARS: IPhraseDetailsCustomPromptVar[] = [
    {name: '$Phrase', wrapQuotes: true, originalName: '{text}', color: '#dcb8ff', type: EPhraseDetailsCustomPromptType.PHRASE, visible: true},
    {name: PhraseDetailsCustomPromptVars.CONTEXT_VAR, wrapQuotes: true, originalName: '{context}', color: '#CEE2FD', type: EPhraseDetailsCustomPromptType.CONTEXT, visible: true},
    {name: '$TargetLanguage', wrapQuotes: false, originalName: '{targetLang}', color: '#EFEBFE', type: EPhraseDetailsCustomPromptType.TARGET_LANG, visible: true},
    {name: '$NativeLanguage', wrapQuotes: false, originalName: '{nativeLang}', color: '#EFEBFE', type: EPhraseDetailsCustomPromptType.NATIVE_LANG, visible: true},
    {name: '$EntireScript', wrapQuotes: false,
      originalName: '{'+PhraseDetailsCustomPromptVars.ENTIRE_SCRIPT_PARAM+'}', color: '#EFEBFE', type: EPhraseDetailsCustomPromptType.ENTIRE_SCRIPT, visible: false},
  ]

  public static getList(all: boolean = false): IPhraseDetailsCustomPromptVar[] {
    return all ? PhraseDetailsCustomPromptVars.VARS : PhraseDetailsCustomPromptVars.VARS.filter(v => v.visible);
  }

  public static getVarByType(type: EPhraseDetailsCustomPromptType): IPhraseDetailsCustomPromptVar | undefined {
    return PhraseDetailsCustomPromptVars.getList(true).find(v => v.type === type)
  }

  public static isContextVarExist(prompt: string): boolean {
    return !!prompt && prompt.includes(PhraseDetailsCustomPromptVars.CONTEXT_VAR);
  }
}



