import { AppThunk } from '../../../../../store/types';
import { ContentLibEffects } from '../../../../../effects/contentLibEffects';
import { ContentLibActions } from '../../../../../store/contentLib/actions';
import { ContentLibEvents } from '../../effects';

export class ContentLibGroupInfoEvents {

  public static onCopyGroup(groupId: number): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      const group = await ContentLibEffects.copyGroup(groupId);
      dispatch(ContentLibActions.setCopyGroupConfirm({show: true, groupId: group.id}));
    }
  }

  public static onSelectGroup(groupId: number): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      dispatch(ContentLibEvents.openGroup(groupId));
    }
  }


}