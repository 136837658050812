import { Navigator } from '../../../../../effects/navigator';
import { AppThunk } from '../../../../../store/types';
import { UserProfileRest } from '../../../../../../common/rest/user/userProfileRest';

export class PublicProfileEvents {

	public static onClickLogo(): AppThunk {
		return async (
			dispatch,
			getState
		) => {
			Navigator.openDefault();
		}
	}

	public static onLoad(userId: number): AppThunk {
		return async (
			dispatch,
			getState
		) => {
			return await UserProfileRest.get(userId);
		}
	}
}