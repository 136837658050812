import { Reducer } from 'redux';
import {
    ADD_GROUP,
    ADD_VIDEO_IN_GROUP,
    IModelsActions,
    IModelsState,
    IMoveVideoToGroup,
    IPhraseDetailsTabDelete,
    IPhraseDetailsTabSave, IRefreshGroupAction,
    ISetGroupsVideoLangList,
    IUserGptPromptDelete,
    IUserGptPromptSave,
    MOVE_VIDEO_TO_GROUP,
    PHRASE_DETAILS_TAB_DELETE,
    PHRASE_DETAILS_TAB_SAVE, REFRESH_GROUP,
    REMOVE_GROUP,
    REMOVE_VIDEO_FROM_GROUP,
    SET_ACTIVE_GROUP,
    SET_DEFAULT_GPT_PROMPTS,
    SET_GROUP_COUNT,
    SET_GROUPS,
    SET_GROUPS_VIDEO_LANG_LIST,
    SET_LANGS_ACTION,
    SET_LANGUAGES,
    SET_PHRASE_DETAILS_TABS,
    SET_USER_GPT_PROMPTS, SET_USER_ROLES_ACTION,
    SET_VIDEO_TUTORIAL_LIST,
    TUserGroup,
    UPDATE_GROUP,
    USER_GPT_PROMPT_DELETE,
    USER_GPT_PROMPT_SAVE
} from './types';
import { PhraseDetailsTabEffects } from '../../effects/phraseDetailsTabEffects';

const initialState: IModelsState = {
    groups: [],
    languages: [],
    activeGroupId: null,
    defaultGptPrompts: [],
    userGptPrompts: [],
    phraseDetailsTabs: PhraseDetailsTabEffects.DEFAULT_TABS,
    videoTutorials: [],
    langs: [],
    userRoles: []
};

export const modelsReducer: Reducer<IModelsState, IModelsActions> = (
    state = initialState,
    action
) => {
    switch (action.type) {
        case SET_GROUPS:
            return {
                ...state,
                groups: action.groups
            };
        case SET_LANGUAGES:
            return {
                ...state,
                languages: action.languages
            };
        case SET_ACTIVE_GROUP:
            return {
                ...state,
                activeGroupId: action.activeGroupId ? +action.activeGroupId : null
            };
        case ADD_GROUP:
            return {
                ...state,
                groups: [
                    ...state.groups,
                    action.group
                ]
            };
        case REMOVE_GROUP:
            const groups = state.groups.filter(group => group.id !== action.groupId);
            return {
                ...state,
                groups
            };
        case UPDATE_GROUP:
            return {
                ...state,
                groups: state.groups.map((group) => {
                    if (group.id === action.groupId) {
                        return {
                            ...group,
                            name: action.name,
                            languages: action.languages ? [...action.languages] : [...group.languages]
                        }
                    } else {
                        return group;
                    }
                })
            };
        case REFRESH_GROUP:
            return refreshGroup(state, action);
        case ADD_VIDEO_IN_GROUP:
            return {
                ...state,
                groups: state.groups.map(group => {
                    if (group.id === action.groupId) {
                        group.videos = [action.video, ...group.videos];
                    }
                    return group;
                })
            };
        case REMOVE_VIDEO_FROM_GROUP:
            return {
                ...state,
                groups: state.groups.map(group => {
                    if (group.id === action.groupId) {
                        group.videos = group.videos.filter((video) => {
                            return video.videoKey !== action.videoKey;
                        })
                    }
                    return group;
                })
            }
        case MOVE_VIDEO_TO_GROUP:
            return {
                ...state,
                ...moveVideoToGroup(state, action)
            }
        case SET_GROUP_COUNT:
            return {
                ...state,
                groups: state.groups.map(group => {
                    if (group.id === action.groupId) {
                        group.phrasesCount = action.count;
                    }
                    return group;
                })
            }
        case SET_GROUPS_VIDEO_LANG_LIST:
            return setGroupsVideoLangList(state, action);
        case SET_USER_GPT_PROMPTS:
            return {
                ...state,
                userGptPrompts: [...action.prompts]
            }
        case SET_DEFAULT_GPT_PROMPTS:
            return {
                ...state,
                defaultGptPrompts: [...action.defaultPrompts]
            }
        case USER_GPT_PROMPT_SAVE:
            return userGptPromptSave(state, action);
        case USER_GPT_PROMPT_DELETE:
            return userGptPromptDelete(state, action);
        case SET_PHRASE_DETAILS_TABS:
            return {
                ...state,
                phraseDetailsTabs: [...action.tabs]
            }
        case PHRASE_DETAILS_TAB_SAVE:
            return phraseDetailsTabSave(state, action);
        case PHRASE_DETAILS_TAB_DELETE:
            return phraseDetailsTabDelete(state, action);
        case SET_VIDEO_TUTORIAL_LIST:
            return {...state, videoTutorials: [...action.videoTutorials]}
        case SET_LANGS_ACTION:
            return {...state, langs: [...action.langs]}
        case SET_USER_ROLES_ACTION:
            return {...state, userRoles: [...action.userRoles]}
        default:
            return state;
    }
};

const moveVideoToGroup = (state: IModelsState, action: IMoveVideoToGroup): IModelsState => {
    if (action.fromGroupId === action.toGroupId)
        return {...state};
    const groups: TUserGroup[] = [...state.groups];
    const fromGroup = groups.find(g => g.id === action.fromGroupId);
    const toGroup = groups.find(g => g.id === action.toGroupId);
    if (!fromGroup || !toGroup)
        return {...state};
    const video = fromGroup.videos.find(v => v.videoKey === action.videoId);
    if (!video)
        return {...state};

    fromGroup.videos = fromGroup.videos.filter((video) => {
        return video.videoKey !== action.videoId;
    })
    toGroup.videos = [video, ...toGroup.videos];
    return {
        ...state,
        groups
    }
}

const setGroupsVideoLangList = (state: IModelsState, action: ISetGroupsVideoLangList) => {
    const groups: TUserGroup[] = [...state.groups];
    groups.forEach(g => {
        const video = g.videos.find(v => v.videoKey === action.videoId);
        if (video) {
            video.info.langList = [...action.langList];
            video.info.langsChecked = true;
        }
    })
    return {
        ...state,
        groups
    }
}

const userGptPromptSave = (state: IModelsState, action: IUserGptPromptSave) => {
    const userGptPrompts = [...state.userGptPrompts];
    const savePrompt = {...action.prompt};
    const index = userGptPrompts.findIndex(p => p.id === savePrompt.id);
    if (index >= 0) {
        userGptPrompts[index] = savePrompt;
    } else {
        userGptPrompts.push(savePrompt);
    }
    return {
        ...state,
        userGptPrompts
    }
}

const userGptPromptDelete = (state: IModelsState, action: IUserGptPromptDelete) => {
    const userGptPrompts = [...state.userGptPrompts];
    const index = userGptPrompts.findIndex(p => p.id === action.id);
    if (index >= 0) {
        userGptPrompts.splice(index, 1);
    }
    return {
        ...state,
        userGptPrompts
    }
}


const phraseDetailsTabSave = (state: IModelsState, action: IPhraseDetailsTabSave) => {
    const phraseDetailsTabs = [...state.phraseDetailsTabs];
    const saveTab = {...action.tab};
    const index = action.replaceIndex === undefined ?
      phraseDetailsTabs.findIndex(t => t.id === saveTab.id) :
      action.replaceIndex;
    if (index >= 0) {
        phraseDetailsTabs[index] = saveTab;
    } else {
        phraseDetailsTabs.push(saveTab);
    }
    return {
        ...state,
        phraseDetailsTabs
    }
}

const phraseDetailsTabDelete = (state: IModelsState, action: IPhraseDetailsTabDelete) => {
    const phraseDetailsTabs = [...state.phraseDetailsTabs];
    const index = phraseDetailsTabs.findIndex(t => t.id === action.id);
    if (index >= 0) {
        phraseDetailsTabs.splice(index, 1);
    }
    return {
        ...state,
        phraseDetailsTabs
    }
}

const refreshGroup = (state: IModelsState, action: IRefreshGroupAction): IModelsState => {
    const index = state.groups.findIndex(g => g.id === action.group.id);
    if (index < 0) return state;
    const groups: TUserGroup[] = [...state.groups];
    groups[index] = {...action.group};
    return {
        ...state,
        ...{
            groups
        }
    }
}