import { IPhraseSelectResult } from '../../../../../effects/phrase/utils/phrase-select-preparator';
import { IPhraseNote } from '../../../../../types/common';

export interface IPhraseNotesFields {
  active: boolean,
  phraseId: number,
  text: string,
  processing: boolean,
  canEdit: boolean,
  pause: boolean
  selectResult?: IPhraseSelectResult,
  clickedCaptionIndex: number,
  isEdit?: boolean;
  authorId?: number,
  authorName?: string
}

export interface IPhraseNotesEvents {
  onSave: (note: Partial<IPhraseNote>) => void;
  onUpdate: () => void;
  onClickAuthor: () => void;
  onLoadImage: (data: string) => void;
}

export interface IPhraseNotesProps extends
  IPhraseNotesFields,
  IPhraseNotesEvents
{}

export const PHRASE_DETAILS_NOTES_CLASS_NAME = 'phraseDetailsNotes';