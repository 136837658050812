import React from 'react';
import { IPhraseTranslationProps } from './types';
import { Button, CircularProgress, IconButton, Menu, Stack } from '@mui/material';
import { theme } from '../../../../../../common/styles/theme';
import { UserLangsTranslationColors } from '../../../PhraseDetailsTabsEditor/PhraseDetailsTabTranslation/helpers/userLangsTranslationColors';
import { TextWithSpeakerHOC } from '../../../../../../common/components/TextWithSpeaker/hocs/TextWithSpeakerHOC';
import { CLASS_ONBOARDING_NOEVENT, CLASS_ONBOARDING_TRIGGER } from '../../../Onboarding/constants';
import { PhraseTranslationSettingsHOC } from '../../../PhraseDetailsTabsEditor/PhraseDetailsTabTranslation/PhraseTranslationSettings/hocs/PhraseTranslationSettingsHOC';
import { SettingsIcon } from '../../../../../../../icons/SettingsIcon';
import { CopyNote } from '../../../../../../../icons/CopyNote';
import { DeviceContext } from '../../../../../../common/contexts/DeviceContext';
import { Modal } from '../../../../../../common/components/Modal/Modal';

export const PhraseTranslation: React.FC<IPhraseTranslationProps> = ({
	active,
	text,
	translateResults,
	processing,
	translateLangs,
	onLoad,
	onRefresh,
	onCopyNote
}) => {

	const {isMobile} = React.useContext(DeviceContext);

	const [settingsEl, setSettingsEl] = React.useState<null | HTMLElement>(null);
	const [translateKey, setTranslateKey] = React.useState<string>(null);

	const handleSettingsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setSettingsEl(event.currentTarget);
	};
	const handleSettingsClose = () => {
		setSettingsEl(null);
	};

	React.useEffect(() => {
		if (active && !settingsEl) {
			setTranslateKey(text + '|' + translateLangs.map(l => l.code).join('-'));
		}
	}, [active, text, translateLangs, settingsEl]);

	React.useEffect(() => {
		onLoad();
	}, [translateKey]);


	return (processing
		? <Stack
			sx={{
				width: 1,
				mt: theme.spacing(3),
				alignItems: 'center',
			}}
		>
			<CircularProgress size={theme.spacing(3)} />
		</Stack>
		: !translateResults ? null :
			<Stack
				sx={{
					position: 'relative',
					width: 1,
					pl: theme.spacing(3),

					'.mobile &': {
						p: `${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(1)} ${theme.spacing(3)}`,
					},
				}}
			>
				<Stack
					className={`${CLASS_ONBOARDING_TRIGGER} ${CLASS_ONBOARDING_TRIGGER}--step6`}
					gap={theme.spacing(0.75)}
				>
					{translateResults.map((result, index) => {
						return (
							<Stack
								direction={'row'}
								key={result.langCode}
							>
								<TextWithSpeakerHOC
									key={result.langCode}
									textSx={{
										color: UserLangsTranslationColors.getColor(result.langCode as string),
									}}
									lang={String(result.langCode)}
									text={result.success ? result.result : 'Unknown error'}
								/>
								{!result.success &&
									<Button
										sx={{
											ml: theme.spacing(1),
										}}
										onClick={() => onRefresh(result.langCode as string)}
									>
										Refresh
									</Button>
								}
							</Stack>
						)
					})}
				</Stack>

				<Stack
					flexDirection={'row'}
					gap={theme.spacing(.5)}
					justifyContent={'space-between'}
					sx={{
						mt: theme.spacing(1),
					}}
				>
					<Button
						variant={'text'}
						sx={{
							height: theme.spacing(4),
							p: theme.spacing(1),
							fontSize: theme.typography.fontSize,
							color: theme.palette.grey[400],

							'.MuiButton-startIcon': {
								ml: theme.spacing(-1),
								mr: theme.spacing(.5),

								'.MuiSvgIcon-root': {
									width: theme.spacing(2),
									height: theme.spacing(2),
									
								},
							},
						}}
						startIcon={<CopyNote />}
						onClick={onCopyNote}
					>
						Copy to note
					</Button>
					
					<IconButton
						className={`${CLASS_ONBOARDING_NOEVENT}`}
						sx={{
							width: theme.spacing(4),
							height: theme.spacing(4),
							mr: theme.spacing(-.5),
							color: theme.palette.grey[400],

							'.MuiSvgIcon-root': {
								width: theme.spacing(2),
								height: theme.spacing(2),
								
							},
						}}
						onClick={handleSettingsClick}
					>
						<SettingsIcon />
					</IconButton>
					{isMobile
						? <Modal
							onClose={handleSettingsClose}
							open={!!settingsEl}
							isMobile={isMobile}
							>
							<PhraseTranslationSettingsHOC />
						</Modal>
						: <Menu
							anchorEl={settingsEl}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'right',
							}}
							transformOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
							open={!!settingsEl}
							onClose={handleSettingsClose}
							sx={{
								'& .MuiPaper-root': {
									width: theme.spacing(35),
									mt: theme.spacing(1),
									boxShadow: theme.shadows[16],
								},
							}}
						>
							<PhraseTranslationSettingsHOC />
						</Menu>
					}
				</Stack>

			</Stack>
	);

}
