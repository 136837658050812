import React, { useContext, useRef, useState } from 'react';
import { IPhraseListProps, phrasesPartLoadSize } from './types';
import { theme } from '../../../../../common/styles/theme';
import { Box, CircularProgress, IconButton, List, MenuItem, Select, Stack } from '@mui/material';
import { EPhraseListMode, EPhraseListPlayMode } from '../../../../store/phrase-list/types';
import { PlayIcon } from '../../../../../../icons/PlayIcon';
import { PauseIcon } from '../../../../../../icons/PauseIcon';
import { Loader } from '../../../../../common/components/Loader/Loader';
import { VocabularyPhraseHOC } from '../../Vocabulary/vocabulary-phrase/hocs/VocabularyPhraseHOC';
import { Text } from '../../../../../common/components/Text/Text';
import { PhraseListContext } from '../../Vocabulary/PhraseListDetailsColumn/components/types';
import { Paper } from '../../../../../common/components/Paper/Paper';
import { PhrasePlaySettingsHOC } from '../../PhrasePlaySettings/hocs/PhrasePlaySettingsHOC';

import { IPlayerManagerListener } from '../../../../effects/player/manager/playerManagerListener';
import { PlayerManager } from '../../../../effects/player/manager/playerManager';
import { ArrowIcon } from '../../../../../../icons/ArrowIcon';
import { EMainViewMode } from '../../../../store/general/types';
import { CLASS_ONBOARDING_BLUR, CLASS_ONBOARDING_NOEVENT, CLASS_ONBOARDING_TRIGGER } from '../../Onboarding/constants';
import { DeviceContext } from '../../../../../common/contexts/DeviceContext';
import { TeacherListHOC } from '../../Teacher/TeacherList/hocs/TeacherListHOC';
import { UserTeacherModeSwitchHOC } from '../../Teacher/UserTeacherModeSwitch/hocs/UserTeacherModeSwitchHOC';
import { CLASS_PLAY_CONTROL } from '../../Video/components/constants';
import { AddAnnotationButton } from '../AddAnnotationButton/AddAnnotationButton';

export const PhraseList: React.FC<IPhraseListProps> = ({
  groupId,
  videoId,
  phraseList,
  phrasesPlayStatus,
  phrasesExist,
  activeListModeId,
  listModes,
  lazyLoadingPhrases,
  mainViewMode,
  isTeacherMode,
  onPlay,
  onStop,
  onChangeListMode,
  onLoadPhrases,
  onAppendPhrases,
  onTargetCaptionIndexChange,
  onKeyDown,
  onKeyUp,
  onClickTitle,
  onAddAnnotation,
}) => {
  const {isMobile, scrollWidth = 0 } = React.useContext(DeviceContext);
  const context = useContext(PhraseListContext);

  const isPhrasesView = mainViewMode === EMainViewMode.PHRASES;

  const [allPhrasesLoaded, setAllPhrasesLoaded] = useState<boolean>(false);
  const pauseSelectRef = React.useRef<HTMLElement | null>(null);
  const phraseSelectRef = React.useRef<HTMLElement | null>(null);
  const allPhrasesLoadedRef = useRef(allPhrasesLoaded);
  allPhrasesLoadedRef.current = allPhrasesLoaded;

  const [phrasesLoadCount, setPhrasesLoadCount] = useState<number>(0);
  const phrasesLoadCountRef = useRef(phrasesLoadCount);
  phrasesLoadCountRef.current = phrasesLoadCount;

  const phraseListRef = React.useRef<HTMLElement>(null);

  React.useEffect(() => {
    if (groupId && videoId) {
      setPhrasesLoadCount(0);
      setAllPhrasesLoaded(true);
      const loadCount = lazyLoadingPhrases ? phrasesPartLoadSize : 0;
      onLoadPhrases(loadCount).then(allLoaded => {
        setAllPhrasesLoaded(allLoaded);
        setPhrasesLoadCount(phrasesPartLoadSize);
      });
    }
  }, [groupId, videoId, activeListModeId]);

  const onListScrollToBottom = async () => {
    if (lazyLoadingPhrases && !allPhrasesLoadedRef.current) {
      const allLoaded = await onAppendPhrases(phrasesLoadCountRef.current, phrasesPartLoadSize);
      if (allLoaded) {
        setAllPhrasesLoaded(true);
      }
      setPhrasesLoadCount(phrasesLoadCountRef.current + phrasesPartLoadSize);
    }
  }

  const handleChangeListMode = ({ target }: any) => {
    onChangeListMode(target.value);
  };

  function handleClose(
    isPauseSelect: boolean
  ) {
    const el = isPauseSelect ? pauseSelectRef.current : phraseSelectRef.current;
    if (el) {
      const selectList = el.querySelector('.MuiSelect-select') as HTMLElement;
      if (selectList) setTimeout(() => selectList.blur(), 0);
    }
  }

  const minHeight = context?.height || 100;

  const paperStyle = {
    position: 'relative',
    width: 1,
    flex: '1 1 25%',
    minHeight: theme.spacing(20),
    maxHeight: `100%`,
    // maxHeight: `calc(100% - ${minHeight}px)`, // уже нет режима .columns-3
    p: `${theme.spacing(1)} ${theme.spacing(1)} 0`,
    display: 'flex',
    justifyContent: 'center',

    '.columns-4 &': {
      flexBasis: '25%',
      // maxHeight: `100%`,
    },

    '.phrases-view &': {
      // maxHeight: `100%`,
    },

    '.mobile &': {
      p: theme.spacing(1),
    },
  };

  const playerListener: IPlayerManagerListener = {
    onTargetIndexChange(index: number): void {
      onTargetCaptionIndexChange(index);
    }
  }

  React.useEffect(() => {
    PlayerManager.getInstance().addListener(playerListener);
    return () => {
      PlayerManager.getInstance().removeListener(playerListener);
    };
  }, [phraseList, videoId]);

  const [showSelectListMode, setShowSelectListMode] = useState(false);
  React.useEffect(() => {
    setShowSelectListMode(
      isPhrasesView ||
      activeListModeId !== String(EPhraseListMode.CURRENT_VIDEO)
    );
  }, []);

  const selectVideo = showSelectListMode && (
    <Select
      variant={'filled'}
      ref={phraseSelectRef}
      onClose={() => handleClose(false)}
      onChange={handleChangeListMode}
      value={activeListModeId}
      disabled={!phrasesExist}
      fullWidth
    >
      {listModes.map((listMode) => {
        return (
          <MenuItem
            key={listMode.id}
            value={listMode.id}
          >
            {listMode.title}
          </MenuItem>
        )
      })}
    </Select>
  );

  let prevId = 0;

  return (
      <Paper
        className={`
          phrases-list
          ${CLASS_ONBOARDING_BLUR}
        `}
        sx={paperStyle}
        onKeyUp={onKeyUp}
        onKeyDown={onKeyDown}
      >
          <Stack
            className={`shortcuts-listener shortcuts-listener--phrases`}
            sx={{
              position: 'relative',
              width: '100%',

              '&.shortcuts-listener--active': {
                ':after': {
                  content: '""',
                  position: 'absolute',
                  inset: '-22px',
                  border: `2px solid ${theme.palette.primary.main}`,
                  borderRadius: '10px',
                }
              }
            }}
            gap={theme.spacing()}
          >
            {!isMobile &&
              <Stack
                className={CLASS_ONBOARDING_NOEVENT}
                direction='row'
                gap={theme.spacing(2)}
                sx={{
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                {isPhrasesView
                  ? selectVideo
                  : <IconButton
                    color={'secondary'}
                    onClick={onClickTitle}
                    sx={{
                      width: 'auto',
                      p: 0,
                      background: 'none !important',

                      '.MuiSvgIcon-root': {
                        color: theme.palette.grey[400],
                      },
                    }}
                  >
                    <Text variant='h6' fontWeight={600}>
                      Saved Phrases
                    </Text>

                    <ArrowIcon />
                  </IconButton>
                }
                
                <Stack direction='row' gap={theme.spacing()}>
                  <IconButton
                    className={CLASS_PLAY_CONTROL}
                    onClick={EPhraseListPlayMode.STOP === phrasesPlayStatus ? onPlay : onStop}
                    color={'secondary'}
                    disabled={!phrasesExist}
                    sx={{
                      borderRadius: '50%',
                      border: `${theme.spacing(0.25)} solid ${theme.palette.grey[200]}`,
                      background: 'none',
                      '&:hover': {
                        background: theme.palette.grey[50],
                      },
                      '&:active': {
                        bgcolor: 'none',
                        color: theme.palette.primary[400]
                      },
                      '&:focus': {
                        '&:after': {
                          borderRadius: '50%'
                        }
                      },
                      '.MuiSvgIcon-root': {
                        width: theme.spacing(2),
                        height: theme.spacing(2),
                      }
                    }}
                  >
                    {EPhraseListPlayMode.STOP === phrasesPlayStatus ?
                      <PlayIcon sx={{
                        ml: theme.spacing(0.5),
                        pointerEvents: 'none',
                      }} /> : <PauseIcon />}
                  </IconButton>

                  <PhrasePlaySettingsHOC />
                  
                </Stack>
              </Stack>
            }

            <UserTeacherModeSwitchHOC />

            {!isPhrasesView && !isMobile &&
              <Box
                className={CLASS_ONBOARDING_NOEVENT}
              >
                {selectVideo}
              </Box>
            }

            
            <List
              className={`phrase-list`}
              sx={{
                position: 'static',
                mr: theme.spacing(-2),
                overflowY: 'scroll',
                overflowX: 'hidden',
                '.onboarding-active &': {
                  overflowY: 'hidden',
                }
              }}
              ref={phraseListRef}
            >
              <Box
                sx={{
                  maxWidth: `calc(100% - ${parseInt(theme.spacing(2), 10) - scrollWidth}px)`,
                }}
              >
                <TeacherListHOC />

                <Box
                  className={`${CLASS_ONBOARDING_TRIGGER} ${CLASS_ONBOARDING_TRIGGER}--step8`}
                  sx={{
                    position: 'absolute',
                    left: theme.spacing(1),
                    right: theme.spacing(1),
                    height: '1px',
                    mt: theme.spacing(5),
                    pointerEvents: 'none',
                  }}
                />
                  {isTeacherMode && !phraseList.length &&
                    <AddAnnotationButton
                      onClick={() => onAddAnnotation()}
                    />
                  }
                  {!!phraseList.length && <>
                    {phraseList.map((phraseId, idx) => {
                        let _prevId = prevId;
                        prevId = phraseId;
                        return <>
                          {isTeacherMode &&
                            <AddAnnotationButton
                              isCollapsed={!!idx}
                              onClick={() => onAddAnnotation(_prevId, phraseId)}
                            />
                          }
                          <VocabularyPhraseHOC
                            phraseId={phraseId}
                            key={phraseId}
                          />
                        </>
                    })}
                    {isTeacherMode &&
                      <AddAnnotationButton
                        onClick={() => onAddAnnotation(prevId)}
                      />
                    }

                    {lazyLoadingPhrases && !allPhrasesLoaded &&
                      <Loader onShow={onListScrollToBottom}>
                        <CircularProgress size='small' sx={{ alignSelf: 'center' }}/>
                      </Loader>
                    }
                  </>}
              </Box>
            </List>


          </Stack>

          <Box
            className={`${CLASS_ONBOARDING_TRIGGER} ${CLASS_ONBOARDING_TRIGGER}--step8-highlight`}
            sx={{
              position: 'absolute',
              pointerEvents: 'none',
              left: theme.spacing(-.5),
              right: theme.spacing(-.5),
              top: theme.spacing(-.5),
              bottom: theme.spacing(-.5),
              borderRadius: theme.spacing(1),
            }}
          />
      </Paper>
  );
};
