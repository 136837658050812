import React from 'react';
import { ISavedVideoConfirmNotifyProps } from './types';
import { Box, Button, CircularProgress, FormGroup, MenuItem, Select, SelectChangeEvent, Stack, TextField } from '@mui/material';
import { theme } from '../../../../../../common/styles/theme';
import { Text } from '../../../../../../common/components/Text/Text';
import { getShortTitle } from "../../helpers/getShortTitle";
import { VideoCard } from '../../../VideoCard/VideoCard';
import { createGroupNameByLangs } from '../../../../../store/models/selectors';
import { Divider } from '../../../../../../common/components/Divider/Divider';


export const SavedVideoConfirmNotify: React.FC<ISavedVideoConfirmNotifyProps> = ({
	videoAuthor,
	videoImg,
	videoLangCodes,
	videoTitle,
	groupId,
	groupTitle,
	groups,
	newListName,
	onSelectGroup,
}) => {

	const [progress, setProgress] = React.useState<boolean>(false);
	const [selected, setSelected] = React.useState(-1);
	const [_newListName, setNewListName] = React.useState(newListName);
	const [isNewList, setIsNewList] = React.useState(false);

	const onChangeHandle = (groupId: number, newListName?: string) => {
		setSelected(groupId);
		setProgress(true);
		try {
			onSelectGroup(groupId, newListName);
		} catch(e) {
			setProgress(false);
		}
	};

	const onChangeSelect = (event: SelectChangeEvent) => {
		const groupId = +event.target.value;
		if (groupId) {
			onChangeHandle(groupId);
		} else {
			setIsNewList(true);
		}
	};

	const onChangeNewList = (event: React.ChangeEvent<HTMLInputElement>) => {
		setNewListName(event.target.value);
	};

	const onSubmitNewList = () => {
		onChangeHandle(0, _newListName);
	};

	const handleKeyDown = (event: React.KeyboardEvent) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			onSubmitNewList();
		}
	};

	const CLASS_NEWLIST_CONTROL = 'newlist-control';
	const onBlurNewList = (event: React.FocusEvent) => {
		if (event.relatedTarget && event.relatedTarget.classList.contains(CLASS_NEWLIST_CONTROL)) {
			// not blur
		} else {
			setIsNewList(false);
		}
	};

	return (
		<Stack
			gap={theme.spacing(2)}
		>
			<Text
				sx={{
					fontSize: theme.typography.subtitle2,
				}}
			>
				<Text
					sx={{
						mt: -2,
						fontSize: theme.typography.h6,
						fontWeight: 600,
					}}
				>
					Video was saved
				</Text>
				to {getShortTitle(groupTitle)}
			</Text>

			<VideoCard
				author={videoAuthor}
				img={videoImg}
				title={videoTitle}
				langCodes={videoLangCodes}
			/>

			<Box
				sx={{
					position: 'relative',
				}}
			>
				{progress
					? <Box
						sx={{
							height: theme.spacing(5),
							width: 1,
							pt: theme.spacing(1),
							textAlign: 'center',
						}}
					>
						<CircularProgress
							size={theme.spacing(3)}
						/>
					</Box>
					: isNewList || !groups.length
						? <FormGroup
							sx={{
								flexFlow: 'row nowrap',
								gap: theme.spacing(1),

								[theme.breakpoints.down('sm')]: {
									flexWrap: 'wrap',
									rowGap: theme.spacing(1),
								}
							}}
							onKeyDown={handleKeyDown}
							onBlur={onBlurNewList}
						>     
							<TextField
								autoFocus
								fullWidth
								value={_newListName}
								onChange={onChangeNewList}
								multiline={false}
								variant={'filled'}
							/>
							<Button
								className={CLASS_NEWLIST_CONTROL}
								disabled={progress}
								onClick={onSubmitNewList}
								variant={'contained'}
								fullWidth
								sx={{
									'&[disabled]': {
										background: theme.palette.grey[100],
									}
								}}
							>
								Create new playlist
							</Button>
						</FormGroup>
						: <Select
							disabled={progress}
							variant={'filled'}
							fullWidth
							value={String(selected)}
							onChange={onChangeSelect}
						>
							<MenuItem
								disabled
								value={'-1'}
								key={'placeholder'}
								sx={{
									display: 'none',
								}}
							>
								Modify
							</MenuItem>

							{groups.map((group) => {
								return <MenuItem
									key={group.id}
									value={group.id}
								>
									{group.name || createGroupNameByLangs(group)}
								</MenuItem>
							})}

							<Divider sx={{
								mt: theme.spacing(.5),
								mb: theme.spacing(.5),
							}}/>

							<MenuItem
								key={0}
								value={0}
							>
								Create new playlist
							</MenuItem>
						</Select>
				}
			</Box>
		</Stack>
	);
}
