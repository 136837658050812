import { ILanguageItem } from '../general/types';
import { TPhraseDetailsTab } from '../models/types';

export const SET_PHRASE_DETAILS_ACTIVE_TAB = 'PHRASE_DETAILS/SET_PHRASE_DETAILS_ACTIVE_TAB';
export const SET_PHRASE_DETAILS_HIDE = 'PHRASE_DETAILS/SET_PHRASE_DETAILS_HIDE';
export const SET_PHRASE_DETAILS_ACTIVATE = 'PHRASE_DETAILS/SET_PHRASE_DETAILS_ACTIVATE';
export const SET_PHRASE_DETAILS_DISACTIVATE = 'PHRASE_DETAILS/SET_PHRASE_DETAILS_DISACTIVATE';
export const SET_PHRASE_DETAILS_HASH = 'PHRASE_DETAILS/SET_PHRASE_DETAILS_HASH';
export const SET_PHRASE_DETAILS_TRANSLATE_RESULT = 'PHRASE_DETAILS/SET_PHRASE_DETAILS_TRANSLATE_RESULT';
export const SET_PHRASE_DETAILS_TRANSLATE_LANG_RESULT = 'PHRASE_DETAILS/SET_PHRASE_DETAILS_TRANSLATE_LANG_RESULT';
export const SET_PHRASE_DETAILS_EXPLAIN_RESULT = 'PHRASE_DETAILS/SET_PHRASE_DETAILS_EXPLAIN_RESULT';
export const SET_PHRASE_DETAILS_EXPLAIN_HASH = 'PHRASE_DETAILS/SET_PHRASE_DETAILS_EXPLAIN_HASH';
export const SET_PHRASE_DETAILS_USAGE_RESULT = 'PHRASE_DETAILS/SET_PHRASE_DETAILS_USAGE_RESULT';
export const SET_PHRASE_DETAILS_ID = 'PHRASE_DETAILS/SET_PHRASE_DETAILS_ID';
export const SET_PHRASE_DETAILS_RESET_ALLOW_SAVE_RESULTS = 'PHRASE_DETAILS/SET_PHRASE_DETAILS_RESET_ALLOW_SAVE_RESULTS';
export const SET_PHRASE_DETAILS_USAGE_HASH = 'PHRASE_DETAILS/SET_PHRASE_DETAILS_USAGE_HASH';
export const SET_PHRASE_DETAILS_CUSTOM_PROMPT_RESULT = 'PHRASE_DETAILS/SET_PHRASE_DETAILS_CUSTOM_PROMPT_RESULT';
export const SET_PHRASE_DETAILS_CUSTOM_PROMPT_HASH = 'PHRASE_DETAILS/SET_PHRASE_DETAILS_CUSTOM_PROMPT_HASH';
export const SET_PHRASE_DETAILS_NOTE_PHRASE_ID = 'PHRASE_DETAILS/SET_PHRASE_DETAILS_NOTE_PHRASE_ID';
export const SET_PHRASE_DETAILS_NOTE_TEXT = 'PHRASE_DETAILS/SET_PHRASE_DETAILS_NOTE_TEXT';
export const SET_PHRASE_DETAILS_NOTE_LAST_PAUSE = 'PHRASE_DETAILS/SET_PHRASE_DETAILS_NOTE_LAST_PAUSE';
export const SET_PHRASE_DETAILS_NOTE_PREVIEW_MODE = 'PHRASE_DETAILS/SET_PHRASE_DETAILS_NOTE_PREVIEW_MODE';
export const SET_PHRASE_DETAILS_POPUP_MENU = 'PHRASE_DETAILS/SET_PHRASE_DETAILS_POPUP_MENU';

export const PHRASE_DETAILS_RESULT_CUSTOM_PREF = 'CUSTOM-';
export const PHRASE_DETAILS_RESULT_LIB_PREF = 'LIB-';

export interface IPhrasesDetailTranslateLangResult {
  langCode: string,
  result: string,
  success: boolean
}

export interface IPhrasesDetailTranslate {
  type: typeof SET_PHRASE_DETAILS_TRANSLATE_RESULT;
  processing: boolean;
  allowSaveResult: boolean;
  results: IPhrasesDetailTranslateLangResult[] | null
}

export interface IPhrasesDetailTranslateLang {
  type: typeof SET_PHRASE_DETAILS_TRANSLATE_LANG_RESULT;
  result: IPhrasesDetailTranslateLangResult
}

export interface IPhrasesDetailExplainResult extends IPhrasesDetailBase {
  type: typeof SET_PHRASE_DETAILS_EXPLAIN_RESULT;
}

export interface IPhrasesDetailUsageResult extends IPhrasesDetailBase {
  type: typeof SET_PHRASE_DETAILS_USAGE_RESULT;
}

export interface IPhrasesDetailExplainHash {
  type: typeof SET_PHRASE_DETAILS_EXPLAIN_HASH;
  hash: string;
}

export interface IPhrasesDetailExplain extends IPhrasesDetailBase {
  type: typeof SET_PHRASE_DETAILS_EXPLAIN_RESULT;
  hash: string;
}

export interface IPhrasesDetailUsage extends IPhrasesDetailBase {
  type: typeof SET_PHRASE_DETAILS_USAGE_RESULT;
  hash: string;
}

export interface IPhrasesDetailNote extends IPhrasesDetailBase {
  phraseId?: number;
  savedStatus?: boolean;
  lastPauseId?: number;
}

export interface IPhrasesDetailUsageHash {
  type: typeof SET_PHRASE_DETAILS_USAGE_HASH;
  hash: string;
}

export interface IPhrasesDetailSetNotePhraseId {
  type: typeof SET_PHRASE_DETAILS_NOTE_PHRASE_ID;
  phraseId: number;
}

export interface IPhrasesDetailSetNoteText {
  type: typeof SET_PHRASE_DETAILS_NOTE_TEXT;
  text: string;
}

export interface IPhrasesDetailSetNoteLastPause {
  type: typeof SET_PHRASE_DETAILS_NOTE_LAST_PAUSE,
  noteId: number
}

export interface IPhrasesDetailCustomPromptMapState {
  [id: string]: IPhrasesDetailCustomPrompt;
}

export interface IPhrasesDetailCustomPrompt extends IPhrasesDetailBase {
  hash: string;
}

export interface IPhrasesDetailPopupMenu {
  show: boolean;
  text: string;
  anchorElement: HTMLElement | null
}

export interface IPhraseDetailsState {
  activeTab?: TPhraseDetailsTab,
  text?: string,
  context?: string,
  captionLineContext?: string,
  selectText?: string,
  phraseId?: number,
  fromLang?: ILanguageItem,
  toLang?: ILanguageItem,
  hash: string,
  translate?: IPhrasesDetailTranslate,
  note?: IPhrasesDetailNote,
  explain?: IPhrasesDetailExplain,
  usage?: IPhrasesDetailUsage,
  customPrompts?: IPhrasesDetailCustomPromptMapState,
  notePreviewMode?: boolean,
  popupMenu?: IPhrasesDetailPopupMenu,
  prevTab?: TPhraseDetailsTab,
  disableNotes?: boolean
}


export interface ISetPhrasesDetailsActiveTab {
  type: typeof SET_PHRASE_DETAILS_ACTIVE_TAB;
  activeTab: TPhraseDetailsTab;
}

export interface ISetPhrasesDetailsHide {
  type: typeof SET_PHRASE_DETAILS_HIDE;
}

export interface IPhrasesDetailsActivate {
  type: typeof SET_PHRASE_DETAILS_ACTIVATE;
  text: string;
  context: string;
  selectText: string;
  captionLineContext: string;
  fromLang: ILanguageItem;
  toLang: ILanguageItem;
  phraseId?: number;
  activeTab?: TPhraseDetailsTab;
  hash?: string,
  notesDisable?: boolean
}

export interface IPhrasesDetailsDisActivate {
  type: typeof SET_PHRASE_DETAILS_DISACTIVATE;
}

interface IPhrasesDetailBase {
  resultText: string;
  processing: boolean;
  allowSaveResult: boolean;
}


export interface ISetPhrasesDetailId {
  type: typeof SET_PHRASE_DETAILS_ID;
  phraseId: number;
}

export interface ISetPhrasesDetailHash {
  type: typeof SET_PHRASE_DETAILS_HASH;
  hash: string;
}

export interface ISetPhrasesResetAllowSaveResults {
  type: typeof SET_PHRASE_DETAILS_RESET_ALLOW_SAVE_RESULTS,
  allowSave: boolean
}

export interface IPhrasesDetailCustomPromptResult extends IPhrasesDetailBase {
  type: typeof SET_PHRASE_DETAILS_CUSTOM_PROMPT_RESULT;
  id: string;
}

export interface IPhrasesDetailCustomPrompHash {
  type: typeof SET_PHRASE_DETAILS_CUSTOM_PROMPT_HASH;
  id: string,
  hash: string;
}

export interface IPhrasesDetailsNotePreviewMode {
  type: typeof SET_PHRASE_DETAILS_NOTE_PREVIEW_MODE;
  notePreviewMode: boolean
}

export interface IPhrasesDetailsPopupMenu {
  type: typeof SET_PHRASE_DETAILS_POPUP_MENU;
  popupMenu: Partial<IPhrasesDetailPopupMenu>
}

export type TPhrasesDetailsActions =
  | ISetPhrasesDetailsActiveTab
  | ISetPhrasesDetailsHide
  | IPhrasesDetailsActivate
  | IPhrasesDetailTranslate
  | IPhrasesDetailTranslateLang
  | IPhrasesDetailExplain
  | IPhrasesDetailExplainHash
  | IPhrasesDetailUsage
  | ISetPhrasesDetailId
  | ISetPhrasesDetailHash
  | ISetPhrasesResetAllowSaveResults
  | IPhrasesDetailUsageHash
  | IPhrasesDetailCustomPromptResult
  | IPhrasesDetailCustomPrompHash
  | IPhrasesDetailSetNotePhraseId
  | IPhrasesDetailSetNoteText
  | IPhrasesDetailSetNoteLastPause
  | IPhrasesDetailsDisActivate
  | IPhrasesDetailsNotePreviewMode
  | IPhrasesDetailsPopupMenu