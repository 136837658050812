import React from 'react';
import { IControlSwitchProps } from './types';
import { FormControlLabel, Stack } from '@mui/material';
import { theme } from '../../../../../common/styles/theme';
import { Switch } from '../../../../../common/components/Switch/Switch';
import { Text } from '../../../../../common/components/Text/Text';


export const ControlSwitch: React.FC<IControlSwitchProps> = ({
  title,
  subTitle,
  checked,
  disabled,
    labelPlacement = 'start',
  sx = {},
  onChange,
}) => {

  let label: string | JSX.Element = title;
  if (subTitle) {
    label = <Stack>
      {title}
      <Text
        sx={{
          color: theme.palette.grey[500],
          fontWeight: 300,
        }}
      >{subTitle}</Text>
    </Stack>;
  }

  return (
      <FormControlLabel
        disabled={disabled}
        label={label}
        labelPlacement={labelPlacement}
        control={<Switch checked={checked} onChange={onChange} />}
        sx={{
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          mt: theme.spacing(2),
          ml: 0,
          mr: 0,

          '.MuiFormControlLabel-label': {
            mt: theme.spacing(.25),
            fontWeight: 600,
          },

          '.MuiSwitch-root': {
            mr: 0,
              ...sx['.MuiSwitch-root'],
          },

          ...sx,
        }}
      />
  );
};
