import { AppThunk } from '../../../../../store/types';
import { ContentLibActions } from '../../../../../store/contentLib/actions';
import { ContentLibSelectors } from '../../../../../store/contentLib/selectors';
import { Navigator } from '../../../../../effects/navigator';
import { setMainViewMode } from '../../../../../store/general/actions';
import { EMainViewMode } from '../../../../../store/general/types';

export class ContentLibCopyVideoConfirmEvents {

  public static onClose(): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      dispatch(ContentLibActions.setCopyVideoConfirm({show: false}));
    }
  }

  public static onOpenVideo(): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      dispatch(setMainViewMode(EMainViewMode.DASHBOARD));
      const {groupId = 0, videoId = ''} = ContentLibSelectors.getCopyVideoConfirm(getState());
      Navigator.openVideo(groupId, videoId);
      dispatch(this.onClose());
    }
  }
}