import { IState } from '../../../../../../store/types';
import { connect } from 'react-redux';
import { CopyVideoConfirm } from '../components/CopyVideoConfirm';
import { ICopyVideoConfirmEvents, ICopyVideoConfirmFields } from '../components/types';
import { getCopyVideoConfirm } from '../../../../../../store/general/selectors';
import {
  getGroupTargetLanguage,
  getUserGroupById,
  getUserGroupTitle,
  getUserOwnGroups,
} from '../../../../../../store/models/selectors';
import { CopyVideoConfirmEvents } from './events';
import { VideoLibraryGroupItemHelpers } from '../../../../VideoGroups/VideoLibraryGroupItem/hocs/helpers';
import { TVideoInfo } from '../../../../../../store/models/types';


const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): ICopyVideoConfirmFields => {
    const copyVideoConfirm = getCopyVideoConfirm(state);
    const {show, targetGroupId = 0, targetGroupName} = copyVideoConfirm;
    let videoInfo = copyVideoConfirm?.videoInfo as TVideoInfo;
    const group = getUserGroupById(state, targetGroupId);
    const videoLangCodes = videoInfo?.langs?.split(',') || [];
    const langCodes = group ? VideoLibraryGroupItemHelpers.getSelectedVideoLangs(state, group, videoLangCodes) : [];
    const groupName = targetGroupName ? targetGroupName : (group ? getUserGroupTitle(state, group) : '');
    
    const groups = getUserOwnGroups(state).filter(g => {
      const targetLang = g ? getGroupTargetLanguage(state, g) : null;
      return videoLangCodes && targetLang &&
        videoLangCodes.includes(targetLang.code);
    })

    return {
      groupId: targetGroupId,
      groupName,
      groups,
      show,
      videoInfo,
      langCodes,
    }
  };
}

const mapDispatchToProps = (
  dispatch: any
): ICopyVideoConfirmEvents => ({
    onClose: () => dispatch(CopyVideoConfirmEvents.onClose()),
    onChange: (groupId) => dispatch(CopyVideoConfirmEvents.onGroupChange(groupId)),
  }
);

export const CopyVideoConfirmHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(CopyVideoConfirm);

CopyVideoConfirmHOC.displayName = 'CopyVideoConfirmHOC';
