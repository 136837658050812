import React from 'react';
import { theme } from '../../../../../../common/styles/theme';
import { Button, Stack } from '@mui/material';
import { Text } from '../../../../../../common/components/Text/Text';
import { IContentLibCopyGroupConfirmProps } from './types';
import { Modal } from '../../../../../../common/components/Modal/Modal';

export const ContentLibCopyGroupConfirm: React.FC<IContentLibCopyGroupConfirmProps> = ({
  show,
  onClose,
  onOpenGroup
}) => {
  
  return (
    <Modal
      onClose={onClose}
      open={show}
      PaperProps={{
        sx: {
          maxWidth: '700px !important',
        }
      }}
    >
      <Stack
        gap={theme.spacing(2)}
      >
        <Stack
          direction='row'
          alignItems={'center'}
          gap={theme.spacing(2)}
          sx={{ pr: theme.spacing(2.5) }}
        >

          <Text
            fontWeight={600}
            variant={'subtitle2'}
            sx={{
              display: '-webkit-box',
              WebkitLineClamp: 3,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
            }}
          >
            Playlist was saved
          </Text>

          <Button onClick={onOpenGroup}>Open</Button>
        </Stack>

      </Stack>
    </Modal>
  );
}