import { IState } from '../types';
import { IPhraseSelectResult } from '../../effects/phrase/utils/phrase-select-preparator';
import { IVocabularyPhrase } from '../../types/common';

export class PhraseContextEditorSelectors {

  public static getShow(state: IState): boolean {
    return state.phraseContextEditor.show
  }

  public static getPhraseId(state: IState): number {
    return state.phraseContextEditor.phraseId
  }

  public static getSelectResult(state: IState): IPhraseSelectResult | null {
    return state.phraseContextEditor.selectResult
  }

  public static getPreviewContextPhrase(state: IState): IVocabularyPhrase | null {
    return state.phraseContextEditor.previewContextPhrase
  }

  public static getPromptsMode(state: IState): boolean {
    return !!state.phraseContextEditor.promptsMode;
  }

  public static getPhrases (
    state: IState
  ): IVocabularyPhrase[] | undefined {
    return state.phraseContextEditor.phrases;
  }

}