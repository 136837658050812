import React from 'react';
import { IContentLibGroupTagProps } from './types';
import { theme } from '../../../../../common/styles/theme';
import { Box, Tooltip } from '@mui/material';


export function ContentLibGroupTag({
	name
}: IContentLibGroupTagProps) {
	if (!name) return null;

	const [isLong, setLong] = React.useState(false);
	const ref1 = React.useRef(null);
	const ref2 = React.useRef(null);

    React.useEffect(() => {
        if (!isLong && ref1.current && ref2.current) {
			//@ts-ignore
			if (ref2.current.offsetWidth > ref1.current.offsetWidth) {
				setLong(true);
			}
		}
    }, [name]);

	const element = <Box
		sx={{
			height: theme.spacing(3),
			px: 1,
			fontSize: theme.typography.subtitle2,
			color: theme.palette.primary[500],
			background: theme.palette.primary[100],
			borderRadius: theme.spacing(1.5),
			
			maxWidth: theme.spacing(25),
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
		}}
		ref={ref1}
	>
		<span
			style={{
				lineHeight: theme.spacing(3),
			}}
			ref={ref2}
		>
			#{name}
		</span>
	</Box>;

	return isLong
		? <Tooltip
			enterDelay={1000}
			title={name}
		>
			{element}	
		</Tooltip>
		: element;
};