import { IState } from '../types';
import { ILanguageItem } from '../general/types';
import {
  IPhraseDetailsState,
  IPhrasesDetailCustomPrompt, IPhrasesDetailPopupMenu,
  IPhrasesDetailTranslateLangResult,
  PHRASE_DETAILS_RESULT_CUSTOM_PREF, PHRASE_DETAILS_RESULT_LIB_PREF
} from './types';
import { TPhraseDetailsTab } from '../models/types';
import { getPlayerState } from '../general/selectors';
import { IPhraseNote, PlayerStates } from '../../types/common';
import { CaptionsSelectionPopupSelectors } from '../captions-selection-popup/selectors';
import { getVideoPhraseNoteByPhraseId } from '../videos/selectors';

export class PhraseDetailsSelectors {

  public static getActiveTab(state: IState): TPhraseDetailsTab | undefined {
    return state.phraseDetails.activeTab;
  }

  public static getPhraseDetails(state: IState): IPhraseDetailsState {
    return state.phraseDetails;
  }

  public static getText(state: IState): string | undefined {
    return state.phraseDetails.text;
  }

  public static getContext(state: IState): string | undefined {
    return state.phraseDetails.context;
  }

  public static getSelectText(state: IState): string | undefined {
    return state.phraseDetails.selectText;
  }

  public static getCaptionLineContext(state: IState): string | undefined {
    return state.phraseDetails.captionLineContext;
  }

  public static getHash(state: IState): string {
    return state.phraseDetails.hash;
  }

  public static getFromLang(state: IState): ILanguageItem | undefined {
    return state.phraseDetails.fromLang;
  }

  public static getToLang(state: IState): ILanguageItem | undefined {
    return state.phraseDetails.toLang;
  }

  public static getTranslateResultText(state: IState): IPhrasesDetailTranslateLangResult[] | null | undefined {
    return state.phraseDetails.translate?.results;
  }

  public static getTranslateResultProcessing(state: IState): boolean | undefined {
    return state.phraseDetails.translate?.processing;
  }

  public static getTranslateResultAllowSave(state: IState): boolean | undefined {
    return state.phraseDetails.translate?.allowSaveResult;
  }

  public static getNoteResultText(state: IState): string | undefined {
    return state.phraseDetails.note?.resultText;
  }

  public static getNotePhraseId(state: IState): number | undefined {
    return state.phraseDetails.note?.phraseId;
  }

  public static getNoteResultProcessing(state: IState): boolean | undefined {
    return state.phraseDetails.note?.processing;
  }

  public static getNoteResultAllowSave(state: IState): boolean | undefined {
    return state.phraseDetails.note?.allowSaveResult;
  }

  public static getNoteLastPauseId(state: IState): number {
    return state.phraseDetails.note?.lastPauseId || 0;
  }

  public static getExplainResultText(state: IState): string | undefined {
    return state.phraseDetails.explain?.resultText;
  }

  public static getExplainResultProcessing(state: IState): boolean | undefined {
    return state.phraseDetails.explain?.processing;
  }

  public static getExplainResultAllowSave(state: IState): boolean | undefined {
    return state.phraseDetails.explain?.allowSaveResult;
  }

  public static getExplainHash(state: IState): string {
    return state.phraseDetails.explain?.hash || '';
  }

  public static getUsageResultText(state: IState): string | undefined {
    return state.phraseDetails.usage?.resultText;
  }

  public static getUsageHash(state: IState): string {
    return state.phraseDetails.usage?.hash || '';
  }

  public static getUsageResultProcessing(state: IState): boolean | undefined {
    return state.phraseDetails.usage?.processing;
  }

  public static getUsageResultAllowSave(state: IState): boolean | undefined {
    return state.phraseDetails.usage?.allowSaveResult;
  }

  public static getPhraseId(state: IState): number | undefined {
    return state.phraseDetails.phraseId;
  }

  public static getCustomPrompt(state: IState, id: number): IPhrasesDetailCustomPrompt | undefined {
    return state.phraseDetails.customPrompts ? state.phraseDetails.customPrompts[PHRASE_DETAILS_RESULT_CUSTOM_PREF + id] : undefined;
  }

  public static getLibPrompt(state: IState, id: number): IPhrasesDetailCustomPrompt | undefined {
    return state.phraseDetails.customPrompts ? state.phraseDetails.customPrompts[PHRASE_DETAILS_RESULT_LIB_PREF + id] : undefined;
  }

  public static getNotePreviewMode(state: IState): boolean | undefined {
    return state.phraseDetails.notePreviewMode;
  }

  public static getPopupMenu(state: IState): IPhrasesDetailPopupMenu | undefined {
    return state.phraseDetails.popupMenu;
  }

  public static getPrevTab(state: IState): TPhraseDetailsTab | undefined {
    return state.phraseDetails.prevTab;
  }

  public static getCurrentNote(state: IState): IPhraseNote | undefined {
    const playVideo = getPlayerState(state) === PlayerStates.PLAYING;
    let phraseId = playVideo ?
      PhraseDetailsSelectors.getNotePhraseId(state) :
      PhraseDetailsSelectors.getPhraseId(state);
    return getVideoPhraseNoteByPhraseId(state, phraseId || 0);
  }

  public static isNotesDisabled(state: IState): boolean {
    return state.phraseDetails.disableNotes;
  }
}