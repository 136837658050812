import React from 'react';
import { theme } from '../../../../../../../common/styles/theme';
import { Modal } from '../../../../../../../common/components/Modal/Modal';
import { Box, Button, CardMedia, CircularProgress, MenuItem, Select, SelectChangeEvent, Stack } from '@mui/material';
import { Text } from '../../../../../../../common/components/Text/Text';
import { ICopyVideoConfirmProps } from './types';
import { createGroupNameByLangs } from "../../../../../../store/models/selectors";
import { DeviceContext } from '../../../../../../../common/contexts/DeviceContext';
import { COPY_VIDEO_CONFIRM_TEXT } from './wordings';
import LangFlag from '../../../../LangFlag/LangFlag';
import { Divider } from '../../../../../../../common/components/Divider/Divider';

export const CopyVideoConfirm: React.FC<ICopyVideoConfirmProps> = ({
    groupId,
    groupName,
    groups,
    show,
    videoInfo,
    langCodes,
    onClose,
    onChange,
}) => {
    if (!show) return null;

    const { isMobile } = React.useContext(DeviceContext);

    const [progress, setProgress] = React.useState<boolean>(false);
	const [selected, setSelected] = React.useState(-1);

	const onChangeHandle = (groupId: number) => {
		setSelected(groupId);
		setProgress(true);
		try {
			onChange(groupId);
		} catch(e) {
			setProgress(false);
		}
	};

	const onChangeSelect = (event: SelectChangeEvent) => {
		onChangeHandle(+event.target.value);
	};
    
    return (
        <Modal
			onClose={onClose}
			open={show}
			isMobile={isMobile}
			PaperProps={{
				sx: {
					maxWidth: `${theme.spacing(62)} !important`,
				}
			}}
		>
			<Stack
				gap={theme.spacing(2)}
			>
				<Text
					sx={{
						mt: -2,
						fontSize: theme.typography.h6,
						fontWeight: 600,
					}}
				>
					Select playlist
				</Text>

				<Text
					sx={{
						fontSize: theme.typography.subtitle2,
					}}
				>
					{COPY_VIDEO_CONFIRM_TEXT}
				</Text>

				<Stack
					direction={'row'}
					alignItems={'flex-start'}
					gap={theme.spacing(2)}
				>
					<CardMedia
						component='img'
						image={videoInfo.thumb}
						alt={videoInfo.title}
						sx={{
							flex: `1 1 ${theme.spacing(20)}`,
							aspectRatio: '16/9',
							borderRadius: theme.spacing(.75),
						}}
					/>
					<Stack spacing={theme.spacing(.5)}>
						<Text
							sx={{
								display: '-webkit-box',
								WebkitLineClamp: 2,
								WebkitBoxOrient: 'vertical',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								fontSize: theme.typography.h6,
								fontWeight: 600,
							}}
						>
							{videoInfo.title}
						</Text>
						<Text
							noWrap
							sx={{
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								color: theme.palette.grey[400],
								fontSize: theme.typography.subtitle2,
							}}
						>
							{videoInfo.author}
						</Text>
						{!!langCodes && !!langCodes.length &&
							<Stack
								direction={'row'}
								flexWrap={'wrap'}
								gap={theme.spacing(1)}
								sx={{
									'.MuiStack-root': {
										height: theme.spacing(2),
									}
								}}
							>
								{langCodes.map(langCode => (
									<LangFlag
										code={langCode}
										showCode={true}
										key={langCode}
									/>
								))}
							</Stack>
						}
					</Stack>
				</Stack>

				<Box
					sx={{
						position: 'relative',
					}}
				>
					{groups.length
						? <Select
							disabled={progress}
							variant={'filled'}
							fullWidth
							value={String(selected)}
							onChange={onChangeSelect}
						>
							<MenuItem
								disabled
								value={'-1'}
								key={'placeholder'}
								sx={{
									display: 'none',
								}}
							>
								Select playlist
							</MenuItem>

							{groups.map((group) => {
								return <MenuItem
									key={group.id}
									value={group.id}
								>
									{group.name || createGroupNameByLangs(group)}
								</MenuItem>
							})}

							<Divider sx={{
								mt: theme.spacing(.5),
								mb: theme.spacing(.5),
							}} />

							<MenuItem
								key={0}
								value={0}
							>
								Create new playlist
							</MenuItem>
						</Select>
							
						: <Button
							disabled={progress}
							onClick={()=>onChangeHandle(0)}
							variant={'contained'}
							fullWidth
							sx={{
								'&[disabled]': {
									background: theme.palette.grey[100],
								}
							}}
						>
							Create new playlist
						</Button>
					}
					{progress &&
						<CircularProgress
							size={theme.spacing(3)}
							sx={{
								position: 'absolute',
								left: `50%`,
								bottom: theme.spacing(1),
								ml: theme.spacing(-1.5) + ' !important',
							}}
						/>
					}
				</Box>
			</Stack>
		</Modal>
    );
}
