import { API_URL } from '../../constants';
import { getDispatch } from '../../../spa/store';
import { getRequest, postRequest } from '../../utils/requestService';
import { IContentLibGroup, IContentLibVideo } from '../../../spa/store/contentLib/types';
import { TLanguage, TUserGroup, TVideo } from '../../../spa/store/models/types';

export type TContentLibGroupsSearchRequest = {
  targetLangCode: string;
  nativeLangCode: string;
  searchText: string;
  maxVideoCount: number
};

export type TContentLibVideosSearchRequest = {
  groupId: number;
  searchText: string;
  targetLangCode: string;
  nativeLangCode: string;
  startIndex: number;
  count: number;
};


export type TContentLibLangsResult = {
  targetLangs: TLanguage[],
  nativeLangs: TLanguage[]
}


export class ContentLibRest {

  private static SEARCH_GROUPS_URL = API_URL + '/contentLib/searchGroups';
  private static SEARCH_VIDEOS_URL = API_URL + '/contentLib/searchVideos';
  private static COPY_VIDEO_URL = API_URL + '/contentLib/copyVideo';
  private static COPY_GROUP_URL = API_URL + '/contentLib/copyGroup';
 // private static VIDEO_URL = API_URL + '/contentLib/video/';
  private static LANG_LIST_URL = API_URL + '/contentLib/langList';

  public static async searchGroups(filter: TContentLibGroupsSearchRequest): Promise<IContentLibGroup[]> {
    const dispatch = getDispatch();
    return await dispatch(postRequest(ContentLibRest.SEARCH_GROUPS_URL, filter));
  }

  public static async searchVideos(filter: TContentLibVideosSearchRequest): Promise<IContentLibVideo[]> {
    const dispatch = getDispatch();
    return await dispatch(postRequest(ContentLibRest.SEARCH_VIDEOS_URL, filter));
  }

  public static async copyVideo(libVideoId: number, targetGroupId: number): Promise<TVideo | null> {
    const dispatch = getDispatch();
    return await dispatch(postRequest(ContentLibRest.COPY_VIDEO_URL, {libVideoId, targetGroupId}));
  }

  public static async copyGroup(libGroupId: number): Promise<TUserGroup> {
    const dispatch = getDispatch();
    return await dispatch(postRequest(ContentLibRest.COPY_GROUP_URL, {libGroupId}));
  }

 /* public static async getVideo(videoId: number): Promise<IContentLibVideo> {
    const dispatch = getDispatch();
    const url = ContentLibRest.VIDEO_URL + videoId;
    return await dispatch(getRequest(url));
  }*/

  public static async getLangList(): Promise<TContentLibLangsResult> {
    const dispatch = getDispatch();
    const url = ContentLibRest.LANG_LIST_URL;
    return await dispatch(getRequest(url));
  }

}