import { AppThunk } from '../../../../../store/types';
import { PhraseDetailsActions } from '../../../../../store/phrase-details/actions';
import { PhraseDetailsTabEffects } from '../../../../../effects/phraseDetailsTabEffects';
import { getPhraseDetailsTabByTypeAndRelationId } from '../../../../../store/models/selectors';
import { EPhraseDetailsTabType } from '../../../../../store/models/types';

export class PromptsLibItemEvents {

	public static onClick (
		id: number,
		onSelected?: () => void,
	): AppThunk {
		return async (
			dispatch,
			getState
		) => {
			const state = getState();
			let tab = getPhraseDetailsTabByTypeAndRelationId(state, EPhraseDetailsTabType.LIB_PROMPT, id);
			if (!tab) {
				tab = await PhraseDetailsTabEffects.saveLibTab(id) as any;
			}
			dispatch(PhraseDetailsActions.setActiveTab(tab as any));
			if (onSelected) onSelected();
		}
	}

	public static onClickFavorite(id: number): AppThunk {
		return async(
		  dispatch,
		  getState
		) => {
			PhraseDetailsTabEffects.toggleLibTabFavourite(id);
		}
	}

}
