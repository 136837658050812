import { IState } from '../../../../store/types';
import { connect } from 'react-redux';
import { OpenPublicProfilePopup } from '../components/OpenPublicProfilePopup';
import { IOpenPublicProfilePopupEvents, IOpenPublicProfilePopupFields } from '../components/types';
import { OpenPublicProfilePopupEvents } from './events';
import { isShowOpenPublicProfilePopup } from '../../../../store/general/selectors';


const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): IOpenPublicProfilePopupFields => {
    return {
      show: isShowOpenPublicProfilePopup(state)
    }
  };
}

const mapDispatchToProps = (
  dispatch: any
): IOpenPublicProfilePopupEvents => ({
    onClose: () => dispatch(OpenPublicProfilePopupEvents.onClose()),
      onOpen: () => dispatch(OpenPublicProfilePopupEvents.onOpen()),
  }
);

export const OpenPublicProfilePopupHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(OpenPublicProfilePopup);

OpenPublicProfilePopupHOC.displayName = 'OpenPublicProfilePopupHOC';
