import { IProfileListSetAction, IProfilesState, IProfilesUpdateAction, PROFILE_LIST_SET_ACTION, PROFILES_UPDATE_ACTION, TUserProfile } from './types';

export class ProfilesActions {

  public static set(
    list: TUserProfile[]
  ): IProfileListSetAction {
    return {
      type: PROFILE_LIST_SET_ACTION,
      list
    }
  }

  public static update(
    profiles: Partial<IProfilesState>
  ): IProfilesUpdateAction {
    return {
      type: PROFILES_UPDATE_ACTION,
      profiles,
    }
  }
}