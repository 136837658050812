import { IState } from '../../../../../../store/types';
import { IPhraseCustomPromptEvents, IPhraseCustomPromptFields } from '../../components/types';
import { PhraseDetailsSelectors } from '../../../../../../store/phrase-details/selectors';
import { GptUtils } from '../../../../../../effects/gptUtils';
import { PhraseDetailsTabEffects } from '../../../../../../effects/phraseDetailsTabEffects';
import { LangUtil } from '../../../../../../../common/utils/lang-util';
import { connect } from 'react-redux';
import { PhraseCustomPrompt } from '../../components/PhraseCustomPrompt';
import { PhraseLibPromptEvents } from './events';
import { PhraseDetailsTabsEditorPopupSelectors } from '../../../../../../store/phrase-details-tabs-editor-popup/selectors';

const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): IPhraseCustomPromptFields => {
    const activeTab = PhraseDetailsSelectors.getActiveTab(state);
    const tabId = activeTab?.id || 0;
    const text = PhraseDetailsSelectors.getText(state);
    const context = PhraseDetailsSelectors.getContext(state) || PhraseDetailsSelectors.getCaptionLineContext(state);
    const fromLang = PhraseDetailsSelectors.getFromLang(state);
    const toLang = PhraseDetailsSelectors.getToLang(state);
    let id = activeTab?.relationId || 0;
    const libPrompt = PhraseDetailsSelectors.getLibPrompt(state, id);
    const resultText = libPrompt && libPrompt.resultText &&
      GptUtils.formatResult(libPrompt.resultText) || '';
    const processing = libPrompt && libPrompt.processing || false;
    const prompt = PhraseDetailsTabsEditorPopupSelectors.getPromptLibById(state, id)?.prompt || '';

    return {
      tabId,
      fromLangCode: LangUtil.checkLangCode(fromLang?.code),
      toLangCode: LangUtil.checkLangCode(toLang?.code),
      text,
      context,
      resultText,
      processing,
      prompt,
    }
  };
}

const mapDispatchToProps = (
  dispatch: any
): IPhraseCustomPromptEvents => ({
    onLoad: useCache => dispatch(PhraseLibPromptEvents.onLoad(useCache)),
    onClickSettings: () => dispatch(PhraseLibPromptEvents.onClickSettings()),
    onClickContext: () => dispatch(PhraseLibPromptEvents.onClickContext()),
    onCopyNote: () => dispatch(PhraseLibPromptEvents.onCopyNote())
  }
);

export const PhraseLibPromptHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(PhraseCustomPrompt);

PhraseLibPromptHOC.displayName = 'PhraseLibPromptHOC';