import { AppThunk } from '../../../../../store/types';
import { ContentLibSelectors } from '../../../../../store/contentLib/selectors';
import { ContentLibRest } from '../../../../../../common/rest/contentLib/contentLibRest';
import { IContentLibVideo } from '../../../../../store/contentLib/types';
import { ContentLibActions } from '../../../../../store/contentLib/actions';
import { ContentLibEffects } from '../../../../../effects/contentLibEffects';


export class ContentLibSelectedGroupEvents {

  public static onLoadVideos(startIndex: number, count: number): AppThunk {
    return (
      dispatch,
      getState
    ): Promise<IContentLibVideo[]> => {
      const groupId = ContentLibSelectors.getSelectedGroupId(getState());
      const searchText = ContentLibSelectors.getSearchFilter(getState()).text;
      return ContentLibEffects.searchVideos(groupId, searchText, startIndex, count);
    }
  }

  public static onVideoClick(videoId: number): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      const groupId = ContentLibSelectors.getSelectedGroupId(getState());
      const video = await ContentLibRest.getVideo(videoId);
      if (video) {
        dispatch(ContentLibActions.setVideoPopup({groupId, videoId, video}));
      }
    }
  }

}