import React from 'react';
import { IContentLibraryProps } from './types';
import { theme } from '../../../../../../common/styles/theme';
import { Box, Stack, alpha } from '@mui/material';
import { ContentLibraryHeader } from '../ContentLibraryHeader/ContentLibraryHeader';
import img from '../../content-library-placeholder.jpg';
import { Paper } from '../../../../../../common/components/Paper/Paper';
import { BookIcon } from '../../../../../../../icons/BookIcon';
import { createGlobalStyle } from 'styled-components';

export const ContentLibrary: React.FC<IContentLibraryProps> = ({
	isShow,
}) => {
	if (!isShow) return null;

	const className = 'content-library';

	const GlobalStyle = createGlobalStyle`
		@keyframes animation_content_library_comming {
			0% { transform: translateY(100px); };
			100% { transform: translateY(0); };
		}
		@media (max-width: 1150px) {
			.content-library-header__title {
				display: none;
			}
		}
		@media (max-width: 900px) {
			.content-library-header__tabs {
				.MuiTab-root {
					max-width: 36px;
				}
			}
		}
	`;

	return (<Stack
		className={`
			${className}
		`}
		gap={theme.spacing(2)}
		sx={{
			width: 1,
			height: 1,
			p: theme.spacing(2),
			background: theme.palette.secondary.main,
		}}
	>
		<ContentLibraryHeader />

		<Paper
			sx={{
				flex: `1 1 100%`,
				width: 1,
				backgroundImage: `url(${img})`,
				backgroundSize: 'contain',
				backgroundRepeat: 'repeat-y',
				backgroundPosition: '50% 0',
			}}
		>
			<Stack
				alignItems={'center'}
				justifyContent={'center'}
				sx={{
					width: 1,
					height: 1,
					background: alpha(theme.palette.common.white, .3),
				}}
			>
				<Stack
					alignItems={'center'}
					justifyContent={'center'}
					gap={theme.spacing(2)}
					sx={{
						width: 1,
						maxWidth: theme.spacing(50),
						p: `${theme.spacing(4)} ${theme.spacing(3)}`,
						background: theme.palette.grey[50],
						borderRadius: theme.spacing(1.5),
						boxShadow: `0px 27px 59px 0px #0000000A, 0px 427px 171px 0px #00000003, 0px 32px 500px 0px #0000001a`,
						animation: 'animation_content_library_comming .2s ease-in-out forwards',
					}}
				>
					<BookIcon
						sx={{
							color: theme.palette.grey[400],
						}}
					/>
					<Box
						sx={{
							fontSize: theme.typography.h6,
							fontWeight: 600,
							textAlign: 'center',
						}}
					>
						Content Library Coming Soon
					</Box>
					<Box
						sx={{
							fontSize: theme.typography.subtitle2,
							fontWeight: 400,
							textAlign: 'center',
						}}
					>
						Discover user-created playlists, videos, audiobooks, and more for language learning. Stay tuned for a world of resources enhanced by subtitles and ChatGPT prompts.
					</Box>
				</Stack>
			</Stack>
			<GlobalStyle />
		</Paper>
	</Stack>)
};
