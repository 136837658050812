import { AppThunk } from '../../../../store/types';
import { EPlayerControllerMode, PlayerController } from '../../../../effects/player/manager/playerController';
import { PhraseListSelectors } from '../../../../store/phrase-list/selectors';
import { VocabularyComponentUtils } from '../../Vocabulary/vocabulary/utils/VocabularyComponentUtils';
import { PhraseListActions } from '../../../../store/phrase-list/actions';
import { PhraseEffects } from '../../../../effects/phrase/PhraseEffects';
import {
  findMaxUnionVideoPhraseByTime,
  findVideoPhrasesByTime,
  getTargetCaptionByIndex,
  getVideoTeacherUserActiveId
} from '../../../../store/videos/selectors';
import { EVocabularyPhraseType } from '../../../../types/common';
import { ShortcutManager } from '../../../../effects/shortcut/shortcutManager';
import { EShortcutsListenerContainer, setShortcutsListenerContainer } from '../../../../effects/shortcutsListener';
import { setMainViewMode } from '../../../../store/general/actions';
import { EMainViewMode } from '../../../../store/general/types';
import { TeachersEffects } from '../../../../effects/teachersEffects';

export class PhraseListEvents {

  public static onPlay(): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      PlayerController.getInstance().playPhraseList(false);
      setShortcutsListenerContainer(EShortcutsListenerContainer.PHRASES);
    }
  }

  public static onStop(): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      PlayerController.getInstance().stopPhraseList();
    }
  }

  public static onChangeListMode(listModeId: string): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      const listMode = VocabularyComponentUtils.getListModeById(getState(), listModeId);
      if (listMode) {
        dispatch(PhraseListActions.setListMode(listMode.listMode, listMode.langCode));
      }

      const teacherActiveId = getVideoTeacherUserActiveId(getState());
      if (teacherActiveId) {
        TeachersEffects.hidePhrases();
      }
    }
  }

  public static onLoadPhrases(count: number): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      return await PhraseEffects.loadPhrases(0, count, false);
    }
  }

  public static onAppendPhrases(offset: number, count: number): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      return await PhraseEffects.loadPhrases(offset, count, true);
    }
  }

  public static onTargetCaptionIndexChange(index: number): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      const state = getState();
      let phraseId = 0;
      if (PlayerController.getInstance().isCaptionsPlayMode()) {
        const caption = getTargetCaptionByIndex(state, index);
        if (caption) {
          const phrase = findMaxUnionVideoPhraseByTime(state, caption.startTime, caption.endTime, [EVocabularyPhraseType.WORD_AND_CONTEXT_SELECTED]);
          if (phrase) {
            phraseId = phrase.id;
          }
        }
      }

      const prevValue = PhraseListSelectors.getPlayCaptionByPhraseId(state);
      if (prevValue !== phraseId) {
        dispatch(PhraseListActions.setPlayCaptionByPhraseId(phraseId));
      }
    }
  }


  public static onKeyUp(event: KeyboardEvent): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      ShortcutManager.keyUp(event);
    }
  }

  public static onKeyDown(event: KeyboardEvent): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      ShortcutManager.keyDown(event);
    }
  }

  public static onClickTitle(): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      dispatch(setMainViewMode(EMainViewMode.PHRASES));
    }
  }

}