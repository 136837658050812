import { IState } from '../../../../../store/types';
import { ContentLibSelectors } from '../../../../../store/contentLib/selectors';
import { connect } from 'react-redux';
import { ContentLibGroupDetails } from '../components/ContentLibGroupDetails';
import { IContentLibGroupDetailsEvents, IContentLibGroupDetailsFields } from '../components/types';
import { IContentLibGroupDetailsHOCOwnProps } from './types';
import { ContentLibGroupDetailsEvents } from './events';

const mapStateToProps = (
  dispatch: any,
  {groupId}: IContentLibGroupDetailsHOCOwnProps
) => {
  return (
    state: IState
  ): IContentLibGroupDetailsFields => {
    const group = ContentLibSelectors.findGroupById(state, groupId);
    let {
      id = 0,
      videos = [],
      videoCount = 0,
    } = group || {};
    return {
      id,
      videos,
      videoCount,
    }
  };
}

const mapDispatchToProps = (
  dispatch: any,
  {groupId}: IContentLibGroupDetailsHOCOwnProps
): IContentLibGroupDetailsEvents => ({
    onClickMore: () => dispatch(ContentLibGroupDetailsEvents.onSelectGroup(groupId))
  }
);

export const ContentLibGroupDetailsHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(ContentLibGroupDetails);

ContentLibGroupDetailsHOC.displayName = 'ContentLibGroupDetailsHOC';
