import React from 'react';
import { Modal } from '../../../../../common/components/Modal/Modal';
import { Button, Stack, TextField } from '@mui/material';
import { theme } from '../../../../../common/styles/theme';
import { Text } from '../../../../../common/components/Text/Text';
import { IEditGroupProps } from './types';
import { EN_AUTO_GEN_LANG_CODE, EN_LANG_CODE, TLanguageCode } from '../../../../store/models/types';
import { LangSelectHOC } from "../../LangSelect/hocs/LangSelectHOC";

export const EditGroup: React.FC<IEditGroupProps> = ({
  show,
  title,
  targetLangCode,
  nativeLangCode,
  onSave,
  onClose
}) => {

  if (!show) return null;

  const [groupNativeLangCode, setGroupNativeLangCode] = React.useState<string>(nativeLangCode);
  const [groupTargetLangCode, setGroupTargetLangCode] = React.useState<string>(targetLangCode);
  const [error, setError] = React.useState<string>();
  const [createProgress, setCreateProgress] = React.useState<boolean>(false);
  const [groupTitle, setGroupTitle] = React.useState<string>('');


  React.useEffect(() => {
    setGroupTitle(title);
    setGroupNativeLangCode(nativeLangCode);
    setGroupTargetLangCode(targetLangCode);
    setCreateProgress(false);
    setError('');
  }, [show, title, targetLangCode, nativeLangCode]);


  const validation = () => {
    if (isEqualLangCodes(groupNativeLangCode, groupTargetLangCode)) {
      setError('Choose different languages');
    } else {
      setError('');
    }
  }

  const isEqualLangCodes = (code1: TLanguageCode, code2: TLanguageCode) => {
    return (code1 === code2 ||
      (code1 === EN_AUTO_GEN_LANG_CODE && code2 === EN_LANG_CODE) ||
      (code1 === EN_LANG_CODE && code2 === EN_AUTO_GEN_LANG_CODE));
  }

  const handleSaveGroup = async () => {
    validation();
    if (error) return;
    setCreateProgress(true);
    await onSave(groupTargetLangCode, groupNativeLangCode, groupTitle);
    onClose();
  }

  const onTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGroupTitle(e.target.value);
  }

  return (
    <Modal
      onClose={onClose}
      open={show}
      title={'Edit Playlist'}
    >
      <Stack
        spacing={theme.spacing(2)}
        sx={{ width: theme.spacing(45.75) }}
      >
        <LangSelectHOC
          label={'Target language'}
          onChange={setGroupTargetLangCode}
          langCode={groupTargetLangCode}
        />
        <LangSelectHOC
          label={'Native language'}
          onChange={setGroupNativeLangCode}
          langCode={groupNativeLangCode}
        />
        <TextField
          label={'Title'}
          variant={'filled'}
          fullWidth
          value={groupTitle}
          onChange={onTitleChange}
          multiline={false}
        />
        <Text sx={{ color: theme.palette.error.main }}>
          {error}
        </Text>
        <Button
          disabled={!groupTargetLangCode || !groupNativeLangCode || !!error || createProgress}
          onClick={handleSaveGroup}
          variant={'contained'}
          sx={{ justifySelf: 'flex-start', width: theme.spacing(14.5) }}
        >
          Save Playlist
        </Button>
      </Stack>
    </Modal>
  );
}
