import { AppThunk } from '../../../../../store/types';
import { ContentLibActions } from '../../../../../store/contentLib/actions';
import { EContentLibViewMode } from '../../../../../store/contentLib/types';
import { ContentLibSelectors } from '../../../../../store/contentLib/selectors';
import { MainLayoutEffects } from '../../../../../effects/mainLayoutEffects';

export class ContentLibraryHeaderEvents {

  public static onTextChange(text: string): AppThunk {
     return (
       dispatch,
       getState
     ) => {
       dispatch(ContentLibActions.setSearchFilter({text}));
     }
   }

  public static onChangeTargetLangCode(targetLangCode: string): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      if (ContentLibSelectors.getViewMode(getState()) === EContentLibViewMode.SELECTED_GROUP) {
        dispatch(ContentLibActions.setViewMode(EContentLibViewMode.GROUP_LIST_WITH_VIDEOS));
      }

      dispatch(ContentLibActions.setSearchFilter({targetLangCode}));
    }
  }

  public static onChangeNativeLangCode(nativeLangCode: string): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      if (ContentLibSelectors.getViewMode(getState()) === EContentLibViewMode.SELECTED_GROUP) {
        dispatch(ContentLibActions.setViewMode(EContentLibViewMode.GROUP_LIST_WITH_VIDEOS));
      }

      dispatch(ContentLibActions.setSearchFilter({nativeLangCode}));
    }
  }

  public static onChangeViewMode(viewMode: EContentLibViewMode): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      dispatch(ContentLibActions.setViewMode(viewMode));
    }
  }

  public static onBack(): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      history.back();
    }
  }

  public static onClickHelp (): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      dispatch(MainLayoutEffects.showHelp());
    }
  }



}