import { IState } from '../types';
import { TUserProfile } from './types';

export class ProfilesSelectors {

  public static isLoading(state: IState): boolean {
    return state.profiles.loading;
  }

  public static getList(state: IState): TUserProfile[] {
    return state.profiles.list;
  }
}