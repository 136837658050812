import { IState } from '../../../../../../store/types';
import { connect } from 'react-redux';
import { PhraseDetailsTabPromptForm } from '../../components/PhraseDetailsTabPromptForm';
import { IPhraseDetailsTabPromptFormEvents, IPhraseDetailsTabPromptFormFields } from '../../components/types';
import { getPhraseDetailsTabById, getUserGptPromptById } from '../../../../../../store/models/selectors';
import { PhraseDetailsTabsEditorPopupSelectors } from '../../../../../../store/phrase-details-tabs-editor-popup/selectors';
import { PhraseDetailsTabCustomPromptFormEvents } from './events';
import { PhraseDetailsCustomPromptVars } from '../../../../../../effects/phrase-details/phraseDetailsCustomPromptVars';
import { PhraseDetailsSelectors } from '../../../../../../store/phrase-details/selectors';
import { PhraseDetailsTabEffects } from '../../../../../../effects/phraseDetailsTabEffects';


const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): IPhraseDetailsTabPromptFormFields => {
    const tabId = PhraseDetailsTabsEditorPopupSelectors.getEditTabId(state);
    const tab = getPhraseDetailsTabById(state, tabId);
    let context = PhraseDetailsSelectors.getContext(state) || PhraseDetailsSelectors.getCaptionLineContext(state) || '';
    const prompt = tab && PhraseDetailsTabEffects.getCustomPromptByTab(tab);
    let phrase = PhraseDetailsSelectors.getText(state) || '';
    let isNoPhrase;
    if (!phrase) {
      isNoPhrase = true;
      const firstCaptionAsPhrase = PhraseDetailsTabsEditorPopupSelectors.getFirstCaptionAsPhrase(state);
      if (firstCaptionAsPhrase && firstCaptionAsPhrase.text) {
        phrase = firstCaptionAsPhrase.text;
        context = firstCaptionAsPhrase.context || '';
      }
    }
    const lastSaveTabId = PhraseDetailsTabsEditorPopupSelectors.getLastSaveTabId(state);
    const showSaveConfirm = lastSaveTabId > 0 && lastSaveTabId === tabId;

    return {
      id: tabId,
      show: true,
      showSaveConfirm,
      title: prompt?.title || '',
      prompt: prompt?.prompt || '',
      description: prompt?.description || '',
      processing: PhraseDetailsTabsEditorPopupSelectors.isProcessing(state),
      vars: PhraseDetailsCustomPromptVars.getList(),
      phrase,
      context,
      isNoPhrase,
    }
  };
}

const mapDispatchToProps = (
  dispatch: any
): IPhraseDetailsTabPromptFormEvents => ({
    onShow: () => dispatch(PhraseDetailsTabCustomPromptFormEvents.onShow()),
    onSave: (title, prompt, description) => dispatch(PhraseDetailsTabCustomPromptFormEvents.onSave(title, prompt, description)),
    onValidate: prompt => dispatch(PhraseDetailsTabCustomPromptFormEvents.onValidate(prompt)),
    onPromptChange: prompt => dispatch(PhraseDetailsTabCustomPromptFormEvents.onPromptChange(prompt)),
    onShowPhraseSelectPopup: () => dispatch(PhraseDetailsTabCustomPromptFormEvents.onShowPhraseSelectPopup()),
    onClickContext: () => dispatch(PhraseDetailsTabCustomPromptFormEvents.onClickContext()),
  }
);

export const PhraseDetailsTabCustomPromptFormHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(PhraseDetailsTabPromptForm);

PhraseDetailsTabCustomPromptFormHOC.displayName = 'PhraseDetailsTabCustomPromptFormHOC';