import { connect } from 'react-redux';
import { IState } from '../../../../../store/types';
import {
  getAuthUser,
  getIsCreatePublicProfileModalOpen,
  getPublicProfile
} from '../../../../../store/general/selectors';
import { ICreatePublicProfileModalEvents, ICreatePublicProfileModalFields } from '../components/types';
import { PUBLIC_PROFILE_USERNAME_MAX_LENGTH } from "../../../../../../common/constants";
import { CreatePublicProfileModalEvents } from "./events";
import { ICreatePublicProfileModalHOCProps } from "./types";
import { CreatePublicProfileModal } from "../components/CreatePublicProfileModal";

const mapStateToProps = (
    state: IState,
    { isShow: isShowOwn, onClose } : ICreatePublicProfileModalHOCProps,
): ICreatePublicProfileModalFields => {
  const user = getAuthUser(state);
  const publicProfile = getPublicProfile(state);
  const name = publicProfile?.name || user?.name || '';

  let userName = publicProfile?.userName ||
    name.toLowerCase().replaceAll(' ', '_').slice(0, PUBLIC_PROFILE_USERNAME_MAX_LENGTH);

  return {
    bio: publicProfile?.bio || '',
    isEdit: !!publicProfile,
    isShow: onClose ? !!isShowOwn : getIsCreatePublicProfileModalOpen(state),
    name,
    userName,
    avatar: publicProfile?.picture || user?.picture || '',
    showAvatar: publicProfile?.showAvatar,
  }
};

const mapDispatchToProps = (
    dispatch: any,
    { onClose } : ICreatePublicProfileModalHOCProps,
): ICreatePublicProfileModalEvents => {
  return {
    // checkNick: (nick) => dispatch(CreatePublicProfileModalEvents.checkNick(nick)),
    onClose: () => dispatch(CreatePublicProfileModalEvents.onClose(() => {
      if (onClose) onClose(false)
    })),
    onCreate: (profile) => dispatch(CreatePublicProfileModalEvents.onCreate(profile, () => {
      if (onClose) onClose(true)
    })),
  }
}

export const CreatePublicProfileModalHOC = connect(
    mapStateToProps,
    mapDispatchToProps)
(CreatePublicProfileModal);

CreatePublicProfileModalHOC.displayName = 'CreatePublicProfileModalHOC';
