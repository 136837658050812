import { setMainLayoutShowLeftPanelAction, setMainLayoutTypeAction, setMainViewMode, setVideoTutorialModeAction } from '../store/general/actions';
import { getDispatch, getState } from '../store';
import { getGeneralStorage } from '../../common/utils/local-storage/local-storage-helpers';
import { getMainLayoutType, getMainViewMode } from '../store/general/selectors';
import { EMainLayoutType, EMainViewMode } from '../store/general/types';
import { AppThunk } from '../store/types';
import { PhraseDetailsTabsEditorPopupEffects } from './phrase-details-tabs-editor-popup/phrase-details-tabs-editor-popup';
import { EVideoTutorialType } from '../store/models/types';

export class MainLayoutEffects {

  public static showLeftPanel(show: boolean) {
    const dispatch = getDispatch();
    dispatch(setMainLayoutShowLeftPanelAction(show));
    getGeneralStorage().setShowLeftPanel(show);
  }

  public static toggleLayoutType() {
    let type = getMainLayoutType(getState());
    type = type === EMainLayoutType.COLUMNS_3 ? EMainLayoutType.COLUMNS_4 : EMainLayoutType.COLUMNS_3;
    MainLayoutEffects.setLayoutType(type);
  }

  public static setLayoutType(type: EMainLayoutType) {
    const dispatch = getDispatch();
    dispatch(setMainLayoutTypeAction(type));
    getGeneralStorage().setMainLayoutType(type);
  }

  public static backToDashboard (): AppThunk {
    return (
      dispatch
    ) => {
      dispatch(setMainViewMode(EMainViewMode.DASHBOARD));
      dispatch(PhraseDetailsTabsEditorPopupEffects.closePhraseDetailsTabsEditorPopup());
    }
  }

  public static showHelp (): AppThunk {
    return (
      dispatch,
      getState,
    ) => {
      const mainView = getMainViewMode(getState());
      const videoTutorails = EMainViewMode.PHRASE_DETAILS_TABS_EDITOR === mainView ? EVideoTutorialType.PROMPTS : EVideoTutorialType.COMMON;
      dispatch(setVideoTutorialModeAction(videoTutorails));
    }
  }
}