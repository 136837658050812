import React from 'react';
import { Modal } from '../../../../../common/components/Modal/Modal';
import { Button, CircularProgress, Stack, TextField } from '@mui/material';
import { theme } from '../../../../../common/styles/theme';
import { Text } from '../../../../../common/components/Text/Text';
import { IEditGroupProps } from './types';
import { LangSelectHOC } from "../../LangSelect/hocs/LangSelectHOC";
import { ControlSwitch } from '../../Controls/component/ControlSwitch';
import { PUBLIC_PLAYLIST_TEXT, PUBLIC_PROFILE_TEXT } from '../../CreatePlaylist/components/wordings';
import { CreatePublicProfileModalHOC } from '../../PublicProfile/CreatePublicProfileModal/hocs/CreatePublicProfileModalHOC';

export const EditGroup: React.FC<IEditGroupProps> = ({
	show,
	isPublicProfile,
	title,
	targetLangCode,
	nativeLangCode,
	publicGroup,
	onSave,
	onClose
}) => {
	if (!show) return null;

	const [error, setError] = React.useState<string>();
	const [createProgress, setCreateProgress] = React.useState<boolean>(false);
	const [groupTitle, setGroupTitle] = React.useState<string>('');
	const [groupPublic, setGroupPublic] = React.useState<boolean>(false);
	const [isPublicCreate, setPublicCreate] = React.useState(false);

	React.useEffect(() => {
		setGroupTitle(title);
		setGroupPublic(publicGroup);
		setCreateProgress(false);
		setPublicCreate(false);
		setError('');
	}, [show, title, targetLangCode, nativeLangCode, publicGroup]);

	const handleSaveGroup = async () => {
		if (error) return;
		setCreateProgress(true);

		if (!isPublicProfile && groupPublic) {
			setPublicCreate(true);
		} else {
			await onSave(groupTitle, groupPublic);
			onClose();
		}
	}

	const onTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setGroupTitle(e.target.value);
	}

	return (<>
		<Modal
			onClose={onClose}
			open={show && !isPublicCreate}
			title={'Edit Playlist'}
		>
			<Stack
				gap={theme.spacing(2)}
				sx={{
					width: theme.spacing(64),
					maxWidth: '100%',
				}}>

				<Stack
					flexDirection={'row'}
					gap={theme.spacing(2)}
					justifyContent={'space-between'}
					sx={{
						'.MuiInputBase-root.Mui-disabled': {
							background: theme.palette.grey[100],
						},

						'.MuiFormControl-root': {
							m: 0,
						},

						[theme.breakpoints.down('sm')]: {
							flexWrap: 'wrap',
						},
					}}
				>
					<LangSelectHOC
						disabled
						label={'Target language'}
						langCode={targetLangCode}
					/>
					<LangSelectHOC
						disabled
						label={'Native language'}
						langCode={nativeLangCode}
					/>
				</Stack>

				<TextField
					label={'Title'}
					variant={'filled'}
					fullWidth
					value={groupTitle}
					onChange={onTitleChange}
					multiline={false}
				/>

				<Stack
					sx={{ bgcolor: theme.palette.primary[50], p: theme.spacing(1.5), borderRadius: theme.spacing() }}
					gap={theme.spacing()}
				>
					<ControlSwitch
						title='LangMagic library'
						checked={groupPublic}
						onChange={() => setGroupPublic(!groupPublic)}
						labelPlacement={'end'}
						sx={{ mt: 0, gap: theme.spacing(1.5), width: 'fit-content', '.MuiSwitch-root': { m: 0 }, }}
					/>
					<Text sx={{ color: theme.palette.grey[500] }}>
						{PUBLIC_PLAYLIST_TEXT}
						{!isPublicProfile && (' ' + PUBLIC_PROFILE_TEXT)}
					</Text>
				</Stack>

				<Stack
					gap={theme.spacing(2)}
					direction={'row'}
					alignItems={'center'}
					sx={{
						mt: theme.spacing(1),
					}}
				>
					<Button
						disabled={!nativeLangCode || !targetLangCode || !!error || createProgress}
						onClick={handleSaveGroup}
						variant={'contained'}
						sx={{
							alignSelf: 'flex-start',
							mt: 1,
						}}
					>
						Save playlist
					</Button>
					{createProgress && <CircularProgress size={theme.spacing(3)} />}
				</Stack>
			</Stack>
		</Modal>
		<CreatePublicProfileModalHOC
			onClose={(isApply) => {
				setPublicCreate(false);
				if (isApply) {
					onSave(groupTitle, groupPublic);
				}
				onClose();
			}}
			isShow={isPublicCreate}
		/>
	</>);
}
