import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { IPhraseTextFields } from '../components/types';
import { PhraseText } from '../components/PhraseText';
import { PhraseDetailsSelectors } from '../../../../../store/phrase-details/selectors';

const mapStateToProps = (
	dispatch: any
) => {
	return (
		state: IState
	): IPhraseTextFields => {
		const text = PhraseDetailsSelectors.getSelectText(state) || '';
		const fromLangCode = PhraseDetailsSelectors.getFromLang(state)?.code || '';
		return {
			fromLangCode,
			text,
		}
	};
}

export const PhraseTextHOC = connect(
	mapStateToProps,
	null
)(PhraseText);

PhraseTextHOC.displayName = 'PhraseTextHOC';
