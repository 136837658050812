import React from 'react';
import { IPhraseTextProps } from './types';
import { theme } from '../../../../../../common/styles/theme';
import { TextWithSpeakerHOC } from '../../../../../../common/components/TextWithSpeaker/hocs/TextWithSpeakerHOC';
import { Text } from '../../../../../../common/components/Text/Text';

export const PhraseText: React.FC<IPhraseTextProps> = ({
	text,
	fromLangCode,
}) => {

	return (
		<TextWithSpeakerHOC
			lang={fromLangCode}
			children={
				<Text
					sx={{
						fontSize: theme.typography.h5,
						fontWeight: 400,
					}}
				>
					{text}
				</Text>
			}
			text={String(text)}
		/>
	);

}
