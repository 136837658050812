import { AppThunk } from '../../../../store/types';
import { setShowOpenPublicProfilePopupAction } from '../../../../store/general/actions';
import { getPublicProfile } from "../../../../store/general/selectors";
import { Navigator } from "../../../../effects/navigator";

export class OpenPublicProfilePopupEvents {

  public static onClose(): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      dispatch(setShowOpenPublicProfilePopupAction(false));
    }
  }

  public static onOpen(): AppThunk {
    return async (
        dispatch,
        getState
    ) => {
      const publicProfile = getPublicProfile(getState());
      if (publicProfile) Navigator.openPublicProfile(publicProfile.userName);
      dispatch(setShowOpenPublicProfilePopupAction(false));
    }
  }

}
