import React from 'react';
import { ISavedVideoConfirmPopupProps } from './types';
import { theme } from '../../../../../../common/styles/theme';
import { SavedVideoConfirmSelectGroupHOC } from '../../select-group/hocs/SavedVideoConfirmSelectGroupHOC';
import { ESavedVideoConfirmShowMode } from '../../../../../store/general/types';
import { SavedVideoConfirmNotifyHOC } from '../../notify/hocs/SavedVideoConfirmNotifyHOC';
import { Snackbar } from "../../../../../../common/components/Snackbar/Snackbar";
import { SavedVideoConfirmErrorHOC } from '../../error/hocs/SavedVideoConfirmErrorHOC';


export const SavedVideoConfirmPopup: React.FC<ISavedVideoConfirmPopupProps> = ({
  showMode,
  onClose
}) => {

  return (
    <Snackbar
      open={showMode !== ESavedVideoConfirmShowMode.HIDE}
      onClose={onClose}
      sx={{ width: theme.spacing(37.5) }}
    >
     <>
       {showMode === ESavedVideoConfirmShowMode.NOTIFY && <SavedVideoConfirmNotifyHOC/>}
       {showMode === ESavedVideoConfirmShowMode.SELECT_GROUP && <SavedVideoConfirmSelectGroupHOC/>}
       {showMode === ESavedVideoConfirmShowMode.ERROR && <SavedVideoConfirmErrorHOC/>}
     </>
    </Snackbar>
  );
}
