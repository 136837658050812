import { CaptionsSelectionPopupActions } from '../store/captions-selection-popup/actions';
import { PhraseEffects } from './phrase/PhraseEffects';
import { getDispatch, getState } from '../store';
import { PlayerController } from './player/manager/playerController';
import { getMainViewMode } from '../store/general/selectors';
import { EMainViewMode } from '../store/general/types';
import { CaptionsSelectionPopupSelectors } from '../store/captions-selection-popup/selectors';

export class SelectionPopupEffects {

  public static dismissSelectText(unboundClick: boolean = false) {
    const dispatch = getDispatch();
    if (CaptionsSelectionPopupSelectors.isShow(getState())) {
      dispatch(CaptionsSelectionPopupActions.setShow(false));
      dispatch(CaptionsSelectionPopupActions.setSelectResult(null));
      dispatch(PhraseEffects.deleteCurrentPreviewPhrase());
      const viewMode = getMainViewMode(getState())
      if (unboundClick && viewMode === EMainViewMode.DASHBOARD) {
        PlayerController.getInstance().onDeselectText();
      }
    }
  }
}