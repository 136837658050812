import {
  CHANGE_AUTO_SYNC,
  ECurrentFocusArea,
  EMainLayoutType,
  EMainViewMode,
  EPlayMode,
  ESavedVideoConfirmShowMode,
  IGeneralActions,
  IGeneralState,
  IUserTranslateLangAdd,
  IUserTranslateLangDelete,
  IUserTranslateLangsUpdate,
  SET_ADD_VIDEO_CONFIRM,
  SET_ADD_VIDEO_ON_LOGIN,
  SET_APP_SETTINGS,
  SET_AUTH_USER,
  SET_AUTO_PAUSE_SIZE,
  SET_CAPTION_SELECT_STATUS,
  SET_COPY_GROUP_ON_LOGIN,
  SET_COPY_VIDEO_CONFIRM,
  SET_COPY_VIDEO_ON_LOGIN,
  SET_CREATE_PLAYLIST_MODAL_OPEN,
  SET_CREATE_PLAYLIST_SAVE_MODAL_OPEN,
  SET_CURRENT_FOCUS_AREA,
  SET_EDIT_PLAYLIST_MODAL_ID,
  SET_EDIT_PLAYLIST_MODAL_OPEN,
  SET_EXTENSION_INSTALLED,
  SET_FEEDBACK_MODAL_OPEN,
  SET_FLASH_PHRASE_ID,
  SET_FLASH_VIDEO_ID,
  SET_HOVER_AUTO_PAUSE,
  SET_LIBRARY_GROUP,
  SET_MAIN_LAYOUT_SHOW_LEFT_PANEL,
  SET_MAIN_LAYOUT_TYPE,
  SET_MAIN_VIEW_MODE,
  SET_ONBOARDING,
  SET_ONE_TIME_TOOLTIP,
  SET_PAUSE_ON_NOTE,
  SET_PHRASES_EXIST,
  SET_PLAY_MODE,
  SET_PLAYER_RATE,
  SET_PLAYER_STATE,
  SET_SAVED_VIDEO_CONFIRM,
  SET_SHOW_ADD_NEW_VIDEO_POPUP,
  SET_SHOW_NATIVE_CAPTIONS,
  SET_SHOW_NEW_FEATURES_POPUP,
  SET_SHOW_PIN_EXTENSION_POPUP,
  SET_SHOW_TELEGRAM_BOT_POPUP,
  SET_SNACKBAR_PANEL,
  SET_TEXT_TRANSLATION,
  SET_VIDEO_TUTORIAL_MODE,
  SET_VOCABULARY_PHRASE_DETAILS_HEIGHT, SHOW_INSTALL_EXTENSION_WARNING_POPUP_ACTION,
  SHOW_ONE_TIME_TOOLTIP_NATIVE_LANGUAGE,
  SHOW_ONE_TIME_TOOLTIP_PHRASES_EDIT,
  SYNC_USER_LOCAL_STORAGE,
  TAuthUser,
  TAuthUserTranslateLang,
  TOGGLE_SETTINGS_MODAL, USER_TEACHER_SET_MODE,
  USER_TRANSLATE_LANG_ADD,
  USER_TRANSLATE_LANG_DELETE,
  USER_TRANSLATE_LANGS_UPDATE,
  VOCABULARY_PHRASE_DETAILS_HEIGHT_LOCAL_STORAGE
} from './types';
import { Reducer } from 'redux';
import { PlayerStates } from '../../types/common';
import { DEFAULT_VOCABULARY_PHRASE_DETAILS_HEIGHT } from '../../../common/constants';

const initialGeneralState: IGeneralState = {
  isEditPlaylistModalOpen: false,
  isCreatePlaylistModalOpen: false,
  isCreatePlaylistSaveModalOpen: false,
  editPlaylistModalId: 0,
  autoPause: 0,
  showCaptionSelect: false,
  autoSync: true,
  hoverAutoPause: false,
  authUser: null,
  libraryGroupId: null,
  playerState: PlayerStates.BUFFERING,
  playerRate: 1,
    vocabularyPhraseDetailsHeight: Number(localStorage.getItem(VOCABULARY_PHRASE_DETAILS_HEIGHT_LOCAL_STORAGE)) || DEFAULT_VOCABULARY_PHRASE_DETAILS_HEIGHT,
  playMode: EPlayMode.DEFAULT,
  currentFocusArea: ECurrentFocusArea.MAIN,
  showNewFeaturesPopup: false,
  extensionInstalled: false,
  showPinExtensionPopup: false,
  savedVideoConfirm: {
    showMode: ESavedVideoConfirmShowMode.HIDE
  },
  copyVideoConfirm: {
    show: false
  },
  snackbarPanel: {
    show: false,
    text: ''
  },
  textTranslation: {
    active: false
  },
  oneTimeTooltip: {
    showPhrasesEditTooltip: false,
    showNativeLanguageTooltip: false,
    showNativeAvailableTooltip: true,
    showTutorialPopup: true,
    showTutorialTooltip: true,
  },
  showTelegramBotPopup: false,
  showNewVideoPopup: false,
  mainViewMode: EMainViewMode.DASHBOARD,
  phrasesExist: false,
  mainLayout: {
    showLeftPanel: true,
    type: EMainLayoutType.COLUMNS_4
  },
  showNativeCaptions: false,
  onboarding: {},
  appSettings: {
    saveVideoCaptions: false
  },
  pauseOnNote: false,
  addVideoConfirm: {
    show: false,
  },
  showInstallExtensionWarningPopup: false
};

const getInitialGeneralState = (): IGeneralState => {
    const state: IGeneralState = {...initialGeneralState};
    return state;
}

export const generalReducer: Reducer<IGeneralState, IGeneralActions> = (
    state= getInitialGeneralState(),
    action
) => {

    switch (action.type) {
      case SYNC_USER_LOCAL_STORAGE:
        return {
          ...state,
          autoPause: action.data.autoPause,
          showPinExtensionPopup: action.data.showPinExtensionPopup,
          mainLayout: {
            ...state.mainLayout,
            showLeftPanel: action.data.showLeftPanel,
            type: action.data.mainLayoutType
          }
        }
      case TOGGLE_SETTINGS_MODAL:
        const modalState =
          typeof action.forceState !== 'undefined' ? action.forceState : !state.isEditPlaylistModalOpen;
        return {
          ...state,
          isSettingsModalOpen: modalState
        };
        case SET_EDIT_PLAYLIST_MODAL_OPEN:
            return {
                ...state,
              isEditPlaylistModalOpen: action.open
            };
        case SET_CREATE_PLAYLIST_MODAL_OPEN:
            return {
                ...state,
                isCreatePlaylistModalOpen: action.open
            };
      case SET_CREATE_PLAYLIST_SAVE_MODAL_OPEN:
        return {
          ...state,
          isCreatePlaylistSaveModalOpen: action.open
        };
      case SET_FEEDBACK_MODAL_OPEN:
        return {
          ...state,
          isFeedbackModalOpen: action.open
        };
      case SET_EDIT_PLAYLIST_MODAL_ID:
        return {
          ...state,
          editPlaylistModalId: action.id
        };
        case CHANGE_AUTO_SYNC:
            return {
                ...state,
                autoSync: action.state
            };
        case SET_CAPTION_SELECT_STATUS:
            return {
                ...state,
                showCaptionSelect: action.status
            };
        case SET_AUTH_USER:
            return {
                ...state,
                authUser: action.user
            };
        case SET_AUTO_PAUSE_SIZE:
            return {
                ...state,
                autoPause: action.pause
            }
        case SET_VOCABULARY_PHRASE_DETAILS_HEIGHT:
            localStorage.setItem(VOCABULARY_PHRASE_DETAILS_HEIGHT_LOCAL_STORAGE, String(action.height));
            return {
                ...state,
                vocabularyPhraseDetailsHeight: action.height
            }
        case SET_LIBRARY_GROUP:
            return {
                ...state,
                libraryGroupId: action.groupId ? +action.groupId : null
            }
        case SET_PLAYER_STATE:
            return {
                ...state,
                playerState: action.state
            }
        case SET_PLAYER_RATE:
            return {
                ...state,
                playerRate: action.rate
            }
        case SET_PLAY_MODE:
            return {
                ...state,
                playMode: action.mode
            }
        case SET_CURRENT_FOCUS_AREA:
            return {
                ...state,
                currentFocusArea: action.currentFocusArea
            }
      case SET_HOVER_AUTO_PAUSE:
        return { ...state, hoverAutoPause: action.hoverAutoPause }
      case SET_SHOW_NEW_FEATURES_POPUP:
        return { ...state, showNewFeaturesPopup: action.show }
      case SET_EXTENSION_INSTALLED:
        return { ...state, extensionInstalled: action.extensionInstalled }
      case SET_SAVED_VIDEO_CONFIRM:
     //   return { ...state, savedVideoConfirm: {...action.value}}
        return { ...state, savedVideoConfirm: {...state.savedVideoConfirm, ...action.value}}
      case SET_ADD_VIDEO_ON_LOGIN:
        return { ...state, addVideoOnLogin: {groupId: action.groupId, videoUrl: action.videoUrl } }
      case SET_COPY_VIDEO_ON_LOGIN:
        return { ...state, copyVideoOnLogin: {...state.copyVideoOnLogin, ...action.data } }
      case SET_COPY_GROUP_ON_LOGIN:
        return { ...state, copyGroupOnLogin: {hash: action.hash } }
      case SET_SHOW_PIN_EXTENSION_POPUP:
        return { ...state, showPinExtensionPopup: action.show }
      case SET_TEXT_TRANSLATION:
        return { ...state, textTranslation: action.textTranslation }
      case SHOW_ONE_TIME_TOOLTIP_PHRASES_EDIT:
        return {
          ...state,
          oneTimeTooltip: {
            ...state.oneTimeTooltip,
            ...{
              showPhrasesEditTooltip: action.show
            }
          }
        }
      case SHOW_ONE_TIME_TOOLTIP_NATIVE_LANGUAGE:
        return {
          ...state,
          oneTimeTooltip: {
            ...state.oneTimeTooltip,
            ...{
              showNativeLanguageTooltip: action.show
            }
          }
        }
      case SET_ONE_TIME_TOOLTIP:
        action
        return {
          ...state,
          oneTimeTooltip: {
            ...state.oneTimeTooltip,
            ...action.value,
          }
        }
      case SET_SHOW_TELEGRAM_BOT_POPUP:
        return { ...state, showTelegramBotPopup: action.show }
      case SET_VIDEO_TUTORIAL_MODE:
        return { ...state, videoTutorialMode: action.value }
      case SET_SHOW_ADD_NEW_VIDEO_POPUP:
        return { ...state, showNewVideoPopup: action.show }
      case SET_MAIN_VIEW_MODE:
        return { ...state, mainViewMode: action.mainViewMode }
      case SET_PHRASES_EXIST:
        return { ...state, phrasesExist: action.exist }
      case SET_FLASH_VIDEO_ID:
        return { ...state, flashVideoId: action.flashVideoId }
      case SET_FLASH_PHRASE_ID:
        return { ...state, flashPhraseId: action.flashPhraseId }
      case USER_TRANSLATE_LANG_ADD:
        return userTranslateLangAdd(state, action);
      case USER_TRANSLATE_LANG_DELETE:
        return userTranslateLangDelete(state, action);
      case USER_TRANSLATE_LANGS_UPDATE:
        return userTranslateLangsUpdate(state, action);
      case USER_TEACHER_SET_MODE:
        return {
          ...state,
          authUser: {
            ...state.authUser,
            ...{teacherMode: action.teacherMode}
          }}
      case SET_MAIN_LAYOUT_TYPE:
        return { ...state, mainLayout: {...state.mainLayout, type: action.layoutType}};
      case SET_MAIN_LAYOUT_SHOW_LEFT_PANEL:
        return { ...state, mainLayout: {...state.mainLayout, showLeftPanel: action.showLeftPanel}}
      case SET_SHOW_NATIVE_CAPTIONS:
        return { ...state, showNativeCaptions: action.show }
      case SET_ONBOARDING:
        return {
          ...state,
          onboarding: {
            ...state.onboarding,
            ...action.onboarding,
          }
        }
      case SET_SNACKBAR_PANEL:
        return {
          ...state,
          snackbarPanel: {
            show: action.show,
            text: action.text
          }
        }
      case SET_COPY_VIDEO_CONFIRM:
        return {
          ...state,
          copyVideoConfirm: {
            ...state.copyVideoConfirm,
            ...action.copyVideoConfirm
          }
        }
      case SET_APP_SETTINGS:
        return {
          ...state,
          appSettings: {
            ...state.appSettings,
            ...action.value
          }
        }
      case SET_PAUSE_ON_NOTE:
        return { ...state, pauseOnNote: action.pauseOnNote }

      case SET_ADD_VIDEO_CONFIRM:
        return {
          ...state,
          addVideoConfirm: {
            ...state.addVideoConfirm,
            ...action.value,
          }
        }

      case SHOW_INSTALL_EXTENSION_WARNING_POPUP_ACTION:
        return { ...state, showInstallExtensionWarningPopup: action.show }

      default:
          return state;
    }
};

const userTranslateLangAdd = (state: IGeneralState, action: IUserTranslateLangAdd): IGeneralState => {
  if (!state.authUser) return state;
  const lang: TAuthUserTranslateLang = {code: action.langCode, orderNum: action.orderNum};
  const translateLangs = [...state.authUser.translateLangs, lang];
  const authUser: TAuthUser = { ...state.authUser, ...{translateLangs}};
  return { ...state, authUser};
}

const userTranslateLangDelete = (state: IGeneralState, action: IUserTranslateLangDelete): IGeneralState => {
  if (!state.authUser) return state;
  const translateLangs = state.authUser.translateLangs.filter(lang => lang.code !== action.langCode);
  const authUser: TAuthUser = { ...state.authUser, ...{translateLangs}};
  return { ...state, authUser};
}

const userTranslateLangsUpdate = (state: IGeneralState, action: IUserTranslateLangsUpdate): IGeneralState => {
  if (!state.authUser) return state;
  const authUser: TAuthUser = { ...state.authUser, ...{translateLangs: action.langs}};
  return { ...state, authUser};
}
