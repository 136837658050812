import React from 'react';
import { Box, Button, CircularProgress, IconButton, Stack, SxProps } from '@mui/material';
import { theme } from '../../../../../../common/styles/theme';
import { IPhraseCustomPromptProps } from './types';
import { TextWithSpeakerHOC } from '../../../../../../common/components/TextWithSpeaker/hocs/TextWithSpeakerHOC';
import { RedoIcon } from '../../../../../../../icons/RedoIcon';
import { PhraseDetailsCustomPromptVars } from '../../../../../effects/phrase-details/phraseDetailsCustomPromptVars';
import { NoPhraseHint } from '../../../OneTimeTooltip/components/NoPhraseHint/NoPhraseHint';
import { Divider } from '../../../../../../common/components/Divider/Divider';
import { CopyNote } from '../../../../../../../icons/CopyNote';
import { PROMPTS_HEADER_BREAKPOINT } from '../../PhrasePromptHeader/constants';
import { CLASS_PHRASE_DETAILS_COLUMN_SCROLL } from '../../PhraseDetailsContainer/components/types';
import { DeviceContext } from '../../../../../../common/contexts/DeviceContext';

export const PhraseCustomPrompt: React.FC<IPhraseCustomPromptProps> = ({
	tabId,
	text,
	context,
	fromLangCode,
	toLangCode,
	resultText,
	processing,
	prompt,
	onLoad,
	onClickSettings,
	onClickContext,
	onCopyNote
}) => {
	const {isMobile} = React.useContext(DeviceContext);

	const buttonStyle: SxProps = {
		height: theme.spacing(4),
		p: theme.spacing(1),
		fontSize: theme.typography.fontSize,
		color: theme.palette.grey[400],
	};

	const dividerStyle = {
		height: theme.spacing(2),
		width: '2px',
		m: '8px 0 0',
	};

	const [isContext, setContext] = React.useState(false);

	React.useEffect(() => {
		if (tabId && text) {
			onLoad(true);

			setContext(PhraseDetailsCustomPromptVars.isContextVarExist(prompt));
		}
	}, [tabId, text, context, fromLangCode, toLangCode, prompt]);

	const [isSmall, setSmall] = React.useState(false);

	const resultRef = React.useRef(null);
	React.useEffect(() => {
		if (resultRef.current) {
			const scroll = document.querySelector(`.${CLASS_PHRASE_DETAILS_COLUMN_SCROLL}`) as HTMLElement;
			const scrollTop = scroll?.scrollTop || 0;
			const el = resultRef.current as HTMLElement;
			if (scrollTop < el.offsetTop) {
				scroll.scrollTo({
					top: el.offsetTop,
					behavior: 'smooth',
				})
			}

			let rect = el.getBoundingClientRect();
			setSmall(rect.width < PROMPTS_HEADER_BREAKPOINT);
		}
	}, [resultText]);

	return (processing
		? <Stack
			sx={{
				width: 1,
				my: theme.spacing(3),
				alignItems: 'center',
			}}
		>
			<CircularProgress size={theme.spacing(3)} />
		</Stack>
		: resultText
			? <Stack
				gap={theme.spacing(2)}
				sx={{
					position: 'relative',
					width: 1,
					pl: theme.spacing(3),
					pb: theme.spacing(2),
				}}
				ref={resultRef}
			>

<Stack
					className={`${isSmall ? 'newline' : 'collapsed'}`}
					flexDirection={'row'}
					justifyContent={'flex-end'}
					gap={theme.spacing(.5)}
					sx={{
						'&.collapsed': {
							position: 'absolute',
							zIndex: 10,
							top: theme.spacing(-5),
							right: 0,
						},
						'&.newline': {
							mt: theme.spacing(-1),
						},
					}}
				>
					{!isMobile && <>
						<Button
							onClick={onClickSettings}
							variant={'text'}
							sx={buttonStyle}
						>
							Prompt
						</Button>
						<Divider sx={dividerStyle} />
						{isContext && <>
							<Button
								onClick={onClickContext}
								variant={'text'}
								sx={buttonStyle}
							>
								Context
							</Button>
							<Divider sx={dividerStyle} />
						</>}
					</>}
					<IconButton
						onClick={() => onLoad(false)}
						sx={{...buttonStyle,
							width: theme.spacing(3),
						}}
					>
						<RedoIcon sx={{ width: theme.spacing(2.5), height: theme.spacing(2.5) }}/>
					</IconButton>
				</Stack>

				<TextWithSpeakerHOC
					text={resultText}
				/>

				<Box>
					<Button
						variant={'text'}
						sx={{
							height: theme.spacing(4),
							p: theme.spacing(1),
							fontSize: theme.typography.fontSize,
							color: theme.palette.grey[400],

							'.MuiButton-startIcon': {
								ml: theme.spacing(-1),
								mr: theme.spacing(.5),

								'.MuiSvgIcon-root': {
									width: theme.spacing(2),
									height: theme.spacing(2),
									
								},
							},
						}}
						startIcon={<CopyNote />}
						onClick={onCopyNote}
					>
						Copy to note
					</Button>
				</Box>

		</Stack>
		: !text
			? <NoPhraseHint />
			: null
	);

}
