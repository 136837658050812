import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { SavedVideoConfirmSelectGroup } from '../components/SavedVideoConfirmSelectGroup';
import { ISavedVideoConfirmSelectGroupEvents, ISavedVideoConfirmSelectGroupFields } from '../components/types';
import { getUserOwnGroups, getVideoById } from '../../../../../store/models/selectors';
import { getSavedVideoConfirm } from '../../../../../store/general/selectors';
import { SavedVideoConfirmSelectGroupEvents } from './events';

const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState,
  ): ISavedVideoConfirmSelectGroupFields => {
    const confirmState = getSavedVideoConfirm(state);
    const groupId = confirmState.groupId || 0;
    const groups = getUserOwnGroups(state);
    const video = confirmState.videoId ? getVideoById(state, confirmState.videoId) : null;
    const videoTitle = video ? video.info.title : '';
    return {
      groupId,
      groups,
      videoTitle
    }
  };
}

const mapDispatchToProps = (
  dispatch: any
): ISavedVideoConfirmSelectGroupEvents => ({
  onSave: groupId => dispatch(SavedVideoConfirmSelectGroupEvents.onSave(groupId))
});


export const SavedVideoConfirmSelectGroupHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(SavedVideoConfirmSelectGroup);

SavedVideoConfirmSelectGroupHOC.displayName = 'SavedVideoConfirmSelectGroupHOC';