import { AppThunk } from '../../../../../../store/types';
import {
  setFeedbackModalOpenAction,
  setMainViewMode,
  setOneTimeTooltipAction,
  setVideoTutorialModeAction
} from '../../../../../../store/general/actions';
import { EMainViewMode } from '../../../../../../store/general/types';
import { getVideoTutorialMode } from '../../../../../../store/general/selectors';
import { getDispatch } from '../../../../../../store';
import { PlayerManager } from '../../../../../../effects/player/manager/playerManager';
import { PhraseDetailsTabsEditorPopupActions } from '../../../../../../store/phrase-details-tabs-editor-popup/actions';
import { EVideoTutorialType } from '../../../../../../store/models/types';
import { historyPush } from '../../../../../../../common/utils/historyHelper';
import { MainLayoutEffects } from '../../../../../../effects/mainLayoutEffects';
import { getOneTimeTooltipsStorage } from '../../../../../../../common/utils/local-storage/onetime-tooltips';
import { EventsRouter } from '../../../../../../../common/events/eventsRouter';
import { Events } from '../../../../../../../common/events/types';
import { TVideoTutorialTimes } from '../../types';


export class VideoTutorialPopupEvents {

  public static onLoad(): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      await PlayerManager.getInstance().pausePlay();
    }
  }

  public static onChangeActive(id: EVideoTutorialType): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      dispatch(setVideoTutorialModeAction(id));
    }
  }

  public static onClose(): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      dispatch(setVideoTutorialModeAction(undefined));
      
      getOneTimeTooltipsStorage().setShow({ showTutorialPopup: false });
      dispatch(setOneTimeTooltipAction({ showTutorialPopup: false }));
    }
  }

  public static onClosed(
    activeId: EVideoTutorialType,
    times: TVideoTutorialTimes,
  ): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      let event = {
        ...Events.TUTORIAL_VIDEO_CLOSED,
        action: Events.TUTORIAL_VIDEO_CLOSED.action.replace('{0}', String(activeId))
      };
      let options = {};
      for (let i in times) {
        options['video' + i] = times[i];
      }
      EventsRouter.trackEvent(event, undefined, options);
    }
  }

  public static onPlay(
    activeId: EVideoTutorialType,
  ): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      let event = {
        ...Events.TUTORIAL_VIDEO_PLAYED,
        action: Events.TUTORIAL_VIDEO_PLAYED.action.replace('{0}', String(activeId))
      };
      EventsRouter.trackEvent(event);
    }
  }

  public static onButtonClick(): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      const activeId = getVideoTutorialMode(getState());
      if (activeId) {
        VideoTutorialPopupEvents.getEventHandler(activeId).onButtonClick();
      }
    }
  }

  private static getEventHandler(activeId: EVideoTutorialType) {
    return activeId === EVideoTutorialType.PROMPTS ?
      new PromptsVideoTutorialEventHandler() :
      new CommonVideoTutorialEventHandler();
  }

  public static onClickFeedback (): AppThunk {
    return (
      dispatch,
    ) => {
      dispatch(this.onClose());
      dispatch(setFeedbackModalOpenAction(true));
    }
  }

}

class CommonVideoTutorialEventHandler {
  public onButtonClick() {}
}

class PromptsVideoTutorialEventHandler {

  public onButtonClick() {
    const dispatch = getDispatch();
    
    dispatch(VideoTutorialPopupEvents.onClose());

    dispatch(setMainViewMode(EMainViewMode.PHRASE_DETAILS_TABS_EDITOR));
    dispatch(PhraseDetailsTabsEditorPopupActions.setShowFavoritePromptTooltip(true));
    setTimeout(() => {
      dispatch(PhraseDetailsTabsEditorPopupActions.setShowFavoritePromptTooltip(false));
    }, 10000);

    historyPush({
      state: { page: 'settings' },
      title: 'Settings',
      onpopstate: () => dispatch(MainLayoutEffects.backToDashboard())
    });

    EventsRouter.trackEvent(Events.TUTORIAL_VIDEO_ADD_PROMPT_CLICKED);
  };

}
