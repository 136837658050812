import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { SavedVideoConfirmNotify } from '../components/SavedVideoConfirmNotify';
import { ISavedVideoConfirmNotifyEvents, ISavedVideoConfirmNotifyFields } from '../components/types';
import { SavedVideoConfirmNotifyEvents } from './events';
import {
  createGroupNameIndex,
  getGroupNativeLanguage,
  getGroupTargetLanguage,
  getUserGroupById,
  getUserGroupTitle,
  getUserOwnGroups,
  getVideoById
} from '../../../../../store/models/selectors';
import { getSavedVideoConfirm } from '../../../../../store/general/selectors';

const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState,
  ): ISavedVideoConfirmNotifyFields => {
    const confirmState = getSavedVideoConfirm(state);
    const video = confirmState.videoId ? getVideoById(state, confirmState.videoId) : null;
    const videoInfo = video?.info;
    const videoLangCodes = videoInfo?.langList || confirmState.videoLangCodes || [];

    const group = confirmState.groupId ? getUserGroupById(state, confirmState.groupId) : null;
    const groupTitle = group ? getUserGroupTitle(state, group) : '';

    const groups = getUserOwnGroups(state)
      .filter(g => {
        const targetLang = g ? getGroupTargetLanguage(state, g) : null;
        return videoLangCodes && targetLang &&
          videoLangCodes.includes(targetLang.code);
      })
      .sort((a, b) => {
        if (a.id === confirmState.groupId) return -1;
        if (b.id === confirmState.groupId) return 1;
        return 0;
      });

    const srcGroupTargetLang = getGroupTargetLanguage(state, group as any);
    const srcGroupNativeLang = getGroupNativeLanguage(state, group as any);
    let newListName = createGroupNameIndex(state, `${srcGroupTargetLang?.name} / ${srcGroupNativeLang?.name}`);

    return {
      videoAuthor: videoInfo?.author || '',
      videoImg: videoInfo?.thumb || '',
      videoLangCodes,
      videoTitle: videoInfo?.title || '',
      groupId: confirmState.groupId || 0,
      groupTitle,
      groups,
      newListName,
    }
  };
}


const mapDispatchToProps = (
  dispatch: any
): ISavedVideoConfirmNotifyEvents => ({
  onSelectGroup: (groupId, newListName) => dispatch(SavedVideoConfirmNotifyEvents.onSelectGroup(groupId, newListName))
});


export const SavedVideoConfirmNotifyHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(SavedVideoConfirmNotify);

SavedVideoConfirmNotifyHOC.displayName = 'SavedVideoConfirmNotifyHOC';