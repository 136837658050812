import { AppThunk, IState } from '../../../../../store/types';
import { PlayerController } from '../../../../../effects/player/manager/playerController';
import { isCurrentAudioMovie } from '../../../../../store/current-video/selectors';
import { AudioPlayerManager } from '../../../../../effects/audioPlayer/audioPlayerManager';
import {
  getVideo,
  getVideoTeacherNoteByPhraseId,
  getVideoTeacherPhraseById,
  getVideoTeacherUserActiveId
} from '../../../../../store/videos/selectors';
import { setVideoTeacherShowNoteIdAction } from '../../../../../store/videos/actions';
import { getPhraseDetailsTabByType } from '../../../../../store/models/selectors';
import { EPhraseDetailsTabType } from '../../../../../store/models/types';
import { PhraseDetailsActions } from '../../../../../store/phrase-details/actions';
import { batch } from 'react-redux';
import { getPlayerState } from '../../../../../store/general/selectors';
import { PlayerStates } from '../../../../../types/common';

export class TeacherPhraseEvents {

  public static onPlayPhrase(phraseId: number, isPause?: boolean): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      if (isPause) {
        PlayerController.getInstance().pause();
      } else {
        const userId = getVideoTeacherUserActiveId(getState());
        const phrase = getVideoTeacherPhraseById(getState(), userId, phraseId);
        if (phrase) {
          if (isCurrentAudioMovie(getState())) {
            AudioPlayerManager.getInstance().playPhrase(phrase);
          } else {
            PlayerController.getInstance().playPhrase(phrase);
          }
        }
      }
    }
  }

  public static onClickPhrase(phraseId: number): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      const state = getState();
      if (getPlayerState(getState()) === PlayerStates.PLAYING) {
        PlayerController.getInstance().pause();
      }
      const userId = getVideoTeacherUserActiveId(state);
      const contextPhrase = getVideoTeacherPhraseById(state, userId, phraseId);
      const note = getVideoTeacherNoteByPhraseId(state, userId, contextPhrase?.wordPhraseId);
      if (note) {
        const noteTab = getPhraseDetailsTabByType(state, EPhraseDetailsTabType.NOTES);
        batch(() => {
          dispatch(setVideoTeacherShowNoteIdAction(note.id));
          dispatch(PhraseDetailsActions.setActiveTab(noteTab));
        })
      }

    }
  }


}