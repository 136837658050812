import { API_URL } from '../../constants';
import { getDispatch } from '../../../spa/store';
import { postRequest } from '../../utils/requestService';

export type TCreateCopyLinkBody = {
  groupId: number;
};

type TCreateCopyLinkResponse = {
  hash: string;
};

export type TCreateCopyResponse = {
  groupId: number;
}

export type TGetCopyInfoResponse = {
  targetLangCode?: string,
  nativeLangCode?: string
}

export class UserGroupCopyRest {

  private static CREATE_COPY_LINK_URL = API_URL + '/groups/createCopyLink';
  private static CREATE_COPY_URL = API_URL + '/groups/createCopy';
  private static CREATE_GET_COPY_INFO_URL = API_URL + '/groups/getCopyInfo';

  public static async createCopyLink(data: TCreateCopyLinkBody): Promise<string> {
    const dispatch = getDispatch();
    const resp: TCreateCopyLinkResponse = await dispatch(postRequest(UserGroupCopyRest.CREATE_COPY_LINK_URL, data));
    return resp?.hash || '';
  }

  public static async createCopy(hash: string): Promise<TCreateCopyResponse> {
    const dispatch = getDispatch();
    return await dispatch(postRequest(UserGroupCopyRest.CREATE_COPY_URL, {hash}));
  }

  public static async getCopyInfo(hash: string): Promise<TGetCopyInfoResponse> {
    const dispatch = getDispatch();
    return await dispatch(postRequest(UserGroupCopyRest.CREATE_GET_COPY_INFO_URL, {hash}));
  }

}