import { API_URL } from '../../constants';
import { getDispatch } from '../../../spa/store';
import { postRequest } from '../../utils/requestService';

export type TPhraseNoteSaveBody = {
  phraseId: number;
  text: string;
  pause: boolean;
  teacherMode: boolean;
}

export type TPhraseNoteUpdateBody = {
  noteId: number;
  text?: string;
  pause?: boolean;
  teacherMode: boolean;
}

export type TPhraseResponse = {
  result: string
}

export class PhraseNoteRest {

  private static FIND_URL = API_URL + '/phraseNote/find';
  private static SAVE_URL = API_URL + '/phraseNote/save';
  private static UPDATE_URL = API_URL + '/phraseNote/update';
  private static REMOVE_URL = API_URL + '/phraseNote/remove';

  public static async find(phraseId: number): Promise<string> {
    const dispatch = getDispatch();
    const resp: TPhraseResponse = await dispatch(postRequest(PhraseNoteRest.FIND_URL, {phraseId}));
    return resp.result;
  }

  public static async save(body: TPhraseNoteSaveBody): Promise<any> {
    const dispatch = getDispatch();
    return await dispatch(postRequest(PhraseNoteRest.SAVE_URL, body));
  }

  public static async update(body: TPhraseNoteUpdateBody): Promise<any> {
    const dispatch = getDispatch();
    return await dispatch(postRequest(PhraseNoteRest.UPDATE_URL, body));
  }

  public static async remove(phraseId: number, teacherMode: boolean): Promise<void> {
    const dispatch = getDispatch();
    await dispatch(postRequest(PhraseNoteRest.REMOVE_URL, {phraseId, teacherMode}));
  }
}