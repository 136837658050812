import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { PhraseNotes } from '../components/PhraseNotes';
import { IPhraseNotesEvents, IPhraseNotesFields } from '../components/types';
import { PhraseDetailsSelectors } from '../../../../../store/phrase-details/selectors';
import { PhraseNotesEvents } from './events';
import { getPlayerState } from '../../../../../store/general/selectors';
import { IPhraseNote, PlayerStates } from '../../../../../types/common';
import { EPhraseDetailsTabType } from '../../../../../store/models/types';
import { CaptionsSelectionPopupSelectors } from '../../../../../store/captions-selection-popup/selectors';
import { getCurrentVideoClickedCaptionIndex } from '../../../../../store/current-video/selectors';

const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): IPhraseNotesFields => {
    const activeTab = PhraseDetailsSelectors.getActiveTab(state);
    const active = !!(activeTab && activeTab.type === EPhraseDetailsTabType.NOTES);
    const playVideo = getPlayerState(state) === PlayerStates.PLAYING;
    let phraseId = 0;
    let canEdit;
    let selectResult;
    let clickedCaptionIndex;

    if (playVideo) {
      phraseId = PhraseDetailsSelectors.getNotePhraseId(state) || 0;
      canEdit = false;
    } else {
      selectResult = CaptionsSelectionPopupSelectors.getSelectResult(state);
      phraseId = PhraseDetailsSelectors.getPhraseId(state) || 0;
      clickedCaptionIndex = getCurrentVideoClickedCaptionIndex(state);
      canEdit = !!(selectResult || phraseId || clickedCaptionIndex >= 0);
    }
    const note = PhraseDetailsSelectors.getCurrentNote(state);
    const pause = note?.pause || false;
    const processing = PhraseDetailsSelectors.getNoteResultProcessing(state) || false;
    const text = note?.text || '';
    const isEdit = activeTab?.isNoteEdit;
    return {
      active,
      phraseId,
      selectResult,
      clickedCaptionIndex,
      text,
      processing,
      canEdit,
      pause,
      isEdit,
    }
  };
}

const mapDispatchToProps = (
  dispatch: any
): IPhraseNotesEvents => ({
    onSave: (note) => dispatch(PhraseNotesEvents.onSave(note)),
    onUpdate: () => dispatch(PhraseNotesEvents.onUpdate())
  }
);

export const PhraseNotesHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(PhraseNotes);

PhraseNotesHOC.displayName = 'PhraseNotesHOC';
