import { PhraseNoteRest, TPhraseNoteRest, TPhraseNoteUpdateBody } from '../../common/rest/phraseNote/phraseNoteRest';
import { savePhraseNoteAction } from '../store/videos/actions';
import { getDispatch, getState } from '../store';
import { PhraseDetailsActions } from '../store/phrase-details/actions';
import { getPhraseDetailsTabByType } from '../store/models/selectors';
import { EPhraseDetailsTabType, TPhraseDetailsTab } from '../store/models/types';
import { PhraseDetailsSelectors } from '../store/phrase-details/selectors';
import { EventsRouter } from '../../common/events/eventsRouter';
import { Events } from '../../common/events/types';
import { getCurrentVideoClickedCaptionIndex } from '../store/current-video/selectors';
import { PhraseEffects } from './phrase/PhraseEffects';
import { CaptionsSelectionPopupSelectors } from '../store/captions-selection-popup/selectors';
import { CaptionsSelectionPopupActions } from '../store/captions-selection-popup/actions';
import { getTargetCaptionByIndex } from '../store/videos/selectors';
import { IPhraseSelectResult } from './phrase/utils/phrase-select-preparator';
import { setSnackbarPanelAction } from '../store/general/actions';
import { IPhraseNote } from '../types/common';
import { VocabularyEffects } from './phrase/vocabulary-effects';
import { PhraseNoteTimeScaleEffects } from './phrase/phraseNoteTimeScaleEffects';
import { getAuthUser, getSavePhraseMode } from '../store/general/selectors';
import { ITeacher } from '../store/videos/types';
import { ESavePhraseMode } from '../store/general/types';
import { ESavePhraseModePopupType } from '../store/captions-selection-popup/types';
import { phraseDetailsReducer } from '../store/phrase-details/reducer';

export class PhraseNoteEffects {

  public static async save(phraseId: number, pause: boolean, text: string): Promise<IPhraseNote | null> {
    const dispatch = getDispatch();
    const {teacherMode} = getAuthUser(getState());
    const noteRest: TPhraseNoteRest = await PhraseNoteRest.save({
      phraseId,
      pause,
      text,
      teacherMode
    });
    const note = this.prepareNoteRest(noteRest);
    dispatch(savePhraseNoteAction(note));
    PhraseNoteTimeScaleEffects.load();
    return note;
  }

  public static async saveFromTeacherNote(teacherNote: IPhraseNote, phraseId: number, teacher?: ITeacher): Promise<IPhraseNote | null> {
    const dispatch = getDispatch();
    const noteRest: TPhraseNoteRest = await PhraseNoteRest.save({
      phraseId,
      pause: teacherNote.pause,
      text: teacherNote.text,
      teacherMode: false,
      authorInfo: teacher ? JSON.stringify(teacher) : ''
    });
    const note = this.prepareNoteRest(noteRest);
    dispatch(savePhraseNoteAction(note));
    PhraseNoteTimeScaleEffects.load();
    return note;
  }

  public static async runCopyToNoteText(appendText: string) {
    const dispatch = getDispatch();

    const note = PhraseDetailsSelectors.getCurrentNote(getState());
/*    let noteText = note?.text || '';
    if (noteText) {
      noteText += "\n\n";
    }
    noteText += appendText;*/

    const saveMode = getSavePhraseMode(getState());
    let selectResult = CaptionsSelectionPopupSelectors.getSelectResult(getState());
    if (!selectResult?.text) {
      selectResult = CaptionsSelectionPopupSelectors.getLastSelectResult(getState());
    }
    const clickedCaptionIndex = getCurrentVideoClickedCaptionIndex(getState());

    if (!note) {
      if (saveMode === ESavePhraseMode.NOT_SET && selectResult?.text) {
        dispatch(CaptionsSelectionPopupActions.setSavePhraseModePopup({
          show: true,
          type: ESavePhraseModePopupType.NOTE,
          selectResult: {...selectResult},
          noteText: appendText,
          clickedCaptionIndex
        }));
        return;
      }
    }

    this.copyToNoteText(appendText, selectResult, clickedCaptionIndex, saveMode === ESavePhraseMode.SAVE_CONTEXT);
  }

  public static async copyToNoteText(appendText: string, selectResult: IPhraseSelectResult, clickedCaptionIndex: number, useContext: boolean) {
    const dispatch = getDispatch();
    let note = PhraseDetailsSelectors.getCurrentNote(getState());
    let noteText = note?.text || '';
    if (!noteText && selectResult) {
      noteText = selectResult.text;
    }
    if (noteText) {
      noteText += "\n\n";
    }
    noteText += appendText;
    let newNote = false;
    if (!note) {
    /*  console.log('saveNote text', noteText)
      console.log('saveNote selectResult', selectResult)
      console.log('getLastSelectResult', CaptionsSelectionPopupSelectors.getLastSelectResult(getState()))
      console.log('phraseDetails state',getState().phraseDetails)
      console.log('CaptionsSelectionPopupSelectors.getSelectResult', CaptionsSelectionPopupSelectors.getSelectResult(getState()) );*/

       note = await PhraseNoteEffects.saveNote(noteText, false, selectResult, clickedCaptionIndex, useContext);


      console.log('saveNote', note)
      newNote = true;
      if (note) {
        dispatch(PhraseDetailsActions.setNotePhraseId(note.phraseId));
      }
    } else {
      await PhraseNoteEffects.updateNote(note.id, noteText);
    }

    const noteTab = getPhraseDetailsTabByType(getState(), EPhraseDetailsTabType.NOTES) as TPhraseDetailsTab;
    dispatch(PhraseDetailsActions.setActiveTab({
      ...noteTab,
      isNoteEdit: true,
    }));
    if (newNote && note) {
      dispatch(VocabularyEffects.flashPhrase(note.phraseId));
    }
    dispatch(setSnackbarPanelAction(true, 'Text copied to note'));
  }

  public static async updateNote(
    noteId: number,
    text?: string,
    pause?: boolean,
    authorInfo?: string
  ) {
    const dispatch = getDispatch();
    const {teacherMode} = getAuthUser(getState())
    let update: TPhraseNoteUpdateBody = {
      noteId,
      teacherMode
    }
    if (text !== undefined && text !== null) {
      update.text = text;
    }
    if (pause !== undefined && pause !== null) {
      update.pause = pause;
    }

    if (authorInfo !== undefined && authorInfo !== null) {
      update.authorInfo = authorInfo;
    }
    const noteRest = await PhraseNoteRest.update(update);
    const note = this.prepareNoteRest(noteRest);
    dispatch(savePhraseNoteAction(note));
  }


  public static async saveNote(text: string, pause: boolean, selectResult: IPhraseSelectResult, clickedCaptionIndex: number, useContextPhrase: boolean): Promise<IPhraseNote | null> {
    const dispatch = getDispatch();
    EventsRouter.trackEvent(Events.NOTE_ADDED);
    dispatch(PhraseDetailsActions.setNotePhraseText(text));
    const phraseId: number = PhraseDetailsSelectors.getNotePhraseId(getState()) || 0;
    if (phraseId) {
      return await PhraseNoteEffects.save(phraseId, pause, text);
    } else {
      if (selectResult?.text) {
        return await PhraseNoteEffects.saveNoteBySelectResult(selectResult, text, pause, useContextPhrase);
      } else if (clickedCaptionIndex >= 0) {
        return await PhraseNoteEffects.saveNoteByCaption(clickedCaptionIndex, text, pause);
      }
    }
  }

  private static async saveNoteBySelectResult(selectResult: IPhraseSelectResult, text: string, pause: boolean, useContextPhrase: boolean): Promise<IPhraseNote | null> {
    const dispatch = getDispatch();
    dispatch(CaptionsSelectionPopupActions.setActionButton(null));
    dispatch(PhraseEffects.deleteCurrentPreviewPhrase());
    const phrase = await PhraseEffects.saveFromSelectResult(selectResult, false, useContextPhrase);
    let note = null;
    if (phrase) {
      note = await PhraseNoteEffects.save(phrase.id, pause, text);
      dispatch(PhraseDetailsActions.setPhraseId(phrase.id));
    }
    return note;
  }

  private static async saveNoteByCaption(captionIndex: number, text: string, pause: boolean): Promise<IPhraseNote | null>  {
    const dispatch = getDispatch();
    const caption = getTargetCaptionByIndex(getState(), captionIndex);
    let note = null;
    if (caption) {
      const phrase = await PhraseEffects.saveFromCaption(caption, captionIndex);
      if (phrase) {
        note = await PhraseNoteEffects.save(phrase.id, pause, text);
        dispatch(PhraseDetailsActions.setPhraseId(phrase.id));
      }
    }
    return note;
  }

  private static prepareNoteRest(noteRest: TPhraseNoteRest): IPhraseNote {
    const note: IPhraseNote = {
      ...noteRest,
      ...{authorInfo: noteRest.authorInfo ? JSON.parse(noteRest.authorInfo) : undefined}
    }
    return note;
  }
}