import { IState } from '../../../../../../store/types';
import { connect } from 'react-redux';
import { PhraseDetailsTabPromptForm } from '../../components/PhraseDetailsTabPromptForm';
import { IPhraseDetailsTabPromptFormEvents, IPhraseDetailsTabPromptFormFields } from '../../components/types';
import { PhraseDetailsTabsEditorPopupSelectors } from '../../../../../../store/phrase-details-tabs-editor-popup/selectors';
import { PhraseDetailsCustomPromptVars } from '../../../../../../effects/phrase-details/phraseDetailsCustomPromptVars';
import { PhraseDetailsSelectors } from '../../../../../../store/phrase-details/selectors';
import { PhraseDetailsTabLibPromptFormEvents } from './events';


const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): IPhraseDetailsTabPromptFormFields => {
    const promptLib = PhraseDetailsTabsEditorPopupSelectors.getOpenPromptLib(state);
    let context = PhraseDetailsSelectors.getContext(state) || PhraseDetailsSelectors.getCaptionLineContext(state) || '';
    let phrase = PhraseDetailsSelectors.getText(state) || '';
    let isNoPhrase;
    if (!phrase) {
      isNoPhrase = true;
      const firstCaptionAsPhrase = PhraseDetailsTabsEditorPopupSelectors.getFirstCaptionAsPhrase(state);
      if (firstCaptionAsPhrase && firstCaptionAsPhrase.text) {
        phrase = firstCaptionAsPhrase.text;
        context = firstCaptionAsPhrase.context || '';
      }
    }

    return {
      id: promptLib?.id || 0,
      show: !!promptLib,
      showSaveConfirm: false,
      title: promptLib?.title || '',
      prompt: promptLib?.prompt || '',
      description: promptLib?.description || '',
      processing: PhraseDetailsTabsEditorPopupSelectors.isProcessing(state),
      vars: PhraseDetailsCustomPromptVars.getList(),
      phrase,
      context,
      isNoPhrase,
    }
  };
}

const mapDispatchToProps = (
  dispatch: any
): IPhraseDetailsTabPromptFormEvents => ({
    onShow: () => dispatch(PhraseDetailsTabLibPromptFormEvents.onShow()),
    onSave: (title, prompt, description) => dispatch(PhraseDetailsTabLibPromptFormEvents.onSave(title, prompt, description)),
    onValidate: prompt => dispatch(PhraseDetailsTabLibPromptFormEvents.onValidate(prompt)),
    onPromptChange: prompt => dispatch(PhraseDetailsTabLibPromptFormEvents.onPromptChange(prompt)),
    onShowPhraseSelectPopup: () => dispatch(PhraseDetailsTabLibPromptFormEvents.onShowPhraseSelectPopup()),
    onClickContext: () => dispatch(PhraseDetailsTabLibPromptFormEvents.onClickContext()),
  }
);

export const PhraseDetailsTabLibPromptFormHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(PhraseDetailsTabPromptForm);

PhraseDetailsTabLibPromptFormHOC.displayName = 'PhraseDetailsTabLibPromptFormHOC';