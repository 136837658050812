import React from 'react';
import { IconButton, Stack, TextField } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { theme } from '../../../../../../common/styles/theme';
import { Text } from '../../../../../../common/components/Text/Text';
import { VideoIcon } from '../../../../../../../icons/VideoIcon';
import { CommentIcon } from '../../../../../../../icons/CommentIcon';
import { TrashIcon } from '../../../../../../../icons/TrashIcon';
import { ConfirmPopup } from '../../../ConfirmPopup/ConfirmPopup';
import { IEditPlaylistItemProps } from './types';
import { LangFlag } from '../../../LangFlag/LangFlag';

export const EditPlaylistItem: React.FC<IEditPlaylistItemProps> = ({
                                                                             label,
                                                                             videoCount,
                                                                             phrasesCount,
  targetLangCode,
  nativeLangCode,
                                                                             onDelete,
                                                                             onSave
}) => {
  const className = 'edit-playlist-item';
  const classText = className + '__text';

  const history = useHistory();
  let [name, _setName] = React.useState<string>(label || '');
  const [isInput, setInput] = React.useState(false);
  const [anchor, setAnchor] = React.useState<HTMLButtonElement | null>(null);

  const setName = (value: string) => {
    name = value;
    _setName(value);
  };

  const handleDelete = () => {
    setAnchor(null);
    onDelete(history);
  };

  const handleSave = () => {
    if (name.length) {
      onSave(name);
    } else {
      setName(name);
    }
    setInput(false);
  }

  const onKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSave();
    } else if (
      e.key === 'Escape'
    ) {
      setName(name);
      setInput(false);
    }
  };

  const onHandleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  return (
    <Stack
      direction='row'
      gap={theme.spacing()}
      sx={{
        alignItems: 'center',
        justifyContent: 'space-between',

        '@media (max-width: 520px)': {
          flexWrap: 'wrap',

          [`.${classText}`]: {
            maxWidth: 1,
            mt: theme.spacing(1),
            mb: theme.spacing(-1),
          },
        },
      }}
    >
      <Stack
        role={'button'}
        onClick={() => setInput(true)}
        direction='row'
        sx={{
          alignItems: 'center',
          width: '100%',
          ':hover': {cursor: 'pointer'}
        }}
        spacing={theme.spacing()}
      >
        {isInput ?
          <TextField
            fullWidth
            value={name}
            autoFocus={isInput}
            onChange={onHandleChange}
            multiline={false}
            variant={'filled'}
            onKeyDown={onKeyDown}
            onBlur={handleSave}
          />
          :
          <>
            <Text
              className={classText}
              noWrap
              sx={{
                maxWidth: theme.spacing(28),
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontSize: theme.typography.subtitle2,
              }}
            >
              {name}
            </Text>
          </>
        }
      </Stack>
      <Stack
        direction='row'
        justifyContent={'space-between'}
        alignItems={'center'}
        gap={theme.spacing(2)}
        sx={{
          flex: '1 1 100%',
        }}
      >

        <Stack
          direction='row'
          spacing={theme.spacing()}
          alignItems={'center'}
        >
          <LangFlag code={targetLangCode} showCode={true}/>
          <LangFlag code={nativeLangCode} showCode={true}/>
        </Stack>

        <Stack
          direction='row'
          spacing={theme.spacing()}
          alignItems={'center'}
          sx={{ minWidth: theme.spacing(4.5), maxWidth: theme.spacing(4.5)}}
        >
          <VideoIcon
            sx={{
              width: theme.spacing(2),
              color: theme.palette.grey[300]
            }}
          />
          <Text sx={{ color: theme.palette.grey[500] }}>
            {videoCount}
          </Text>
        </Stack>
        <Stack
          direction='row'
          spacing={theme.spacing()}
          alignItems={'center'}
          sx={{ minWidth: theme.spacing(4.5), maxWidth: theme.spacing(4.5)}}
        >
          <CommentIcon
            sx={{
              width: theme.spacing(2),
              color: theme.palette.grey[300]
            }}
          />
          <Text sx={{ color: theme.palette.grey[500] }}>
            {phrasesCount}
          </Text>
        </Stack>
        <IconButton
          onClick={e => setAnchor(e.currentTarget)}
          color={'primary'}
          sx={{
            color: theme.palette.grey[400],
            '&:hover, &:active, &:focus': { color: theme.palette.error.main },
            '.MuiSvgIcon-root': { width: theme.spacing(2), height: theme.spacing(2) }
          }}
        >
          <TrashIcon />
        </IconButton>
        <ConfirmPopup
          anchor={anchor}
          text={'Delete playlist?'}
          onConfirm={handleDelete}
          onClose={() => setAnchor(null)}
        />
      </Stack>
    </Stack>
  );
}
