import { IVocabularyPhrase } from '../../types/common';

export const SET_PHRASE_LIST_PLAY_MODE = 'PHRASE_LIST/SET_PHRASE_LIST_PLAY_MODE';
export const SET_PHRASE_LIST_LIST_MODE = 'PHRASE_LIST/SET_PHRASE_LIST_LIST_MODE';
export const SET_PHRASE_LIST_PAUSE_DELAY = 'PHRASE_LIST/SET_PHRASE_LIST_PAUSE_DELAY';
export const SET_PHRASE_LIST_CURRENT_PHRASE_ID = 'PHRASE_LIST/SET_PHRASE_LIST_CURRENT_PHRASE_ID';
export const SET_PHRASE_LIST_PLAY_PHRASE_ID = 'PHRASE_LIST/SET_PHRASE_LIST_PLAY_PHRASE_ID';
export const SET_PHRASE_LIST_FORWARD_PHRASE_ID = 'PHRASE_LIST/SET_PHRASE_LIST_FORWARD_PHRASE_ID';
export const SET_PHRASE_LIST_PLAY_CAPTION_BY_PHRASE_ID = 'PHRASE_LIST/SET_PHRASE_LIST_PLAY_CAPTION_BY_PHRASE_ID';
export const SET_PHRASE_LIST_TOP_PHRASE_ID = 'PHRASE_LIST/SET_PHRASE_LIST_TOP_PHRASE_ID';
export const SET_PHRASE_LIST = 'PHRASE_LIST/SET_PHRASE_LIST';
export const APPEND_PHRASE_LIST = 'PHRASE_LIST/APPEND_PHRASE_LIST';
export const PHRASE_LIST_DELETE_PHRASE = 'PHRASE_LIST/PHRASE_LIST_DELETE_PHRASE';
export const PHRASE_LIST_UPDATE_PHRASE = 'PHRASE_LIST/PHRASE_LIST_UPDATE_PHRASE';
export const PHRASE_LIST_REMOVE_BY_VIDEO_GROUP = 'PHRASE_LIST/PHRASE_LIST_REMOVE_BY_VIDEO_GROUP';
export const PHRASE_LIST_REMOVE_BY_GROUP = 'PHRASE_LIST/PHRASE_LIST_REMOVE_BY_GROUP';
export const SET_PHRASE_LIST_PLAY_OFFSET = 'PHRASE_LIST/SET_PHRASE_LIST_PLAY_OFFSET';
export const SET_PHRASE_LIST_VIEW_TYPE = 'PHRASE_LIST/SET_PHRASE_LIST_VIEW_TYPE';

export const PHRASE_LIST_VIEW_TYPE_LOCAL_STORAGE = 'PHRASE_LIST_VIEW_TYPE';

export enum EPhraseListPlayMode {
  STOP, PLAY
}

export enum EPhraseListMode {
  CURRENT_VIDEO = 0,
  ALL = 1,
  CURRENT_GROUP = 2,
  LANG = 3,
  TEACHER = 4,
}

export interface IPhrasePlayOffset {
  start: number;
  end: number;
}

export enum EPhraseListViewType {
  DEFAULT = 'DEFAULT',
  COMPACT = 'COMPACT',
}

export interface IPhraseListState {
  playMode: EPhraseListPlayMode,
  listMode: EPhraseListMode,
  listModeLang?: string,
  pauseDelay: number,
  currentPhraseId: number,
  playPhraseId: number,
  forwardPhraseId: number,
  playCaptionByPhraseId: number,
  phraseList: IVocabularyPhrase[],
  topPhraseId: number,
  playOffset: IPhrasePlayOffset
  viewType: EPhraseListViewType;
}


export interface IPhraseListPlayMode {
  type: typeof SET_PHRASE_LIST_PLAY_MODE;
  playMode: EPhraseListPlayMode;
}

export interface IPhraseListMode {
  type: typeof SET_PHRASE_LIST_LIST_MODE;
  listMode: EPhraseListMode;
  listModeLang?: string;
}

export interface IPhraseListPauseDelay {
  type: typeof SET_PHRASE_LIST_PAUSE_DELAY;
  pauseDelay: number;
}

export interface IPhraseListCurrentPhraseId {
  type: typeof SET_PHRASE_LIST_CURRENT_PHRASE_ID;
  currentPhraseId: number;
}

export interface IPhraseListPlayPhraseId {
  type: typeof SET_PHRASE_LIST_PLAY_PHRASE_ID;
  playPhraseId: number;
}

export interface IPhraseListForwardPhraseId {
  type: typeof SET_PHRASE_LIST_FORWARD_PHRASE_ID;
  forwardPhraseId: number;
}

export interface IPhraseListPlayCaptionByPhraseId {
  type: typeof SET_PHRASE_LIST_PLAY_CAPTION_BY_PHRASE_ID;
  playCaptionByPhraseId: number;
}

export interface IPhraseListTopPhraseId {
  type: typeof SET_PHRASE_LIST_TOP_PHRASE_ID;
  topPhraseId: number;
}

export interface IPhraseListSet {
  type: typeof SET_PHRASE_LIST;
  list: IVocabularyPhrase[];
}

export interface IPhraseListAppend {
  type: typeof APPEND_PHRASE_LIST;
  list: IVocabularyPhrase[];
}

export interface IPhraseListDeletePhrase {
  type: typeof PHRASE_LIST_DELETE_PHRASE;
  phraseId: number;
}

export interface IPhraseListUpdatePhrase {
  type: typeof PHRASE_LIST_UPDATE_PHRASE;
  phrase: IVocabularyPhrase;
}

export interface IPhraseListRemoveByVideoGroup {
  type: typeof PHRASE_LIST_REMOVE_BY_VIDEO_GROUP
  videoKey: string
  groupId: number
}

export interface IPhraseListRemoveByGroup {
  type: typeof PHRASE_LIST_REMOVE_BY_GROUP
  groupId: number
}

export interface IPhraseListPlayOffset {
  type: typeof SET_PHRASE_LIST_PLAY_OFFSET,
  offset: Partial<IPhrasePlayOffset>
}

export interface ISetPhraseListViewTypeAction {
  type: typeof SET_PHRASE_LIST_VIEW_TYPE,
  value: EPhraseListViewType,
}

export type TPhraseListActions =
  | IPhraseListPlayMode
  | IPhraseListMode
  | IPhraseListPauseDelay
  | IPhraseListCurrentPhraseId
  | IPhraseListPlayPhraseId
  | IPhraseListForwardPhraseId
  | IPhraseListPlayCaptionByPhraseId
  | IPhraseListSet
  | IPhraseListAppend
  | IPhraseListDeletePhrase
  | IPhraseListUpdatePhrase
  | IPhraseListRemoveByVideoGroup
  | IPhraseListRemoveByGroup
  | IPhraseListTopPhraseId
  | IPhraseListPlayOffset
  | ISetPhraseListViewTypeAction
