import { IState } from '../../../../../store/types';
import { IPhraseTextFields } from '../../PhraseText/components/types';
import { PhraseDetailsSelectors } from '../../../../../store/phrase-details/selectors';
import { connect } from 'react-redux';
import { PhraseText } from '../../PhraseText/components/PhraseText';
import {
  getVideoTeacherPhraseById,
  getVideoTeacherShowPhraseId,
  getVideoTeacherUserActiveId
} from '../../../../../store/videos/selectors';


const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): IPhraseTextFields => {
    const userId = getVideoTeacherUserActiveId(state);
    const phraseId = getVideoTeacherShowPhraseId(state);
    const phrase = getVideoTeacherPhraseById(state, userId, phraseId);
    const text = phrase?.highlighted;
    const fromLangCode = PhraseDetailsSelectors.getFromLang(state)?.code || '';
    return {
      fromLangCode,
      text,
    }
  };
}

export const PhraseTeacherTextHOC = connect(
  mapStateToProps,
  null
)(PhraseText);

PhraseTeacherTextHOC.displayName = 'PhraseTeacherTextHOC';
