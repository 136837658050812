import React from 'react';
import { Modal } from '../../../../../common/components/Modal/Modal';
import { Button, Stack, TextField } from '@mui/material';
import { theme } from '../../../../../common/styles/theme';
import { EN_AUTO_GEN_LANG_CODE, EN_LANG_CODE, TLanguageCode } from '../../../../store/models/types';
import { Text } from '../../../../../common/components/Text/Text';
import { ICreatePlaylistProps } from './types';
import { LangUtil } from '../../../../../common/utils/lang-util';
import { ConfirmPopup } from '../../ConfirmPopup/ConfirmPopup';
import { Hint } from '../../../../../common/components/Hint/Hint';
import { LangSelectHOC } from "../../LangSelect/hocs/LangSelectHOC";
import { DeviceContext } from '../../../../../common/contexts/DeviceContext';

export const CreatePlaylist: React.FC<ICreatePlaylistProps> = ({
  isOpen,
    isSave,
  nativeLangCode: nativeCode,
  onCreate,
  onCheckConfirmCreateDefVideos,
  onClose,
}) => {
  const {isMobile} = React.useContext(DeviceContext);

  const [nativeLangCode, setNativeLangCode] = React.useState<string>(nativeCode);
  const [targetLangCode, setTargetLangCode] = React.useState<string>('');
  const [error, setError] = React.useState<string>();
  const [createProgress, setCreateProgress] = React.useState<boolean>(false);
  const [title, setTitle] = React.useState<string>('');
  const [titleChanged, setTitleChanged] = React.useState<boolean>(false);
  const [confirmAnchor, setConfirmAnchor] = React.useState<HTMLButtonElement | null>(null);

  React.useEffect(() => {
    if (isOpen) {
      setNativeLangCode(nativeCode);
      setTargetLangCode('');
      setCreateProgress(false);
      setError('');
    }
  }, [isOpen]);

  React.useEffect(() => {
    validation(nativeLangCode, targetLangCode);
    updateTitle(nativeLangCode, targetLangCode);
  }, [nativeLangCode, targetLangCode]);

  const updateTitle = (nativeLangCode: string, targetLangCode: string) => {
    if ((!nativeLangCode && !targetLangCode) || titleChanged) return;
    const list: string[] = [];
    [
      LangUtil.getLangNameByCode(targetLangCode),
      LangUtil.getLangNameByCode(nativeLangCode),
    ].forEach((title) => {
      if (title) {
        list.push(title);
      }
    });
    setTitle(list.join(' / ') + ' playlist');
  };

  const validation = (nativeLangCode: string, targetLangCode: string) => {
    if (!nativeLangCode || !targetLangCode) return;
    if (isEqualLangCodes(nativeLangCode, targetLangCode)) {
      setError('Choose different languages');
    } else {
      setError('');
    }
  };

  const isEqualLangCodes = (code1: TLanguageCode, code2: TLanguageCode) => {
    return (
      code1 === code2 ||
      (code1 === EN_AUTO_GEN_LANG_CODE && code2 === EN_LANG_CODE) ||
      (code1 === EN_LANG_CODE && code2 === EN_AUTO_GEN_LANG_CODE)
    );
  };

  const handleCreateGroup = async (elem: HTMLButtonElement) => {
    if (!nativeLangCode || !targetLangCode || error) return;
    if (onCheckConfirmCreateDefVideos(targetLangCode) && !isSave) {
      setConfirmAnchor(elem);
    } else {
      onConfirmCreateDefaultVideos(false);
    }
  };

  const onTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
    setTitleChanged(true);
  };

  const onConfirmCreateDefaultVideos = async (createDefVideos: boolean) => {
    setConfirmAnchor(null);
    setCreateProgress(true);
    await onCreate(nativeLangCode, targetLangCode, title, createDefVideos);
    onClose();
  };

  return (
    <Modal
      isMobile={isMobile}
      title={'Create Playlist'}  
      open={isOpen}
      onClose={onClose}  
    >
      <Stack
        gap={theme.spacing(2)}
        sx={{
          width: theme.spacing(45.75),
          maxWidth: '100%',
        }}>
        <Hint>
            <Text>
                Organise your videos in playlists, like you do in YouTube. Playlists can have the same or
                different target and native languages.
            </Text>
            <Text>
                Very soon you will be able to share those lists with your friends, teachers, or pupils. Or grab a list created by someone else.
            </Text>
        </Hint>
        <LangSelectHOC
          label={'Target language'}
          onChange={setTargetLangCode}
          langCode={targetLangCode}
        />
        <LangSelectHOC
          label={'Native language'}
          onChange={setNativeLangCode}
          langCode={nativeLangCode}
        />
        <TextField
          label={'Title'}
          variant={'filled'}
          fullWidth
          value={title}
          onChange={onTitleChange}
          multiline={false}
        />
        <Text sx={{ color: theme.palette.error.main }}>{error}</Text>
        <Button
          disabled={!nativeLangCode || !targetLangCode || !!error || createProgress}
          onClick={(e) => handleCreateGroup(e.currentTarget)}
          variant={'contained'}
          sx={{
            width: '100%',
          }}
        >
          {isSave ? 'Create playlist and save video' : 'Create playlist'}
        </Button>
        <ConfirmPopup
          anchor={confirmAnchor}
          text={'Do you want to load some sample videos for this playlist?'}
          closeTitle={'No, start form blank list'}
          onConfirm={() => onConfirmCreateDefaultVideos(true)}
          onClose={() => onConfirmCreateDefaultVideos(false)}
        />
      </Stack>
    </Modal>
  );
};
