import React from 'react';
import { ILoaderProps } from './types';

export const Loader: React.FC<ILoaderProps> = ({ children, onShow }) => {
  const loadingRef = React.useRef<HTMLDivElement>(null);

  /*React.useEffect(() => {
    const observer = new IntersectionObserver(onShow, {
      root: null,
      rootMargin: '0px',
      threshold: 1.0
    });

    if (loadingRef.current) {
      observer.observe(loadingRef.current);
    }

    console.log('Loader load')

    return () => {
      if (loadingRef.current) {
        observer.unobserve(loadingRef.current);
      }
    };
  }, [onShow]);*/

  const intersectionCallback = (entries: IntersectionObserverEntry[]) => {
    if (entries && entries.length > 0) {
      if (entries[0].isIntersecting) {
        onShow();
      }
    }
  }

  React.useEffect(() => {
    const observer = new IntersectionObserver(intersectionCallback, {
      root: null,
      rootMargin: '0px',
      threshold: 1.0
    });

    if (loadingRef.current) {
      observer.observe(loadingRef.current);
    }

    console.log('Loader load')

    return () => {
      if (loadingRef.current) {
        observer.unobserve(loadingRef.current);
      }
    };
  }, []);

  return (
    <div ref={loadingRef}>
      {children}
    </div>
  );
}
