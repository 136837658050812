import React from 'react';
import { theme } from '../../../../../common/styles/theme';
import { IconButton } from '@mui/material';
import { HelpIcon } from '../../../../../../icons/HelpIcon';

export const ButtonHelp = ({
	onClick
}) => {

	return (
		<IconButton
			sx={{
				position: 'fixed',
				zIndex: 100,
				left: theme.spacing(.5),
				top: theme.spacing(9),
				width: theme.spacing(4),
				height: theme.spacing(4),
				m: `0 !important`,
				color: theme.palette.grey[400],
				background: theme.palette.common.white,
				borderRadius: '50%',
				boxShadow: theme.shadows[8],
				
				'.MuiSvgIcon-root': {
					width: theme.spacing(2.5),
					height: theme.spacing(2.5),
				},
			}}
			onClick={onClick}
		>
			<HelpIcon />
		</IconButton>
	);
};