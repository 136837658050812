import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { PhraseTeacherNotes } from '../components/PhraseTeacherNotes';
import { IPhraseTeacherNotesEvents, IPhraseTeacherNotesFields } from '../components/types';
import {
  findVideoTeacherNoteByPhrase,
  getVideoTeacherByNoteId, getVideoTeacherPhraseById, getVideoTeacherSelectPhraseId,
  getVideoTeacherShowNote, getVideoTeacherShowPhraseId, getVideoTeacherUserActiveId,
  isExistPhrasesFromTeacherPhrase
} from '../../../../../store/videos/selectors';
import { PhraseTeacherNotesEvents } from './events';
import { getPlayerState } from '../../../../../store/general/selectors';
import { PlayerStates } from '../../../../../types/common';

const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): IPhraseTeacherNotesFields => {
    const canEdit = getPlayerState(state) !== PlayerStates.PLAYING;
    const note = getVideoTeacherShowNote(state);

    const userId = getVideoTeacherUserActiveId(state);
    const phraseId = getVideoTeacherSelectPhraseId(state);
    const phrase = getVideoTeacherPhraseById(state, userId, phraseId);
    const phraseNote = findVideoTeacherNoteByPhrase(state, userId, phrase);
    const saved = !!note && isExistPhrasesFromTeacherPhrase(state, note.phraseId);
    const user = getVideoTeacherByNoteId(state, note?.id || 0);

    return {
      canEdit,
      text: phraseNote?.text,
      saved,
      createUserName: user?.user.name || '',
    }
  };
}

const mapDispatchToProps = (
  dispatch: any
): IPhraseTeacherNotesEvents => ({
    onCopy: () => dispatch(PhraseTeacherNotesEvents.onCopy()),
    onClickAuthor: () => dispatch(PhraseTeacherNotesEvents.onClickAuthor()),
  }
);

export const PhraseTeacherNotesHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(PhraseTeacherNotes);

PhraseTeacherNotesHOC.displayName = 'PhraseTeacherNotesHOC';