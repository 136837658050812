import { getDispatch, getState } from '../store';
import { UserGptPromptRest } from '../../common/rest/userGptPrompt/userGptPromptRest';
import {
  phraseDetailsTabDeleteAction,
  phraseDetailsTabSaveAction,
  setDefaultGptPromptsAction,
  setPhraseDetailsTabsAction,
  setUserGptPromptsAction
} from '../store/models/actions';
import { PhraseDetailsTabRest, TPhraseDetailsTabRest } from '../../common/rest/phraseDetailsTab/phraseDetailsTabRest';
import {
  EDefaultGptPromptType,
  EPhraseDetailsTabType,
  EUserGpPromptType,
  TPhraseDetailsTab,
  TUserGptPrompt
} from '../store/models/types';
import { UserGptPromptEffects } from './userGptPromptEffects';
import {
  getDefaultGptPrompt,
  getPhraseDetailsTabById,
  getPhraseDetailsTabByType,
  getPhraseDetailsTabByTypeAndRelationId,
  getPhraseDetailsTabs,
  getUserGptPromptById
} from '../store/models/selectors';
import { EventsRouter } from '../../common/events/eventsRouter';
import { Events } from '../../common/events/types';
import { GptPromptRest } from '../../common/rest/gptPrompt/gptPromptRest';
import { PhraseDetailsTabsEditorPopupSelectors } from '../store/phrase-details-tabs-editor-popup/selectors';
import { PhraseDetailsSelectors } from '../store/phrase-details/selectors';
import { PhraseDetailsActions } from '../store/phrase-details/actions';

export class PhraseDetailsTabEffects {

  private static EDITABLE_TAB_TYPES = [EPhraseDetailsTabType.EXPLAIN, EPhraseDetailsTabType.USAGE, EPhraseDetailsTabType.CUSTOM_PROMPT];

  public static DEFAULT_TABS: TPhraseDetailsTab[] = [
    {
      id: -1,
      title: 'Translation',
      type: EPhraseDetailsTabType.TRANSLATION,
      orderNum: 0,
      favorite: false
    },
    {
      id: -2,
      title: 'Explain',
      type: EPhraseDetailsTabType.EXPLAIN,
      orderNum: 1,
      favorite: true,
    },
    {
      id: -3,
      title: 'Usage',
      type: EPhraseDetailsTabType.USAGE,
      orderNum: 2,
      favorite: true,
    },
    {
      id: -4,
      title: 'Notes',
      type: EPhraseDetailsTabType.NOTES,
      orderNum: 3,
      favorite: true,
    }
  ]

  public static isEditableTabType(type: EPhraseDetailsTabType): boolean {
    return PhraseDetailsTabEffects.EDITABLE_TAB_TYPES.includes(type);
  }

  public static isCanDeleteTabType(type: EPhraseDetailsTabType): boolean {
    return EPhraseDetailsTabType.CUSTOM_PROMPT === type;
  }


  public static async load() {
    const dispatch = getDispatch();
    const [defaultGptPrompts, userGptPrompts, phraseDetailsTabs] = await Promise.all([
      GptPromptRest.getLists(),
      UserGptPromptRest.getLists(),
      PhraseDetailsTabRest.getLists()
    ]);
    dispatch(setDefaultGptPromptsAction(defaultGptPrompts));
    dispatch(setUserGptPromptsAction(userGptPrompts));
    const tabs: TPhraseDetailsTab[] = [];
    PhraseDetailsTabEffects.DEFAULT_TABS.forEach(tab => PhraseDetailsTabEffects.checkExistTab(tabs, tab, phraseDetailsTabs));

    const state = getState();
    phraseDetailsTabs.forEach((tabRest: TPhraseDetailsTab) => {
      if (tabRest.type === EPhraseDetailsTabType.CUSTOM_PROMPT) {
        if (tabRest.relationId) {
          const prompt = getUserGptPromptById(state, tabRest.relationId);
          if (prompt) {
            const tab: TPhraseDetailsTab = {
              ...tabRest, ...{title: prompt.title}
            }
            tabs.push(tab);
          }
        }
      } else if (tabRest.type === EPhraseDetailsTabType.LIB_PROMPT) {
        tabs.push(tabRest);
      }
    });

    PhraseDetailsTabEffects.sortTabs(tabs);
    dispatch(setPhraseDetailsTabsAction(tabs));
  }



  public static async saveCustomPrompt(tabId: number, title: string, prompt: string, description: string): Promise<TPhraseDetailsTab> {
    const dispatch = getDispatch();
    const tab = tabId ? getPhraseDetailsTabById(getState(), tabId) : null;
    let userGptPrompt: TUserGptPrompt | undefined;
    let tabSaved: TPhraseDetailsTab;

    if (tab) {
      let tabOrderNum;
      if (PhraseDetailsTabEffects.isDefaultTabId(tabId)) {
        tabOrderNum = tab.orderNum;
        dispatch(phraseDetailsTabDeleteAction(tabId));
      }
      userGptPrompt = await UserGptPromptEffects.save(tab.relationId || 0, title, prompt, description);
      tabSaved = await PhraseDetailsTabEffects.syncTabByCustomPrompt(userGptPrompt, tab.type, tabOrderNum);
    } else {
      userGptPrompt = await UserGptPromptEffects.save(0, title, prompt, description);
      tabSaved = await PhraseDetailsTabEffects.syncTabByCustomPrompt(userGptPrompt, EPhraseDetailsTabType.CUSTOM_PROMPT);
      EventsRouter.trackEvent(Events.SAVE_PROMPT);
    }

    const tabs = [...getPhraseDetailsTabs(getState())];
    PhraseDetailsTabEffects.sortTabs(tabs);
    dispatch(setPhraseDetailsTabsAction(tabs));
    return tabSaved;
  }

  public static async saveLibTab(libId: number): Promise<TPhraseDetailsTab | null> {
    const dispatch = getDispatch();
    const libPrompt = PhraseDetailsTabsEditorPopupSelectors.getPromptLibById(getState(), libId);
    if (!libPrompt) return null;
    const tab: TPhraseDetailsTabRest = {
      type: EPhraseDetailsTabType.LIB_PROMPT,
      favorite: false,
      relationId: libId,
      orderNum: 0,
      title: libPrompt.title,
      isTemp: true
    }
    const saveTab = await PhraseDetailsTabRest.save(tab);
    dispatch(phraseDetailsTabSaveAction(saveTab));
    return saveTab;
  }

  public static async toggleLibTabFavourite(libId: number) {
    const dispatch = getDispatch();
    let tab = getPhraseDetailsTabByTypeAndRelationId(getState(), EPhraseDetailsTabType.LIB_PROMPT, libId);
    const libPrompt = PhraseDetailsTabsEditorPopupSelectors.getPromptLibById(getState(), libId);
    if (!tab) {
      if (!libPrompt) return;
      tab = {
        type: EPhraseDetailsTabType.LIB_PROMPT,
        favorite: true,
        relationId: libId,
        orderNum: 0,
        title: libPrompt.title
      }
    } else {
      tab.favorite = !tab.favorite;
      if (libPrompt) {
        tab.title = libPrompt.title;
      }
    }
    const saveTab = await PhraseDetailsTabRest.save(tab);
    dispatch(phraseDetailsTabSaveAction(saveTab));

    const activeTab = PhraseDetailsSelectors.getActiveTab(getState());
    if (activeTab && activeTab.relationId === libId && (activeTab.isTemp || activeTab.type === EPhraseDetailsTabType.LIB_PROMPT)) {
      if (tab.favorite) {
        const tab = getPhraseDetailsTabByTypeAndRelationId(getState(), EPhraseDetailsTabType.LIB_PROMPT, libId);
        if (tab) {
          dispatch(PhraseDetailsActions.setActiveTab(tab));
        }
      } else {
        const translateTab = getPhraseDetailsTabByType(getState(), EPhraseDetailsTabType.TRANSLATION);
        dispatch(PhraseDetailsActions.setActiveTab(translateTab as any));
      }
    }
  }

  private static isDefaultTabId(tabId: number): boolean {
    return PhraseDetailsTabEffects.DEFAULT_TABS.some(t => t.id === tabId)
  }

  public static getCustomPromptByTab(tab: TPhraseDetailsTab): TUserGptPrompt | undefined {
    const state = getState();
    if (tab.relationId) {
      return getUserGptPromptById(state,tab.relationId);
    }
    if (tab.type === EPhraseDetailsTabType.EXPLAIN || tab.type === EPhraseDetailsTabType.USAGE) {
      const type: EDefaultGptPromptType = tab.type === EPhraseDetailsTabType.EXPLAIN ? EDefaultGptPromptType.EXPLAIN : EDefaultGptPromptType.USAGE;
      const defaultPrompt = getDefaultGptPrompt(state, type);
      return {
        id: tab.id,
        title: tab.title,
        type: EUserGpPromptType.CUSTOM,
        prompt: defaultPrompt?.prompt || '',
        description: defaultPrompt?.description || ''
      }
    }
  }

  public static async deleteTab(tabId: number) {
    const dispatch = getDispatch();
    const tab = getPhraseDetailsTabById(getState(), tabId);
    if (tab) {
      if (tab.type === EPhraseDetailsTabType.CUSTOM_PROMPT) {
        if (tab.relationId) {
          await UserGptPromptEffects.delete(tab.relationId);
        }
      }
    }

    await PhraseDetailsTabRest.remove(tabId);
    dispatch(phraseDetailsTabDeleteAction(tabId));
  }

/*  public static async toggleVisibleTab(tabId: number, setBot: boolean) {
    const dispatch = getDispatch();
    const state = getState();
    const tab = getPhraseDetailsTabById(state, tabId);
    const tabIndex = getPhraseDetailsTabs(state).findIndex(t => t.id === tabId);
    if (tab) {
      const update = setBot ? {botVisible: !tab.botVisible} : {visible: !tab.visible};
      const saveTab = {...tab, ...update};
      let savedTab = await PhraseDetailsTabRest.save(saveTab);
      const updateTab: TPhraseDetailsTab = {...savedTab, ...{title: tab.title}};
      dispatch(phraseDetailsTabSaveAction(updateTab, tabIndex));
    }
  }*/

  public static async toggleFavorite(tabId: number) {
    const dispatch = getDispatch();
    const state = getState();
    const tab = getPhraseDetailsTabById(state, tabId);
    const tabIndex = getPhraseDetailsTabs(state).findIndex(t => t.id === tabId);
    if (tab) {
      const favorite = !tab.favorite;
      const update = {favorite};
      const saveTab = {...tab, ...update};
      let savedTab = await PhraseDetailsTabRest.save(saveTab);
      const updateTab: TPhraseDetailsTab = {...savedTab, ...{title: tab.title}};
      dispatch(phraseDetailsTabSaveAction(updateTab, tabIndex));
      if (!favorite) {
        const translateTab = getPhraseDetailsTabByType(state, EPhraseDetailsTabType.TRANSLATION);
        dispatch(PhraseDetailsActions.setActiveTab(translateTab as any));
      }
    }
  }

  public static async createDefaultFavorite() {
    const dispatch = getDispatch();
    const typeList: EPhraseDetailsTabType[] = [EPhraseDetailsTabType.EXPLAIN, EPhraseDetailsTabType.USAGE];
    for await(const type of typeList) {
      const tab = PhraseDetailsTabEffects.DEFAULT_TABS.find(t => t.type === type);
      if (tab) {
        let tabRest: TPhraseDetailsTabRest = {
          type,
          title: tab.title,
          favorite: true,
          relationId: 0,
          orderNum: tab.orderNum
        }
        tabRest = await PhraseDetailsTabRest.save(tabRest);
        dispatch(phraseDetailsTabSaveAction(tabRest));
      }
    }

  }

  public static async updateTabsOrder(tabs: TPhraseDetailsTab[]) {
    const dispatch = getDispatch();
    const tabList: TPhraseDetailsTab[] = [];

    tabs
      .sort((a, b) => {
        return +b.favorite - +a.favorite;
      })
      .forEach((tab, index) => {
        tabList.push({ ...tab, ...{orderNum: index + 1} });
      });

    const translateTab = getPhraseDetailsTabByType(getState(), EPhraseDetailsTabType.TRANSLATION);
    if (translateTab && !tabs.find(t => t.type === EPhraseDetailsTabType.TRANSLATION)) {
      tabList.push({ ...translateTab, ...{orderNum: tabList.length + 1} });
    }
    
    const notesTab = getPhraseDetailsTabByType(getState(), EPhraseDetailsTabType.NOTES);
    if (notesTab && !tabs.find(t => t.type === EPhraseDetailsTabType.NOTES)) {
      tabList.push({ ...notesTab, ...{orderNum: tabList.length + 1} });
    }

    dispatch(setPhraseDetailsTabsAction(tabList));
    const tabsRest = await PhraseDetailsTabRest.sort(tabList);
    const saveTabs: TPhraseDetailsTab[] = tabsRest.map(tabRest => {
      return {...tabRest, ...{title: PhraseDetailsTabEffects.getTabTitle(tabRest)}}
    })

    dispatch(setPhraseDetailsTabsAction(saveTabs));
  }

  public static async resetToDefault() {
    await PhraseDetailsTabRest.removeAll();
    await PhraseDetailsTabEffects.load();
  }

  private static async syncTabByCustomPrompt(userGptPrompt: TUserGptPrompt, tabType: EPhraseDetailsTabType, tabOrderNum?: number): Promise<TPhraseDetailsTab> {
    const dispatch = getDispatch();
    const state = getState();
    let existTab;
    if (tabType === EPhraseDetailsTabType.EXPLAIN || tabType === EPhraseDetailsTabType.USAGE) {
      existTab = getPhraseDetailsTabByType(state, tabType);
    } else {
      existTab = getPhraseDetailsTabByTypeAndRelationId(state, tabType, userGptPrompt.id);
    }

    const orderNum = tabOrderNum !== undefined ? tabOrderNum :
      (existTab ? existTab.orderNum : PhraseDetailsTabEffects.getTabsMaxOrderNum() + 1);
    let tabRest: TPhraseDetailsTabRest = {
      id: existTab ? existTab.id : 0,
      type: tabType,
      favorite: existTab ? existTab.favorite : true,
      relationId: userGptPrompt.id,
      orderNum
    }

    tabRest = await PhraseDetailsTabRest.save(tabRest);
    const tab: TPhraseDetailsTab = {
      ...tabRest,
      title: userGptPrompt.title
    }
    dispatch(phraseDetailsTabSaveAction(tab));
    return tab;
  }

  private static checkExistTab(result: TPhraseDetailsTab[], defaultTab: TPhraseDetailsTab, restTabs: TPhraseDetailsTabRest[]) {
    let tab: TPhraseDetailsTab | undefined = result.find(t => t.type === defaultTab.type);
    if (!tab) {
      const restTab = restTabs.find(t => t.type === defaultTab.type);
      let resultTab = restTab ? {...restTab, ...{title: defaultTab.title}} : {...defaultTab};
      if (resultTab.relationId) {
        const prompt = getUserGptPromptById(getState(), resultTab.relationId);
        if (prompt) {
          resultTab.title = prompt.title;
        }
      }
      result.push(resultTab);
    }
  }

  private static getTabTitle = (tabRest: TPhraseDetailsTabRest) => {
    const state = getState();
    if (tabRest.type === EPhraseDetailsTabType.CUSTOM_PROMPT) {
      const prompt = getUserGptPromptById(state, tabRest.relationId || 0);
      return prompt?.title || '';
    } else {
      const defTab = PhraseDetailsTabEffects.DEFAULT_TABS.find(t => t.type === tabRest.type);
      return defTab?.title || '';
    }
   }

  private static getTabsMaxOrderNum() {
    let result = 0;
    getPhraseDetailsTabs(getState()).forEach(p => {
      if (p.orderNum > result)
        result = p.orderNum;
    })
    return result;
  }

  private static sortTabs(tabs: TPhraseDetailsTab[]) {
    let minOrder = -1;
    let maxOrder = 1;
    tabs.forEach(t => {
      if (t.orderNum <= minOrder) {
        minOrder = t.orderNum - 1;
      }
      if (t.orderNum >= maxOrder) {
        maxOrder = t.orderNum + 1;
      }
    })

    const getTabOrderNumForSort = (tab: TPhraseDetailsTab) => {
      if (tab.type === EPhraseDetailsTabType.TRANSLATION)
        return minOrder;
      if (tab.type === EPhraseDetailsTabType.NOTES)
        return maxOrder;
      return tab.orderNum;
    }

    tabs.sort((t1, t2) => {
      const orderNum1 = getTabOrderNumForSort(t1);
      const orderNum2 = getTabOrderNumForSort(t2);
      if (t1.type === EPhraseDetailsTabType.CUSTOM_PROMPT && t2.type === EPhraseDetailsTabType.CUSTOM_PROMPT) {
        return orderNum2 - orderNum1
      }
      return orderNum1 == orderNum2 ? t1.type - t2.type : orderNum1 - orderNum2;
    });

  }
}
