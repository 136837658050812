import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { PhraseExplain } from '../components/PhraseExplain';
import { IPhraseExplainEvents, IPhraseExplainFields } from '../components/types';
import { PhraseDetailsSelectors } from '../../../../../store/phrase-details/selectors';
import { PhraseExplainEvents } from './events';
import { LangUtil } from '../../../../../../common/utils/lang-util';
import { EPhraseDetailsTabType } from '../../../../../store/models/types';
import { GptUtils } from '../../../../../effects/gptUtils';

const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): IPhraseExplainFields => {
    const activeTab = PhraseDetailsSelectors.getActiveTab(state);
    const active = !!(activeTab && activeTab.type === EPhraseDetailsTabType.EXPLAIN);
    const text = PhraseDetailsSelectors.getText(state);
    const context = PhraseDetailsSelectors.getContext(state) || PhraseDetailsSelectors.getCaptionLineContext(state);
    const fromLang = PhraseDetailsSelectors.getFromLang(state);
    const toLang = PhraseDetailsSelectors.getToLang(state);
    const resultText = text && GptUtils.formatResult(PhraseDetailsSelectors.getExplainResultText(state) || '');
    const processing = PhraseDetailsSelectors.getExplainResultProcessing(state) || false;
    return {
      active,
      fromLangCode: LangUtil.checkLangCode(fromLang?.code),
      toLangCode: LangUtil.checkLangCode(toLang?.code),
      text,
      context,
      resultText,
      processing
    }
  };
}

const mapDispatchToProps = (
  dispatch: any
): IPhraseExplainEvents => ({
    onLoad: useCache => dispatch(PhraseExplainEvents.onLoad(useCache)),
    onClickSettings: () => dispatch(PhraseExplainEvents.onClickSettings()),
    onClickContext: () => dispatch(PhraseExplainEvents.onClickContext()),
    onCopyNote: () => dispatch(PhraseExplainEvents.onCopyNote())
  }
);

export const PhraseExplainHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(PhraseExplain);

PhraseExplainHOC.displayName = 'PhraseExplainHOC';
