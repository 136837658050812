import { PhraseNoteRest, TPhraseNoteUpdateBody } from '../../common/rest/phraseNote/phraseNoteRest';
import { savePhraseNoteAction } from '../store/videos/actions';
import { getDispatch, getState } from '../store';
import { PhraseDetailsActions } from '../store/phrase-details/actions';
import { getPhraseDetailsTabByType } from '../store/models/selectors';
import { EPhraseDetailsTabType, TPhraseDetailsTab } from '../store/models/types';
import { PhraseDetailsSelectors } from '../store/phrase-details/selectors';
import { EventsRouter } from '../../common/events/eventsRouter';
import { Events } from '../../common/events/types';
import { getCurrentVideoClickedCaptionIndex } from '../store/current-video/selectors';
import { PhraseEffects } from './phrase/PhraseEffects';
import { CaptionsSelectionPopupSelectors } from '../store/captions-selection-popup/selectors';
import { CaptionsSelectionPopupActions } from '../store/captions-selection-popup/actions';
import { getTargetCaptionByIndex } from '../store/videos/selectors';
import { IPhraseSelectResult } from './phrase/utils/phrase-select-preparator';
import { setSnackbarPanelAction } from '../store/general/actions';
import { IPhraseNote } from '../types/common';
import { VocabularyEffects } from './phrase/vocabulary-effects';
import { PhraseNoteTimeScaleEffects } from './phrase/phraseNoteTimeScaleEffects';
import { getAuthUser } from '../store/general/selectors';

export class PhraseNoteEffects {

  public static async save(phraseId: number, pause: boolean, text: string): Promise<IPhraseNote | null> {
    const dispatch = getDispatch();
    const {teacherMode} = getAuthUser(getState());
    const note = await PhraseNoteRest.save({
      phraseId,
      pause,
      text,
      teacherMode
    })
    dispatch(savePhraseNoteAction(note));
    PhraseNoteTimeScaleEffects.load();
    return note;
  }

  public static async saveFromTeacherNote(teacherNote: IPhraseNote, phraseId: number): Promise<IPhraseNote | null> {
    const dispatch = getDispatch();
    const note = await PhraseNoteRest.save({
      phraseId,
      pause: teacherNote.pause,
      text: teacherNote.text,
      teacherMode: false
    })
    dispatch(savePhraseNoteAction(note));
    PhraseNoteTimeScaleEffects.load();
    return note;
  }

  public static async copyToNoteText(appendText: string) {
    const dispatch = getDispatch();
    let note = PhraseDetailsSelectors.getCurrentNote(getState());
    let noteText = note?.text || '';
    if (noteText) {
      noteText += "\n\n";
    }
    noteText += appendText;
    let newNote = false;
    if (!note) {
      note = await PhraseNoteEffects.saveNote(noteText, false);
      newNote = true;
      if (note) {
        dispatch(PhraseDetailsActions.setNotePhraseId(note.phraseId));
      }
    } else {
      await PhraseNoteEffects.updateNote(note.id, noteText);
    }

    const noteTab = getPhraseDetailsTabByType(getState(), EPhraseDetailsTabType.NOTES) as TPhraseDetailsTab;
    dispatch(PhraseDetailsActions.setActiveTab({
      ...noteTab,
      isNoteEdit: true,
    }));
    if (newNote && note) {
      dispatch(VocabularyEffects.flashPhrase(note.phraseId));
    }
    dispatch(setSnackbarPanelAction(true, 'Text copied to note'));
  }

  public static async updateNote(
    noteId: number,
    text?: string,
    pause?: boolean,
  ) {
    const dispatch = getDispatch();
    const {teacherMode} = getAuthUser(getState())
    let update: TPhraseNoteUpdateBody = {
      noteId,
      teacherMode
    }
    if (text !== undefined && text !== null) {
      update.text = text;
    }
    if (pause !== undefined && pause !== null) {
      update.pause = pause;
    }
    const note = await PhraseNoteRest.update(update);
    console.log('updateNote', note)
    dispatch(savePhraseNoteAction(note));
  }


  public static async saveNote(text: string, pause: boolean): Promise<IPhraseNote | null> {
    const dispatch = getDispatch();
    EventsRouter.trackEvent(Events.NOTE_ADDED);
    dispatch(PhraseDetailsActions.setNotePhraseText(text));
    const clickedCaptionIndex = getCurrentVideoClickedCaptionIndex(getState());
    const phraseId: number = PhraseDetailsSelectors.getNotePhraseId(getState()) || 0;
    if (phraseId) {
      await PhraseNoteEffects.save(phraseId, pause, text);
    } else {
      const selectResult = CaptionsSelectionPopupSelectors.getSelectResult(getState());
      if (selectResult) {
        return await PhraseNoteEffects.saveNoteBySelectResult(selectResult, text, pause);
      } else if (clickedCaptionIndex >= 0) {
        return await PhraseNoteEffects.saveNoteByCaption(clickedCaptionIndex, text, pause);
      }
    }
  }

  private static async saveNoteBySelectResult(selectResult: IPhraseSelectResult, text: string, pause: boolean): Promise<IPhraseNote | null> {
    const dispatch = getDispatch();
    dispatch(CaptionsSelectionPopupActions.setActionButton(null));
    dispatch(PhraseEffects.deleteCurrentPreviewPhrase());
    const phrase = await PhraseEffects.saveFromSelectResult(selectResult);
    let note = null;
    if (phrase) {
      note = await PhraseNoteEffects.save(phrase.id, pause, text);
      dispatch(PhraseDetailsActions.setPhraseId(phrase.id));
    }
    return note;
  }

  private static async saveNoteByCaption(captionIndex: number, text: string, pause: boolean): Promise<IPhraseNote | null>  {
    const dispatch = getDispatch();
    const caption = getTargetCaptionByIndex(getState(), captionIndex);
    let note = null;
    if (caption) {
      const phrase = await PhraseEffects.saveFromCaption(caption, captionIndex);
      if (phrase) {
        note = await PhraseNoteEffects.save(phrase.id, pause, text);
        dispatch(PhraseDetailsActions.setPhraseId(phrase.id));
      }
    }
    return note;
  }

}