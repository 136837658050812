import React from 'react';
import { DeviceContext } from '../../../../../common/contexts/DeviceContext';
import { theme } from '../../../../../common/styles/theme';
import { Modal } from '../../../../../common/components/Modal/Modal';
import { Box, Button, Stack } from '@mui/material';
import { ISavePhraseModeSelectPopupProps } from './types';
import { ControlSwitch } from '../../Controls/component/ControlSwitch';


export const SavePhraseModeSelectPopup: React.FC<ISavePhraseModeSelectPopupProps> = ({
  show,
  onSelect,
  onClose
}) => {
  if (!show) return null;

  const {isMobile} = React.useContext(DeviceContext);

  const [defaultAction, setDefaultAction] = React.useState<boolean>(false);

  const onSaveWithContext = () => {
    onSelect(true, defaultAction);
  }

  const onSaveWithoutContext = () => {
    onSelect(false, defaultAction);
  }

  const onChangeDefaultAction = () => {
    setDefaultAction(!defaultAction);
  }

  return (
    <Modal
      onClose={onClose}
      open={show}
      title={'Select save phrase mode'}
      isMobile={isMobile}
    >
      <Stack
        gap={theme.spacing(2)}
        sx={{
          width: theme.spacing(45.75),
          maxWidth: '100%',
        }}
      >

          <Box
            sx={{
              position: 'relative',
            }}
          >
            <Stack
              direction={'row'}
              gap={theme.spacing(2)}
            >
              <Button
                onClick={onSaveWithContext}
                variant={'contained'}
              >
                Save with context
              </Button>
              <Button
                onClick={onSaveWithoutContext}
                variant={'contained'}
              >
                Save without context
              </Button>
            </Stack>

            <ControlSwitch
              title='Make this a default option'
              checked={defaultAction}
              sx={{}}
              onChange={onChangeDefaultAction}
            />

          </Box>
      </Stack>
    </Modal>
  );
}
