import { AppThunk } from '../../../../../store/types';
import { ContentLibEffects } from '../../../../../effects/contentLibEffects';

export class ContentLibraryDashboardEvents {

  public static onLoad(): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      await ContentLibEffects.loadLangs();
    }
  }
}