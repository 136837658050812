export const historyPush = ({
  state,
  title,
  url,
  onpopstate
}: IHistoryProps) => {
  window.history.pushState(state, title, url);
  if (!window.onpopstates) {
    window.onpopstates = [];
  }
  if (window.onpopstate) {
    window.onpopstates.push(window.onpopstate);
  }
  window.onpopstate = onpopstate;
};

export interface IHistoryProps{
  state: {
      page: string;
  };
  title?: string;
  url?: string;
  onpopstate: () => void;
}
