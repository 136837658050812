import { ILanguageItem } from '../../store/general/types';
import { EPhraseDetailsTabType, TPhraseDetailsTab, TUserLanguage } from '../../store/models/types';
import { PhraseDetailsActions } from '../../store/phrase-details/actions';
import { getDispatch, getState } from '../../store';
import { IPhraseDetailsState } from '../../store/phrase-details/types';
import { PhraseDetailsSelectors } from '../../store/phrase-details/selectors';

import {
  findVideoPhraseById, findVideoTeacherNoteByPhrase,
  getTargetCaptions,
  getVideoPhraseNoteByPhraseId, getVideoTeacherNoteByPhraseId,
  getVideoTeacherPhraseNotes,
  getVideoTeacherPhrases,
  getVideoTeacherShowNoteId,
  getVideoTeacherShowPhraseId,
  getVideoTeacherUserActiveId
} from '../../store/videos/selectors';
import { PhraseListSelectors } from '../../store/phrase-list/selectors';
import { EVocabularyPhraseType, ICaptionsItem, IPhraseNote, IVocabularyPhrase } from '../../types/common';
import { getPhraseDetailsTabByType, getPhraseDetailsTabs } from '../../store/models/selectors';
import { IPhraseSelectResult } from '../phrase/utils/phrase-select-preparator';
import { PhraseContextEditorActions } from '../../store/phrase-context-editor/actions';
import { setCurrentVideoClickedCaptionIndex } from '../../store/current-video/actions';
import _ from 'lodash';
import { PhraseNoteTimeScaleEffects } from '../phrase/phraseNoteTimeScaleEffects';
import { isPauseOnNote } from '../../store/general/selectors';
import { PlayerController } from '../player/manager/playerController';
import { IState } from '../../store/types';
import { CaptionsSelectionPopupSelectors } from '../../store/captions-selection-popup/selectors';
import { TeachersEffects } from '../teachersEffects';
import { batch } from 'react-redux';
import { setVideoTeacherShowNoteIdAction, setVideoTeacherShowPhraseIdAction } from '../../store/videos/actions';

const md5 = require('md5');

export class PhraseDetailsEffects {

  public static async showFromSelectText(
    targetLang: ILanguageItem,
    nativeLang: ILanguageItem | null,
    groupNativeLang: TUserLanguage | null,
    selectResult: IPhraseSelectResult
  ) {
    const translateTo = nativeLang || groupNativeLang;
    if (!translateTo) return;
    const dispatch = getDispatch();
    const state = getState();

    let captionLineContext = '';
    const captions = getTargetCaptions(state);
    for (let index = selectResult.startCaptionIndex; index <= selectResult.endCaptionIndex; index++) {
      if (index >= 0 && index < captions.length) {
        const line = captions[index].text.replace(/[\r]/gm, '').replace(/[\n]/gm, ' ');
        captionLineContext += ' ' + line;
      }
    }

    dispatch(PhraseDetailsActions.resetAllowSaveResults(false));
    const context = _.unescape(selectResult.externalContextPhrase?.fullPhrase) || '';
    const text = selectResult.text;

    const hash = PhraseDetailsEffects.getHash(text, targetLang.code, translateTo.code);
    TeachersEffects.hidePhrases();
    dispatch(PhraseDetailsActions.activate(text, context, captionLineContext.trim(), text, targetLang, translateTo, PhraseDetailsEffects.getDefaultTab(), hash));
    dispatch(PhraseDetailsActions.setNotePhraseId(0));
    dispatch(PhraseDetailsActions.setNotePhraseText(''));
  }


  public static async showFromCaption(
    index: number,
    targetLang: ILanguageItem,
    nativeLang: ILanguageItem | null,
    groupNativeLang: TUserLanguage | null,
    caption: ICaptionsItem) {
    const translateTo = nativeLang || groupNativeLang;
    if (!translateTo) return;
    const dispatch = getDispatch();
    const text = caption.text;
    const hash = PhraseDetailsEffects.getHash(text, targetLang.code, translateTo.code);

    dispatch(setCurrentVideoClickedCaptionIndex(index));
    batch(() => {
      TeachersEffects.hidePhrases();
      dispatch(PhraseDetailsActions.resetAllowSaveResults(false));
      dispatch(PhraseDetailsActions.activate(text, text, '', text,
        targetLang, translateTo, PhraseDetailsEffects.getDefaultTab(), 0, hash));
      dispatch(PhraseDetailsActions.setNotePhraseId(0));
    })

    /*dispatch(PhraseDetailsActions.batchActions([
        PhraseDetailsActions.resetAllowSaveResults(false),
        PhraseDetailsActions.activate(text, text, '', text,
          targetLang, translateTo, PhraseDetailsEffects.getDefaultTab(), 0, hash),
        PhraseDetailsActions.setNotePhraseId(0)
    ]));*/
  }

  public static async showFromPhrase(
    targetLang: ILanguageItem,
    nativeLang: ILanguageItem | null,
    groupNativeLang: TUserLanguage | null,
    phraseId: number,
    selectText: string
  ) {
    const translateTo = nativeLang || groupNativeLang;
    if (!translateTo) return;
    const dispatch = getDispatch();
    const state = getState();

    const phrase = PhraseListSelectors.findPhraseById(state, phraseId);
    const note = phrase && getVideoPhraseNoteByPhraseId(state, phrase.id);
    dispatch(PhraseDetailsActions.resetAllowSaveResults(false));
    //const contextPhrase = phrase && PhraseListSelectors.findPhraseByWordId(state, phrase.id);
    const context = /*contextPhrase ? contextPhrase.fullPhrase :*/ selectText;

    const activeTab = note ? getPhraseDetailsTabByType(state, EPhraseDetailsTabType.NOTES) : PhraseDetailsEffects.getDefaultTab();
    const hash = PhraseDetailsEffects.getHash(phrase?.highlighted || '', targetLang.code, translateTo.code, phraseId);
    TeachersEffects.hidePhrases();
    dispatch(PhraseDetailsActions.activate(phrase?.highlighted || '', context, '', selectText,
      targetLang, translateTo, activeTab, phraseId, hash));
   // dispatch(PhraseDetailsActions.setHash(PhraseDetailsEffects.getHash(phrase?.highlighted || '', targetLang.code, translateTo.code, phraseId)));
    dispatch(PhraseDetailsActions.setNotePhraseId(phraseId));
  }

  public static async showFromSavedPhrase(
    targetLang: ILanguageItem,
    nativeLang: ILanguageItem | null,
    groupNativeLang: TUserLanguage | null,
    text: string,
    phrase: IVocabularyPhrase,
    openDefaultTab: boolean
  ) {
    const translateTo = nativeLang || groupNativeLang;
    if (!translateTo) return;
    const dispatch = getDispatch();

    dispatch(PhraseDetailsActions.resetAllowSaveResults(false));
    const context = _.unescape(phrase?.fullPhrase || '');
    const note = phrase ? PhraseDetailsEffects.findNoteByPhrase(phrase) : null;

    const activeTab = openDefaultTab ? PhraseDetailsEffects.getDefaultTab() :
      (note ? getPhraseDetailsTabByType(getState(), EPhraseDetailsTabType.NOTES) : PhraseDetailsEffects.getDefaultTab());

    // todo: include setHash into activate
    dispatch(PhraseDetailsActions.setHash(PhraseDetailsEffects.getHash(text, targetLang.code, translateTo.code)));
    const hash = PhraseDetailsEffects.getHash(text, targetLang.code, translateTo.code);
    TeachersEffects.hidePhrases();
    dispatch(PhraseDetailsActions.activate(text, context, '', text, targetLang, translateTo, activeTab, phrase.wordPhraseId, hash));
 //   dispatch(PhraseDetailsActions.setHash(PhraseDetailsEffects.getHash(text, targetLang.code, translateTo.code)));
    dispatch(PhraseDetailsActions.setNotePhraseId(phrase.wordPhraseId));
    dispatch(setCurrentVideoClickedCaptionIndex(-1));
    const contextEditorPhrases = [phrase];
    let contextPhrase;
    if (phrase.wordPhraseId) {
      contextPhrase = findVideoPhraseById(getState(), phrase.wordPhraseId);
      if (contextPhrase)
        contextEditorPhrases.push(contextPhrase);
    }
    dispatch(PhraseContextEditorActions.updatePhraseContextEditorAction({
      phrases: contextEditorPhrases,
    }));
  }

  public static async showFromTeacherPhrase(
    targetLang: ILanguageItem,
    nativeLang: ILanguageItem | null,
    groupNativeLang: TUserLanguage | null,
    text: string,
    phrase: IVocabularyPhrase,
    openDefaultTab: boolean
  ) {
    const translateTo = nativeLang || groupNativeLang;
    if (!translateTo) return;
    const dispatch = getDispatch();

    dispatch(PhraseDetailsActions.resetAllowSaveResults(false));
    const context = _.unescape(phrase?.fullPhrase || '');
    const note = phrase ? PhraseDetailsEffects.findNoteByPhrase(phrase) : null;

    const activeTab = openDefaultTab ? PhraseDetailsEffects.getDefaultTab() :
      (note ? getPhraseDetailsTabByType(getState(), EPhraseDetailsTabType.NOTES) : PhraseDetailsEffects.getDefaultTab());

    dispatch(PhraseDetailsActions.setHash(PhraseDetailsEffects.getHash(text, targetLang.code, translateTo.code)));
    const hash = PhraseDetailsEffects.getHash(text, targetLang.code, translateTo.code);

    dispatch(PhraseDetailsActions.activate(text, context, '', text, targetLang, translateTo, activeTab, phrase.wordPhraseId, hash));
    //   dispatch(PhraseDetailsActions.setHash(PhraseDetailsEffects.getHash(text, targetLang.code, translateTo.code)));
    dispatch(PhraseDetailsActions.setNotePhraseId(phrase.wordPhraseId));
    dispatch(setCurrentVideoClickedCaptionIndex(-1));


  }

  public static async showFromNativeSelect(
    targetLang: ILanguageItem,
    nativeLang: ILanguageItem,
    text: string,
    context: string
  ) {
    const dispatch = getDispatch();
    const translateTo = nativeLang;
    dispatch(PhraseDetailsActions.resetAllowSaveResults(false));
    const activeTab = PhraseDetailsEffects.getDefaultTab();
    dispatch(PhraseDetailsActions.setHash(PhraseDetailsEffects.getHash(text, targetLang.code, translateTo.code)));
    const hash = PhraseDetailsEffects.getHash(text, targetLang.code, translateTo.code);
    TeachersEffects.hidePhrases();
    dispatch(PhraseDetailsActions.activate(text, context, '', text, targetLang, translateTo, activeTab, 0, hash, true));
  }

  public static async showNoteFromSavedPhrase(
    targetLang: ILanguageItem,
    nativeLang: ILanguageItem | null,
    groupNativeLang: TUserLanguage | null,
    text: string,
    phrase: IVocabularyPhrase,
  ) {
    const translateTo = nativeLang || groupNativeLang;
    if (!translateTo) return;
    const dispatch = getDispatch();

    dispatch(PhraseDetailsActions.resetAllowSaveResults(false));
    const context = phrase?.fullPhrase || '';

    const activeTab = getPhraseDetailsTabByType(getState(), EPhraseDetailsTabType.NOTES);

    TeachersEffects.hidePhrases();
    dispatch(PhraseDetailsActions.activate(text, context, '', text, targetLang, translateTo, activeTab, phrase.wordPhraseId || phrase.id));
    dispatch(PhraseDetailsActions.setNotePhraseId(phrase.wordPhraseId || phrase.id));
  }

  public static async showNoteFromSavedPhraseAnnotation(
    phrase: IVocabularyPhrase,
  ) {
    const dispatch = getDispatch();
    dispatch(PhraseDetailsActions.resetAllowSaveResults(false));
    const activeTab = getPhraseDetailsTabByType(getState(), EPhraseDetailsTabType.NOTES);
    TeachersEffects.hidePhrases();
    dispatch(PhraseDetailsActions.activate('', '', '', '', null as any, null as any, activeTab, phrase.id));
    dispatch(PhraseDetailsActions.setNotePhraseId(phrase.id));
    
    const noteTab = getPhraseDetailsTabByType(getState(), EPhraseDetailsTabType.NOTES);
    if (noteTab) {
      dispatch(PhraseDetailsActions.setActiveTab({
        ...noteTab,
        isNoteEdit: true,
      }));
      dispatch(PhraseDetailsActions.setNotePreviewMode(true));
    }
  }

  private static findNoteByPhrase(phrase: IVocabularyPhrase) {
    let note = phrase && getVideoPhraseNoteByPhraseId(getState(), phrase.id);
    if (!note && phrase.wordPhraseId) {
      note = phrase && getVideoPhraseNoteByPhraseId(getState(), phrase.wordPhraseId);
    }
    return note;
  }

  public static async saveForPhrase(phraseId: number) {
    const state = getState();
    const dispatch = getDispatch();
    const phraseDetails: IPhraseDetailsState = PhraseDetailsSelectors.getPhraseDetails(state);

    const results = PhraseDetailsEffects.getStateResuts();
    /*PhraseDetailsEffects.services.map((service, index) => {
      if (results[index]) {
        if (phraseDetails.text && phraseDetails.fromLang && phraseDetails.toLang) {
          service.save(phraseId, phraseDetails.text || '', phraseDetails.fromLang, phraseDetails.toLang, results[index])
        }
      }
    });*/
    dispatch(PhraseDetailsActions.setPhraseId(phraseId));
    dispatch(PhraseDetailsActions.setNotePhraseId(phraseId));
  }

  public static async deleteByPhrase(phraseId: number) {
    const dispatch = getDispatch();
   // await Promise.all( PhraseDetailsEffects.services.map(s => s.deleteByPhrase(phraseId)) );
    const state = getState();
    if (PhraseDetailsSelectors.getPhraseId(state) === phraseId) {
      dispatch(PhraseDetailsActions.hide());
    }
  }

  private static getStateResuts(): string[] {
    const state = getState();
    return [
  //    PhraseDetailsSelectors.getTranslateResultAllowSave(state) ? PhraseDetailsSelectors.getTranslateResultText(state) || '' : '',
   //   PhraseDetailsSelectors.getNoteResultText(state) || ''
    ]
  }

  private static getHash(text: string, fromLang: string, toLang: string, phraseId?: number) {
    return md5([text, fromLang, toLang, (phraseId || 0)].join('|'));
  }

  public static getDefaultTab(): TPhraseDetailsTab | undefined {
    const tabs = getPhraseDetailsTabs(getState());
    return  tabs && tabs.length ? tabs[0] : undefined;
  }

  public static updateOnChangeActiveTargetIndex(index: number) {
    /*const state = getState();
    const dispatch = getDispatch();
    const selection = CaptionsSelectionPopupSelectors.getCurrentSelection(state)
    if (selection?.captionIndex !== index) {
      dispatch(PhraseDetailsActions.setActiveTab(undefined));
    }*/
  }

  public static updateOnChangePlayerPosition(position: number) {
    const state = getState();
    const dispatch = getDispatch();
    const note = PhraseNoteTimeScaleEffects.findPhraseNoteByTime(position);
    if (note) {
      this.updateNoteTab(state, position, note.phraseId, note.text, true);
      const lastPauseNoteId = PhraseDetailsSelectors.getNoteLastPauseId(getState());
      if (lastPauseNoteId !== note.id && (isPauseOnNote(getState()) || note.pause)) {
        PlayerController.getInstance().pause();
        batch(() => {
          dispatch(PhraseDetailsActions.setPhraseId(note.phraseId));
          dispatch(PhraseDetailsActions.setNoteLastPauseId(note.id));
        })
      }
    } else {
      const teacherPhrase = this.getTeacherActivePhraseByPosition(position);
      const teacherNote = teacherPhrase ? findVideoTeacherNoteByPhrase(state, getVideoTeacherUserActiveId(state), teacherPhrase) : null;
      const lastTeacherNoteId = getVideoTeacherShowNoteId(state);
      const lastTeacherPhraseId = getVideoTeacherShowPhraseId(state);
      const currentTeacherNoteId = teacherNote?.id || 0;
      const currentTeacherPhraseId = teacherNote?.phraseId || 0;
      if (currentTeacherNoteId !== lastTeacherNoteId) {
        dispatch(setVideoTeacherShowNoteIdAction(currentTeacherNoteId));
      }
      if (currentTeacherPhraseId !== lastTeacherPhraseId) {
        dispatch(setVideoTeacherShowPhraseIdAction(currentTeacherPhraseId));
      }

      if (teacherNote) {
        const noteTab = getPhraseDetailsTabByType(state, EPhraseDetailsTabType.NOTES);
        dispatch(PhraseDetailsActions.setActiveTab(noteTab));
      } else {
        this.updateNoteTab(state, position, 0, '', false);
      }
    }
  }

  private static getTeacherActivePhraseByPosition(position: number): IVocabularyPhrase | null {
    const state = getState();
    const teacherId = getVideoTeacherUserActiveId(state);
    if (!teacherId) return null;
    const phrases = getVideoTeacherPhrases(state, teacherId);
    if (!phrases) return null;
    return phrases
      .filter(p => p.type === EVocabularyPhraseType.WORD_AND_CONTEXT_SELECTED)
      .find(p => {
        return (position >= p.startTime && position <= p.endTime)
      });
  }

  private static updateNoteTab(state: IState, position: number, phraseId: number, text: string, showTab: boolean) {
    const notePhraseId = PhraseDetailsSelectors.getNotePhraseId(state);
    const noteText = PhraseDetailsSelectors.getNoteResultText(state);
    const activeTab = PhraseDetailsSelectors.getActiveTab(state);

    const tabType: EPhraseDetailsTabType = showTab ? EPhraseDetailsTabType.NOTES : EPhraseDetailsTabType.TRANSLATION;
    if (notePhraseId !== phraseId || noteText !== text || tabType !== activeTab?.type) {
      const dispatch = getDispatch();
      batch(() => {
        dispatch(PhraseDetailsActions.setNotePhraseId(phraseId));
        dispatch(PhraseDetailsActions.setNotePhraseText(text));
        dispatch(PhraseDetailsActions.setActiveTab(tabType ? getPhraseDetailsTabByType(state, tabType) : undefined));
      })
    }
  }

}