import React from 'react';
import { IconButton, Stack, Tooltip } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { theme } from '../../../../../../common/styles/theme';
import { Text } from '../../../../../../common/components/Text/Text';
import { VideoIcon } from '../../../../../../../icons/VideoIcon';
import { CommentIcon } from '../../../../../../../icons/CommentIcon';
import { TrashIcon } from '../../../../../../../icons/TrashIcon';
import { ConfirmPopup } from '../../../ConfirmPopup/ConfirmPopup';
import { IEditPlaylistItemProps } from './types';
import { LangFlag } from '../../../LangFlag/LangFlag';
import { TranslationsIcon } from '../../../../../../../icons/TranslationsIcon';
import { PencilIcon } from '../../../../../../../icons/PencilIcon';

export const EditPlaylistItem: React.FC<IEditPlaylistItemProps> = ({
  label,
  videoCount,
  phrasesCount,
  targetLangCode,
  nativeLangCode,
  publicGroup,
  onDelete,
  onEdit,
}) => {
  const className = 'edit-playlist-item';
  const classText = className + '__text';

  const history = useHistory();
  const [anchor, setAnchor] = React.useState<HTMLButtonElement | null>(null);

  const handleDelete = () => {
    setAnchor(null);
    onDelete(history);
  };

  return (
    <Stack
      direction='row'
      gap={theme.spacing()}
      sx={{
        alignItems: 'center',
        justifyContent: 'space-between',

        '@media (max-width: 520px)': {
          flexWrap: 'wrap',

          [`.${classText}`]: {
            maxWidth: 1,
            mt: theme.spacing(1),
            mb: theme.spacing(-1),
          },
        },
      }}
    >
      <Stack
        role={'button'}
        onClick={onEdit}
        direction='row'
        sx={{
          alignItems: 'center',
          width: '100%',
          ':hover': {cursor: 'pointer'}
        }}
        spacing={theme.spacing()}
      >
        <Text
          className={classText}
          noWrap
          sx={{
            maxWidth: theme.spacing(25),
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontSize: theme.typography.subtitle2,
          }}
        >
          {label}
        </Text>
      </Stack>
      <Stack
        direction='row'
        justifyContent={'space-between'}
        alignItems={'center'}
        gap={theme.spacing(2)}
        sx={{
          flex: '1 1 100%',
        }}
      >

        <Stack
          direction='row'
          spacing={theme.spacing()}
          alignItems={'center'}
        >
          <LangFlag code={targetLangCode} showCode={true}/>
          <LangFlag code={nativeLangCode} showCode={true}/>
        </Stack>

        <Stack
          direction='row'
          spacing={theme.spacing()}
          alignItems={'center'}
          sx={{ minWidth: theme.spacing(4.5), maxWidth: theme.spacing(4.5)}}
        >
          <VideoIcon
            sx={{
              width: theme.spacing(2),
              color: theme.palette.grey[300]
            }}
          />
          <Text sx={{ color: theme.palette.grey[500] }}>
            {videoCount}
          </Text>
        </Stack>
        <Stack
          direction='row'
          spacing={theme.spacing()}
          alignItems={'center'}
          sx={{ minWidth: theme.spacing(4.5), maxWidth: theme.spacing(4.5)}}
        >
          <CommentIcon
            sx={{
              width: theme.spacing(2),
              color: theme.palette.grey[300]
            }}
          />
          <Text sx={{ color: theme.palette.grey[500] }}>
            {phrasesCount}
          </Text>
        </Stack>

        <Stack
          direction='row'
          alignItems={'center'}
          sx={{ }}
        >
          <Tooltip
            title={publicGroup ? 'Public playlist' : 'Edit playlist'}
          >
            <IconButton
              className={publicGroup ? 'active' : ''}
              onClick={onEdit}
              color={'primary'}
              sx={{
                color: theme.palette.grey[400],
                '&:hover, &:active': { color: theme.palette.primary.main },
                '&:focus': { color: theme.palette.grey[600] },
                '.MuiSvgIcon-root': { width: theme.spacing(2), height: theme.spacing(2) },

                '&.active': {
                  color: theme.palette.primary.main,
                },
              }}
            >
              {publicGroup
                ? <TranslationsIcon />
                : <PencilIcon />
              }
            </IconButton>
          </Tooltip>

          <IconButton
            onClick={e => setAnchor(e.currentTarget)}
            color={'primary'}
            sx={{
              color: theme.palette.grey[400],
              '&:hover, &:active, &:focus': { color: theme.palette.error.main },
              '.MuiSvgIcon-root': { width: theme.spacing(2), height: theme.spacing(2) }
            }}
          >
            <TrashIcon />
          </IconButton>
        </Stack>

        <ConfirmPopup
          anchor={anchor}
          text={'Delete playlist?'}
          onConfirm={handleDelete}
          onClose={() => setAnchor(null)}
        />
      </Stack>
    </Stack>
  );
}
