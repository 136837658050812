import {
  ECaptionsSelectionPopupActionButton,
  ICaptionsSelectionPopupState,
  ICurrentSelectionCaptionsSelectionPopupAction,
  ISetCaptionsSelectionPopupsLastSelectText,
  ISetCaptionsSelectionPopupsPreviewPhraseId,
  ISetCaptionsSelectionPopupsSetActionButton,
  ISetCaptionsSelectionPopupsSetAutoSpeechText,
  ISetCaptionsSelectionPopupsSetPlayingSelectText,
  ISetCaptionsSelectionPopupsSetSelection,
  ISetCaptionsSelectionPopupsSetSelectResult,
  ISetCaptionsSelectionPopupsShow,
  IUpdateCaptionsSelectionPopupAction,
  SET_CAPTIONS_SELECTION_POPUP_CURRENT_SELECTION,
  SET_CAPTIONS_SELECTION_POPUP_LAST_SELECT_TEXT,
  SET_CAPTIONS_SELECTION_POPUP_PREVIEW_PHRASE_ID,
  SET_CAPTIONS_SELECTION_POPUP_SET_ACTION_BUTTON,
  SET_CAPTIONS_SELECTION_POPUP_SET_AUTO_SPEECH_TEST,
  SET_CAPTIONS_SELECTION_POPUP_SET_PLAYING_SELECT_TEXT,
  SET_CAPTIONS_SELECTION_POPUP_SET_SELECT_RESULT,
  SET_CAPTIONS_SELECTION_POPUP_SET_SELECTION,
  SET_CAPTIONS_SELECTION_POPUP_SHOW, TCurrentSelection,
  UPDATE_CAPTIONS_SELECTION_POPUP
} from './types';
import { IPhraseSelectResult } from '../../effects/phrase/utils/phrase-select-preparator';

export class CaptionsSelectionPopupActions {

  public static setShow(
    show: boolean
  ): ISetCaptionsSelectionPopupsShow {
    return {
      type: SET_CAPTIONS_SELECTION_POPUP_SHOW,
      show
    }
  }

  public static setLastSelectText(
    lastSelectText?: string
  ): ISetCaptionsSelectionPopupsLastSelectText {
    return {
      type: SET_CAPTIONS_SELECTION_POPUP_LAST_SELECT_TEXT,
      lastSelectText
    }
  }

  public static setPreviewPhraseId(
    previewPhraseId: number
  ): ISetCaptionsSelectionPopupsPreviewPhraseId {
    return {
      type: SET_CAPTIONS_SELECTION_POPUP_PREVIEW_PHRASE_ID,
      previewPhraseId
    }
  }

  public static setSelectResult(
    selectResult: IPhraseSelectResult | null
  ): ISetCaptionsSelectionPopupsSetSelectResult {
    return {
      type: SET_CAPTIONS_SELECTION_POPUP_SET_SELECT_RESULT,
      selectResult
    }
  }

  public static setActionButton(
    actionButton: ECaptionsSelectionPopupActionButton | null
  ): ISetCaptionsSelectionPopupsSetActionButton {
    return {
      type: SET_CAPTIONS_SELECTION_POPUP_SET_ACTION_BUTTON,
      actionButton
    }
  }

  public static setSelection(
    selection?: Selection
  ): ISetCaptionsSelectionPopupsSetSelection {
    return {
      type: SET_CAPTIONS_SELECTION_POPUP_SET_SELECTION,
      selection
    }
  }

  public static setAutoSpeechText(
    autoSpeechText: boolean
  ): ISetCaptionsSelectionPopupsSetAutoSpeechText {
    return {
      type: SET_CAPTIONS_SELECTION_POPUP_SET_AUTO_SPEECH_TEST,
      autoSpeechText
    }
  }


  public static setPlayingSelectText(
    playingSelectText: boolean
  ): ISetCaptionsSelectionPopupsSetPlayingSelectText {
    return {
      type: SET_CAPTIONS_SELECTION_POPUP_SET_PLAYING_SELECT_TEXT,
      playingSelectText
    }
  }

  public static updateCaptionsSelectionPopupAction(
    value: Partial<ICaptionsSelectionPopupState>
  ): IUpdateCaptionsSelectionPopupAction {
    return {
      type: UPDATE_CAPTIONS_SELECTION_POPUP,
      value
    }
  }

  public static setCurrentSelection(
    value: Partial<TCurrentSelection>
  ): ICurrentSelectionCaptionsSelectionPopupAction {
    return {
      type: SET_CAPTIONS_SELECTION_POPUP_CURRENT_SELECTION,
      value
    }
  }


}
