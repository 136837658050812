import { API_URL } from '../../constants';
import { getDispatch } from '../../../spa/store';
import { postRequest } from '../../utils/requestService';
import { ICaptionsItem } from '../../../spa/types/common';

export type TVideoCaptionsResponse = {
  success: boolean;
  captions?: ICaptionsItem[];
};

type TCheckExistResponse = {
  exists: string
}

export type TVideoLangCaptionSaveItem = {
  startTime: number;
  endTime: number;
  text: string;
}


export class VideoLangRest {

  private static GET_VIDEO_LANG_CAPTIONS_URL = API_URL + '/videoLang/captions';
  private static CHECK_EXIST_VIDEO_LANG_CAPTIONS_URL = API_URL + '/videoLang/checkExist';
  private static SAVE_VIDEO_LANG_CAPTIONS_URL = API_URL + '/videoLang/saveCaptions';

  public static async getVideoCaptions(videoKey: string, langCode: string): Promise<TVideoCaptionsResponse> {
    const dispatch = getDispatch();
    return await dispatch(postRequest(VideoLangRest.GET_VIDEO_LANG_CAPTIONS_URL, {videoKey, langCode}));
  }

  public static async checkExitsVideoCaptions(videoKey: string, langCode: string): Promise<boolean> {
    const dispatch = getDispatch();
    const resp: TCheckExistResponse = await dispatch(postRequest(VideoLangRest.CHECK_EXIST_VIDEO_LANG_CAPTIONS_URL, {videoKey, langCode}));
    return resp?.exists === '1';
  }

  public static async saveVideoCaptions(videoKey: string, langCode: string, captions: TVideoLangCaptionSaveItem[]): Promise<any> {
    const dispatch = getDispatch();
    return await dispatch(postRequest(VideoLangRest.SAVE_VIDEO_LANG_CAPTIONS_URL, {videoKey, langCode, captions}));
  }

}