import React from 'react';
import { Button, FormControlLabel, RadioGroup, Stack } from '@mui/material';
import { ISavedVideoConfirmSelectGroupProps } from './types';
import { getUserGroupTitle } from '../../../../../store/models/selectors';
import { getState } from '../../../../../store';
import { theme } from '../../../../../../common/styles/theme';
import { Text } from '../../../../../../common/components/Text/Text';
import { getShortTitle } from "../../helpers/getShortTitle";
import { Radio } from "../../../../../../common/components/Radio/Radio";

export const SavedVideoConfirmSelectGroup: React.FC<ISavedVideoConfirmSelectGroupProps> = ({
  videoTitle,
  groupId,
  groups,
  onSave
}) => {
  const [selGroupId, setSelGroupId] = React.useState<number>(0);
  const [saveProcessing, setSaveProcessing] = React.useState<boolean>(false);

  const onGroupClick = (id: number) => {
      if (saveProcessing) return;
    setSelGroupId(id);
  }

  const handleSave = () => {
    setSaveProcessing(true);
    onSave(selGroupId);
  }

  const onEnter = (e: React.KeyboardEvent) => {
      if (e.key === 'Enter') {
          handleSave();
      }
  };

  React.useEffect(() => {
    setSelGroupId(groupId);
  }, [groupId]);

  return (
    <Stack onKeyDown={onEnter}>
        <Text
            sx={{ color: theme.palette.common.white, pr: theme.spacing(3) }}
            variant={'subtitle2'}
        >
          Save video "<b>{getShortTitle(videoTitle)}</b>" to:
      </Text>
      <RadioGroup
        defaultValue={selGroupId}
        value={selGroupId}
        name="radio-buttons-group"
      >
      {
        groups.map((group, index) => {
          const title = <Text sx={{ color: theme.palette.common.white }}>{getUserGroupTitle(getState(), group)}</Text>;
          return (
              <FormControlLabel
                  value={group.id}
                  control={<Radio />}
                  label={title}
                  key={group.id}
                  onClick={() => onGroupClick(group.id)}
                  disabled={saveProcessing}
              />
          );
        })
      }
      </RadioGroup>
      <Button
          onClick={handleSave}
          disabled={saveProcessing}
          fullWidth
          variant={'contained'}
          sx={{ mt: theme.spacing(2) }}
      >
        {saveProcessing ? 'Please wait...' : 'Save'}
      </Button>
    </Stack>
  );
}
