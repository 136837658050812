import React from 'react';
import { IControlSwitchProps } from './types';
import { FormControlLabel } from '@mui/material';
import { theme } from '../../../../../common/styles/theme';
import { Text } from '../../../../../common/components/Text/Text';
import { Switch } from '../../../../../common/components/Switch/Switch';


export const ControlSwitch: React.FC<IControlSwitchProps> = ({
  title,
  checked,
  disabled,
  sx,
  onChange
}) => {
  return (
      <FormControlLabel
        disabled={disabled}
        label={title}
        labelPlacement={'start'}
        control={<Switch checked={checked} onChange={onChange} />}
        sx={{
          justifyContent: 'space-between',
          mt: theme.spacing(2),
          ml: 0,
          mr: 0,

          '.MuiFormControlLabel-label': {
            whiteSpace: 'nowrap',
            fontWeight: 600,
          },

          '.MuiSwitch-root': {
            mr: 0,
          },

          ...sx,
        }}
      />
  );
};