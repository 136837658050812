import { ICaptionsItem, IPhraseNote, IVocabularyPhrase } from '../../types/common';

export const ADD_VIDEO_PHRASE_ACTION = 'VIDEOS/ADD_VIDEO_PHRASE';
export const UPDATE_VIDEO_PHRASE_ACTION = 'VIDEOS/UPDATE_VIDEO_PHRASE_ACTION';
export const DELETE_VIDEO_PHRASE_ACTION = 'VIDEOS/DELETE_VIDEO_PHRASE_ACTION';
export const ADD_VIDEO = 'VIDEOS/ADD_VIDEO';
export const SET_VIDEO_CAPTIONS = 'VIDEOS/SET_VIDEO_CAPTIONS';
export const SAVE_PHRASE_NOTE = 'VIDEOS/SAVE_PHRASE_NOTE';
export const UPDATE_PHRASE_NOTE = 'VIDEOS/UPDATE_PHRASE_NOTE';
export const RESET_VIDEO = 'VIDEOS/RESET_VIDEO';
export const SET_VIDEO_CAPTIONS_EMPTY = 'VIDEOS/SET_VIDEO_CAPTIONS_EMPTY';
export const SET_PHRASE_TIME_SCALES = 'VIDEOS/SET_PHRASE_TIME_SCALES';
export const SET_PHRASES_NOTES_LISTS_ACTION = 'VIDEOS/SET_PHRASES_NOTES_LISTS_ACTION';
export const SET_VIDEO_TEACHER_USER_ACTIVE_ID_ACTION = 'VIDEOS/SET_VIDEO_TEACHER_USER_ACTIVE_ID_ACTION';
export const SET_VIDEO_TEACHER_SHOW_NOTE_ID_ACTION = 'VIDEOS/SET_VIDEO_TEACHER_SHOW_NOTE_ID_ACTION';
export const SET_VIDEO_TEACHER_SHOW_PHRASE_ID_ACTION = 'VIDEOS/SET_VIDEO_TEACHER_SHOW_PHRASE_ID_ACTION';
export const SET_VIDEO_TEACHER_SELECT_PHRASE_ID_ACTION = 'VIDEOS/SET_VIDEO_TEACHER_SELECT_PHRASE_ID_ACTION';
export const SET_VIDEO_TEACHER_ACTION = 'VIDEOS/SET_VIDEO_TEACHER_ACTION';

export interface IPhraseNoteTimeScale {
  noteId: number,
  phraseId: number,
  startTime: number,
  endTime: number
}

export interface ITeacher {
  id: number,
  name: string,
  picture: string
}

export interface ITeacherUser {
  user: ITeacher;
  nickName: string;
  phrases?: IVocabularyPhrase[];
  phraseNotes?: IPhraseNote[];
  phraseCount: number;
  phrasesLoading: boolean;
  phrasesLoaded: boolean;
}

export interface IVideoItemTeachers {
  list: ITeacherUser[],
  activeId: number,
  showPhraseNoteId: number,
  showPhraseId: number,
  selectPhraseId: number
}

export interface IVideoItem {
  videoId: string;
  title: string;
  author: string;
  captions: Record<string, ICaptionsItem[]>;
  phrases: IVocabularyPhrase[];
  phraseNotes: IPhraseNote[];
  thumbnail: string;
  captionsEmpty: boolean;
  phraseNoteTimeScales: IPhraseNoteTimeScale[];
  teachers: IVideoItemTeachers
}

export interface IVideosState extends IVideoItem {
}

export interface IAddVideoAction {
  type: typeof ADD_VIDEO;
  video: IVideoItem;
}

export interface IResetVideoAction {
  type: typeof RESET_VIDEO;
}

export interface ISetVideoCaptionsAction {
  type: typeof SET_VIDEO_CAPTIONS;
  code: string;
  captions: ICaptionsItem[];
}

export interface ISavePhraseNoteAction {
  type: typeof SAVE_PHRASE_NOTE;
  note: IPhraseNote;
}

export interface IUpdatePhraseNoteAction {
  type: typeof UPDATE_PHRASE_NOTE;
  note: Partial<IPhraseNote>;
}

export interface IAddVideoPhraseAction {
  type: typeof ADD_VIDEO_PHRASE_ACTION;
  phrase: IVocabularyPhrase;
}

export interface IUpdateVideoPhraseAction {
  type: typeof UPDATE_VIDEO_PHRASE_ACTION;
  phrase: IVocabularyPhrase;
}

export interface IDeleteVideoPhraseAction {
  type: typeof DELETE_VIDEO_PHRASE_ACTION;
  phraseId: number;
}

export interface ISetVideoCaptionsEmptyAction {
  type: typeof SET_VIDEO_CAPTIONS_EMPTY;
  captionsEmpty: boolean;
}

export interface ISetPhraseTimeScaleAction {
  type: typeof SET_PHRASE_TIME_SCALES;
  phraseNoteTimeScales: IPhraseNoteTimeScale[];
}

export interface ISetPhrasesNotesListsAction {
  type: typeof SET_PHRASES_NOTES_LISTS_ACTION;
  phrases: IVocabularyPhrase[],
  notes: IPhraseNote[]
}

export interface ISetVideoTeacherUserActiveIdAction {
  type: typeof SET_VIDEO_TEACHER_USER_ACTIVE_ID_ACTION;
  id: number
}

export interface ISetVideoTeacherShowNoteIdAction {
  type: typeof SET_VIDEO_TEACHER_SHOW_NOTE_ID_ACTION;
  id: number
}

export interface ISetVideoTeacherShowPhraseIdAction {
  type: typeof SET_VIDEO_TEACHER_SHOW_PHRASE_ID_ACTION;
  id: number
}

export interface ISetVideoTeacherSelectPhraseIdAction {
  type: typeof SET_VIDEO_TEACHER_SELECT_PHRASE_ID_ACTION;
  id: number
}

export interface ISetVideoTeacherAction {
  type: typeof SET_VIDEO_TEACHER_ACTION;
  userId: number,
  data: Partial<ITeacherUser>
}

export type IVideosActions =
  | IAddVideoAction
  | IResetVideoAction
  | ISetVideoCaptionsAction
  | ISavePhraseNoteAction
  | IUpdatePhraseNoteAction
  | IAddVideoPhraseAction
  | IUpdateVideoPhraseAction
  | IDeleteVideoPhraseAction
  | ISetVideoCaptionsEmptyAction
  | ISetPhraseTimeScaleAction
  | ISetPhrasesNotesListsAction
  | ISetVideoTeacherUserActiveIdAction
  | ISetVideoTeacherShowNoteIdAction
  | ISetVideoTeacherShowPhraseIdAction
  | ISetVideoTeacherSelectPhraseIdAction
  | ISetVideoTeacherAction
