import { API_URL } from '../../constants';
import { postRequest } from '../../utils/requestService';
import { TAsyncThunk } from '../../../spa/store/types';
import { TVideoRest } from '../../../spa/store/models/types';

const VIDEO_ADD_URL = API_URL + '/video/add';

export const createGroupVideo = (
  videoId: string,
  groupId: number,
  langTargetAutoGenCode: string,
  title: string,
  author: string,
  thumbnail: string,
  nativeLangCode: string,
  targetLangCode: string,
  langs: string
): TAsyncThunk => (
  dispatch
): Promise<TVideoRest> => {
  const body = { videoId, groupId, langTargetAutoGenCode, title, author, thumbnail, nativeLangCode, targetLangCode, langs };
  return dispatch(postRequest(VIDEO_ADD_URL, body));
};
