import { IState } from '../../../../store/types';
import { connect } from 'react-redux';
import { CreatePlaylist } from '../components/CreatePlaylist';
import { ICreatePlaylistEvents, ICreatePlaylistFields } from '../components/types';
import { ICreatePlaylistHOCOwnProps } from './types';
import { CreatePlaylistEvents } from './events';
import { getIsCreatePlaylistModalOpen, getIsCreatePlaylistSaveModalOpen } from '../../../../store/general/selectors';
import { TLanguageCode } from '../../../../store/models/types';
import { getNativeLanguage } from '../../../../store/models/selectors';

const mapStateToProps = (
  dispatch: any,
  ownProps: ICreatePlaylistHOCOwnProps
) => {
  return (
    state: IState,
    ownProps: ICreatePlaylistHOCOwnProps
  ): ICreatePlaylistFields => {
    return {
      isOpen: getIsCreatePlaylistModalOpen(state) || getIsCreatePlaylistSaveModalOpen(state),
        isSave: getIsCreatePlaylistSaveModalOpen(state),
      nativeLangCode: getNativeLanguage(state)?.code || '',
    }
  };
}

const mapDispatchToProps = (
  dispatch: any,
  ownProps: ICreatePlaylistHOCOwnProps
): ICreatePlaylistEvents => ({
    onCreate: (nativeLangCode: TLanguageCode, targetLangCode: TLanguageCode, title, createDefVideos) =>
      dispatch(CreatePlaylistEvents.onCreate(nativeLangCode, targetLangCode, title, createDefVideos)),
    onClose: () => dispatch(CreatePlaylistEvents.onClose()),
    onCheckConfirmCreateDefVideos: targetLangCode => dispatch(CreatePlaylistEvents.onCheckConfirmCreateDefVideos(targetLangCode))
  }
);

export const CreatePlaylistHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreatePlaylist);

CreatePlaylistHOC.displayName = 'CreatePlaylistHOC';
