import { IState } from '../../../../../store/types';
import { getMainViewMode } from '../../../../../store/general/selectors';
import { connect } from 'react-redux';
import { MenuEvents } from './events';
import { IMenuEvents, IMenuFields } from '../components/types';
import { Menu } from '../components/Menu';
import { PhraseDetailsTabsEditorPopupSelectors } from '../../../../../store/phrase-details-tabs-editor-popup/selectors';
import { EMainMenuActive, EMainViewMode } from '../../../../../store/general/types';
import { EPhraseDetailsTabsEditorViewMode } from '../../../../../store/phrase-details-tabs-editor-popup/types';
import { NewFeaturesChecker } from '../../../../../effects/new-features-checker';
import { ProfilesSelectors } from '../../../../../store/profiles/selectors';

const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState,
  ): IMenuFields => {
    let active;

    const mainViewMode = getMainViewMode(state);
    if (mainViewMode === EMainViewMode.DASHBOARD) {
      active = EMainMenuActive.HOME;
    } else if (mainViewMode === EMainViewMode.PLAYLISTS) {
      active = EMainMenuActive.PLAYLISTS;
    } else if (mainViewMode === EMainViewMode.PHRASES) {
      active = EMainMenuActive.SAVED_PHRASES;
    } else if (mainViewMode === EMainViewMode.CONTENT_LIBRARY) {
      active = EMainMenuActive.CONTENT_LIBRARY;
    } else {
      const phraseDetailsTabsEditorPopupViewMode = PhraseDetailsTabsEditorPopupSelectors.getViewMode(state);
      if (phraseDetailsTabsEditorPopupViewMode === EPhraseDetailsTabsEditorViewMode.TRANSLATIONS) {
        active = EMainMenuActive.SETTINGS;
      } else {
        active = EMainMenuActive.CHATGPT_PROMPTS;
      }
    }

    return {
      active,
      version: NewFeaturesChecker.getCurrentVersion(),
      isLoadingProfiles: ProfilesSelectors.isLoading(state),
    }
  };
}

const mapDispatchToProps = (
  dispatch: any
): IMenuEvents => ({
    onClickChatGpt: () => dispatch(MenuEvents.onClickChatGpt()),
    onClickContent: () => dispatch(MenuEvents.onClickContent()),
    onClickHelp: () => dispatch(MenuEvents.onClickHelp()),
    onClickHome: () => dispatch(MenuEvents.onClickHome()),
    onClickPlaylists: () => dispatch(MenuEvents.onClickPlaylists()),
    onClickPhrases: () => dispatch(MenuEvents.onClickPhrases()),
    onClickStatistics: () => dispatch(MenuEvents.onClickStatistics()),
    onExtension: () => dispatch(MenuEvents.onExtension()),
    onTelegram: () => dispatch(MenuEvents.onTelegram()),
    onClickFeedback: () => dispatch(MenuEvents.onClickFeedback()),
    onLoadProfiles: () => dispatch(MenuEvents.onLoadProfiles()),
  }
);

export const MenuHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(Menu);

MenuHOC.displayName = 'MenuHOC';