import { AppThunk } from '../../../../../../store/types';
import {
	setCopyVideoConfirmAction,
} from '../../../../../../store/general/actions';
import { CopyVideoEffects } from '../../../../../../effects/copyVideoEffects';
import { getCopyVideoConfirm } from '../../../../../../store/general/selectors';
import { updateUserGroups } from '../../../../../../effects/updateUserGroups';
import { Navigator } from '../../../../../../effects/navigator';
import { TCreateCopyResponse } from '../../../../../../../common/rest/videoCopy/videoCopyRest';
import { UserGroupEffects } from '../../../../../../effects/userGroupEffects';
import { LangUtil } from '../../../../../../../common/utils/lang-util';
import { createGroupNameIndex } from '../../../../../../store/models/selectors';

export class CopyVideoConfirmEvents {

	public static onClose(): AppThunk {
		return async (
			dispatch,
			getState
		) => {
			dispatch(setCopyVideoConfirmAction({ show: false }));
		}
	}

	public static onGroupChange(
		groupId: number,
		name?: string,
	): AppThunk {
		return async (
			dispatch,
			getState
		) => {
			const state = getState();
			const {
				videoId,
				srcGroupNativeLang = '',
				srcGroupTargetLang = '',
			} = getCopyVideoConfirm(state) || {};
			if (!videoId) {
				dispatch(this.onClose());
				return Promise.reject();
			}

			if (!groupId) { // create group
				let groupName = createGroupNameIndex(state, name || `${LangUtil.getLangNameByCode(srcGroupTargetLang)} / ${LangUtil.getLangNameByCode(srcGroupNativeLang)}`);

				groupId = await UserGroupEffects.createGroup(groupName, srcGroupTargetLang, srcGroupNativeLang);

				if (groupId) {
					await UserGroupEffects.loadGroup(groupId);
				}
			}
			
			const { hash = '' } = getCopyVideoConfirm(state);
			// @ts-ignore
			const result: TCreateCopyResponse = await CopyVideoEffects.createCopy(hash, groupId);

			await dispatch(updateUserGroups(true));
			dispatch(this.onClose());
			if (result && result.videoId) {
				Navigator.openVideo(groupId, videoId, 0, true);
				UserGroupEffects.setLibraryGroupId(groupId);
			}

			return Promise.resolve();
		}
	}


}
