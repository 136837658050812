import { API_URL } from '../../constants';
import { postRequest } from '../../utils/requestService';
import { TAsyncThunk } from '../../../spa/store/types';
import { TUserLanguage } from '../../../spa/store/models/types';

const USER_GROUPS_URL = API_URL + '/groups/add';

type TResponse = {
  groupId?: number
}

export const createUserGroup = (
  name: string,
  langs: TUserLanguage[],
  createDefVideos: boolean
): TAsyncThunk => async (
  dispatch
): Promise<number> => {
  const resp: TResponse = await dispatch(postRequest(USER_GROUPS_URL, {name, langs, createDefVideos}));
  return resp.groupId || 0;
};
