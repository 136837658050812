import { DetectLangRest } from '../../common/rest/detectLang/detectLangRest';
import { LangUtil } from '../../common/utils/lang-util';
import { getState } from '../store';
import { PhraseDetailsSelectors } from '../store/phrase-details/selectors';
import { PhraseDetailsCache } from './phrase-details/phraseDetailsService/phrase-details-cache';

export class TextSpeaker {

  text: string;

  public async speak(text: string, lang?: string, stopOnPlay?: boolean, onStop?: () => void): Promise<boolean> {
    if (lang) {
      return this.speakByLang(text, lang, stopOnPlay, onStop);
    }
    const langCode = await TextSpeaker.getLangByText(text);
    return this.speakByLang(text, langCode, stopOnPlay, onStop);
  }

  public speakByLang(text: string, lang: string, stopOnPlay?: boolean, onStop?: () => void): boolean {
    if (!speechSynthesis) return false;
    if (speechSynthesis.speaking) {
      speechSynthesis.cancel();
      if (stopOnPlay &&
        text === this.text
      ) return true;
    }

    this.text = TextSpeaker.prepareText(text);
    TextSpeaker.playText(this.text, lang, onStop);
    return true;
  }

  public stop() {
    if (!speechSynthesis) return false;
    if (speechSynthesis.speaking) {
      speechSynthesis.cancel();
      this.text = '';
    }
  }

  private static async getLangByText(text: string): Promise<string> {
    const primaryKey = 'langByText';
    let langCode = PhraseDetailsCache.get([primaryKey, text]);
    if (langCode) {
      return langCode;
    }
    try {
      langCode = await DetectLangRest.exec(TextSpeaker.prepareText(text));
    } catch (e) {}
    if (!langCode) {
      langCode = LangUtil.checkLangCode(PhraseDetailsSelectors.getFromLang(getState())?.code);
    }
    PhraseDetailsCache.put([primaryKey, text], langCode);
    return langCode;
  }

  private static prepareText(text: string): string {
    let result = text.replace(/<\/?[^>]+(>|$)/g, ' ');
    result = result.replace(/\d+\./g, ' ');
    result = result.replace(/"|'/g, '');
    return result;
  }

  private static playText(text: string, lang: string, onStop?: () => void) {
    let isPlaying = false;

    let msg = new SpeechSynthesisUtterance();
    msg.text = text;
    msg.lang = lang;
    msg.onerror = () => {
      isPlaying = false;
      clearInterval(synthesisInterval);
      if (onStop) onStop();
    }
    msg.onend = () => {
      isPlaying = false;
      clearInterval(synthesisInterval);
      if (onStop) onStop();
    }

    speechSynthesis.speak(msg);
    isPlaying = true;

    // fix chrome bug  https://stackoverflow.com/questions/21947730/chrome-speech-synthesis-with-longer-texts
    const synthesisInterval = setInterval(() => {
      if (isPlaying) {
        speechSynthesis.pause();
        speechSynthesis.resume();
      } else {
        clearInterval(synthesisInterval);
      }
    }, 10000);
  }
}

export const textSpeaker = new TextSpeaker();
