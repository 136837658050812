import React from 'react';
import { Stack } from '@mui/material';
import { Text } from '../../../../../../common/components/Text/Text';
import { theme } from '../../../../../../common/styles/theme';
import { ISavedVideoConfirmErrorProps } from './types';

export const SavedVideoConfirmError: React.FC<ISavedVideoConfirmErrorProps> = ({
                                                                          errorMessage
}) => {

  return (
    <Stack >

      <Text
        sx={{ color: theme.palette.common.white, pr: theme.spacing(3) }}
        variant={'subtitle2'}
      >
        An error occurred while adding the video:
      </Text>
      <Text
        sx={{ color: theme.palette.common.white, pr: theme.spacing(3) }}
        variant={'subtitle2'}
      >
        {errorMessage}
      </Text>

    </Stack>
  );
}
