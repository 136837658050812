import { AppThunk, IState } from '../../../../../store/types';
import { VocabularyEffects } from '../../../../../effects/phrase/vocabulary-effects';
import {
  getGroupNativeLanguage,
  getGroupTargetLanguage,
  getUserGroupById
} from '../../../../../store/models/selectors';
import { getCurrentMovieKey, isCurrentAudioMovie } from '../../../../../store/current-video/selectors';
import { EVocabularyPhraseType, IVocabularyPhrase, PlayerStates } from '../../../../../types/common';
import { PhraseDetailsEffects } from '../../../../../effects/phrase-details/phrase-details-effects';
import { PlayerController } from '../../../../../effects/player/manager/playerController';
import {
  IPhraseSelectResult,
  PhraseSelectPreparator
} from '../../../../../effects/phrase/utils/phrase-select-preparator';
import { PhraseListSelectors } from '../../../../../store/phrase-list/selectors';
import { LangUtil } from '../../../../../../common/utils/lang-util';
import { PhraseContextEditorActions } from '../../../../../store/phrase-context-editor/actions';
import { EShortcutsListenerContainer, setShortcutsListenerContainer } from '../../../../../effects/shortcutsListener';
import { SelectionUtil } from '../../../../../../common/utils/selection-util';
import { PhraseListActions } from '../../../../../store/phrase-list/actions';
import { AudioPlayerManager } from '../../../../../effects/audioPlayer/audioPlayerManager';
import { getPlayerState } from '../../../../../store/general/selectors';
import _ from 'lodash';
import { CaptionsSelectionPopupActions } from '../../../../../store/captions-selection-popup/actions';
import { PhraseNoteTimeScaleEffects } from '../../../../../effects/phrase/phraseNoteTimeScaleEffects';
import { textSpeaker } from '../../../../../effects/textSpeaker';
import { findVideoPhraseById, getVideoPhraseNoteByPhraseId } from '../../../../../store/videos/selectors';
import { getState } from '../../../../../store';
import { HtmlUtils } from '../../../../../../common/utils/html-utils';

export class VocabularyPhraseEvents {

  public static onPlayPhrase(phraseId: number, isPause?: boolean): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      if (isPause) {
        PlayerController.getInstance().pause();
      } else {
        const phrase = VocabularyPhraseEvents.getPhrase(getState(), phraseId);
        if (phrase) {
          if (isCurrentAudioMovie(getState())) {
            AudioPlayerManager.getInstance().playPhrase(phrase);
          } else {
            PlayerController.getInstance().playPhrase(phrase);
          }
          setShortcutsListenerContainer(EShortcutsListenerContainer.PHRASES);
        }
      }
    }
  }

  public static onSpeechText(
    phraseId: number,
  ): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      PlayerController.getInstance().pause();
      const state = getState();
      const phrase = VocabularyPhraseEvents.getPhrase(getState(), phraseId);
      if (phrase) {
        const group = getUserGroupById(state, phrase.userGroupId || 0);
        if (group) {
          const lang = getGroupTargetLanguage(getState(), group);
          textSpeaker.speak(phrase.highlighted, lang?.code, false);
        }
      }
    }
  }

  public static onClickPhrase(phraseId: number): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      dispatch(PhraseListActions.setForwardPhraseId(phraseId));
      const state = getState();
      const phrase = VocabularyPhraseEvents.getPhrase(state, phraseId);
      if (!phrase?.userGroupId) return;

      const group = getUserGroupById(state, phrase.userGroupId);
      if (!group) return;
      const targetLang = getGroupTargetLanguage(state, group);
      if (!targetLang) return;
      PlayerController.getInstance().resetMode();
      const nativeLang = getGroupNativeLanguage(state, group);
      PhraseDetailsEffects.showFromSavedPhrase(targetLang, nativeLang, nativeLang, phrase.highlighted, phrase, false);
      dispatch(CaptionsSelectionPopupActions.setCurrentSelection({wordPhraseId: phrase.wordPhraseId, contextPhraseId: phrase.id}));
      dispatch(PhraseListActions.setPlayCaptionByPhraseId(phrase.id));
      if (getPlayerState(getState()) === PlayerStates.PLAYING) {
        PlayerController.getInstance().pause();
      }
      dispatch(CaptionsSelectionPopupActions.setShow(true));
    }
  }

  public static onRemovePhrase(phraseId: number): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      const state = getState();
      const videoId = getCurrentMovieKey(state);
      const phrase = VocabularyPhraseEvents.getPhrase(state, phraseId);
      PlayerController.getInstance().resetMode();
      if (phrase) {
        if (phrase.wordPhraseId) {
          const phrases = PhraseListSelectors.findPhraseByVideoIdAndType(state, videoId, EVocabularyPhraseType.WORD_AND_CONTEXT_SELECTED);
          const wordPhraseUsed = phrases.some(p => p.id !== phrase.id && p.wordPhraseId === phrase.wordPhraseId);
          if (!wordPhraseUsed) {
            dispatch(VocabularyEffects.removePhrase(phrase.wordPhraseId));
          }
        }
        dispatch(VocabularyEffects.removePhrase(phrase.id));
        if (phrase.wordPhraseId) {
          await PhraseDetailsEffects.deleteByPhrase(phrase.wordPhraseId);
        }
        PhraseNoteTimeScaleEffects.load();
      }
    }
  }

  public static onEditPhrase(phraseId: number): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      PlayerController.getInstance().resetMode();
      dispatch(PhraseContextEditorActions.activate(phraseId));
    }
  }

  public static onSelectText(phraseId: number, selection: Selection): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      const state = getState();
      const phrase = VocabularyPhraseEvents.getPhrase(state, phraseId);
      if (!phrase?.userGroupId) return;
      const group = getUserGroupById(state, phrase.userGroupId);
      if (!group) return;
      const targetLang = getGroupTargetLanguage(state, group);
      if (!targetLang) return;
      const nativeLang = getGroupNativeLanguage(state, group);

      const text = VocabularyPhraseEvents.getPhraseSelectionText(selection, phrase, targetLang.code);
      PlayerController.getInstance().resetMode();
      PhraseDetailsEffects.showFromSavedPhrase(targetLang, nativeLang, nativeLang, text, phrase, true);
      if (getPlayerState(getState()) === PlayerStates.PLAYING) {
        PlayerController.getInstance().pause();
      }
    }
  }

  private static getPhrase(state: IState, phraseId: number) {
    return PhraseListSelectors.findPhraseById(state, phraseId);
  }

  private static getPhraseSelectionText(selection: Selection, phrase: IVocabularyPhrase, langCode: string): string {
    let s = selection.toString().trim().replace(/[\n\r]/gm, ' ');
    if (!s) return '';

    let fullText = phrase.fullPhrase.replace(/[\n\r]/gm, ' ');

    const note = getVideoPhraseNoteByPhraseId(getState(), phrase.wordPhraseId);
    if (note) {
      const noteText = HtmlUtils.removeHtmlTags(note.text, ' ');
      fullText += ' ' + noteText;
    }

    const startPosition = fullText.indexOf(s);
    if (startPosition >= 0) {
      const result: IPhraseSelectResult = {
        element: undefined, endCaptionIndex: 0, endTime: 0, startCaptionIndex: 0, startTime: 0,
        text: s,
        startPosition,
        endPosition: startPosition + s.length
      }
      const useCheckSpanWords  = !LangUtil.isHieroglyphLang(langCode);
      if (useCheckSpanWords) {
        const selection = SelectionUtil.getSelectedText();
        if (selection) {
          const ret = new PhraseSelectPreparator(selection, [{
            startTime:0, endTime:0, duration:0, text: fullText
          }], [], [], null, true).checkSpanWords(result);
          s = ret.text;
        }
      }
    }

    return s;
  }

  public static onOpenNote (
    phraseId: number
  ): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      dispatch(PhraseListActions.setForwardPhraseId(phraseId));

      const state = getState();
      const phrase = VocabularyPhraseEvents.getPhrase(state, phraseId);
      if (!phrase?.userGroupId) return;
      const group = getUserGroupById(state, phrase.userGroupId);
      if (!group) return;
      const targetLang = getGroupTargetLanguage(state, group);
      if (!targetLang) return;
      const nativeLang = getGroupNativeLanguage(state, group);
      PlayerController.getInstance().resetMode();
      PhraseDetailsEffects.showNoteFromSavedPhrase(targetLang, nativeLang, nativeLang, phrase.highlighted, phrase);
      // // copy from onClickPhrase:
      dispatch(CaptionsSelectionPopupActions.setCurrentSelection({wordPhraseId: phrase.wordPhraseId, contextPhraseId: phrase.id}));
      dispatch(PhraseListActions.setPlayCaptionByPhraseId(phrase.id));
      if (getPlayerState(getState()) === PlayerStates.PLAYING) {
        PlayerController.getInstance().pause();
      }
      dispatch(CaptionsSelectionPopupActions.setShow(true));
    }
  }

}