import { AppThunk } from '../../../../../store/types';
import { setSavedVideoConfirmAction } from '../../../../../store/general/actions';
import { ESavedVideoConfirmShowMode } from '../../../../../store/general/types';

export class SavedVideoConfirmNotifyEvents {

  public static onSelectGroup(): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      dispatch(setSavedVideoConfirmAction({showMode: ESavedVideoConfirmShowMode.SELECT_GROUP}));
    }
  }

}