import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { IPhraseUsageEvents, IPhraseUsageFields } from '../components/types';
import { PhraseUsage } from '../components/PhraseUsage';
import { PhraseDetailsSelectors } from '../../../../../store/phrase-details/selectors';
import { PhraseUsageEvents } from './events';
import { EPhraseDetailsTabType } from '../../../../../store/models/types';
import { GptUtils } from '../../../../../effects/gptUtils';

const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): IPhraseUsageFields => {
    const activeTab = PhraseDetailsSelectors.getActiveTab(state);
    const active = !!(activeTab && activeTab.type === EPhraseDetailsTabType.USAGE);
    const text = PhraseDetailsSelectors.getText(state);
    const fromLang = PhraseDetailsSelectors.getFromLang(state);
    const toLang = PhraseDetailsSelectors.getToLang(state);
    const resultText = text && GptUtils.formatResult(PhraseDetailsSelectors.getUsageResultText(state) || '');
    const processing = PhraseDetailsSelectors.getUsageResultProcessing(state) || false;
    return {
      active,
      fromLangCode: fromLang?.code,
      toLangCode: toLang?.code,
      text,
      resultText,
      processing
    }
  };
}

const mapDispatchToProps = (
  dispatch: any
): IPhraseUsageEvents => ({
    onLoad: useCache => dispatch(PhraseUsageEvents.onLoad(useCache)),
    onClickSettings: () => dispatch(PhraseUsageEvents.onClickSettings()),
    onCopyNote: () => dispatch(PhraseUsageEvents.onCopyNote()),
  }
);

export const PhraseUsageHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(PhraseUsage);

PhraseUsageHOC.displayName = 'PhraseUsageHOC';
