import { AppThunk } from '../../../../../store/types';
import { TeachersEffects } from '../../../../../effects/teachersEffects';

import { isVideoTeacherPhrasesLoaded } from '../../../../../store/videos/selectors';
import { PhraseEffects } from '../../../../../effects/phrase/PhraseEffects';
import { setSnackbarPanelAction } from '../../../../../store/general/actions';
import { TEACHER_COPY_NOTHING_COPY, TEACHER_COPY_PHRASES_RESULT } from '../components/wordings';
import { getVideoTeacherUserActiveId, getVideoTeacherUserNick } from '../../../../../store/videos/selectors';
import { Navigator } from '../../../../../effects/navigator';
import { UrlUtil } from '../../../../../../common/utils/url-util';


export class TeacherListEvents {

	public static onTeacherSelect(id: number): AppThunk {
		return async (
			dispatch,
			getState
		) => {
			const userId = getVideoTeacherUserActiveId(getState());
			if (userId === id) {
				TeachersEffects.hidePhrases();
			} else {
				TeachersEffects.showPhrases(id);
			}
		}
	}

	public static onLoadNotes(userId: number): AppThunk {
		return async (
			dispatch,
			getState
		) => {
			if (!isVideoTeacherPhrasesLoaded(getState(), userId)) {
				await TeachersEffects.loadPhrases(userId);
			}
			const copyCount = await PhraseEffects.copyAllTeacherPhrases(userId);
			TeachersEffects.hidePhrases();
			const msg = copyCount ? TEACHER_COPY_PHRASES_RESULT.replace('{copyCount}', ''+copyCount) : TEACHER_COPY_NOTHING_COPY;
			dispatch(setSnackbarPanelAction(true, msg));
		}
	}

	public static onViewProfile(userId: number): AppThunk {
		return async (
			dispatch,
			getState
		) => {
			const nick = getVideoTeacherUserNick(getState(), userId);
			const url = UrlUtil.getLinkProfile(nick);
      		Navigator.openUrl(url);
		}
	}

}
