import {
  CONTENT_LIB_SET_GROUP_COPY_CONFIRM_ACTION,
  CONTENT_LIB_SET_SEARCH_FILTER_ACTION,
  CONTENT_LIB_SET_SEARCH_RESULT_ACTION,
  CONTENT_LIB_SET_SELECTED_GROUP_ACTION,
  CONTENT_LIB_SET_VIDEO_COPY_CONFIRM_ACTION,
  CONTENT_LIB_SET_VIDEO_POPUP_ACTION,
  CONTENT_LIB_SET_VIEW_MODE_ACTION,
  EContentLibViewMode,
  IContentLibCopyGroupConfirm,
  IContentLibCopyVideoConfirm,
  IContentLibSearchFilter,
  IContentLibSearchResult,
  IContentLibSetGroupCopyConfirmAction,
  IContentLibSetSearchFilterAction,
  IContentLibSetSearchResultAction,
  IContentLibSetSelectedGroupIdAction,
  IContentLibSetVideoCopyConfirmAction,
  IContentLibSetVideoPopupAction,
  IContentLibSetViewModeAction,
  IContentLibVideoPopup
} from './types';

export class ContentLibActions {

  public static setSearchResult(
    searchResult: Partial<IContentLibSearchResult>
  ): IContentLibSetSearchResultAction {
    return {
      type: CONTENT_LIB_SET_SEARCH_RESULT_ACTION,
      searchResult
    }
  }

  public static setSearchFilter(
    searchFilter: Partial<IContentLibSearchFilter>
  ): IContentLibSetSearchFilterAction {
    return {
      type: CONTENT_LIB_SET_SEARCH_FILTER_ACTION,
      searchFilter
    }
  }

  public static setViewMode(
    viewMode: EContentLibViewMode
  ): IContentLibSetViewModeAction {
    return {
      type: CONTENT_LIB_SET_VIEW_MODE_ACTION,
      viewMode
    }
  }

  public static setVideoPopup(
    videoPopup: Partial<IContentLibVideoPopup>
  ): IContentLibSetVideoPopupAction {
    return {
      type: CONTENT_LIB_SET_VIDEO_POPUP_ACTION,
      videoPopup
    }
  }

  public static setCopyVideoConfirm(
    copyVideoConfirm: Partial<IContentLibCopyVideoConfirm>
  ): IContentLibSetVideoCopyConfirmAction {
    return {
      type: CONTENT_LIB_SET_VIDEO_COPY_CONFIRM_ACTION,
      copyVideoConfirm
    }
  }

  public static setCopyGroupConfirm(
    copyGroupConfirm: Partial<IContentLibCopyGroupConfirm>
  ): IContentLibSetGroupCopyConfirmAction {
    return {
      type: CONTENT_LIB_SET_GROUP_COPY_CONFIRM_ACTION,
      copyGroupConfirm
    }
  }

  public static setSelectedGroupId(
    selectedGroupId: number
  ): IContentLibSetSelectedGroupIdAction {
    return {
      type: CONTENT_LIB_SET_SELECTED_GROUP_ACTION,
      selectedGroupId
    }
  }

  
}