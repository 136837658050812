import { IPhraseSelectResult } from '../../../../effects/phrase/utils/phrase-select-preparator';
import { getTargetCaptions } from '../../../../store/videos/selectors';
import { getState } from '../../../../store';
import { ICaptionsItem } from '../../../../types/common';

export class TextSelectionPopupUtils {

  public static getSpeechTextFromSelect(selectResult: IPhraseSelectResult): string {
    if (selectResult.startCaptionIndex === selectResult.endCaptionIndex) {
      return selectResult.text;
    }
    const captions: ICaptionsItem[] = getTargetCaptions(getState());
    if (selectResult.startPosition === 0) {
      return captions[selectResult.startCaptionIndex].text;
    } else if (selectResult.startPosition > 0) {
      let text = captions[selectResult.startCaptionIndex].text;
      text = text.substring(selectResult.startPosition);
      const nextIndex = selectResult.startCaptionIndex + 1;
      if (nextIndex < captions.length) {
        if (selectResult.endCaptionIndex === nextIndex) {
          text += ' ' + captions[nextIndex].text.substring(0, selectResult.endPosition);
        } else {
          text += ' ' + captions[nextIndex].text;
        }
      }
      return text;
    }
    return '';
  }
}