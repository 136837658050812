import { Navigator } from '../../../../../effects/navigator';
import { AppThunk } from '../../../../../store/types';
import { UserProfileRest } from '../../../../../../common/rest/user/userProfileRest';
import { isAuthorised } from '../../../../../store/general/selectors';
import { setRouteOnLoginAction } from '../../../../../store/general/actions';
import { UrlUtil } from '../../../../../../common/utils/url-util';

export class PublicProfileEvents {

	public static onClickLogo(): AppThunk {
		return async (
			dispatch,
			getState
		) => {
			Navigator.openDefault();
		}
	}

	public static onLoad(nick: string): AppThunk {
		return async (
			dispatch,
			getState
		) => {
			if (!isAuthorised(getState())) {
				dispatch(setRouteOnLoginAction({route: UrlUtil.getLinkProfile(nick)}));
				Navigator.openDefault();
				return Promise.reject();
			}

			return await UserProfileRest.getByNick(nick);
		}
	}

	public static onLoadGroups(nick: string): AppThunk {
		return async (
			dispatch,
			getState
		) => {
			if (!isAuthorised(getState())) {
				dispatch(setRouteOnLoginAction({route: UrlUtil.getLinkProfile(nick)}));
				Navigator.openDefault();
				return Promise.reject();
			}
			return await UserProfileRest.findGroups(nick);
		}
	}

	public static onClickBack(): AppThunk {
		return (
		  dispatch,
		  getState
		) => {
		  history.back();
		}
	  }
}