import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { VocabularyPhrase } from '../components/VocabularyPhrase';
import { IVocabularyPhraseEvents, IVocabularyPhraseFields } from '../components/types';
import { IVocabularyPhraseHOCOwnProps } from './types';
import { VocabularyPhraseEvents } from './events';
import { PhraseListSelectors } from '../../../../../store/phrase-list/selectors';
import { getFlashPhraseId, getPlayerState } from '../../../../../store/general/selectors';
import { IVocabularyPhrase, PlayerStates } from '../../../../../types/common';
import { getVideoPhraseNoteByPhraseId } from '../../../../../store/videos/selectors';

const getHighlightedStartPos = (phrase?: IVocabularyPhrase, wordPhrase?: IVocabularyPhrase | null): number => {
  if (!phrase || !wordPhrase) return 0;
  if (phrase.startCaptionIndex === wordPhrase.startCaptionIndex) {
    return wordPhrase.startPosition - phrase.startPosition;
  }
  return 0;
}

const mapStateToProps = (
  dispatch: any,
  {phraseId}: IVocabularyPhraseHOCOwnProps
) => {
  return (
    state: IState
  ): IVocabularyPhraseFields => {
    const phrase = PhraseListSelectors.findPhraseById(state, phraseId);
    const wordPhrase = phrase && phrase.wordPhraseId ?
      PhraseListSelectors.findPhraseById(state, phrase.wordPhraseId) : null;
    const playPhrase = PhraseListSelectors.getPlayPhraseId(state) === phraseId;
    const playRelationCaption = PhraseListSelectors.getPlayCaptionByPhraseId(state) === phraseId;
    const top = PhraseListSelectors.getTopPhraseId(state) === phraseId;
    const flashPhraseId = getFlashPhraseId(state);
    const highlightedStartPos = getHighlightedStartPos(phrase, wordPhrase);
    const viewType = PhraseListSelectors.getPhraseListViewType(state);
    let note = getVideoPhraseNoteByPhraseId(state, phrase?.wordPhraseId || phrase?.id || 0)?.text;

    return {
      phraseId: phrase ? phrase.id : 0,
      fullPhrase: phrase ? phrase.fullPhrase : '',
      highlighted: phrase ? phrase.highlighted : '',
      playPhrase,
      top,
      playRelationCaption,
      highlightedStartPos,
      flashPhrase: !!(phrase && (flashPhraseId === phrase.id || flashPhraseId === phrase.wordPhraseId)),
      note,
      play: getPlayerState(state) === PlayerStates.PLAYING,
      viewType,
      showMenu: true,
      type: phrase?.type,
    }
  };
}

const mapDispatchToProps = (
  dispatch: any,
  {phraseId}: IVocabularyPhraseHOCOwnProps
): IVocabularyPhraseEvents => ({
    onPlayPhrase: (isPause) => dispatch(VocabularyPhraseEvents.onPlayPhrase(phraseId, isPause)),
    onClickPhrase: () => dispatch(VocabularyPhraseEvents.onClickPhrase(phraseId)),
    onRemovePhrase: () => dispatch(VocabularyPhraseEvents.onRemovePhrase(phraseId)),
    onEditPhrase: () => dispatch(VocabularyPhraseEvents.onEditPhrase(phraseId)),
    onSelectText: selection => dispatch(VocabularyPhraseEvents.onSelectText(phraseId, selection)),
    onOpenNote: () => dispatch(VocabularyPhraseEvents.onOpenNote(phraseId)),
  }
);

export const VocabularyPhraseHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(VocabularyPhrase);

VocabularyPhraseHOC.displayName = 'VocabularyPhraseHOC';