import { AppThunk } from '../../../../store/types';
import { setShowAddNewVideoPopupAction } from '../../../../store/general/actions';
import { YoutubeUrlUtil } from '../../../../../common/utils/youtube-url-util';
import { IAddVideoFromUrlValidateResult } from '../components/types';
import { getLibraryGroupId } from '../../../../store/general/selectors';
import { createVideoEffect, TAddVideoResult } from '../../../../effects/video_effects';
import { EventsRouter } from '../../../../../common/events/eventsRouter';
import { Events } from '../../../../../common/events/types';
import { Navigator } from '../../../../effects/navigator';
import { CopyVideoEffects } from '../../../../effects/copyVideoEffects';

export class AddNewVideoPopupEvents {

  public static onClose(): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      dispatch(setShowAddNewVideoPopupAction(false));
    }
  }

  public static onValidate(url: string): AppThunk {
    return (
      dispatch,
      getState
    ): IAddVideoFromUrlValidateResult => {
      if (CopyVideoEffects.isCopyUrl(url)) {
        return {success: true};
      }
      if (!YoutubeUrlUtil.isYoutubeUrl(url)) {
        return {
          success: false,
          error: 'Error: Wrong youtube url'
        }
      }
      return {
        success: true
      }
    }
  }

  public static onCreate(url: string): AppThunk {
    return async(
      dispatch,
      getState
    ) => {
      const groupId = getLibraryGroupId(getState());
      if (CopyVideoEffects.isCopyUrl(url)) {
        const hash = CopyVideoEffects.getHashFromCopyUrl(url);
        await CopyVideoEffects.startCopy(hash, groupId);
      } else {
        EventsRouter.trackEvent(Events.ADD_VIDEO_BUTTON_CLICKED);
        if (groupId) {
            const result: TAddVideoResult = await dispatch(createVideoEffect(groupId, url));
            Navigator.openVideo(result.userGroupId, result.videoId);
        }
      }
    }
  }

}