import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { PublicProfile } from '../components/PublicProfile';
import { IPublicProfileEvents, IPublicProfileFields } from '../components/types';
import { PublicProfileEvents } from './events';
import { UrlUtil } from '../../../../../../common/utils/url-util';
import { getAuthUser } from '../../../../../store/general/selectors';

const mapStateToProps = (
	dispatch: any
) => {
	return (
		state: IState,
	): IPublicProfileFields => {
		const authUser = getAuthUser(state) as any;
		const userName = UrlUtil.getRouteProfileNick();
		return {
			...authUser,
			userName,
		}
	};
};

const mapDispatchToProps = (
	dispatch: any
): IPublicProfileEvents => ({
	onClickLogo: () => dispatch(PublicProfileEvents.onClickLogo()),
	onLoad: (nick) => dispatch(PublicProfileEvents.onLoad(nick)),
	onLoadGroups: (nick) => dispatch(PublicProfileEvents.onLoadGroups(nick)),
	onClickBack: () => dispatch(PublicProfileEvents.onClickBack()),
});

export const PublicProfileHOC = connect(
	mapStateToProps,
	mapDispatchToProps
)(PublicProfile);

PublicProfileHOC.displayName = 'PublicProfileHOC';
