import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { IProfileGroupInfoEvents, IProfileGroupInfoFields } from '../components/types';
import { IProfileGroupInfoHOCProps } from './types';
import { ProfileGroupInfoEvents } from './events';
import { ProfileGroupInfo } from '../components/ProfileGroupInfo';

const mapStateToProps = (
  dispatch: any,
  props: IProfileGroupInfoHOCProps
) => {
  return (
    state: IState
  ): IProfileGroupInfoFields => {
    let {
      id = 0,
      title = '',
      description = '',
      targetLang = '',
      nativeLang = '',
      videos = [],
    } = props;

    return {
      id,
      title,
      description,
      targetLang,
      nativeLang,
      videos,
    }
  };
}

const mapDispatchToProps = (
  dispatch: any,
  { id }: IProfileGroupInfoHOCProps
): IProfileGroupInfoEvents => ({
    onCopyGroup: () => dispatch(ProfileGroupInfoEvents.onCopyGroup(id)),
  }
);

export const ProfileGroupInfoHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileGroupInfo);

ProfileGroupInfoHOC.displayName = 'ProfileGroupInfoHOC';
