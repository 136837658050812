import { Reducer } from 'redux';
import {
  ICaptionsSelectionPopupState,
  ISetCaptionsSelectionPopupActions, SET_CAPTIONS_SELECTION_POPUP_CURRENT_SELECTION,
  SET_CAPTIONS_SELECTION_POPUP_LAST_SELECT_TEXT,
  SET_CAPTIONS_SELECTION_POPUP_PREVIEW_PHRASE_ID, SET_CAPTIONS_SELECTION_POPUP_SAVE_PHRASE_MODE,
  SET_CAPTIONS_SELECTION_POPUP_SEL_PHRASE_ID,
  SET_CAPTIONS_SELECTION_POPUP_SET_ACTION_BUTTON,
  SET_CAPTIONS_SELECTION_POPUP_SET_AUTO_SPEECH_TEST,
  SET_CAPTIONS_SELECTION_POPUP_SET_PLAYING_SELECT_TEXT,
  SET_CAPTIONS_SELECTION_POPUP_SET_SELECT_RESULT,
  SET_CAPTIONS_SELECTION_POPUP_SET_SELECTION,
  SET_CAPTIONS_SELECTION_POPUP_SHOW,
  UPDATE_CAPTIONS_SELECTION_POPUP
} from './types';

const initialState: ICaptionsSelectionPopupState = {
  show: false,
  previewPhraseId: 0,
  actionButton: null,
  autoSpeechText: true,
  playingSelectText: false,
  currentSelection: {},
  savePhraseModePopup: {
    show: false
  },
};

export const captionsSelectionPopupReducer: Reducer<ICaptionsSelectionPopupState, ISetCaptionsSelectionPopupActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SET_CAPTIONS_SELECTION_POPUP_SHOW:
      return { ...state, show: action.show }
    case SET_CAPTIONS_SELECTION_POPUP_SEL_PHRASE_ID:
      return { ...state, selelectedPhraseId: action.selelectedPhraseId }
    case SET_CAPTIONS_SELECTION_POPUP_PREVIEW_PHRASE_ID:
      return { ...state, previewPhraseId: action.previewPhraseId }
    case SET_CAPTIONS_SELECTION_POPUP_LAST_SELECT_TEXT:
      return { ...state, lastSelectText: action.lastSelectText }
    case SET_CAPTIONS_SELECTION_POPUP_SET_SELECT_RESULT:
      if (action.selectResult) {
        return { ...state, ...{ selectResult: {...action.selectResult}, lastSelectResult: {...action.selectResult}} }
      }
      return { ...state, ...{ selectResult: {...action.selectResult} } }
    case SET_CAPTIONS_SELECTION_POPUP_SET_ACTION_BUTTON:
      return { ...state, actionButton: action.actionButton }
    case SET_CAPTIONS_SELECTION_POPUP_SET_SELECTION:
      return { ...state, selection: action.selection }
    case SET_CAPTIONS_SELECTION_POPUP_SET_AUTO_SPEECH_TEST:
      return { ...state, autoSpeechText: action.autoSpeechText }
    case SET_CAPTIONS_SELECTION_POPUP_SET_PLAYING_SELECT_TEXT:
      return { ...state, playingSelectText: action.playingSelectText }
    case UPDATE_CAPTIONS_SELECTION_POPUP:
      return {
        ...state,
        ...action.value,
      }
    case SET_CAPTIONS_SELECTION_POPUP_CURRENT_SELECTION:
      return { ...state, currentSelection: {...action.value} }
    case SET_CAPTIONS_SELECTION_POPUP_SAVE_PHRASE_MODE:
      return { ...state, savePhraseModePopup: {...action.value} }
    default:
      return state;
  }
};
