import React, { useContext } from 'react';
import { theme } from '../../../../../../common/styles/theme';
import { Box, Button, IconButton, Stack } from '@mui/material';
import { Logo } from '../../../../../../../icons/Logo';
import { IToolbarPanelProps } from './types';
import { ButtonIconGrey } from '../../../../../../common/components/ButtonIconGrey/ButtonIconGrey';
import { Columns3Icon } from '../../../../../../../icons/Columns3Icon';
import { EMainLayoutType, EMainViewMode } from '../../../../../store/general/types';
import { Columns4Icon } from '../../../../../../../icons/Columns4Icon';
import { ArrowIcon } from '../../../../../../../icons/ArrowIcon';
import { LogoLabel } from '../../../../../../../icons/LogoLabel';
import { MenuHOC } from '../../Menu/hocs/MenuHOC';
import { ArrowLightLeftIcon } from '../../../../../../../icons/ArrowLightLeftIcon';
import { CLASS_ONBOARDING_NOEVENT } from '../../../Onboarding/constants';
import { HelpIcon } from '../../../../../../../icons/HelpIcon';
import { OneTimeTooltipTutorialHOC } from '../../../OneTimeTooltip/hocs/OneTimeTooltipTutorialHOC/OneTimeTooltipTutorialHOC';
import { DeviceContext } from '../../../../../../common/contexts/DeviceContext';
import { ToolbarVideoHOC } from '../../ToolbarVideo/hocs/ToolbarVideoHOC';
import { ChatGptLightIcon } from '../../../../../../../icons/ChatGptLightIcon';
import { Modal } from '../../../../../../common/components/Modal/Modal';
import { PromptsListHOC } from '../../../PhraseDetailsTabsEditor/PromptsList/hocs/PromptsListHOC';
import { EPhraseDetailsTabType } from '../../../../../store/models/types';


export const TopBarPanel: React.FC<IToolbarPanelProps> = ({
	isCanHidePanel,
	isVideo,
	isWide,
	mainLayoutType,
	mainViewMode,
	phraseDetailsTabType,
	onClickBack,
	onClickLogo,
	onLeaveFeedback,
	onLayoutToggle,
	onHideLeftPanelToggle,
	onClickHelp,
}) => {

	const {isMobile} = useContext(DeviceContext);
	const isDashboard = mainViewMode === EMainViewMode.DASHBOARD;
	const isPhrasesView = mainViewMode === EMainViewMode.PHRASES;
	const isMobilePlaylists = mainViewMode === EMainViewMode.PLAYLISTS;
	const isMobilePhraseDetails = mainViewMode === EMainViewMode.PHRASE_DETAILS;
	const isMobileNotes = isMobilePhraseDetails && phraseDetailsTabType === EPhraseDetailsTabType.NOTES;
	const isMobilePrompts = isMobilePhraseDetails && !isMobileNotes;
	const isMobileContentLib = isMobile && mainViewMode === EMainViewMode.CONTENT_LIBRARY;
	const isMobileView = isMobile && (
		isDashboard ||
		isMobilePlaylists ||
		isMobilePhraseDetails
	);

	const [isPromptsLibraryOpened, setPromptsLibraryOpened] = React.useState(false);

	const onClickHidePanel = () => {
		const floatPanel = document.querySelector('.left-panel-float .MuiBackdrop-root') as HTMLElement;
		if (floatPanel) {
			floatPanel.click();
		} else {
			onHideLeftPanelToggle();
		}
	};

	const button = isDashboard || isMobilePlaylists
		? <MenuHOC />
		: <ButtonIconGrey
			sx={{
				'.MuiSvgIcon-root': {
					width: theme.spacing(3),
					height: theme.spacing(3),
				},
			}}
			onClick={() => onClickBack(isMobile)}
		>
			<ArrowLightLeftIcon />
		</ButtonIconGrey>;

	const logo = !isVideo && !isMobilePhraseDetails && !isMobileContentLib &&
		<IconButton
			sx={{ p: 0, }} 
			onClick={onClickLogo}
		>
			{isMobilePlaylists
				? <LogoLabel sx={{ height: theme.spacing(4), width: 'auto' }} />
				: <Logo sx={{ height: theme.spacing(4), width: 'auto' }} />
			}
		</IconButton>;

	return (
		<Stack
			className={`top-bar-panel ${CLASS_ONBOARDING_NOEVENT}`}
			direction={'row'}
			sx={{
				position: 'sticky',
				zIndex: 100,
				top: 0,
				justifyContent: 'space-between',
				alignItems: 'center',
				width: '100%',
				background: theme.palette.common.white,

				'&:before': {
					content: '""',
					position: 'absolute',
					left: theme.spacing(-2),
					right: theme.spacing(-2),
					top: theme.spacing(-2),
					bottom: theme.spacing(-1),
					background: 'white',
					borderRadius: theme.spacing(.75),
				},
				...(isMobileView ? {
					'.mobile &': {
						padding: theme.spacing(1),
						boxShadow: theme.shadows[8],
	
						'&:before': {
							display: 'none',
						}
					},
				}: {}),
			}}
			gap={theme.spacing(1)}
		>
			<Stack
				direction={'row'}
				sx={{
					maxWidth: '100%',
					alignItems: 'center',
				}}
				gap={theme.spacing(1)}
			>
				{button}
				{logo}
				{isVideo &&
					<ToolbarVideoHOC />
				}
				{isPhrasesView &&
					<Box
						sx={{
							ml: theme.spacing(1),
							zIndex: 0,
							fontSize: theme.typography.subtitle2,
							fontWeight: 600,

							[theme.breakpoints.up('xl')]: { // same as VideoLeftColumn
								fontSize: theme.typography.h6,
								fontWeight: 600,
							}
						}}
					>
						Saved phrases
					</Box>
				}
				{isMobilePrompts &&
					<Box
						sx={{
							ml: theme.spacing(1),
							fontSize: theme.typography.h6,
							fontWeight: 600,
						}}
					>
						Prompts
					</Box>
				}
				{isMobileNotes &&
					<Box
						sx={{
							ml: theme.spacing(1),
							fontSize: theme.typography.h6,
							fontWeight: 600,
						}}
					>
						Notes
					</Box>
				}
			</Stack>
			
			{!isVideo && !isMobilePhraseDetails &&
				<Stack
					direction={'row'}
					sx={{
						justifyContent: 'flex-end',
					}}
					gap={theme.spacing(1)}
				>
					{/* {isDashboard && // пока один тип
						<ButtonIconGrey
							sx={{
								'.MuiSvgIcon-root': {
									width: theme.spacing(5),
									height: theme.spacing(5),
								},
							}}
							onClick={onLayoutToggle}
						>
							{mainLayoutType === EMainLayoutType.COLUMNS_3
								? <Columns3Icon />
								: <Columns4Icon />
							}
						</ButtonIconGrey>
					} */}


					<ButtonIconGrey
						className={'toolbar-help-button'}
						sx={{
							'.MuiSvgIcon-root': {
								width: theme.spacing(2.5),
								height: theme.spacing(2.5),
							},
						}}
						title={'Help'}
						onClick={onClickHelp}
					>
						<HelpIcon />
					</ButtonIconGrey>
					
					<OneTimeTooltipTutorialHOC />

					{isCanHidePanel && !isMobileView && (isDashboard || isPhrasesView) &&
						<ButtonIconGrey
							sx={{
							borderRadius: '50%',
							'.MuiSvgIcon-root': {
								width: theme.spacing(2),
								height: theme.spacing(1.5),
								transform: 'rotate(180deg)',
							},
							}}
							onClick={onClickHidePanel}
						>
							<ArrowIcon />
						</ButtonIconGrey>
					}
				</Stack>
			}

			{isMobilePrompts &&
				<Stack
					direction={'row'}
					sx={{
						justifyContent: 'flex-end',
					}}
					gap={theme.spacing(1)}
				>
					
					<Button
						onClick={()=>setPromptsLibraryOpened(true)}
						variant={'contained'}
						color={'primary'}
						sx={{
							color: theme.palette.primary.main,
							background: theme.palette.primary[100],

							'&:hover': {
								background: theme.palette.primary.light,
							},
						}}
					>
						<ChatGptLightIcon sx={{
							width: theme.spacing(2),
							height: theme.spacing(2),
							ml: theme.spacing(-.5),
							mr: theme.spacing(.5),
						}} />
						Library
					</Button>

					<Modal
						onClose={()=>setPromptsLibraryOpened(false)}
						open={isPromptsLibraryOpened}
						isMobile={isMobile}
					>
							
						<Stack
							gap={theme.spacing(1)}
							sx={{
								// px: theme.spacing(2),
								// py: theme.spacing(1),
							}}
						>
							<Box
								sx={{
									fontSize: theme.typography.h6,
									fontWeight: 600,
								}}
							>
								Prompts library
							</Box>

							<PromptsListHOC
								onSelected={()=>setPromptsLibraryOpened(false)}
							/>
						</Stack>
					</Modal>
				</Stack>
			}
		</Stack>

	);
};