import { AppThunk, IState } from '../../../../../store/types';
import { History } from 'history';
import {
  getActiveGroupId,
  getUserGroupById,
  getUserGroupLangToLearn,
  getUserGroups,
  getUserGroupWithLangCode
} from '../../../../../store/models/selectors';
import { ELanguageStatus } from '../../../../../store/general/types';
import { TUserGroup } from '../../../../../store/models/types';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { removeUserGroups } from '../../../../../../common/rest/userGroup/removeUserGroups';
import {
  removeGroupAction,
  updateGroupAction
} from '../../../../../store/models/actions';
import { updateUserGroup } from '../../../../../../common/rest/userGroup/updateUserGroup';
import { getLibraryGroupId } from '../../../../../store/general/selectors';
import { EExtMessageType, ExtMessageSender } from '../../../../../../common/utils/ext-message-sender';
import { UserGroupEffects } from '../../../../../effects/userGroupEffects';
import { LANG_CODE_AUTO_GEN_SUBSTR } from '../../../../../types/common';
import { PhraseListActions } from '../../../../../store/phrase-list/actions';
import { setEditPlaylistModalIdAction } from '../../../../../store/general/actions';

export class EditPlaylistItemEvents {

  public static onDelete(groupId: number, history: History): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      const state = getState();
      const group = getUserGroupById(state, groupId);

      if (group) {
        const delGroupIds = [group.id];
        await EditPlaylistItemEvents.removeGroup(dispatch, group.id);
        const targetLang = getUserGroupLangToLearn(state, group);
        if (targetLang) {
          if (targetLang.code.indexOf(LANG_CODE_AUTO_GEN_SUBSTR) < 0) {
            const autoGenTargetLangGroup = getUserGroupWithLangCode(state, ELanguageStatus.TO_LEARN, targetLang.code + LANG_CODE_AUTO_GEN_SUBSTR);
            if (autoGenTargetLangGroup) {
              await EditPlaylistItemEvents.removeGroup(dispatch, autoGenTargetLangGroup.id);
              delGroupIds.push(autoGenTargetLangGroup.id);
            }
          }
        }

        const groups: TUserGroup[] = getUserGroups(getState());
        if (groups && groups.length > 0) {
          UserGroupEffects.setLibraryGroupId(groups[0].id);
        } else {
          UserGroupEffects.setLibraryGroupId(0);
        }

        const activeGroupId = getActiveGroupId(state);
        const libGroupId = getLibraryGroupId(state);
        const activeGroupDeleted: boolean = Boolean(activeGroupId && delGroupIds.includes(activeGroupId));
        const libGroupDeleted: boolean = Boolean(libGroupId && delGroupIds.includes(libGroupId));
        if (libGroupDeleted && !activeGroupDeleted) {
          UserGroupEffects.setLibraryGroupId(activeGroupId || 0);
        }
        ExtMessageSender.send({
          type: EExtMessageType.SET_USER_GROUPS,
          payload: {userGroups: getUserGroups(getState())}
        });
        if (activeGroupDeleted) {
          history.push('/');
        }
      }

    }
  }

  private static async removeGroup(
    dispatch: ThunkDispatch<IState, unknown, Action<string>>,
    groupId: number)
  {
    await dispatch(removeUserGroups(groupId));
    dispatch(removeGroupAction(groupId));
    dispatch(PhraseListActions.removeByGroup(groupId));
  }

  public static onEdit(
    groupId: number,
  ): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      dispatch(setEditPlaylistModalIdAction(groupId));
    }
  }


}
