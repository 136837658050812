import { EVocabularyPhraseType } from '../../../../../types/common';
import React from 'react';
import { theme } from '../../../../../../common/styles/theme';
import { Box, Stack } from '@mui/material';
import {
	CaptionHighlightLayers,
	ECaptionHighlightLayerType,
	TCaptionHighlightLayer
} from '../../../../../effects/phrase/utils/caption-highlight-layers';
import { renderToStaticMarkup } from 'react-dom/server';
import { HighLightedSpan } from '../../../../../../common/components/HighLightedText/HighLightedSpan';
import _ from 'lodash';
import { SxProps } from '@mui/system';
import { ITargetCaptionItemProps } from './types';
import { PlayIcon } from '../../../../../../../icons/PlayIcon';
import { PauseIcon } from '../../../../../../../icons/PauseIcon';
import { HtmlUtils } from '../../../../../../common/utils/html-utils';
import { SelectionUtil } from '../../../../../../common/utils/selection-util';
import { DeviceContext } from '../../../../../../common/contexts/DeviceContext';
const md5 = require('md5');

const layerTypeBgColorMap = {
	[ECaptionHighlightLayerType.BG]: 'highlight-type_bg',
	[ECaptionHighlightLayerType.HIGHTLIGHT]: 'highlight-type_highlight',
	[ECaptionHighlightLayerType.WORD_SELECT]: 'highlight-type_word',
	[ECaptionHighlightLayerType.TRANSLATE_SELECT]: 'highlight-type_translate',
	[ECaptionHighlightLayerType.SELECTED_WORD]: 'highlight-type_word_preview',
	[ECaptionHighlightLayerType.SELECTED_CONTEXT]: 'highlight-type_highlight',
}

let marginHeight: number = -1;

const getMarginHeight = (elem: HTMLDivElement): number => {
	if (marginHeight >= 0) {
		return marginHeight;
	}
	marginHeight = HtmlUtils.getElementMaginsHeight(elem);
	return marginHeight;
}

enum ESelectMode {
	NONE, TEXT, CLICK
}

type TOnUpdateSelectModeCallback = (index: number, wordIndex: number) => void;

const onUpdateSelectMode = (mode: ESelectMode, callback: TOnUpdateSelectModeCallback, param: number, wordIndex: number) => {
	callback(param, wordIndex);
}
const updateSelectModeDebounce = _.debounce(onUpdateSelectMode , 300);

export const TargetCaptionItem: React.FC<ITargetCaptionItemProps> = ({
	index,
	isActive,
	startTime,
	endTime,
	captionText,
	phrases,
	phrasesHash,
	filterSelectContext,
	disableSelectMode,
	playerActive,
	onPlayPauseClick,
	onItemClick,
	onItemTextSelect,
	raiseMouseUpEvent,
	onMouseEnter,
	onMouseLeave,
	onMouseDown,
	onMouseUp,
	onPhraseHover,
	onSetHeight,
	wordStartSelIndex,
	wordEndSelIndex,
	selected
}) => {
	const {isTouch} = React.useContext(DeviceContext);
	const [html, setHtml] = React.useState<string>('');
	const [selectMode, setSelectMode] = React.useState<ESelectMode>(ESelectMode.NONE);
	const [clickWordIndex, setClickWordIndex] = React.useState<number>(-1);

	const rootElemRef = React.useRef<HTMLDivElement>(null);

	if (onSetHeight && rootElemRef.current) {
		const height = rootElemRef.current.offsetHeight;
		if (height) {
			const margin = getMarginHeight(rootElemRef.current);
			onSetHeight(index, height + margin);
		}
	}

	const getUnderscorePos = (layerMap: TCaptionHighlightLayer[]) => {
		let start = -1;
		let end = -1;
		layerMap.forEach((l, index) => {
			if (l.underscore) {
				if (start < 0)
					start = index;
				end = index;
			}
		})
		return [start, end];
	}

	React.useEffect(() => {

		const text = _.unescape(captionText).replace(/[\n\r]/gm, ' ');

		const layersMap = CaptionHighlightLayers.getLayers(startTime, endTime, captionText,
			index, phrases || [], filterSelectContext, wordStartSelIndex, wordEndSelIndex, selected);
		if (
			!layersMap ||
			!layersMap.length ||
			!text ||
			!text.length
		) {
			setHtml('');
			return;
		}
		let _html = '';
		const len = text.length;

		const selPhrases = (phrases || []).filter(pi => pi.phrase.type === EVocabularyPhraseType.WORD_AND_CONTEXT_SELECTED);

		const [startUnderscore, endUnderscore] = getUnderscorePos(layersMap);

		for (let i = 0; i < len; i++) {
			if (i < layersMap.length) {
				const layerType = layersMap[i]?.type;
				const wordActive = layersMap[i]?.wordActive;
				// const style: any = layerTypeBgColorMap[layerType] ? {...layerTypeBgColorMap[layerType]} : { backgroundColor: 'none' };

				//const phrase = selPhrases.map(pi => pi.phrase).find(p => i >= p.startPosition && i < p.endPosition);
				const phrase = selPhrases.map(pi => pi.phrase).find(p => {
					if (p.startCaptionIndex === p.endCaptionIndex) {
						return i >= p.startPosition && i < p.endPosition;
					} else if (p.startCaptionIndex === index) {
						return p.startPosition <= i;
					} else if (p.endCaptionIndex === index) {
						return p.endPosition >= i;
					} else {
						return true;
					}
				});

				// if (startUnderscore === i) {
				// 	_html += '<span style="text-decoration: underline dotted">';
				// }
				// if (wordActive) {
				// 	_html += '<span style="color: red">';
				// }
				const word = layerType === ECaptionHighlightLayerType.WORD_SELECT || layerType === ECaptionHighlightLayerType.SELECTED_WORD;
				let isEnd = word && i < len - 1 && layersMap[i+1]?.type !== ECaptionHighlightLayerType.WORD_SELECT;
				_html += renderToStaticMarkup(<HighLightedSpan
					className={layerTypeBgColorMap[layerType]}
					isEnd={i === len - 1 || isEnd}
					isStart={i === 0}
					// style={style}
					index={i}
					phraseId={phrase ? phrase.id : 0}
					word={word}
				>
					{text[i]}
				</HighLightedSpan>);

				// if (wordActive || endUnderscore === i) {
				// 	_html += '</span>';
				// }
			}
		}
		setHtml(_html);

	}, [/*phrases,*/ phrasesHash, wordStartSelIndex, wordEndSelIndex, selected]);

	React.useEffect(() => {
		if (raiseMouseUpEvent) {
			handleMouseUp();
		}
	}, [raiseMouseUpEvent])

	const elStyle = {
		position: 'relative',
		zIndex: 100,
		width: '100%',
		fontSize: theme.typography.subtitle2,
		lineHeight: theme.spacing(3.25),
		fontWeight: 400,

		'.caption-item.selected & .highlighted-span': {
			backgroundColor: '#cee2fd',
		},

		'.phrase-context-editor & .highlight-type_word, .caption-item.selected & .highlight-type_word': {
			backgroundColor: '#d7aeff',
		},

		'.highlight-type_bg' : {
			backgroundColor: '#cee2fd',
		},

		'.highlight-type_highlight' : {
			backgroundColor: '#cee2fd',
		},

		'.highlight-type_word' : {
			backgroundColor: '#ffdf8766',
		},

		'&:hover .highlight-type_word' : {
			backgroundColor: '#ffdf87',
		},

		'.highlight-type_word_preview' : {
			backgroundColor: '#d7aeff'
		},

		'&:hover .highlight-type_word_preview' : {
			backgroundColor: '#d7aeff'
		},

		'.phrase-context-editor & .highlight-type_word_preview, .caption-item.selected & .highlight-type_word_preview': {
			backgroundColor: '#d7aeff'
		},

		'.selected-onboarding': {
			position: 'absolute',
			zIndex: -1, // under text
			left: 0,
			top: 0,
			width: '100px',
			height: theme.spacing(3.25),
			transformOrigin: '0 0',
			pointerEvents: 'none',

			backgroundColor: '#d7aeff !important',
			animation: 'animation_captions_onboarding_selection 2s infinite',

			'@keyframes animation_captions_onboarding_selection': {
				'0%': { transform: 'scaleX(0)' },
				'60%': { transform: 'scaleX(1)', opacity: 1 },
				'75%, 100%': { opacity: 0 },
			},
		}
	};

	let boxStyle: SxProps = {
		position: 'relative',
		pt: theme.spacing(.5),
		pr: theme.spacing(1.5),
		pb: theme.spacing(.5),

		'&:hover .caption-item__play-icon': {
			opacity: .4,
		},

		'&.active': {
			'.caption-item__play': {
				background: `${theme.palette.primary[100]}`,
			},

			'.caption-item__play-icon': {
				opacity: 1,
			},
		},
	};

	let playStyle = {
		position: 'absolute',
		zIndex: 0,
		left: theme.spacing(-4.25),
		right: theme.spacing(.25),
		top: 0,
		bottom: theme.spacing(.25),
		borderRadius: theme.spacing(2),

		'.captions-selection-popup-show &': {
			display: 'none',
		}
	}

	let playIconStyle = {
		position: 'absolute',
		left: '1px',
		top: '3px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: theme.spacing(3.5),
		height: theme.spacing(3.5),
		color: `${theme.palette.common.white}`,
		background: `${theme.palette.primary.main}`,
		borderRadius: '50%',
		cursor: 'pointer',
		opacity: 0,
		transition: 'all .3s ease',

		'svg': {
			width: theme.spacing(1.3),
			height: theme.spacing(1.3),
		},

		'&:before': {
			content: '""',
			position: 'absolute',
			inset: '-5px',
		},

		'.caption-item:hover &:hover': {
			opacity: 1,
		},
	}

	const handlePlayPauseClick = () => {
		onPlayPauseClick(index);
	}

	const handleMouseDown = () => {
		onMouseDown(index);
	}

	React.useEffect(() => {
		if (selectMode === ESelectMode.CLICK) {
			updateSelectModeDebounce(selectMode, onItemClick, index, clickWordIndex);
		} else if (selectMode === ESelectMode.TEXT) {
			updateSelectModeDebounce(selectMode, onItemTextSelect, index, clickWordIndex);
		}
	}, [selectMode])

	const touchScroll = React.useRef<any>(null);
	const handleTouchStart = () => {
		const scroll = rootElemRef.current?.closest('.captions-parent');
		touchScroll.current = scroll ? scroll.scrollTop : 0;
	}

	const handleMouseUp = (event: MouseEvent) => {

		if (isTouch) {
			const scroll = rootElemRef.current?.closest('.captions-parent');
			let scrollNew = scroll ? scroll.scrollTop : 0;
			if (scrollNew - touchScroll.current !== 0) return; // scroll, not tap
		}

		const selText = SelectionUtil.getText();
		const wordIndex = event?.target?.getAttribute('data-word-index');
		setClickWordIndex(+wordIndex);

		if (selText) {
			setSelectMode(ESelectMode.TEXT)
		} else {
			setSelectMode(ESelectMode.CLICK)
		}
		setTimeout(() => {
			setSelectMode(ESelectMode.NONE)
		})
		onMouseUp(index);
	}


	const handleDoubleClick = () => {
		const selText = SelectionUtil.getText();
		if (selText) {
			setSelectMode(ESelectMode.TEXT)
		}
	}

	const handleMouseEnter = () => {
		onMouseEnter(index);
	}
	const handleMouseLeave = () => {
		onMouseLeave(index);
	}
	const raisePhraseHover = (phraseId: number) => {
		if (!disableSelectMode) {
			onPhraseHover(phraseId);
		}
	}
	const handleMouseMove = (e: MouseEvent) => {
		if (e.target) {
			// @ts-ignore
			const phraseId = parseInt(e.target.getAttribute('data-phrase-id'));
			// @ts-ignore
			const word: boolean = e.target.getAttribute('data-word') === '1';
			if (phraseId && word) {
				raisePhraseHover(phraseId);
			} else {
				raisePhraseHover(0);
			}
		}
		handleMouseEnter();
	}

	if (index === 1) {
	//	console.log(' -------------- text', html ? html : captionText || '');

	}

	return (
		<Stack
			id={'caption-item-'+index}
			ref={rootElemRef}
			className={`
			caption-item
			${isActive ? 'active' : ''}
		`}
			direction={'row'}
			sx={boxStyle}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			onMouseMove={handleMouseMove}
		>
			<Box
				className={`caption-item__play`}
				sx={playStyle}
			>
				<Box
					className={`caption-item__play-icon`}
					sx={playIconStyle}
					onClick={handlePlayPauseClick}
				>
					{playerActive
						? <PauseIcon />
						: <PlayIcon />
					}
				</Box>
			</Box>

			<Box
				className={'caption-item__letters'}
				sx={elStyle}
				data-start={startTime}
				data-end={endTime}
				dangerouslySetInnerHTML={{ __html: html ? html : captionText || ''}}
				onMouseDown={handleMouseDown}
				onMouseUp={handleMouseUp}
				onDoubleClick={handleDoubleClick}
				onTouchStart={handleTouchStart}
				onTouchEnd={handleMouseUp}
			/>
		</Stack>
	);

};