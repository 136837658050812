import { AppThunk } from '../../../../store/types';
import { isAuthorised } from '../../../../store/general/selectors';
import { getUserStateStorage } from '../../../../../common/utils/local-storage/user-state';
import { YoutubeUrlUtil } from '../../../../../common/utils/youtube-url-util';
import { createVideoEffect, TAddVideoResult } from '../../../../effects/video_effects';
import { Navigator } from '../../../../effects/navigator';
import { setSetAddVideoOnLoginAction } from '../../../../store/general/actions';

export class AddVideoUrlEvents {

  public static onLoad(videoUrl: string): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      const state = getState();
      let groupId;
      const userStorageData = getUserStateStorage().getData();
      if (userStorageData.libraryGroupId) {
        groupId = userStorageData.libraryGroupId;
      }
      if (isAuthorised(state)) {
        const result: TAddVideoResult = await dispatch(createVideoEffect(groupId, videoUrl));
        const addedGroupId: number = result.userGroupId;
        const userStorage = getUserStateStorage();
        userStorage.setVideoInfo(result.videoId, groupId, 0);
        userStorage.setLibraryGroupId(groupId);
        if (addedGroupId) {
          Navigator.openVideo(addedGroupId, result.videoId);
        }
      } else {
        dispatch(setSetAddVideoOnLoginAction(groupId, videoUrl));
        Navigator.openDefault();
      }
    }
  }

}