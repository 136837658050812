import { Button, Popover, Stack, Box } from "@mui/material";
import { theme } from "../../../../common/styles/theme";
import React from "react";
import { Text } from "../../../../common/components/Text/Text";
import { IConfirmPopupProps } from './types';

export const ConfirmPopup: React.FC<IConfirmPopupProps> = ({
    anchor,
    text,
    lines,
    confirmTitle,
    closeTitle,
    popoverProps,
    onCancel,
    onConfirm,
    onClose
}) => {
    const textList = text ? [text] : (lines || []);

    const onCancelHandler = () => {
        if (onCancel) onCancel();
        onClose();
    };

    return (
        <Popover
            {...popoverProps}
            open={!!anchor}
            anchorEl={anchor}
            anchorOrigin={popoverProps?.anchorOrigin || {
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={popoverProps?.transformOrigin || {
                vertical: 'top',
                horizontal: 'center',
            }}
            sx={{
                '& > .MuiPaper-root': {
                    borderRadius: theme.spacing(.75),
                },
            }}
            onClose={onClose}
        >
            <Stack spacing={theme.spacing()} sx={{ p: theme.spacing() }}>
                <Box>
                    {textList.map((text, index) => {
                        return <Text fontWeight={600} key={index}>{text}</Text>
                    })}
                </Box>
                <Stack
                    direction='row'
                    gap={theme.spacing()}
                    columnGap={theme.spacing()}
                    sx={{
                        flexWrap: 'wrap',
                    }}
                >
                    <Button
                        onClick={onCancelHandler}
                        variant={'contained'}
                        color={'info'}
                        sx={{ height: theme.spacing(4) }}
                    >
                        {closeTitle || 'No'}
                    </Button>
                    <Button
                        onClick={onConfirm}
                        variant={'contained'}
                        color={'primary'}
                        sx={{ height: theme.spacing(4) }}
                    >
                        {confirmTitle || 'Yes'}
                    </Button>
                </Stack>
            </Stack>
        </Popover>
    );
}
