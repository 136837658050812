import { AppThunk } from '../../../../store/types';
import { isAuthorised } from '../../../../store/general/selectors';
import { Navigator } from '../../../../effects/navigator';
import { setCopyGroupOnLoginAction } from '../../../../store/general/actions';
import { updateUserGroups } from '../../../../effects/updateUserGroups';
import { CopyGroupEffects } from '../../../../effects/copyGroupEffects';

export class CopyGroupEvents {

  public static onLoad(hash: string): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      const state = getState();
      if (isAuthorised(state)) {
        const groupId = await CopyGroupEffects.copy(hash);
        if (groupId) {
          await dispatch(updateUserGroups(true));
        }
      } else {
        dispatch(setCopyGroupOnLoginAction(hash));
        Navigator.openDefault();
      }
    }
  }

}