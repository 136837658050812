import React from 'react';
import { IInstallExtensionWarningProps } from './types';
import { theme } from '../../../../../common/styles/theme';
import { Text } from '../../../../../common/components/Text/Text';
import { Button, Stack } from '@mui/material';
import { ChromeStoreIcon } from '../../../../../../icons/ChromeStoreIcon';

export const InstallExtensionWarning: React.FC<IInstallExtensionWarningProps> = ({
  onCheckInstalled,
  onInstall,
}) => {
  const [show, setShow] = React.useState<boolean>(false);

  React.useEffect(() => {
    onCheckInstalled().then((result) => {
      setShow(!result);
    });
  }, []);

  if (!show) return null;

  const containerStyle = {
    p: theme.spacing(1.5, 2),
    background: theme.palette.warning[100],
    borderRadius: theme.spacing(0.75),
  };

  const textStyle = {
    color: theme.palette.warning[900],
    fontSize: theme.typography.subtitle2.fontSize,
  };

  return (
    <Stack spacing={theme.spacing(1.5)} sx={containerStyle}>
      <Stack direction={'row'} spacing={theme.spacing(1.5)} alignItems={'center'}>
        <ChromeStoreIcon sx={{ width: theme.spacing(4), height: theme.spacing(3.2) }} />
        <Text sx={textStyle} fontWeight={600} variant={'subtitle2'}>
          Lang.app Chrome Extension
        </Text>
      </Stack>
      <Text sx={textStyle}>
        Instantly add YouTube videos to your Lang.app playlist with one click. Elevate your language
        learning now!
      </Text>
      <Button onClick={onInstall} fullWidth variant={'contained'} color={'secondary'}>
        Install extension
      </Button>
    </Stack>
  );
};
