import { IState } from '../../../../store/types';
import { getMainViewMode } from '../../../../store/general/selectors';
import { connect } from 'react-redux';
import { ContentLibrary } from '../components/ContentLibrary/ContentLibrary';
import { IContentLibraryEvents, IContentLibraryFields } from '../components/ContentLibrary/types';
import { EMainViewMode } from '../../../../store/general/types';
import { ContentLibraryEvents } from './events';

const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): IContentLibraryFields => {
    return {
      isShow: getMainViewMode(state) === EMainViewMode.CONTENT_LIBRARY,
    }
  };
}

const mapDispatchToProps = (
  dispatch: any
): IContentLibraryEvents => ({
  }
);

export const ContentLibraryHOC = connect(
  mapStateToProps,
  null
)(ContentLibrary);

ContentLibraryHOC.displayName = 'ContentLibraryHOC';
