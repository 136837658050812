import React from 'react';
import { Stack } from '@mui/material';
import { IOpenPublicProfilePopupProps } from './types';
import { Text } from '../../../../../common/components/Text/Text';
import { theme } from "../../../../../common/styles/theme";
import { Snackbar } from "../../../../../common/components/Snackbar/Snackbar";
import { Button } from "@mui/material";

export const OpenPublicProfilePopup: React.FC<IOpenPublicProfilePopupProps> = ({
    show,
    onClose,
    onOpen,
}) => {
  const snackbarStyle = {
    bottom: `${theme.spacing(2)} !important`,
    left: `${theme.spacing(2)} !important`,
    m: `0 !important`
  };

  if (!show) return null;

  return (
    <Snackbar
      onClose={onClose}
      open={true}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      sx={snackbarStyle}
    >
      <Stack spacing={theme.spacing(2)} direction={'column'}>
        <Text
            fontWeight={600}
            variant={'subtitle2'}
            sx={{ color: theme.palette.common.white, pr: theme.spacing(4) }}
        >
            Your public profile has been created
        </Text>
          <Button onClick={onOpen} variant={'contained'} color={'primary'}>Open Public Profile</Button>
      </Stack>
    </Snackbar>
  );
}
