import { TCreateCopyResponse, UserGroupCopyRest } from '../../common/rest/userGroupCopy/userGroupCopyRest';
import { getHomeUrl } from '../../common/utils/getHomeUrl';
import { getDispatch } from '../store';
import { setSnackbarPanelAction } from '../store/general/actions';

export class CopyGroupEffects {

  private static COPY_SUB_URL = 'copyGroup';

  public static async createCopyLink(groupId: number): Promise<string> {
    const hash = await UserGroupCopyRest.createCopyLink({
      groupId
    })
    return CopyGroupEffects.getCopyBaseUrl() + hash;
  }

  public static async copy(hash: string): Promise<TCreateCopyResponse> {
    const result = await UserGroupCopyRest.createCopy(hash);
    getDispatch()(setSnackbarPanelAction(true, 'Playlist has been copied'));
    return result;
  }

  public static async copyPublic(groupId: number): Promise<TCreateCopyResponse> {
    const result = await UserGroupCopyRest.createPublicCopy(groupId);
    getDispatch()(setSnackbarPanelAction(true, 'Playlist has been copied'));
    return result;
  }

  private static getCopyBaseUrl() {
    return getHomeUrl() + '/' + CopyGroupEffects.COPY_SUB_URL + '/';
  }
}