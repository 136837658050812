import React from 'react';
import { IProfilesListProps } from './types';
import { Avatar, Box, MenuItem, Stack } from '@mui/material';
import { theme } from '../../../../../common/styles/theme';

export const ProfilesList: React.FC<IProfilesListProps> = ({
	profiles,
	onClickProfile,
}) => {

	return (!profiles || !profiles.length
		? <Box
			sx={{
				py: theme.spacing(1),
				px: theme.spacing(2),
			}}
		>
			No results
		</Box>
		: <>
			{profiles.map((profile, index) => {
				return (
					<MenuItem
						key={String(profile.userName) + index}
						sx={{
							gap: theme.spacing(1),
							pr: theme.spacing(1),
						}}
						onClick={()=>onClickProfile(profile.userName || '')}
					>
						<Avatar
							src={profile.avatar}
							alt={profile.userName}
							sx={{
								width: theme.spacing(3),
								height: theme.spacing(3),
							}}
						/>
						<Stack
							sx={{
								minWidth: 0,
							}}
						>
							<Box
								sx={{
									width: 1,
									overflow: 'hidden',
									textOverflow: 'ellipsis',
									lineHeight: 1.2,
								}}
							>
								{profile.name}
							</Box>
							<Box
								sx={{
									width: 1,
									overflow: 'hidden',
									textOverflow: 'ellipsis',
									fontSize: '10px',
									color: theme.palette.grey[400],
								}}
							>
								@{profile.userName}
							</Box>
						</Stack>
					</MenuItem>
				);
			})}
		</>
	);
};
