import { AppThunk } from '../../../../../store/types';
import { setSavedVideoConfirmAction } from '../../../../../store/general/actions';
import { ESavedVideoConfirmShowMode } from '../../../../../store/general/types';
import { getSavedVideoConfirm } from '../../../../../store/general/selectors';
import { moveVideoToGroupAction } from '../../../../../store/models/actions';
import { videoMoveToGroup } from '../../../../../../common/rest/video/videoMoveToGroup';

export class SavedVideoConfirmSelectGroupEvents {

  public static onSave(groupId: number): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      const saveConfirm = getSavedVideoConfirm(getState());
      if (saveConfirm.videoId && saveConfirm.groupId) {
        await dispatch(videoMoveToGroup(saveConfirm.videoId, saveConfirm.groupId, groupId));
        dispatch(moveVideoToGroupAction(saveConfirm.videoId, saveConfirm.groupId, groupId));
        dispatch(setSavedVideoConfirmAction({showMode: ESavedVideoConfirmShowMode.HIDE}));
      }

    }
  }

}