import React from 'react';
import { Box, IconButton, InputAdornment, Menu, Stack, TextField, Tooltip } from '@mui/material';
import { theme } from '../../../../../../common/styles/theme';
import { Paper } from '../../../../../../common/components/Paper/Paper';
import { IContentLibraryHeaderProps } from './types';
import { BookIcon } from '../../../../../../../icons/BookIcon';
import { VideoIcon } from '../../../../../../../icons/VideoIcon';
import { AudioIcon } from '../../../../../../../icons/AudioIcon';
import { TextIcon } from '../../../../../../../icons/TextIcon';
import { Tabs } from '../../../../../../common/components/Tabs/Tabs';
import { Tab } from '../../../../../../common/components/Tab/Tab';
import { GridIcon } from '../../../../../../../icons/GridIcon';
import { ListIcon } from '../../../../../../../icons/ListIcon';
import { SearchIcon } from '../../../../../../../icons/SearchIcon';
import { LangSelectHOC } from '../../../LangSelect/hocs/LangSelectHOC';
import { EContentLibViewMode } from '../../../../../store/contentLib/types';
import LangFlag from '../../../LangFlag/LangFlag';
import { ButtonIconGrey } from '../../../../../../common/components/ButtonIconGrey/ButtonIconGrey';
import { ArrowDropIcon } from '../../../../../../../icons/ArrowDropIcon';
import { DeviceContext } from '../../../../../../common/contexts/DeviceContext';
import { ArrowLightLeftIcon } from '../../../../../../../icons/ArrowLightLeftIcon';
import { Logo } from '../../../../../../../icons/Logo';
import { HelpIcon } from '../../../../../../../icons/HelpIcon';
import { ContentLibLangSelectHOC } from '../../ContentLibLangSelect/hocs/ContentLibLangSelectHOC';

export function ContentLibraryHeader({
	viewMode,
	text,
	targetLangCode,
	nativeLangCode,
	selectedGroupTitle,
	onChangeText,
	onChangeTargetLangCode,
	onChangeNativeLangCode,
	onChangeViewMode,
	onBack,
	onClickHelp,
}: IContentLibraryHeaderProps) {

	const className = 'content-library-header';

	const {isMobile} = React.useContext(DeviceContext);

	const [langsEl, setLangsEl] = React.useState<null | HTMLElement>(null);
	const [searchText, setSearchText] = React.useState<string>('');
	const [searchChangedText, setSearchChangedText] = React.useState<string>('');

	const langsOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
		setLangsEl(event.currentTarget);
	};
	const langsClose = () => {
		setLangsEl(null);
	};

	const onSearchTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchText(e.target?.value || '');
	}

	const onSearchBlur = () => {
		setSearchChangedText(searchText);
	}

	const onSearchKeyPress = (event: React.KeyboardEvent) => {
		if ('Enter' === event.key) {
			setSearchChangedText(searchText);
		}
	}

	React.useEffect(() => {
		setSearchText(text);
	}, [text])

	React.useEffect(() => {
		onChangeText(searchChangedText);
	}, [searchChangedText])

	return (
		<Paper
			className={className}
			sx={{
				position: 'relative',
				zIndex: 100,
				flex: `0 0 ${theme.spacing(8)}`,
				py: 1,
				px: 2,
				borderRadius: 0,
				boxShadow: theme.shadows[8],

				'.mobile &': {
					p: 1,
				}
			}}
		>
			<Stack
				className={selectedGroupTitle ? 'group-selected' : ''}
				direction={'row'}
				alignItems={'center'}
				gap={theme.spacing(2)}
				sx={{
					width: 1,
					height: 1,

					'.mobile &': {
						gap: 1,
						justifyContent: 'space-between',
					},

					[`&.group-selected .${className}__title`]: {
						flex:  '1 1 100%',
						minWidth: 0,
					},
				}}
			>
				<Stack
					direction={'row'}
					alignItems={'center'}
					gap={1}
				>
					<ButtonIconGrey
						sx={{
							'.MuiSvgIcon-root': {
								width: theme.spacing(3),
								height: theme.spacing(3),
							},
						}}
						onClick={onBack}
					>
						<ArrowLightLeftIcon />
					</ButtonIconGrey>

					<IconButton
						sx={{
							p: 0,

							'@media (max-width: 370px)': {
								display: 'none',
							}
						}}
						onClick={onBack}
					>
						<Logo sx={{ height: theme.spacing(4), width: 'auto' }} />
					</IconButton>

					<ButtonIconGrey
						sx={{
							'.MuiSvgIcon-root': {
								width: theme.spacing(2.5),
								height: theme.spacing(2.5),
							},
						}}
						title={'Help'}
						onClick={onClickHelp}
					>
						<HelpIcon />
					</ButtonIconGrey>
				</Stack>

				{!isMobile &&
					<Stack
						className={`${className}__title`}
						direction={'row'}
						alignItems={'center'}
						gap={theme.spacing(1)}
						sx={{
							ml: theme.spacing(2),
							fontSize: theme.typography.h4,
							fontWeight: 600,
							whiteSpace: 'nowrap',
						}}
					>
						<BookIcon
							sx={{
								color: theme.palette.grey[400],
							}}
						/>
						<Box
							sx={{
								flex: `1 1 100%`,
								minWidth: 0,	
								overflow: 'hidden',
								textOverflow: 'ellipsis',
							}}
						>
							Content Library
							{selectedGroupTitle && ': ' + selectedGroupTitle}
						</Box>
					</Stack>
				}

				{!isMobile && !selectedGroupTitle &&
					<Tabs
						className={`${className}__tabs`}
						sx={{
							p: theme.spacing(.5, .75),
							bgcolor: theme.palette.grey[50],
							overflow: 'visible',

							'.MuiTabs-flexContainer': {
								gap: theme.spacing(.75),
							},

							'.MuiTab-root': {
								minWidth: theme.spacing(5),
								minHeight: theme.spacing(5),
								padding: theme.spacing(0, 1),
								fontSize: theme.typography.subtitle2,
								cursor: 'default', // while audio & text disabled

								'.MuiSvgIcon-root': {
									opacity: .3,
								},

								'&.Mui-selected': {
									'.MuiSvgIcon-root': {
										color: theme.palette.primary.main,
										opacity: .9,
									}
								}
							},
						}}
						value={0}
						onChange={(e, value) => {}}
					>
						<Tooltip
							title={'Videos'}
						>
							<Tab
								icon={<VideoIcon />}
								iconPosition={'start'}	
							/>
						</Tooltip>
						<Tooltip
							title={'Audiobooks'}
						>
							<Tab
								icon={<AudioIcon />}
								iconPosition={'start'}
							/>
						</Tooltip>
						<Tooltip
							title={'Text'}
						>
							<Tab
								icon={<TextIcon />}
								iconPosition={'start'}
							/>
						</Tooltip>
					</Tabs>
				}
				{!isMobile && !selectedGroupTitle &&
					<TextField
						sx={{
							flex: '1 1 100%',

							'.MuiInputBase-root': {
								height: theme.spacing(6),
								bgcolor: theme.palette.grey[50],
							},

							'.MuiInputAdornment-root': {
								opacity: .3,
							},
						}}
						variant={'filled'}
						hiddenLabel
						InputProps={{
							startAdornment: (
								<InputAdornment position={'start'}>
									<SearchIcon />
								</InputAdornment>
							),
						}}
						placeholder={'Search...'}
						value={searchText}
						onBlur={onSearchBlur}
						onKeyPress={onSearchKeyPress}
						onChange={onSearchTextChange}
					/>
				}

				{!selectedGroupTitle && <>
					<ButtonIconGrey
						sx={{
							minWidth: theme.spacing(16),
							height: theme.spacing(6),
							bgcolor: theme.palette.grey[50],

							'.mobile &': {
								height: theme.spacing(5),
							},
						}}
						onClick={langsOpen}
					>
						<Stack
							direction='row'
							spacing={theme.spacing(1)}
							alignItems={'center'}
						>
							<LangFlag code={targetLangCode} showCode={true}/>
							<LangFlag code={nativeLangCode} showCode={true}/>
							<ArrowDropIcon />
						</Stack>
					</ButtonIconGrey>

					<Menu
						anchorEl={langsEl}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'right',
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
						open={!!langsEl}
						onClose={langsClose}
						sx={{
							'.MuiPaper-root': {
								width: theme.spacing(30),
								boxShadow: theme.shadows[16],
							},

							'.MuiList-root': {
								p: theme.spacing(2),
							},
						}}
					>
						<Stack
							gap={theme.spacing(2)}
						>
							{/*<LangSelectHOC
								label={'Target language'}
								onChange={onChangeTargetLangCode}
								langCode={targetLangCode}
							/>
							<LangSelectHOC
								label={'Native language'}
								onChange={onChangeNativeLangCode}
								langCode={nativeLangCode}
							/>*/}
							<ContentLibLangSelectHOC
								label={'Target language'}
								targetLangs={true}
								onChange={onChangeTargetLangCode}
								langCode={targetLangCode}
							/>
							<ContentLibLangSelectHOC
								label={'Native language'}
								targetLangs={false}
								onChange={onChangeNativeLangCode}
								langCode={nativeLangCode}
							/>

						</Stack>
					</Menu>

					<Tabs
						sx={{
							p: theme.spacing(.5, .75),
							bgcolor: theme.palette.grey[50],
							overflow: 'visible',

							'.MuiTabs-flexContainer': {
								gap: theme.spacing(.75),
							},

							'.MuiTab-root': {
								minWidth: theme.spacing(5),
								minHeight: theme.spacing(5),
								padding: theme.spacing(0, 1),

								'.MuiSvgIcon-root': {
									opacity: .6,
								},

								'&.Mui-selected': {
									'.MuiSvgIcon-root': {
										color: theme.palette.primary.main,
										opacity: 1,
									}
								},

								'.mobile &': {
									minHeight: theme.spacing(4),
								},

								[theme.breakpoints.down('sm')]: {
									minWidth: theme.spacing(4),
									maxWidth: theme.spacing(4),
									pl: theme.spacing(.75),
									justifyContent: 'flex-start',
								}
							},
						}}
						value={viewMode}
						onChange={(e, value) => {
							onChangeViewMode(value);
						}}
					>
						<Tab
							value={EContentLibViewMode.VIDEO_LIST}
							icon={<GridIcon />}
							iconPosition={'start'}
							label={'Videos'}
						/>
						<Tab
							value={EContentLibViewMode.GROUP_LIST}
							icon={<ListIcon />}
							iconPosition={'start'}
							label={'Playlists'}
						/>
					</Tabs>
				</>}

			</Stack>
		</Paper>
	);
};
